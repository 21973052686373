import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import QuestionIcon from 'assets/icons/QuestionIcon';
import Popover from '../Popover';
import DownIcon from 'assets/icons/DownIcon';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import { F_INNER, TABLE_INNER_TYPE } from 'constants';
import Table from '../Table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import LoadingSpin from '../LoadingSpin';
import { DeliverySearchAllInfo } from './DeliverySearchAllInfo';
import { useDeliveryWarehouses } from '../../hooks/useDeliveryWarehouses';
import { DeliveryWarehouseSelector } from './DeliveryWarehouseSelector';
import { useHistory, useLocation } from 'react-router';
import { useUI } from 'context/ui.context';
import MobileDatePicker from './MobileDatePicker';

const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
};

const commonProps = {
  locale,
  dateFormat: 'dd.MM.yyyy',
  wrapperClassName: 'calendar-input-wrapper w-fit after:!w-0',
  calendarStartDay: 1,
};

const getDate = (date) => {
  if (!date) return '';
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}`;
};

const getPrevWeekDates = () => {
  for (let i = 0; ; i++) {
    const checkDate = new Date(new Date().setDate(new Date().getDate() - i));
    if (i >= 7 && checkDate.getDay() === 1) {
      return [checkDate, new Date(new Date().setDate(new Date().getDate() - (i - 6)))];
    }
  }
};

const DeliverySearch = () => {
  const { isMobile } = useUI();
  const [startDefaultDate, endDefaultDate] = getPrevWeekDates();
  const {
    filter,
    setDeliveryDate: setFilterDeliveryDate,
    setDeliveryStockDate: setFilterDeliveryLoadDate,
  } = useFilterReducer();
  const [deliveryDate, setDeliveryDate] = useState(filter[F_INNER.DELIVERY_DATE]);
  const [deliveryLoadDate, setDeliveryLoadDate] = useState(filter[F_INNER.DELIVERY_STOCK_DATE]);
  const [deliveryDateExpanded, setDeliveryCalendarDateExpanded] = useState(false);
  const deliveryDateRef = useRef();
  const [deliveryPeriodStartDate, setDeliveryPeriodStartDate] = useState(startDefaultDate);
  const [deliveryPeriodStartDateExpanded, setDeliveryPeriodStartDateExpanded] = useState(false);
  const [deliveryPeriodEndDate, setDeliveryPeriodEndDate] = useState(endDefaultDate);
  const [deliveryPeriodEndDateExpanded, setDeliveryPeriodEndDateExpanded] = useState(false);
  const deliveryPeriodDateRef = useRef();
  const [deliveryLoadDateExpanded, setDeliveryLoadCalendarDateExpanded] = useState(false);
  const [emptyDates, setEmptyDates] = useState([]);
  const [tableAvailble, setTableAvailble] = useState(false);
  const [activeWarehouseTab, setActiveWarehouseTab] = useState('all');
  const [datesTouched, setDatesTouched] = useState(false);

  const location = useLocation();
  const { search } = location;
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const urlDeliveryDate = searchParams.get('deliveryDate');
    if (urlDeliveryDate && setDeliveryDate) {
      const splitted = urlDeliveryDate.split('-');
      const newValue = new Date(+splitted[0], +splitted[1] - 1, +splitted[2]);
      if (!deliveryDate || newValue?.getTime() !== deliveryDate?.getTime()) {
        setDeliveryDate(newValue);
      }
    }
    const urlDeliveryLoadDate = searchParams.get('deliveryLoadDate');
    if (urlDeliveryLoadDate && setDeliveryLoadDate) {
      const splitted = urlDeliveryLoadDate.split('-');
      const newValue = new Date(+splitted[0], +splitted[1] - 1, +splitted[2]);
      if (!deliveryLoadDate || newValue?.getTime() !== deliveryLoadDate?.getTime()) {
        setDeliveryLoadDate(newValue);
      }
    }
    const urlActiveTab = searchParams.get('activeTab');
    if (urlActiveTab) setActiveWarehouseTab(urlActiveTab === 'all' ? urlActiveTab : +urlActiveTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const getSearchParams = useCallback(() => {
    return { deliveryDate: getDate(deliveryDate), deliveryLoadDate: getDate(deliveryLoadDate) };
  }, [deliveryDate, deliveryLoadDate]);

  const extFilter = [
    {
      field: 'period',
      values: {
        min: getDate(deliveryPeriodStartDate),
        max: `${getDate(deliveryPeriodEndDate)} 23:59:59`,
      },
    },
  ];
  if (activeWarehouseTab !== 'all') {
    extFilter.push({
      field: 'internal_office_id',
      values: {
        in: [activeWarehouseTab],
      },
    });
  }
  const {
    data = {},
    refetch,
    isLoading,
    isRefetching,
  } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.DELIVERY,
    enabled: tableAvailble && !datesTouched,
    extFilter,
    data: {
      date_delivery: getDate(deliveryDate),
      date_stock_out: getDate(deliveryLoadDate),
    },
  });

  const { sum_data = {}, table_data = [] } = data?.pages?.[0] || {};
  const { data: warehouses = [], isLoading: isWarehousesLoading } = useDeliveryWarehouses();
  const deliveryLoadDateRef = useRef();
  const removeError = (key) => {
    const index = emptyDates.indexOf(key);
    if (index !== -1) {
      const newArr = [...emptyDates];
      newArr.splice(index, 1);
      setEmptyDates(newArr);
    }
  };
  const handleDeliveryDate = (value) => {
    removeError('deliveryDate');
    setDatesTouched(true);
    setDeliveryDate(value);
  };

  const handleCloseMobile = () => {
    setDeliveryCalendarDateExpanded(false);
    setDeliveryPeriodStartDateExpanded(false);
    setDeliveryPeriodEndDateExpanded(false);
    setDeliveryLoadCalendarDateExpanded(false);
  };

  const handleCalculate = () => {
    const errors = [];
    if (!deliveryDate) {
      errors.push('deliveryDate');
    }
    if (!deliveryPeriodStartDate) {
      errors.push('deliveryPeriodStartDate');
    }
    if (!deliveryPeriodEndDate) {
      errors.push('deliveryPeriodEndDate');
    }
    if (!deliveryLoadDate) {
      errors.push('deliveryLoadDate');
    }
    if (errors.length === 0) {
      setFilterDeliveryDate(deliveryDate);
      setFilterDeliveryLoadDate(deliveryLoadDate);
      const oldSearch = new URLSearchParams(window.location.search);
      const params = new URLSearchParams({
        ...getSearchParams(),
        activeTab: activeWarehouseTab,
        filter: oldSearch.get('filter'),
        restricts: oldSearch.get('restricts'),
      });
      history.replace({ pathname: location.pathname, search: params.toString() });
      refetch();
      setTableAvailble(true);
      setDatesTouched(false);
    } else {
      setEmptyDates(errors);
    }
  };
  const errorInputClass = '!border-red';

  return (
    <div>
      <div className="mx-4 border-t border-gray-230 pt-3 pb-4">
        <div className="block space-y-2.5 lg:flex lg:divide-x lg:space-y-0">
          <div className="justify-between flex items-center">
            <div className="flex items-center">
              <span className="block text-sm leading-4 text-gray-700 whitespace-nowrap mr-2">
                Дата поставки
              </span>
              <div ref={deliveryDateRef} className="block text-gray-300 mr-2">
                <QuestionIcon width={15} height={15} />
              </div>
            </div>
            <Popover
              tooltipRef={deliveryDateRef}
              className="bg-opacity-100 whitespace-nowrap"
              tooltip="Укажите дату, когда планируете сделать поставку"
            />
            <div>
              <DatePicker
                {...commonProps}
                selected={deliveryDate}
                onChange={handleDeliveryDate}
                placeholderText=""
                className={cn(
                  'calendar-input calendar-input button-group__item button-group__item--first max-h-8 rounded lg:mr-3 after:!w-0',
                  {
                    [errorInputClass]: emptyDates.includes('deliveryDate'),
                  },
                )}
                onCalendarOpen={() => setTimeout(() => setDeliveryCalendarDateExpanded(true), 10)}
                onCalendarClose={() => !isMobile && setDeliveryCalendarDateExpanded(false)}
                minDate={new Date()}
                calendarClassName={cn('float-none calendar-input-overlay', {
                  ['hidden lg:block calendar-input-overlay__show']: deliveryDateExpanded,
                  ['calendar-input-overlay__hide']: !deliveryDateExpanded,
                })}
              />
            </div>
          </div>
          <div className="flex items-center lg:px-3">
            <div className="flex justify-between w-full">
              <div className="flex items-center">
                <span className="block text-sm leading-4 text-gray-700 whitespace-nowrap mr-2">
                  Период расчета
                </span>
                <div ref={deliveryPeriodDateRef} className="block text-gray-300 mr-2">
                  <QuestionIcon width={15} height={15} />
                </div>
                <Popover
                  tooltipRef={deliveryPeriodDateRef}
                  className="bg-opacity-100 whitespace-nowrap"
                  tooltip={`WeCheck проанализирует ваши продажи за выбранный период.<br/>Рассчитав средние продажи за указанный период, сервис спланирует поставку`}
                />
              </div>
              <div className="flex items-center">
                <DatePicker
                  {...commonProps}
                  selected={deliveryPeriodStartDate}
                  onChange={(value) => {
                    removeError('deliveryPeriodStartDate');
                    setDatesTouched(true);
                    setDeliveryPeriodStartDate(value);
                  }}
                  placeholderText=""
                  className={cn(
                    'calendar-input calendar-input--left button-group__item button-group__item--first max-h-8 max-w-[108px]',
                    {
                      [errorInputClass]: emptyDates.includes('deliveryPeriodStartDate'),
                    },
                  )}
                  onCalendarOpen={() =>
                    setTimeout(() => setDeliveryPeriodStartDateExpanded(true), 10)
                  }
                  onCalendarClose={() => !isMobile && setDeliveryPeriodStartDateExpanded(false)}
                  maxDate={deliveryPeriodEndDate || new Date()}
                  calendarClassName={cn('float-none calendar-input-overlay', {
                    ['hidden lg:block calendar-input-overlay__show']:
                      deliveryPeriodStartDateExpanded,
                    ['calendar-input-overlay__hide']: !deliveryPeriodStartDateExpanded,
                  })}
                />
                <div className="border-y border-[#D9D9D9] py-2 pr-[1px] h-8">
                  <div className="w-px h-4 bg-[#D9D9D9]" />
                </div>
                <DatePicker
                  {...commonProps}
                  selected={deliveryPeriodEndDate}
                  onChange={(value) => {
                    removeError('deliveryPeriodEndDate');
                    setDatesTouched(true);
                    setDeliveryPeriodEndDate(value);
                  }}
                  placeholderText=""
                  className={cn(
                    'calendar-input calendar-input--right button-group__item button-group__item--first max-h-8 rounded-r max-w-[108px] rounded-l-none !pr-3',
                    {
                      [errorInputClass]: emptyDates.includes('deliveryPeriodEndDate'),
                    },
                  )}
                  onCalendarOpen={() =>
                    setTimeout(() => setDeliveryPeriodEndDateExpanded(true), 10)
                  }
                  onCalendarClose={() => !isMobile && setDeliveryPeriodEndDateExpanded(false)}
                  minDate={deliveryPeriodStartDate || undefined}
                  maxDate={new Date()}
                  calendarClassName={cn('float-none calendar-input-overlay', {
                    ['hidden lg:block calendar-input-overlay__show']: deliveryPeriodEndDateExpanded,
                    ['calendar-input-overlay__hide']: !deliveryPeriodEndDateExpanded,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center lg:pl-3">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <span className="block text-sm leading-4 text-gray-700 whitespace-nowrap mr-2">
                  Загрузка склада до
                </span>
                <div ref={deliveryLoadDateRef} className="block text-gray-300 mr-2">
                  <QuestionIcon width={15} height={15} />
                </div>
                <Popover
                  tooltipRef={deliveryLoadDateRef}
                  className="bg-opacity-100 whitespace-nowrap"
                  tooltip="Укажите до какой даты необходимо обеспечить наличие товаров на складе"
                />
              </div>
              <div>
                <DatePicker
                  {...commonProps}
                  selected={deliveryLoadDate}
                  onChange={(value) => {
                    removeError('deliveryLoadDate');
                    setDatesTouched(true);
                    setDeliveryLoadDate(value);
                  }}
                  placeholderText=""
                  className={cn(
                    'calendar-input button-group__item button-group__item--first max-h-8 rounded lg:mr-3',
                    {
                      [errorInputClass]: emptyDates.includes('deliveryLoadDate'),
                    },
                  )}
                  onCalendarOpen={() =>
                    setTimeout(() => setDeliveryLoadCalendarDateExpanded(true), 10)
                  }
                  onCalendarClose={() => !isMobile && setDeliveryLoadCalendarDateExpanded(false)}
                  minDate={new Date()}
                  calendarClassName={cn('float-none calendar-input-overlay', {
                    ['hidden lg:block calendar-input-overlay__show']: deliveryLoadDateExpanded,
                    ['calendar-input-overlay__hide']: !deliveryLoadDateExpanded,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="w-full pt-2 border-t border-[#E7EDF0] lg:border-t-0 lg:pt-0 lg:w-fit lg:pl-3">
            <button
              className="w-full lg:w-fit justify-center flex bg-green border border-white rounded text-white p-[7px] relative lg:justify-end items-center focus:outline-none hover:bg-white hover:text-green hover:border-green"
              onClick={() => handleCalculate()}
            >
              <span className="block mr-2">Рассчитать поставку</span>
              <DownIcon width={12} height={12} className="-rotate-90 relative top-[1px]" />
            </button>
          </div>
        </div>
      </div>
      <>
        {tableAvailble && (
          <>
            {isLoading || isRefetching || isWarehousesLoading ? (
              <div className="h-[108px] bg-[#F0F0F0]">
                <LoadingSpin />
              </div>
            ) : (
              <DeliverySearchAllInfo sum_data={sum_data} />
            )}
            <DeliveryWarehouseSelector
              warehouses={warehouses}
              activeTab={activeWarehouseTab}
              setActiveTab={(value) => {
                const oldSearch = new URLSearchParams(window.location.search);
                const params = new URLSearchParams({
                  ...getSearchParams(),
                  activeTab: value,
                  filter: oldSearch.get('filter'),
                  restricts: oldSearch.get('restricts'),
                });
                history.replace({ pathname: location.pathname, search: params.toString() });
                setActiveWarehouseTab(value);
              }}
            />
            <Table
              data={table_data}
              type={TABLE_INNER_TYPE.DELIVERY}
              paginationOnBack={true}
              isLoading={isLoading || isRefetching || isWarehousesLoading}
              noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
            />
          </>
        )}
      </>
      {isMobile &&
        (deliveryDateExpanded ||
          deliveryLoadDateExpanded ||
          deliveryPeriodStartDateExpanded ||
          deliveryPeriodEndDateExpanded) && (
          <>
            <MobileDatePicker
              deliveryDate={deliveryDate}
              deliveryStartDate={deliveryPeriodStartDate}
              deliveryEndDate={deliveryPeriodEndDate}
              deliveryLoadDate={deliveryLoadDate}
              deliveryDateExpanded={deliveryDateExpanded}
              deliveryPeriodExpanded={
                deliveryPeriodStartDateExpanded || deliveryPeriodEndDateExpanded
              }
              deliveryLoadDateExpanded={deliveryLoadDateExpanded}
              onClose={handleCloseMobile}
              onSubmit={(value) => {
                if (deliveryDateExpanded) {
                  handleDeliveryDate(value);
                } else if (deliveryLoadDateExpanded) {
                  removeError('deliveryLoadDate');
                  setDatesTouched(true);
                  setDeliveryLoadDate(value);
                } else if (deliveryPeriodStartDateExpanded || deliveryPeriodEndDateExpanded) {
                  removeError('deliveryPeriodStartDate');
                  setDeliveryPeriodStartDate(value[0]);
                  removeError('deliveryPeriodEndDate');
                  setDatesTouched(true);
                  setDeliveryPeriodEndDate(value[1]);
                }
                handleCloseMobile();
              }}
            />
          </>
        )}
    </div>
  );
};

export default DeliverySearch;

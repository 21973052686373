import PropTypes from 'prop-types';
import { CrossIcon } from '../../assets/images';
import cn from 'classnames';

const InsertGroup = ({
  list,
  title,
  setList,
  placeholder = 'Введите ссылки на товарные позиции WB или SKU',
  external,
  id,
}) => {
  const handleClear = () => {
    setList(['', '', '', '', '']);
  };
  const handleChange = (index, value) => {
    const resultList = [...list];
    resultList[index] = value;
    if (!resultList.includes('')) {
      resultList.push('');
    }
    if (value === '' && resultList.length > 5) {
      for (let i = resultList.length - 1; i >= 0; i--) {
        if (resultList[i] === '' && i !== index) {
          resultList.splice(i, 1);
          break;
        }
      }
    }
    setList(resultList);
  };
  return (
    <div>
      <div className="flex items-center">
        <span className="block text-sm">{title}</span>
        {list.filter((el) => el).length !== 0 && (
          <button
            className="text-xs leading-[19px] text-green border-l border-[#E7EDF0] ml-[9px] pl-[9px] h-5"
            onClick={handleClear}
          >
            Очистить
          </button>
        )}
      </div>
      <div
        className={cn('rounded-lg border border-gray-250 px-4  mt-[9px] flex', {
          'w-[415px]': !external,
          'w-fit': external,
        })}
      >
        <div
          className={cn('block relative divide-y divide-gray-100', {
            'w-full': !external,
            'w-[365px]': external,
          })}
        >
          {list.map((el, index) => (
            <div className="flex items-center relative" key={`input_${index}`}>
              <input
                id={`input_${index}_${id}`}
                value={list?.[index] || ''}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    const nextInput = document.getElementById(`input_${index + 1}_${id}`);
                    if (nextInput) {
                      nextInput.focus();
                    }
                  }
                }}
                type="text"
                autoFocus={list.length === index}
                placeholder={placeholder}
                className="pr-10 -mx-4 py-3 px-4 rounded-lg transition-all flex-1 leading-4 placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
              />
              {el && (
                <button
                  className="absolute right-0 z-20 !text-green"
                  onClick={() => handleChange(index, '')}
                >
                  <CrossIcon fillProps="#219653" />
                </button>
              )}
            </div>
          ))}
        </div>
        {external}
      </div>
    </div>
  );
};

InsertGroup.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  setList: PropTypes.func,
  external: PropTypes.any,
  id: PropTypes.string,
};

export default InsertGroup;

import PropTypes from 'prop-types';
import cn from 'classnames';

const ButtonPrimary = ({ onClick, label, disabled, fullHeight, className }) => {
  return (
    <button
      className={cn('button-primary items-center flex', {
        [className]: className,
        ['h-full']: fullHeight,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
ButtonPrimary.defaultProps = {
  fullHeight: false,
  disabled: false,
  className: '',
};
ButtonPrimary.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullHeight: PropTypes.bool,
};
export default ButtonPrimary;

import { useProblemsProducts } from 'hooks/useProblemsProducts';
import ProblemsIcon from 'assets/icons/Problems';
import DownIcon from 'assets/icons/DownIcon';
import cn from 'classnames';
import ProblemsDetail from './ProblemsDetail';
const DETAILS = [
  'marginality_qty',
  'lost_orders_qty',
  'buyout_qty',
  /*'raiting_qty',*/ 'profitability_qty',
  'turn_around_orders_qty',
  'sold_out_qty',
  'prime_cost_qty',
];
const ProblemsProductDetails = () => {
  const { showDetails, problemProductsSum, isSumFetched, isExpanded, setIsExpanded } =
    useProblemsProducts();
  if (!showDetails) {
    return null;
  }
  const isEmpty =
    isSumFetched && (!problemProductsSum?.total_qty || problemProductsSum?.total_qty === '-');
  const handleProblemsClick = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="p-4 bg-[#F0F0F0]">
      <div className="px-3 border border-[#E8E8E8] rounded-lg bg-white">
        <div
          className="flex justify-between items-center cursor-pointer pt-3 pb-4 group"
          onClick={handleProblemsClick}
        >
          <div className="flex items-center">
            <ProblemsIcon height={22} width={22} className="mr-3" />
            <span className="relative top-[1px] block text-base leading-[21px] lg:text-lg lg:leading-7 font-medium">
              {isEmpty ? '' : `${problemProductsSum?.total_qty} товаров требующих внимания`}
            </span>
          </div>
          <div className="flex items-center group-hover:text-green">
            <span className="text-[#6E6E6E] group-hover:!text-green leading-[22px] hidden lg:block mr-2">
              {isExpanded ? 'Свернуть' : 'Развернуть'}
            </span>
            <DownIcon width={12} height={12} className={cn({ 'rotate-180': isExpanded })} />
          </div>
        </div>
        {isExpanded && (
          <div className="border-t border-[#E7EDF0] py-4">
            <div className="flex space-x-2 overflow-x-auto lg:overflow-visible">
              {DETAILS.map((key) => {
                return <ProblemsDetail key={key} propertyKey={key} />;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProblemsProductDetails;

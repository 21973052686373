import { useQuery } from 'react-query';
import { STALE_TIME } from '../constants';
import baseInnerClient from '../services/baseInnerClient';

const defaultQueryOptions = {
  retry: 3,
  retryDelay: 3000,
};

const useDeliveryWarehouses = (props) => {
  const enabled = props?.enabled ?? true;

  return useQuery({
    queryKey: ['deliveryWarehouses'],
    queryFn: () => {
      return baseInnerClient.getInnerFilterWarehouses(props);
    },
    enabled: enabled,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: true,
    staleTime: STALE_TIME,
    ...defaultQueryOptions,
  });
};

export { useDeliveryWarehouses };

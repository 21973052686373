/**
 * @typedef IFormatColorChartObject
 *
 * @property {string} name Название цвета ИЛИ размера в человеко-читаемом формате
 * @property {number} value ?
 * @property {string} link Ссылка, по которой будет выполнен переход в случае клика
 * */

export const CHARTS_KEYS = {
  WAREHOUSES_MONEY: 'warehouses_money',
  WAREHOUSES_STOCK: 'warehouses_stock',
  WAREHOUSES_ORDERS_RUB: 'warehouses_orders_rub',
  WAREHOUSES_ORDERS_QTY: 'warehouses_orders_qty',
  WAREHOUSES_STOCK_RUB: 'warehouses_stock_rub',
  WAREHOUSES_STOCK_QTY: 'warehouses_stock_qty',
  STOCK_BY_SIZES_RUB: 'stock_by_sizes_rub',
  STOCK_BY_SIZES_QTY: 'stock_by_sizes',
  ORDERS_BY_SIZES_RUB: 'orders_rub_by_sizes',
  ORDERS_BY_SIZES_QTY: 'orders_qty_by_sizes',
  ORDERS_BY_COLORS_RUB: 'colors_orders_rub',
  ORDERS_BY_COLORS_QTY: 'colors_orders_qty',
  STOCK_BY_COLORS_RUB: 'colors_stock_rub',
  STOCK_BY_COLORS_QTY: 'colors_stock_qty',
};

export const formatChartsDataByKey = ({ data, key: currentKey, mainKey }) => {
  const fullData = data?.[currentKey];
  if (!fullData) return [];

  const result = [];

  Object.keys(data?.[mainKey || currentKey]).forEach((key) => {
    if (key !== 'Не определено') {
      result.push({
        name: key,
        value: +fullData[key] || 0,
      });
    }
  });

  return result;
};

/**
 * Функция для трансформирования сырых данных о цветах в формат, понятный PieChart
 *
 * @property {Record<string, unknown>} productColors
 * @property {string} key Уникальный ключ, который будет использован PieChart'ом при распаковке данных
 *
 * @return {IFormatColorChartObject[]} Массив объектов, содержащих минимально необходимую информацию для работы PieChart
 * */
export const formatColorChartsDataByKey = (productColors, key) => {
  if (!productColors || !productColors?.all_data) {
    return {
      data: [],
    };
  }

  return Object.keys(productColors?.all_data)?.map((id) => {
    return {
      name: productColors?.all_data[id].colors?.join(', '),
      value: +productColors?.all_data[id][key],
      link: `/product/${id}`,
    };
  });
};

export const getColor = ({ colors, index }) => {
  return (
    colors?.[index] ||
    colors[index - colors.length * Math.floor(index / colors.length)] ||
    colors[0]
  );
};

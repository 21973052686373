import React, { useState, useEffect, useCallback } from 'react';
import {
  getToken,
  login,
  register,
  recoverPassword,
  logout,
  recoverForEmail,
  getUser,
  verify,
  activateDemo,
  cleanToken,
  sendMailConfirmation,
  setToken,
} from 'context/auth.methods';

import { DEFAULT_PLATFORMS } from 'context/filter/filter.context.reducer.methods';
import {
  TARIFF_ADMIN,
  TARIFF_BASIC,
  TARIFF_EXPERT,
  TARIFF_PRO,
  TARIFF_TRIAL,
} from 'constants/filter';

const AuthContext = React.createContext();
AuthContext.displayName = 'Auth.Context';

const checkUser = async () => {
  let user = null;
  const token = getToken();

  if (token) {
    user = await getUser(token);
  }

  return user;
};

const userPromise = checkUser();

const TARIFFS_ORDER = [TARIFF_TRIAL, TARIFF_BASIC, TARIFF_EXPERT, TARIFF_PRO, TARIFF_ADMIN];

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const getTariff = useCallback(() => {
    if (!user || !user?.tariffs || !user.tariffs.length || !user.tariffs[0]?.project_name)
      return null;

    if (user.tariffs.length === 1) return user.tariffs[0] || null;

    let bestTariff = null;

    user.tariffs.forEach((tariff) => {
      if (!bestTariff) {
        bestTariff = tariff;
      } else if (
        tariff.project_name &&
        TARIFFS_ORDER.indexOf(tariff.project_name) > TARIFFS_ORDER.indexOf(bestTariff.project_name)
      ) {
        bestTariff = tariff;
      }
    });

    return bestTariff;
  }, [user]);

  const tariff = getTariff();

  const getPlatforms = () => {
    return user?.platforms?.length ? user?.platforms : DEFAULT_PLATFORMS;
  };

  const updateUser = () => {
    checkUser()
      .then(setUser)
      .then(() => setLoading(false))
      .catch((e) => {
        console.error(e);
        cleanToken();
        window.location.assign(window.location);
      });
  };

  const platforms = getPlatforms();

  const tariffName = tariff?.project_name || null;

  const isBasic = tariffName === TARIFF_BASIC;
  const isExpert = tariffName === TARIFF_EXPERT;
  const isPro = tariffName === TARIFF_PRO;
  const isAdmin = tariffName === TARIFF_ADMIN;
  const isTrial = tariffName === TARIFF_TRIAL;
  const noTariff = !tariffName;

  useEffect(() => {
    userPromise
      .then(setUser)
      .then(() => {
        setLoading(false);
        setLoaded(true);
      })
      .catch((e) => {
        console.error(e);
        cleanToken();
        window.location.assign(window.location);
      });
  }, []);

  const value = {
    getToken,
    setToken,
    login,
    register,
    recoverPassword,
    recoverForEmail,
    sendMailConfirmation,
    logout,
    getUser,
    user,
    setUser,
    isLoading,
    isLoaded,
    tariff,
    verify,
    activateDemo,
    platforms,
    cleanToken,
    updateUser,
    isAdmin,
    isPro,
    isBasic,
    isExpert,
    isTrial,
    tariffName,
    noTariff,
  };

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };

import React from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App';
import AppProviders from 'context';

import './assets/styles/index.css';

import ErrorFallbackCommon from 'components/ErrorFallbackCommon';
import ErrorFallback from 'components/ErrorFallback';
import { IS_PRODUCTION } from 'constants/environment';

import('react-gtm-module').then((TagManager) => {
  if (IS_PRODUCTION) {
    const tagManagerArgs = {
      gtmId: 'GTM-T6DSKXK',
    };

    TagManager.initialize(tagManagerArgs);
  }
});

import('react-yandex-metrika/lib/init').then((YMInitializer) => {
  if (IS_PRODUCTION) {
    YMInitializer.default([54208747], { webvisor: true }, '2');
  }
});

import('ga-gtag').then(({ install }) => {
  if (IS_PRODUCTION) {
    install('G-GSREK1D7HK');
  }
});

const root = document.getElementById('root');
root.setAttribute('notranslate', true);

import('@sentry/react').then((Sentry) => {
  import('@sentry/tracing').then((Integrations) => {
    if (IS_PRODUCTION) {
      Sentry.init({
        dsn: 'https://5cead1a609704dfca1dc029def044986@o891338.ingest.sentry.io/5839843', //paste copied DSN value here
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0, //lower the value in production
      });
    }

    ReactDOM.render(
      <React.StrictMode>
        <AppProviders>
          <Sentry.ErrorBoundary
            fallback={process.env.NODE_ENV === 'production' ? ErrorFallbackCommon : ErrorFallback}
            showDialog
          >
            <App />
          </Sentry.ErrorBoundary>
        </AppProviders>
      </React.StrictMode>,
      root,
    );
  });
});

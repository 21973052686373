import { Suspense } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const ProductTabWrapper = ({ tab, currentTab, component: Component }) => (
  <Suspense fallback={<></>}>
    <div
      id={tab}
      className={cn({
        ['hidden']: tab === 'main' ? currentTab !== '' : tab !== currentTab,
      })}
    >
      {(tab === 'main' ? currentTab === '' : tab === currentTab) ? (
        <Component fetchEnabled={tab === 'main' ? currentTab === '' : tab === currentTab} />
      ) : (
        <></>
      )}
    </div>
  </Suspense>
);
ProductTabWrapper.propTypes = {
  tab: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default ProductTabWrapper;

import {
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_SALES,
  PAGE_INNER_WEEKS,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_WAREHOUSES,
} from 'constants/pages';
import NavList from 'components/NavList';

const TableNavInner = () => {
  return (
    <div className="w-full py-3 px-4">
      <NavList
        items={[
          {
            link: PAGE_INNER_IN_DAYS_2,
            title: 'По дням',
            single: true,
          },
          {
            link: PAGE_INNER_PRODUCTS,
            title: 'Товары',
            single: true,
          },
          // {
          //   link: PAGE_INNER_IN_DAYS,
          //   title: 'По дням',
          // },
          {
            link: PAGE_INNER_SALES,
            title: 'Заказы. Продажи. Возвраты',
            single: true,
          },
          {
            link: PAGE_INNER_WEEKS,
            title: 'Еженедельные отчеты',
            single: true,
          },
          {
            link: PAGE_INNER_WAREHOUSES,
            title: 'Склады',
            single: true,
          },
        ]}
      />
    </div>
  );
};

export default TableNavInner;

import React, { lazy } from 'react';

import { Redirect } from 'react-router-dom';
import { useUserTariffs } from 'context/user.tariffs.context';

import { COLUMNS, TABLE_TYPE } from 'constants/table';
import Table from 'components/Table';
import { AlertSingle } from 'components/Alert';
import { useAuth } from 'context/auth.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useDynamic } from 'context/dynamic.context';
import HelpBlock from 'components/HelpBlock';

const DynamicChart = lazy(() => import('components/DynamicChart'));

const Dynamic = () => {
  const { typeActive, dataTable, isDataLoading } = useDynamic();

  const { filter, isLoaded } = useFilterReducer();
  const { isPro } = useAuth();

  const { checkDynamicAvailability } = useUserTariffs();
  const { isDynamicAvailable } = checkDynamicAvailability(filter);

  if (!isDynamicAvailable && isLoaded) {
    return <Redirect to="/main" />;
  }

  return (
    <>
      <HelpBlock type="trends" />
      <div className="bg-gray-100 pt-4 pb-8">
        <div className="wrapper-wide">
          <DynamicChart />
        </div>
      </div>
      <Table
        hidePagination={true}
        data={dataTable || []}
        dataKey={typeActive}
        type={TABLE_TYPE.DYNAMIC}
        autoSizeColumns={[COLUMNS.VERTICAL_HEADER]}
        isLoading={isDataLoading}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />

      {isPro && (
        <AlertSingle
          item={{
            message: 'В разделе "Тренды" вы можете выбрать период до 180 дней',
            type: 'success',
            key: 'trends',
          }}
          placement="bottom"
          timeout={3500}
        />
      )}
    </>
  );
};

export default Dynamic;

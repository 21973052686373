import AdvertisingRates from './AdvertisingRates';
import NewLogo from 'assets/icons/NewLogo';

const UnauthorizedAdvertisingRates = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="px-5 w-full grow bg-gray-50 lg:px-0">
        <div className="pb-0 mt-10 flex justify-center lg:pb-5">
          <div className="flex">
            <NewLogo height={36} width={30} />
            <span className="block self-center ml-3.5 text-xl font-semibold">wecheck</span>
          </div>
        </div>
        <AdvertisingRates />
      </div>
    </div>
  );
};

export default UnauthorizedAdvertisingRates;

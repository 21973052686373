import React, { useMemo } from 'react';
import cn from 'classnames';

import { useProduct } from 'context/product.context';
import { useUI } from 'context/ui.context';
import ExternalLinkIcon from 'assets/icons/ExternalLinkIcon';

export const ProductPlatform = () => {
  const { isOzonPlatform, productData } = useProduct();
  const { isMobile } = useUI();

  const platform = useMemo(() => (isOzonPlatform ? 'Ozon' : 'Wildberries'), [isOzonPlatform]);

  const platformColor = useMemo(() => {
    if (isOzonPlatform) return 'text-ozon';

    return 'text-wb';
  }, [isOzonPlatform]);

  if (isMobile) {
    return (
      <a
        className={cn('flex text-xs leading-4 items-center', {
          [platformColor]: platformColor,
        })}
        target="_blank"
        rel="noreferrer"
        href={productData?.link?.[0]}
      >
        {platform}
        <ExternalLinkIcon className="w-3 h-3 ml-[5px]" />
      </a>
    );
  }

  return (
    <div
      className={cn('lg:text-base text-xs leading-4', {
        [platformColor]: platformColor,
      })}
    >
      {platform}
    </div>
  );
};

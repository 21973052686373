import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Tab } from '@headlessui/react';
import Tag from 'components/Tag';
import { useUI } from 'context/ui.context';

export const FilterContent = ({
  tabs,
  onTabCleanByType,
  additionalContentOnTabLeft,
  showUnderTabs,
}) => {
  const { isMobile } = useUI();

  return (
    <Tab.Group>
      {isMobile && !showUnderTabs && additionalContentOnTabLeft}
      <Tab.List
        className={`border-b-2 lg:border-b-0 border-gray-150 lg:border-r
      flex-none flex lg:flex-col lg:w-[245px]
      px-5 lg:px-0 lg:pt-6 space-x-5 lg:space-x-0
      overflow-x-scroll lg:overflow-x-visible`}
      >
        {tabs.map(({ id, label, count, available }) => (
          <Fragment key={`tab-${id}`}>
            {available && (
              <div className="relative w-full">
                <Tab
                  className={({ selected }) =>
                    cn(
                      'pl-1 lg:pl-6 lg:pr-16 py-4 lg:py-3 text-left hover:text-green w-[calc(100%+1px)]',
                      {
                        ['text-green border-b-2 lg:border-b-0 lg:border-r-2 border-green lg:bg-green-100 mb-[-2px] lg:mb-0']:
                          selected,
                        ['text-black']: !selected,
                        ['pr-14']: count,
                        ['pr-1']: !count,
                      },
                    )
                  }
                >
                  {label}
                </Tab>
                {!!count && (
                  <span className="absolute right-0 lg:right-4 top-4 lg:top-3">
                    <Tag
                      count={count}
                      onClose={onTabCleanByType ? () => onTabCleanByType(id) : null}
                    >
                      {count}
                    </Tag>
                  </span>
                )}
              </div>
            )}
          </Fragment>
        ))}
        {!isMobile && additionalContentOnTabLeft}
      </Tab.List>
      {isMobile && showUnderTabs && additionalContentOnTabLeft}
      <Tab.Panels
        className={`h-full lg:max-h-full lg:h-[500px] lg:flex-1 
      px-4 lg:p-6 
      overflow-y-scroll lg:overflow-hidden`}
      >
        {tabs.map(({ available, panel, id }) => (
          <Fragment key={`panel-${id}`}>
            {available && <Tab.Panel className="h-full flex flex-col">{panel}</Tab.Panel>}
          </Fragment>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

FilterContent.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabCleanByType: PropTypes.func,
  showUnderTabs: PropTypes.bool,
  additionalContentOnTabLeft: PropTypes.node,
};

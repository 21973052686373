import PropTypes from 'prop-types';
import cn from 'classnames';

export const Input = ({
  onChange,
  name,
  value,
  placeholder,
  isNumeric,
  getActiveClassNames,
  onFocus,
  autoFocus,
  outerRef,
  enterCallback,
}) => {
  const externalClassName = getActiveClassNames ? getActiveClassNames(name) : '';
  return (
    <input
      className={cn(
        'w-1/2 appearance-none border border-gray-250 rounded-md py-2 pl-3 pr-1 text-gray-700 leading-tight focus:outline-none focus:border-green',
        { [externalClassName]: externalClassName },
      )}
      type={isNumeric ? 'number' : 'string'}
      name={name}
      autoComplete="off"
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || ''}
      autoFocus={autoFocus}
      ref={outerRef}
      onFocus={() => {
        onFocus && onFocus(name);
      }}
      onKeyDown={(e) => {
        if (enterCallback && e.keyCode === 13) {
          enterCallback(e);
        }
      }}
    />
  );
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isNumeric: PropTypes.bool,
  getActiveClassNames: PropTypes.any,
  onFocus: PropTypes.any,
  enterCallback: PropTypes.func,
  outerRef: PropTypes.any,
  autoFocus: PropTypes.bool,
};

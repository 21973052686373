import PropTypes from 'prop-types';

export const TariffRadio = ({ price, id, name, recommended, onChange, checked }) => (
  <div className="flex flex-col w-1/3">
    <input
      type="radio"
      className="accent-green w-4 h-4"
      id={id}
      onChange={onChange}
      checked={checked}
    />
    <label htmlFor={id} className="mt-2">
      <div className="text-lg">{name}</div>
      <div className="text-lg">{price}</div>
      {recommended && <div className="text-red text-xs"> РЕКОМЕНДУЕМ </div>}
    </label>
  </div>
);

TariffRadio.propTypes = {
  price: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  recommended: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

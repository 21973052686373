const ExcelIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 22">
    <path
      d="m7.067 9.153 1.57 2.7 1.553-2.697a.304.304 0 0 1 .262-.153h.906c.055 0 .108.015.155.044a.328.328 0 0 1 .101.443L9.45 12.984l2.133 3.438a.323.323 0 0 1-.092.438.312.312 0 0 1-.165.05h-.952a.301.301 0 0 1-.26-.15l-1.58-2.674-1.593 2.676a.304.304 0 0 1-.259.15h-.876a.297.297 0 0 1-.155-.043.312.312 0 0 1-.112-.12.327.327 0 0 1 .008-.321l2.118-3.502-2.09-3.438a.323.323 0 0 1 .094-.438.276.276 0 0 1 .163-.05h.975c.106 0 .205.058.261.153Z"
      fill="currentColor"
    />
    <path
      d="M9.5 1h-7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-7-7Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 1v7h7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default ExcelIcon;

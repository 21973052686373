import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import cn from 'classnames';

import { ReactComponent as Icon } from 'assets/images/back.svg';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const ButtonBack = ({ backTo, label, className, callBack }) => {
  const history = useHistory();
  const { setFilterDefault } = useFilterReducer();

  const goBack = () => {
    if (backTo) {
      //при переходе между страницами обнулять некоторые параметры фильтра
      setFilterDefault({ nextLink: backTo, prevLink: window.location.pathname });
      callBack && callBack();
      history.push(backTo);
    } else {
      callBack && callBack();
      history.goBack();
    }
  };

  if (!history.length) {
    return <></>;
  }

  return (
    <button
      className={cn(
        className,
        'flex items-center text-green text-large hover:text-black transition-all',
      )}
      onClick={goBack}
    >
      <Icon /> <span className="ml-2 leading-none pt-0.5">{label}</span>
    </button>
  );
};
ButtonBack.defaultProps = {
  backTo: null,
  label: 'Назад',
  className: '',
};

ButtonBack.propTypes = {
  backTo: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  callBack: PropTypes.func,
};

export default ButtonBack;

import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTable } from 'context/table.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { Footer, InputsRow } from './components';

const getCurrentRestricts = (filterRange, field, index) => {
  if (!filterRange || !filterRange[field]) return '';

  return filterRange[field][index];
};

const RangeFilter = forwardRef(
  (
    {
      colDef: {
        field,
        headerComponentParams: { sortable },
      },
      filterChangedCallback,
      column,
    },
    ref,
  ) => {
    const { type, isTableRestrictedOnBack } = useTable();
    const { filterRange, addFilterRange, removeFilterRange } = useFilterReducer();

    const [from, setFrom] = useState(getCurrentRestricts(filterRange, field, 0));
    const [to, setTo] = useState(getCurrentRestricts(filterRange, field, 1));

    const [instanceParams, setInstanceParams] = useState(null);

    const restrictedOnBack = isTableRestrictedOnBack(type);
    const disabled = (from === '' && to === '') || (from !== '' && to !== '' && +from > +to);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params) {
          if (restrictedOnBack) return true;

          if (from === '') {
            return params.data[field] <= +to;
          }

          if (to === '') {
            return params.data[field] >= +from;
          }

          return params.data[field] >= +from && params.data[field] <= +to;
        },

        isFilterActive() {
          return !disabled;
        },

        getModel() {
          if (!this.isFilterActive()) {
            return null;
          }

          return {
            type: 'inRange',
            filter: from,
            filterTo: to,
          };
        },

        setModel() {},

        hidePopup() {
          if (!instanceParams || !instanceParams?.hidePopup) return;

          instanceParams.hidePopup();
        },

        afterGuiAttached(params) {
          setInstanceParams(params);
        },
      };
    });

    const handleSubmit = () => {
      addFilterRange({ value: [from, to], key: field });
    };

    const cleanFilter = () => {
      setFrom('');
      setTo('');

      removeFilterRange({ key: field });
    };

    useEffect(() => {
      filterChangedCallback();
      return () => {
        filterChangedCallback();
      };
    }, [filterRange, filterChangedCallback]);

    return (
      <div className="p-4 w-full">
        <p className="text-sm mb-8">
          Введите значения, в рамках которых хотите отфильтровать колонку
        </p>

        <InputsRow
          from={from}
          onFromChange={setFrom}
          to={to}
          isNumeric={true}
          onToChange={setTo}
          column={column}
          sortable={sortable}
          enterCallback={(e) => {
            if (e.keyCode === 13) {
              handleSubmit();
            }
          }}
        />

        <Footer disabled={!!disabled} onClean={cleanFilter} onSubmit={handleSubmit} />
      </div>
    );
  },
);

RangeFilter.displayName = 'TableHeaderFilter';
RangeFilter.propTypes = {
  hidePopup: PropTypes.func,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerComponentParams: PropTypes.shape({
      sortable: PropTypes.bool,
    }),
  }).isRequired,
  filterChangedCallback: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
};
export default RangeFilter;

import PropTypes from 'prop-types';

import { TableHeaderMain } from 'components/TableHeader';
import TableLayout from 'components/Layout/TableLayout';
import TableCardList from 'components/TableCardList';
import Layout from 'components/Layout/Layout';

const TableMainLayout = ({ children }) => {
  return (
    <TableLayout isPeriodControlsShown={false} isDateControlsShown={true}>
      <TableCardList />

      <Layout>
        <TableHeaderMain />

        {children}
      </Layout>
    </TableLayout>
  );
};

TableMainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default TableMainLayout;

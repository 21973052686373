import { useProblemsProducts } from 'hooks/useProblemsProducts';
// import { Skeleton } from '../Skeleton';
// import ProblemsIcon from 'assets/icons/Problems';
import DownIcon from 'assets/icons/DownIcon';
// import EmptyListIcon from 'assets/icons/EmptyListIcon';
import { useHistory } from 'react-router-dom';
import { PAGE_INNER_PRODUCTS } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { Skeleton } from '../Skeleton';

const ProblemsProductsInnerCard = () => {
  const { problemProductsSum, isLoading, /*isSumFetched,*/ setShowDetails, setIsExpanded } =
    useProblemsProducts();
  const { setFilterDefault } = useFilterReducer();
  // const isEmpty =
  //   isSumFetched && (!problemProductsSum?.total_qty || problemProductsSum?.total_qty === '-');
  const history = useHistory();
  const handleClick = () => {
    setShowDetails(true);
    setIsExpanded(true);
    setFilterDefault({ nextLink: PAGE_INNER_PRODUCTS, prevLink: window.location.pathname });
    history.push(PAGE_INNER_PRODUCTS);
  };
  return (
    <div
      className="ml-4 px-4 py-3 bg-white border border-[#E8E8E8] group rounded-lg flex cursor-pointer hover:!border-green"
      onClick={handleClick}
    >
      <div
        id="first"
        className="grow pr-2 flex border-r border-[#F0F0F0] justify-between items-center hover:!text-green grou"
      >
        <span className="block text-[#858585] leading-[17px]">Товаров с проблемами</span>
        <div className="flex items-center">
          <span className="block font-bold leading-[17px]">
            {isLoading ? (
              <Skeleton classNames="h-[17px] w-[40px]" />
            ) : (
              problemProductsSum?.total_qty || 0
            )}
          </span>
          <DownIcon width={12} height={12} className="-rotate-90 ml-1 relative top-[1px]" />
        </div>
      </div>
      <div className="grow flex justify-between items-center pl-2 hover:!text-green group">
        <div className="block text-gray-400 leading-[17px]">Без себестоимости</div>
        <div className="flex items-center">
          <span className="block font-bold leading-[17px]">
            {isLoading ? (
              <Skeleton classNames="h-[17px] w-[40px]" />
            ) : (
              problemProductsSum?.prime_cost_qty || 0
            )}
          </span>
          <DownIcon width={12} height={12} className="-rotate-90 ml-1 relative top-[1px]" />
        </div>
      </div>
    </div>
  );
};

export default ProblemsProductsInnerCard;

import React, { useEffect, useState } from 'react';
import { MonitoringContent } from 'components/SeoMonitoring';
import TableHeaderDateControls from 'components/TableHeaderDateControls';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { MonitoringGroupsModal } from '../../../components/SeoMonitoring/MonitoringGroupsModal';
import { useMonitoringGroups } from '../../../context/monitoring.groups.context';
import { MODES } from '../../../components/SeoMonitoring/constants';

const Monitoring = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const productsURL = searchParams.get('products');
  const [mode, setMode] = useState(productsURL ? MODES.RESULT : MODES.INSERT);
  const { resetFilter } = useFilterReducer();
  const filter = searchParams.get('filter');
  const { editGroup } = useMonitoringGroups();
  useEffect(() => {
    if (!filter) {
      resetFilter();
    }
  }, [filter, resetFilter]);
  return (
    <div className="pt-4">
      <div className="px-8 flex justify-between">
        <div>
          {editGroup ? (
            <h1>
              Редактирование группы:{' '}
              <h1 className="inline-block text-[#219653]">{editGroup.name}</h1>
            </h1>
          ) : (
            <h1>Мониторинг поисковых запросов</h1>
          )}
          <div className="text-gray-400 mt-1 mb-4">
            <span className="block">
              Инструмент помогает отслеживать позиции вашего товара по группе поисковых фраз.
            </span>
            <span className="block">
              С помощью него удобно наблюдать за результатами SEO оптимизации
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2 h-fit">
          <MonitoringGroupsModal mode={mode} setMode={setMode} />
          <div className="w-[1px] h-6 bg-[#E7EDF0]" />
          <TableHeaderDateControls usePeriods={false} />
        </div>
      </div>
      <MonitoringContent mode={mode} setMode={setMode} />
    </div>
  );
};

export default Monitoring;

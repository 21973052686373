const NoPhotoIcon = (props) => {
  return (
    <svg
      width="41"
      height="40"
      {...props}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2874 4.167L36.7034 7.75099L36.7033 7.75122C38.9919 8.52832 40.6759 10.6873 40.6759 13.2349L40.6756 27.4845C40.6756 30.723 38.0416 33.314 34.8461 33.314H11.0968L5.52667 38.8842C5.05168 39.3592 4.40405 39.6182 3.7996 39.6182C3.19515 39.6182 2.54753 39.3592 2.07253 38.8842C1.12256 37.9341 1.12256 36.3796 2.07253 35.4296L36.8329 0.712479C37.7829 -0.237493 39.3374 -0.237493 40.2874 0.712479C41.2374 1.66253 41.2374 3.21703 40.2874 4.167ZM17.0563 27.3981C18.0494 27.83 19.172 28.089 20.338 28.089L20.3381 28.0889C24.8287 28.0889 28.4558 24.4619 28.4561 19.9709C28.4561 18.8052 28.197 17.6824 27.7651 16.6893L17.0563 27.3981ZM20.338 11.8531C15.8471 11.8531 12.2201 15.4805 12.2201 19.9711L12.2202 19.9714V20.2308L1.29549 31.1986C0.475001 30.2053 0 28.9101 0 27.5281V13.2354C0 9.99683 2.63409 7.40584 5.82953 7.40584L12.3927 7.40554L14.0768 3.99443C14.4653 3.21735 15.2427 2.74235 16.1063 2.74235H24.5697C25.4332 2.74235 26.2106 3.21734 26.5991 3.99443L27.2037 5.24651L20.597 11.8531H20.338Z"
        fill="#858585"
      />
    </svg>
  );
};
export default NoPhotoIcon;

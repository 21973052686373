import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTableTooltip } from 'context/table.tooltip.context';
import BarIcon from 'assets/icons/BarIcon';
import { FilterWithIcon } from 'assets/icons/FilterWithIcon';
import { useTable } from 'context/table.context';

const renderIcon = ({ isFiltered, settingsIcon, settingsFilteredIcon: SettingsFilteredIcon }) => {
  const ResultIcon = settingsIcon ? settingsIcon : BarIcon;
  if (!isFiltered) {
    return <ResultIcon className="h-3.5 w-3.5" />;
  }
  if (SettingsFilteredIcon) {
    return <SettingsFilteredIcon width={14} height={14} />;
  }
  return <FilterWithIcon className="h-3.5 w-3.5" isFiltered={isFiltered} />;
};

export const FilterButton = ({
  showColumnMenu,
  isFiltered,
  settingsIcon,
  settingsFilteredIcon,
}) => {
  const refButton = useRef(null);
  const barButton = refButton.current;

  const { isTableNeedHideFilter, type } = useTable();
  const isTableFilterHidden = isTableNeedHideFilter(type);

  const { setBarTooltip, cleanTooltip } = useTableTooltip();

  const onMenuClick = () => {
    if (isTableFilterHidden) return;

    showColumnMenu(refButton.current);
  };

  useEffect(() => {
    if (!barButton) return;

    barButton.addEventListener('mouseenter', setBarTooltip);
    barButton.addEventListener('mouseleave', cleanTooltip);

    return () => {
      barButton.removeEventListener('mouseenter', setBarTooltip);
      barButton.removeEventListener('mouseleave', cleanTooltip);
    };
  }, [barButton, setBarTooltip, cleanTooltip]);

  return (
    <button
      ref={refButton}
      onClick={onMenuClick}
      className={cn('relative p-1 transition-all', {
        ['cursor-not-allowed']: isTableFilterHidden,
        ['hover:text-green']: !isTableFilterHidden,
        ['text-gray-300']: !isFiltered,
        ['text-green']: isFiltered,
      })}
    >
      {renderIcon({ isFiltered, settingsIcon, settingsFilteredIcon })}
    </button>
  );
};

FilterButton.propTypes = {
  showColumnMenu: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  settingsIcon: PropTypes.func,
  settingsFilteredIcon: PropTypes.func,
};

import React from 'react';
import { PAGE_PROFILE_SUBSCRIPTIONS } from 'constants/pages';
import SuccessPaymentLayout from 'components/SuccessPaymentLayout';

const SuccessPayment = () => (
  <SuccessPaymentLayout
    title={
      <>
        Оплата прошла успешно! <br />
        Ваш тариф будет активирован.
      </>
    }
    linkText={'Перейти в личный кабинет'}
    link={PAGE_PROFILE_SUBSCRIPTIONS}
  />
);

export default SuccessPayment;

export const getBreadcrumbs = (data, result = []) => {
  if (!data.length) return result;

  let { children, title, key } = data?.[0];

  if (title === 'wb') title = 'Wildberries';

  const resultNew = [...result, { key, title }];

  if (children) {
    return getBreadcrumbs(children, resultNew);
  } else {
    return resultNew;
  }
};

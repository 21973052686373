import { useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import PropTypes from 'prop-types';
import { Input } from '../RangeFilter/components';
import cn from 'classnames';
import baseInnerClient from 'services/baseInnerClient';
import { useProblemsProducts } from 'hooks/useProblemsProducts';
import { useUI } from 'context/ui.context';
import ButtonPrimary from '../ButtonPrimary';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';

const DetailsPopup = ({
  setPopupActive,
  isPercent = true,
  maxValue,
  minValue,
  condition,
  value,
  field,
  setIsLoading,
  setIsThisChanged,
}) => {
  const { isMobile } = useUI();
  const { refetchProblemsSum, refetchProblemsSettings } = useProblemsProducts();
  const [isConditionError, setIsConditionError] = useState(false);
  const [isValueError, setIsValueError] = useState(false);
  const [conditionState, setConditionState] = useState(condition);
  const [valueState, setValueState] = useState(value || '');
  const popupRef = useRef();
  useClickOutside(popupRef, () => setPopupActive(false));
  const useText = maxValue && minValue !== undefined;
  const commonButtonClass =
    'w-[30px] h-[30px] flex items-center justify-center rounded border border-[#D9D9D9] min-w-[30px]';
  const activeButtonClass = 'text-green !border-green';
  const handleApply = async () => {
    if (conditionState === undefined) {
      setIsConditionError(true);
      return;
    }
    if (!valueState || isNaN(+valueState) || +valueState < minValue || +valueState > maxValue) {
      setIsValueError(true);
      return;
    }
    setIsThisChanged(true);
    setIsLoading(true);
    const result = await baseInnerClient.setProblemsSetting({
      data: { value: +valueState, condition: conditionState, field },
    });
    if (result.status === 201 || result.status === 200) {
      refetchProblemsSum();
      refetchProblemsSettings();
    }
    setIsLoading(false);
    setPopupActive(false);
  };
  const content = (
    <>
      <div className="flex border-r border-[#E7EDF0] mr-2">
        <button
          className={cn(commonButtonClass, 'mr-1', {
            [activeButtonClass]: conditionState === 0,
            '!border-red': isConditionError,
          })}
          onClick={() => {
            setConditionState(0);
            setIsConditionError(false);
          }}
        >
          {'<'}
        </button>
        <button
          className={cn(commonButtonClass, 'mr-2', {
            [activeButtonClass]: conditionState === 2,
            '!border-red': isConditionError,
          })}
          onClick={() => {
            setConditionState(2);
            setIsConditionError(false);
          }}
        >
          {'>'}
        </button>
      </div>
      <Input
        onChange={(value) => {
          let resultValue = value;
          if (conditionState !== undefined) {
            resultValue = value.slice(2);
          }
          setValueState(resultValue);
          setIsValueError(false);
        }}
        name="value"
        autoFocus
        value={`${
          conditionState === undefined ? '' : conditionState === 0 ? '< ' : '> '
        }${valueState}`}
        getActiveClassNames={() =>
          cn('flex grow h-[30px] lg:w-[127px] lg:min-w-[127px]', {
            '!border-red': isValueError,
          })
        }
      />
    </>
  );
  if (isMobile) {
    return (
      <MobileInterfaceV2
        onPanelClose={() => setPopupActive(false)}
        rootClassName="h-fit bottom-0 absolute"
        isActive={true}
        headerText={
          <>
            {useText && (
              <span
                className={cn('text-gray-700 whitespace-nowrap text-base font-bold', {
                  '!text-red': isValueError,
                })}
              >{`Задайте значение от ${minValue} до ${maxValue}${isPercent ? '%' : ''}`}</span>
            )}
          </>
        }
      >
        <div className="p-4 flex">{content}</div>
        <div className="p-4 border-t border-[#E7EDF0]">
          <ButtonPrimary
            onClick={handleApply}
            label={<span className="block mx-auto">Применить</span>}
            className="w-full text-center py-2 mb-4 items-center"
          />
        </div>
      </MobileInterfaceV2>
    );
  }
  return (
    <div
      className={cn(
        'absolute border border-[#E8E8E8] bg-white shadow-[0_2px_8px_rgba(0,0,0,0.1)] rounded-lg p-4 top-4 -left-[130px] z-10',
        {
          ['!-left-[70px]']: field === 'marginality_qty',
        },
      )}
      ref={popupRef}
    >
      {useText && (
        <span
          className={cn('text-gray-700 whitespace-nowrap', {
            '!text-red': isValueError,
          })}
        >{`Задайте значение от ${minValue} до ${maxValue}${isPercent ? '%' : ''}`}</span>
      )}
      <div
        className={cn('flex pb-3 border-b border-gray-230', {
          'mt-3': useText,
        })}
      >
        {content}
      </div>
      <button
        className="mt-3 w-full inline-flex justify-center py-[9px] border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:bg-transparent focus:text-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        onClick={handleApply}
      >
        Применить
      </button>
    </div>
  );
};

DetailsPopup.propTypes = {
  setPopupActive: PropTypes.func,
  setIsLoading: PropTypes.func,
  setIsThisChanged: PropTypes.func,
  isPercent: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.any,
  condition: PropTypes.any,
  field: PropTypes.string,
  isNumber: PropTypes.bool,
};

export default DetailsPopup;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';

import DownIcon from 'assets/icons/DownIcon';
import Checkbox from 'components/Checkbox';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';

const DropdownWithCheckboxes = ({
  bgColor,
  color,
  label,
  options,
  onChange,
  checked,
  isOutlined,
  buttonClassnames,
  labelClassnames,
  footer,
}) => {
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const onPanelOpen = () => setIsMobileVisible(true);
  const onPanelClose = () => setIsMobileVisible(false);

  const items = options.map(({ label, value }) => {
    const isChecked = checked.includes(`${value}`) || checked.includes(+value);

    return {
      id: value,
      item: (
        <Checkbox
          key={`checkbox-${value}`}
          className="mx-4 pl-4 lg:mx-0 items-center transition-all lg:hover:bg-gray-100 lg:focus:bg-gray-100"
          label={label}
          onChange={onChange}
          name={value}
          checked={isChecked}
          labelClass="text-gray-450 text-sm flex-1 py-4 pr-4 lg:py-2"
          color={isChecked ? 'green' : 'gray'}
        />
      ),
    };
  });

  return (
    <>
      <Menu as="div" className="relative inline-block text-left hidden lg:block">
        {({ open }) => (
          <>
            <Menu.Button as={Fragment}>
              <button
                className={cn(
                  'whitespace-nowrap inline-flex items-center w-full px-3 py-1.5 text-sm font-medium rounded hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer',
                  {
                    [`bg-${bgColor}`]: !isOutlined && bgColor,
                    [`text-${color}`]: color,
                    [`border-${bgColor}`]: isOutlined && bgColor,
                    [buttonClassnames]: buttonClassnames,
                  },
                )}
              >
                <span className={labelClassnames}>{label}</span>
                <DownIcon
                  className={cn('w-3 h-3 ml-2 transition-all', {
                    ['scale-y-[-1]']: open,
                  })}
                  aria-hidden="true"
                />
              </button>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={cn(
                  'min-w-[200px] absolute border border-gray-200',
                  'left-0 w-40 mt-2 origin-top-right bg-white rounded shadow-lg focus:outline-none z-10',
                  {
                    ['pt-2 pb-1']: footer,
                    ['py-2']: !footer,
                  },
                )}
              >
                {items.map(({ item, id }) => {
                  return (
                    <Fragment key={id}>
                      <Menu.Item>{item}</Menu.Item>
                    </Fragment>
                  );
                })}

                {footer && (
                  <Menu.Item>
                    <div className="mt-1 pt-1 mx-4 border-t border-gray-200">{footer}</div>
                  </Menu.Item>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>

      {/*кнопка для вызова мобильного меню*/}
      <button
        className={cn(
          'lg:hidden flex text-white whitespace-nowrap shadow-sm inline-flex items-center',
          'px-2 py-1 text-sm font-medium rounded cursor-pointer',
          'hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
          {
            [`bg-${bgColor}`]: !isOutlined && bgColor,
            [`text-${color}`]: color,
            [`border-${bgColor}`]: isOutlined && bgColor,
          },
        )}
        onClick={onPanelOpen}
      >
        <span className="mr-auto">{label}</span>
        <DownIcon className="w-3 h-3 ml-2" aria-hidden="true" />
      </button>

      {/*мобильный вид*/}
      <MobileInterfaceV2
        onPanelClose={onPanelClose}
        rootClassName="h-3/4 bottom-0 absolute overflow-y-scroll"
        isActive={isMobileVisible}
        headerText="Выберите ключ"
      >
        <div className="divide-y divide-gray-200">
          {items.map(({ item, id }) => {
            return <Fragment key={`mobile-${id}`}>{item}</Fragment>;
          })}

          {footer && <div className="pt-1 mx-4">{footer}</div>}
        </div>
      </MobileInterfaceV2>
    </>
  );
};
DropdownWithCheckboxes.defaultProps = {
  bgColor: 'green',
  color: 'white',
  label: 'Выбрать',
  isOutlined: false,
  labelClassnames: '',
  checked: [],
};
DropdownWithCheckboxes.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  isOutlined: PropTypes.bool,
  buttonClassnames: PropTypes.string,
  labelClassnames: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  checked: PropTypes.array,
  onChange: PropTypes.func,
  footer: PropTypes.node,
};

export default DropdownWithCheckboxes;

import PropTypes from 'prop-types';

const TableCellDiscountTooltip = ({ reactContainer }) => {
  reactContainer.style.height = '234px';
  reactContainer.style.width = '208px';

  return (
    <div
      className={`
      opacity-100 -mt-12
      pl-7
      `}
    >
      <div
        className={`
        max-h-full max-w-full
        bg-white p-2
        shadow-black-bright shadow rounded-md
        flex items-center justify-center bg-red
        `}
        style={{
          height: '186px',
          width: '180px',
        }}
      >
        grdgrgdrg
      </div>
    </div>
  );
};

TableCellDiscountTooltip.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  location: PropTypes.string.isRequired,
  reactContainer: PropTypes.object,
  colDef: PropTypes.object,
  valueFormatted: PropTypes.any,
};

export default TableCellDiscountTooltip;

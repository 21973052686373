import React, { useState } from 'react';
import gtag from 'ga-gtag';
import ym from 'react-yandex-metrika';
import { useAuth } from 'context/auth.context';
import AuthorizationForm from 'components/AuthorizationForm';

const RecoveryPassword = () => {
  const { recoverForEmail } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = (values) =>
    recoverForEmail(values.email).then((data) => {
      if (!data.success) {
        setError(data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
      } else {
        setError(null);
        gtag('event', 'user_password', {
          password: 'send_email',
        });
        ym('reachGoal', 'password_send_email');
      }
      return data;
    });

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'email',
          label: 'Введите e-mail',
          type: 'text',
          expanded: false,
        },
      ]}
      initialValues={{
        email: '',
      }}
      onSubmit={handleSubmit}
      submitLabel="Отправить мне ссылку"
      showNavigation={false}
      title="Восстановление пароля"
      successMessage="Если такой email зарегистрирован, на него была отправлена ссылка для восстановления пароля."
      superError={error}
    />
  );
};

export default RecoveryPassword;

const shortcuts = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'тыс' },
  { value: 1e6, symbol: 'млн' },
  { value: 1e9, symbol: 'млрд' },
  { value: 1e12, symbol: 'трлн' },
  { value: 1e15, symbol: 'квадр' },
  { value: 1e18, symbol: 'квинт' },
];
const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

export const roundValue = (value, noPadding = false, noShortcut = false) => {
  const short = shortcuts
    .slice()
    .reverse()
    .find((short) => Math.abs(value) >= short.value);
  return short
    ? `${(value / short.value).toFixed(1).replace('.0', '').replace('.', ',').replace(rx, '$1')}${
        noPadding || short.value === 1 ? '' : ' '
      }${!noShortcut ? short.symbol : ''}`
    : '0';
};

export const getDetailInfo = (key) => {
  switch (key) {
    case 'marginality_qty': {
      return {
        label: 'Маржинальность',
        minValue: 0,
        maxValue: 100,
        postfix: '%',
        tableKey: 'marginality',
      };
    }
    case 'lost_orders_qty': {
      return {
        label: 'Упущенная выручка',
        postfix: ' ₽',
        valueFormatter: roundValue,
        tableKey: 'lost_orders_rub',
      };
    }
    case 'buyout_qty': {
      return {
        label: 'Процент выкупа',
        minValue: 0,
        maxValue: 100,
        postfix: '%',
        tableKey: 'buyout',
      };
    }
    case 'raiting_qty': {
      return {
        label: 'Рейтинг',
        isPercent: false,
        minValue: 0,
        maxValue: 5,
        postfix: '',
        tableKey: 'rating',
      };
    }
    case 'profitability_qty': {
      return {
        label: 'Рентабельность',
        minValue: 0,
        maxValue: 100,
        postfix: '%',
        tableKey: 'profitability',
      };
    }
    case 'turn_around_orders_qty': {
      return {
        label: 'Оборачиваемость',
        postfix: ' дн',
        isNumber: true,
        tableKey: 'turn_around_orders',
      };
    }
    case 'sold_out_qty': {
      return {
        label: 'Закончатся через',
        postfix: ' дн',
        isNumber: true,
        tableKey: 'turn_around_orders',
      };
    }
    case 'prime_cost_qty': {
      return {
        label: 'Без себестоимости',
        postfix: '',
        valueFormatter: roundValue,
        tableKey: 'prime_cost',
      };
    }
  }
};

export const getFromTo = (maxValue, minValue, condition, value) => {
  if (condition === 0) {
    return minValue ? [String(minValue), String(value)] : ['', String(value)];
  } else if (condition === 2) {
    return maxValue ? [String(value), String(maxValue)] : [String(value), ''];
  }
};

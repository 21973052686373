import PropTypes from 'prop-types';
import { useFetchCheckCpm } from 'hooks/useFetchCheckCpm';
import { TABLE_TYPE } from '../../../constants';
import Table from 'components/Table';

const AdvertisingRatesTable = ({ searchValue }) => {
  const { data: allData = {}, isLoading, isFetching } = useFetchCheckCpm(searchValue);
  const { data = {} } = allData;
  const { items: searchData = [] } = data;
  if (searchData.length === 0 || isLoading || isFetching) return null;
  return (
    <div className="flex justify-center">
      <div className="lg:grow lg:mt-8 max-w-[1200px]">
        <Table
          data={searchData}
          type={TABLE_TYPE.ADVERTISING_RATES}
          isLoading={isLoading}
          noRowsText={`По вашему запросу нет данных.`}
        />
      </div>
    </div>
  );
};
AdvertisingRatesTable.propTypes = {
  searchValue: PropTypes.string,
};

export default AdvertisingRatesTable;

import Table from 'components/Table';
import { TABLE_INNER_TYPE } from 'constants/table';

import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';

const InDays = () => {
  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.IN_DAYS,
  });

  return (
    <>
      <Table
        data={data}
        type={TABLE_INNER_TYPE.IN_DAYS}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </>
  );
};

export default InDays;

import cn from 'classnames';
import { PAGE_TITLE_STYLES } from 'components/Panel/components';
import FilterInner from 'components/FilterInner';
import React, { useContext, useState } from 'react';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import { TABLE_INNER_TYPE } from 'constants';
import Table from 'components/Table';
import ButtonPrimary from 'components/ButtonPrimary';
import { ProductSettingsProvider, useProductSettings } from 'context/product.settings.context';
import Alert from 'components/Alert';

export const ProductSettingsAlertContext = React.createContext({});

const ProductsSettingsWrapper = () => {
  const [alerts, setAlerts] = useState([]);
  const addAlertByKey = ({ key, message, type, icon }) => {
    setAlerts((prev) => [...prev, { key, message, type, icon }]);
  };

  const removeAlertByKey = (key) => {
    setAlerts((prev) => prev.filter((error) => error.key !== key));
  };
  return (
    <ProductSettingsProvider>
      <ProductSettingsAlertContext.Provider value={addAlertByKey}>
        <ProductsSettings />
      </ProductSettingsAlertContext.Provider>
      <Alert
        hide={removeAlertByKey}
        items={alerts}
        placement="top"
        timeout={3000}
        getClassByType={(type) => `text-white ${type === 'error' ? 'bg-[#EB392D]' : 'bg-gray-700'}`}
        closeButtonClassName="text-gray-300"
      />
    </ProductSettingsProvider>
  );
};

const ProductsSettings = () => {
  const { data, isFetching, isLoading, refetch } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.PRODUCTS_SETTINGS,
  });
  const { primeCost, handleUpdate } = useProductSettings();
  const resultData = data?.pages?.[0] || [];
  const addAlertByKey = useContext(ProductSettingsAlertContext);

  return (
    <div>
      <div className={cn('bg-white pt-2 lg:pt-3 pb-2 lg:pb-[18px]')}>
        <div className="wrapper-wide flex justify-between items-center h-[34px]">
          <h1 className={PAGE_TITLE_STYLES}>Настройка себестоимости</h1>
        </div>

        <div className="pt-2 lg:pt-3 flex justify-between">
          <div className="wrapper-wide flex gap-2">{<FilterInner />}</div>
          <div className="flex items-center pr-4">
            <span className="flex items-center text-[#6E6E6E]">
              Вы изменили себестоимость у
              <span className="block mx-1.5 text-base font-bold !text-green bg-[#F1FCF6] rounded-md px-[7px]">
                {primeCost.length}
              </span>
              товаров
            </span>
            <div className="w-px h-[22px] bg-[#EEEEEE] mx-3" />
            <ButtonPrimary
              onClick={() => handleUpdate(addAlertByKey, refetch)}
              label="Сохранить изменения"
              className="h-[32px]"
              disabled={primeCost.length === 0}
            />
          </div>
        </div>
      </div>
      <Table
        data={resultData}
        type={TABLE_INNER_TYPE.PRODUCTS_SETTINGS}
        paginationOnBack={true}
        isLoading={isLoading || isFetching}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </div>
  );
};

export default ProductsSettingsWrapper;

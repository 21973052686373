import StarIcon from 'assets/icons/StarIcon';
import StarCrossedIcon from 'assets/icons/StarCrossedIcon';

export const IconStarAdded = () => {
  return (
    <span className="p-3 bg-yellow-light rounded-full self-center mr-4">
      <StarIcon className="text-yellow-bright h-5 w-5" />
    </span>
  );
};

export const IconStarRemoved = () => {
  return (
    <span className="p-2.5 bg-gray-600 rounded-full self-center mr-4">
      <StarCrossedIcon className="text-gray-300 h-6 w-6" />
    </span>
  );
};

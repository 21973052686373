import React from 'react';
import { useParams } from 'react-router-dom';

import { COLUMNS, TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';

import Table from 'components/Table';
import HelpBlock from 'components/HelpBlock';
import PropTypes from 'prop-types';
import {
  BLUE,
  CHARTS_COLORS_WB,
  CONTESSA,
  GREEN,
  HELIOTROPE,
  HOT_PINK,
  LEAF,
  PINK,
  TYPE_ABSOLUTE,
  VIKING,
  YELLOW,
} from 'constants';
import MultiChart, { formatDateLabel, formatXAxisDate } from 'components/MultiChart';
import apiClient from 'services/baseClient';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

// const Charts = ({ fetchEnabled }) => {
//   const { id } = useParams();
//   const { productData, isOzonPlatform, isLoading: productLoading } = useProduct();
//   const { filter } = useFilterReducer();
//
//   const {
//     data: ratingsData,
//     isLoading: isRatingLoading,
//     error: isRatingError,
//   } = useFetchFilteredProductData({
//     type: TABLE_TYPE.PRODUCT_RATING,
//     id,
//     enabled: fetchEnabled,
//   });
//
//   const { data, isLoading, isFetching } = useFetchFilteredProductData({
//     type: TABLE_TYPE.PRODUCT_SALES,
//     id,
//     enabled: !!id,
//   });
//
//   const asyncGetDateHoursData = async (params) => {
//     const { data } = params;
//     const { date } = data;
//     const splittedDate = date.split('.');
//     splittedDate[2] = `20${splittedDate[2]}`;
//     const resultDate = splittedDate.reverse().join('-');
//     const response = await apiClient.getProductHours({
//       id,
//       filter: {
//         date: [resultDate],
//         platform: [filter.platform],
//         filter: [filter.warehouse],
//       },
//     });
//     return response?.all_data || [];
//   };
//
//   return (
//     <>
//       <HelpBlock type="product_orders" />
//       <div className="bg-gray-100 py-4">
//         <div className="wrapper-wide">
//           <ProductChart
//             fetchEnabled={fetchEnabled}
//             colors={[BROWN, GRAY_DARK]}
//             extra={{
//               [COLUMNS.REVIEWS_QTY]: ratingsData?.chartData?.[1] || [],
//               [COLUMNS.RATING]: ratingsData?.chartData?.[0] || [],
//             }}
//             labels={{
//               [COLUMNS.REVIEWS_QTY]: 'Отзывы',
//               [COLUMNS.RATING]: 'Рейтинг',
//             }}
//             labelsPostfix={{
//               [COLUMNS.REVIEWS_QTY]: `${
//                 productData?.reviews_count ? productData?.reviews_count?.toLocaleString('ru') : 0
//               } шт`,
//               [COLUMNS.RATING]: productData?.rating,
//             }}
//             loading={isRatingLoading}
//             error={isRatingError}
//             chartKey={TABLE_TYPE.PRODUCT_SALES}
//           />
//         </div>
//       </div>
//       <Table
//         autoSize={false}
//         data={data?.items || []}
//         type={TABLE_TYPE.PRODUCT_SALES}
//         hideSoldColumn={isOzonPlatform}
//         isLoading={isLoading || isFetching || productLoading}
//         noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
//         asyncGetDetailRowData={asyncGetDateHoursData}
//         typeNested={TABLE_TYPE.PRODUCT_SALES_INNER}
//       />
//     </>
//   );
// };
// Charts.propTypes = {
//   fetchEnabled: PropTypes.bool,
// };
// export default Charts;

const LABELS = {
  [COLUMNS.ORDERS_RUB_]: 'Заказы, руб',
  [COLUMNS.ORDERS_QTY_]: 'Заказы, шт',
  ['price']: 'Цена',
  ['stock']: 'Остаток',
  ['reviews_qty']: 'Отзывы',
  ['rating']: 'Рейтинг',
};

const INIT_LABELS = [COLUMNS.ORDERS_RUB_, 'price', 'stock'];
const COLORS = [PINK, BLUE, GREEN];
const COLORS_SCATTER = [VIKING, HELIOTROPE, YELLOW, LEAF, CONTESSA, HOT_PINK];

const Charts = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { filter } = useFilterReducer();

  const {
    data = [],
    isLoading,
    isFetching,
    isRefetching,
    error: isError,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_DAYS,
    id,
    enabled: fetchEnabled,
  });
  const {
    data: sumData = {},
    isLoading: sumDataLoading,
    isFetching: sumDataFetching,
    error: sumError,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.EXT_PRODUCT_SUM,
    id,
    enabled: fetchEnabled,
  });
  const platformColors = CHARTS_COLORS_WB;
  let transformedColors = [...platformColors, ...COLORS, ...platformColors, ...COLORS];
  const transformedData = {
    absolute: data
      .map((el) => {
        const { date, ...other } = el;
        const resultNote = { name: date };
        Object.entries(other).forEach(([key, value]) => {
          if (Object.keys(LABELS).includes(key)) {
            resultNote[`${key}_absolute`] = +value;
          } else {
            resultNote[`${key}_absolute`] = value;
          }
        });
        return resultNote;
      })
      .reverse(),
  };
  const asyncGetDateHoursData = async (params) => {
    const { data } = params;
    const { date } = data;
    const splittedDate = date.split('.');
    splittedDate[2] = `20${splittedDate[2]}`;
    const resultDate = splittedDate.reverse().join('-');
    const response = await apiClient.getProductHours({
      id,
      filter: {
        date: [resultDate],
        platform: [filter.platform],
        filter: [filter.warehouse],
      },
    });
    return response?.all_data || [];
  };
  let transformedLabelsPostfix = {
    [COLUMNS.ORDERS_RUB_]: `${sumData?.[COLUMNS.ORDERS_RUB_]?.toLocaleString('ru') || '-'} ₽`,
    [COLUMNS.ORDERS_QTY_]: `${sumData?.[COLUMNS.ORDERS_QTY_]?.toLocaleString('ru') || '-'} ₽`,
    ['price']: `${sumData?.['price_min']?.toLocaleString('ru')}-${sumData?.[
      'price_max'
    ]?.toLocaleString('ru')} ₽`,
    ['stock']: `${sumData?.['stock']?.toLocaleString('ru') || '-'} шт`,
    ['reviews_qty']: `${sumData?.['reviews_qty']?.toLocaleString('ru') || '-'} шт`,
    ['rating']: `${sumData?.['rating']?.toLocaleString('ru') || '-'}`,
  };

  return (
    <>
      <HelpBlock type="product_orders" />
      <div className="bg-gray-100 py-4">
        <div className="wrapper-wide">
          <MultiChart
            colors={transformedColors}
            data={transformedData}
            isLoading={isLoading || sumDataLoading || isFetching || sumDataFetching}
            error={isError || sumError}
            periods={[]}
            type={TYPE_ABSOLUTE}
            types={[TYPE_ABSOLUTE]}
            labels={LABELS}
            labelsPostfix={transformedLabelsPostfix}
            initialLabels={INIT_LABELS}
            columns={6}
            formatXAxis={formatXAxisDate}
            formatTooltipLabel={formatDateLabel}
            yAxisAngle={-30}
            scatterColors={COLORS_SCATTER}
            chartKey={TABLE_TYPE.PRODUCT_SALES}
          />
        </div>
        <div className="expandable-table">
          <Table
            autoSize={false}
            data={data}
            type={TABLE_TYPE.PRODUCT_DAYS}
            isLoading={isLoading || isFetching || isRefetching}
            noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
            asyncGetDetailRowData={asyncGetDateHoursData}
            typeNested={TABLE_TYPE.PRODUCT_SALES_INNER}
          />
        </div>
      </div>
    </>
  );
};
Charts.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Charts;

import PropTypes from 'prop-types';
import Table from 'components/Table';
import { TABLE_INNER_TYPE } from 'constants/table';

import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';

const OrdersSales = ({ fetchEnabled }) => {
  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.ORDERS_SALES,
    enabled: fetchEnabled,
  });

  return (
    <>
      {fetchEnabled && (
        <Table
          data={data}
          type={TABLE_INNER_TYPE.ORDERS_SALES}
          paginationOnBack={true}
          isLoading={isLoading || (isFetching && !hasNextPage)}
          noRowsText={`По вашему запросу нет данных.`}
        />
      )}
    </>
  );
};

OrdersSales.propTypes = {
  fetchEnabled: PropTypes.bool,
};

export default OrdersSales;

import React, { useMemo, useState } from 'react';
import baseInnerClient from '../services/baseInnerClient';
import BellIcon from '../assets/icons/BellIcon';

const ProductSettingsContext = React.createContext();
ProductSettingsContext.displayName = 'Product.Settings.Context';

const STORAGE_KEY = 'WeCheck_prime_cost';
const ProductSettingsProvider = (props) => {
  const storeValue = localStorage.getItem(STORAGE_KEY);
  const [primeCost, setPrimeCost] = useState(storeValue ? JSON.parse(storeValue) : []);
  const icon = useMemo(
    () => (
      <div className="relative h-[42px] min-w-[42px] flex justify-center items-center self-center mr-4">
        <div className="absolute rounded-full h-[42px] min-w-[42px] opacity-30 bg-[#6E6E6E]" />
        <BellIcon width={24} height={24} className="absolute mx-auto opacity-100" />
      </div>
    ),
    [],
  );

  const isChanged = (barcode) => primeCost.findIndex((el) => el.barcode === barcode) !== -1;

  const removeChanged = (barcode) => {
    const resultPrimeCosts = [...primeCost];
    const index = resultPrimeCosts.findIndex((el) => el.barcode === barcode);
    resultPrimeCosts.splice(index, 1);
    setPrimeCost(resultPrimeCosts);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(resultPrimeCosts));
  };

  const updatePrimeCost = (newObj) => {
    const resultPrimeCosts = JSON.parse(JSON.stringify(primeCost));
    const { barcode } = newObj;
    const index = resultPrimeCosts.findIndex((el) => el.barcode === barcode);
    if (index === -1) {
      resultPrimeCosts.push(newObj);
    } else {
      resultPrimeCosts.splice(index, 1, newObj);
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(resultPrimeCosts));
    setPrimeCost(resultPrimeCosts);
  };

  const handleUpdate = async (addAlertByKey, refetch) => {
    try {
      const result = await baseInnerClient.post('/user/in/products_setting', {
        prime_cost: primeCost.map((el) => ({ ...el, prime_cost: +el.prime_cost })),
      });
      const { data } = result;
      const { status } = data;
      if (status === 'success') {
        setPrimeCost([]);
        localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
        addAlertByKey({
          key: `success-${JSON.stringify(primeCost)}`,
          message: 'Себестоимость успешно сохранена',
          type: 'success',
          icon,
        });
        refetch();
      } else {
        addAlertByKey({
          key: `error-${JSON.stringify(primeCost)}`,
          message: 'Произошла ошибка, попробуйте еще раз',
          type: 'error',
          icon,
        });
      }
    } catch (e) {
      addAlertByKey({
        key: `error-${JSON.stringify(primeCost)}`,
        message: 'Произошла ошибка, попробуйте еще раз',
        type: 'error',
        icon,
      });
    }
  };

  const value = {
    primeCost,
    setPrimeCost,
    updatePrimeCost,
    isChanged,
    handleUpdate,
    removeChanged,
  };

  return <ProductSettingsContext.Provider value={value} {...props} />;
};

const useProductSettings = () => {
  return React.useContext(ProductSettingsContext);
};

export { ProductSettingsProvider, useProductSettings };

import DatePicker from 'react-datepicker';
import React from 'react';
import PropTypes from 'prop-types';

export const FilterDatePicker = ({ selectedDate, locale, handleChangeDate, maxDate, minDate }) => {
  return (
    <div className="filter-datepicker mb-4">
      <DatePicker
        selected={selectedDate}
        locale={locale}
        dateFormat="yyyy-mm-dd"
        onChange={handleChangeDate}
        maxDate={maxDate}
        minDate={minDate}
        inline
      />
    </div>
  );
};

FilterDatePicker.propTypes = {
  selectedDate: PropTypes.any,
  locale: PropTypes.any,
  handleChangeDate: PropTypes.func,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
};

import PropTypes from 'prop-types';
import TrashIcon from 'assets/icons/TrashIcon';

const ButtonReset = ({ onClick }) => (
  <button
    className="h-8 flex items-center text-green ml-auto transition-all hover:text-gray-900 focus:text-gray-900"
    onClick={onClick}
  >
    <span className="mr-2 -mt-1">
      <TrashIcon className="w-4 h-4" />
    </span>
    Сбросить
  </button>
);
ButtonReset.propTypes = { onClick: PropTypes.func };

export default ButtonReset;

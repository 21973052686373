export function PlusIcon({ ...svgProps }) {
  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none" {...svgProps}>
      <path
        d="M2.1005 9.00002H15.8995"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.99992 15.8995L8.99992 2.1005"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

const TrashIcon = (props) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.50002 4H2.80002H17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24982 3.79999V2.8773C5.24982 2.37941 5.43946 1.90191 5.77703 1.54985C6.11459 1.19779 6.57243 1 7.04982 1H10.6498C11.1272 1 11.585 1.19779 11.9226 1.54985C12.2602 1.90191 12.4498 2.37941 12.4498 2.8773V3.79999M14.5 7.30981L14.25 10.5951L13.75 15.1227C13.75 15.6206 13.5604 16.0981 13.2228 16.4501C12.8852 16.8022 12.4274 17 11.95 17H6.05002C5.57263 17 5.11479 16.8022 4.77722 16.4501C4.43966 16.0981 4.25002 15.6206 4.25002 15.1227L3.75002 10.5951L3.50002 7.30981"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;

import client from 'services/profile';
import { getToken } from './auth.methods';

const getTariffPrice = (info) => client.getTariffPrice(info, getToken());

const getPaymentLink = ({ info, businessmanInfo, arbitraryAmount, paymentTypeId }) =>
  client.getPaymentLink({
    info,
    businessmanInfo,
    arbitraryAmount,
    paymentTypeId,
    token: getToken(),
  });

const changePassword = (password, newPassword, repeatPassword) =>
  client.changePassword(password, newPassword, repeatPassword, getToken());

export { getTariffPrice, getPaymentLink, changePassword };

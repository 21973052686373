/**
 * @return {JSX.Element} Иконку "Облачка" (наподобие тех, что испльзуются в Соц.Сетях для обозначения диалогоа)
 *
 * @param {import('react').HTMLAttributes<SVGElement>} props
 */
export function CommentIcon({ ...svgProps }) {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M16.5 1.25391H3.5C2.11929 1.25391 1 2.37319 1 3.75391V11.7539C1 13.1346 2.11929 14.2539 3.5 14.2539H5V17.7319C5 17.7405 5.01012 17.7451 5.01658 17.7394L9 14.2539H16.5C17.8807 14.2539 19 13.1346 19 11.7539V3.75391C19 2.37319 17.8807 1.25391 16.5 1.25391Z"
        stroke="#219653"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

import PropTypes from 'prop-types';

import ozon from 'assets/images/logos/ozon.svg';
import wildberries from 'assets/images/logos/wildberries.svg';

const PLATFORMS = {
  1: {
    label: 'ozon',
    logo: ozon,
  },
  2: {
    label: 'wildberries',
    logo: wildberries,
  },
};

const PlatformLogo = ({ platformId }) => {
  const platform = PLATFORMS[platformId];

  if (!platform) {
    return <></>;
  }

  return <img alt={platform.label} src={platform.logo} />;
};

PlatformLogo.propTypes = {
  platformId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PlatformLogo;

import InsertGroup from './InsertGroup';
import ButtonPrimary from '../ButtonPrimary';
import PropTypes from 'prop-types';
import { useAuth } from 'context/auth.context';
import Popover from '../Popover';
import React, { useRef } from 'react';

const CompareInsert = ({ groupA, groupB, setGroupA, setGroupB, handleCompare }) => {
  const handleSubmit = () => handleCompare();
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();

  return (
    <div className="pt-4 px-8">
      <div className="p-4 border border-[#D9D9D9] rounded-lg bg-[#FAFAFA]">
        <span className="block text-[#6E6E6E]">
          Добавьте не меньше одного товара в каждую группу. Можно добавлять артикулы или ссылки на
          товары
        </span>
      </div>
      <div className="my-4 flex space-x-4">
        <InsertGroup list={groupA} title={'Группа А'} setList={setGroupA} id={'group1'} />
        <InsertGroup list={groupB} title={'Группа B'} setList={setGroupB} id={'group2'} />
      </div>

      <div className="relative w-fit">
        <div ref={tooltipNoTariffRef}>
          <ButtonPrimary
            onClick={handleSubmit}
            label="Получить отчёт"
            className="text-base"
            disabled={
              groupA.filter((el) => el).length === 0 ||
              groupB.filter((el) => el).length === 0 ||
              noTariff
            }
          />
          {noTariff && (
            <Popover
              tooltipRef={tooltipNoTariffRef}
              className="whitespace-nowrap"
              tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
            />
          )}
        </div>
      </div>
    </div>
  );
};

CompareInsert.propTypes = {
  groupA: PropTypes.array,
  groupB: PropTypes.array,
  setGroupA: PropTypes.func,
  setGroupB: PropTypes.func,
  handleCompare: PropTypes.func,
};

export default CompareInsert;

import { useFetchProblemsProductsSum } from './useFetchProblemsProductsSum';
import React, { useEffect, useState } from 'react';
import { useFetchProblemsSettings } from './useFetchProblemsSettings';

const ProblemsProductsContext = React.createContext();
ProblemsProductsContext.displayName = 'Problems.Products.Context';

const ProblemsProductsProvider = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const {
    data: problemsSettings = [],
    refetch: refetchProblemsSettings,
    isLoading: isSettingsLoading,
    isFetching: isSettingsFetching,
    isFetched: isSettingsFetched,
  } = useFetchProblemsSettings();
  const {
    data: problemProductsSum,
    refetch: refetchProblemsSum,
    isLoading: isSumLoading,
    isFetching: isSumFetching,
    isFetched: isSumFetched,
  } = useFetchProblemsProductsSum();
  useEffect(() => {
    if (isSumFetched) {
      if (problemProductsSum?.total_qty && problemProductsSum?.total_qty !== '-' && !showDetails) {
        setShowDetails(true);
      }
    }
  }, [isSumFetched, problemProductsSum, showDetails]);

  const value = {
    isExpanded,
    setIsExpanded,
    refetchProblemsSum,
    problemProductsSum,
    refetchProblemsSettings,
    isLoading: isSumLoading || isSettingsLoading || isSettingsFetching || isSumFetching,
    problemsSettings,
    isSumFetched,
    setShowDetails,
    showDetails,
    isFetched: isSumFetched && isSettingsFetched,
  };
  return <ProblemsProductsContext.Provider value={value} {...props} />;
};

const useProblemsProducts = () => {
  return React.useContext(ProblemsProductsContext);
};

export { ProblemsProductsProvider, useProblemsProducts };

import PropTypes from 'prop-types';
import cn from 'classnames';

import LoadingIcon from 'assets/icons/LoadingIcon';

const COMMON_CLASSES = 'w-4 h-4 mr-2';

const ButtonPlusMinus = ({ isLoading, onClick, isExpanded }) => {
  if (isLoading) {
    return <LoadingIcon className={cn(COMMON_CLASSES, 'animate-spin text-green')} />;
  }

  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className={cn(
        COMMON_CLASSES,
        `relative flex-none rounded border 
          hover:before:bg-green hover:border-green transition-all
          before:block before:absolute before:w-2 before:h-px before:bg-gray-700 before:top-2/4 before:left-2/4 before:-ml-1 before:-mt-[0.5px]`,
        {
          ['after:block after:absolute after:h-2 after:w-px after:bg-gray-700 after:top-2/4 after:left-2/4 after:-mt-1 after:-ml-[0.5px] hover:after:bg-green']:
            !isExpanded,
        },
      )}
    />
  );
};

ButtonPlusMinus.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isExpanded: PropTypes.bool,
};
export default ButtonPlusMinus;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_MODES } from './constants';
import { useUserList } from 'context/user.list.context';
import { useUI } from 'context/ui.context';
import cn from 'classnames';
import ButtonPrimary from '../ButtonPrimary';

const NewContent = ({ setMode }) => {
  const [newName, setNewName] = useState('Новый список');
  const { createList, setSelectedList } = useUserList();
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MODAL_MODES.NO_SELECTED);
    setNewName('Новый список');
  };
  const handleCreate = async () => {
    const createdList = await createList(newName);
    setMode(MODAL_MODES.NO_SELECTED);
    setNewName('Новый список');
    setSelectedList({ id: createdList });
  };
  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };
  return (
    <div
      className={cn({
        'w-full': isMobile,
      })}
    >
      <div className="p-5 lg:p-0">
        <input
          className={cn(
            'w-full lg:w-72 text-2xl font-bold focus:outline-none border-b border-gray-250 pb-1 text-center',
          )}
          value={newName}
          onChange={handleNewNameChange}
          autoFocus
        />
      </div>
      {isMobile ? (
        <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
            onClick={handleCancel}
            label="Отмена"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary"
            onClick={handleCreate}
            label="Добавить"
          />
        </div>
      ) : (
        <div className={'flex space-x-3 justify-center mt-6'}>
          <button
            className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
            onClick={handleCancel}
          >
            Отмена
          </button>
          <button className="py-2.5 px-3 rounded border bg-green text-white" onClick={handleCreate}>
            Добавить
          </button>
        </div>
      )}
    </div>
  );
};
NewContent.propTypes = {
  setMode: PropTypes.func,
};

const RenameListContent = ({ setMode, selectedList }) => {
  const [newName, setNewName] = useState(selectedList.name || '');
  const { renameList } = useUserList();
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MODAL_MODES.NO_SELECTED);
    setNewName('');
  };
  const handleRename = async () => {
    await renameList({ id: selectedList.id, name: newName });
    setMode(MODAL_MODES.NO_SELECTED);
    setNewName('');
  };
  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };
  return (
    <div
      className={cn({
        'w-full': isMobile,
      })}
    >
      <div className="p-5 lg:p-0">
        <input
          className="w-full lg:w-72 text-2xl font-bold focus:outline-none border-b border-gray-250 pb-1 text-center"
          value={newName}
          onChange={handleNewNameChange}
          autoFocus
        />
      </div>
      {isMobile ? (
        <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
            onClick={handleCancel}
            label="Отмена"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary"
            onClick={handleRename}
            label="Переименовать"
          />
        </div>
      ) : (
        <div className="flex space-x-3 justify-center mt-6">
          <button
            className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
            onClick={handleCancel}
          >
            Отмена
          </button>
          <button className="py-2.5 px-3 rounded border bg-green text-white" onClick={handleRename}>
            Переименовать
          </button>
        </div>
      )}
    </div>
  );
};
RenameListContent.propTypes = {
  setMode: PropTypes.func,
  selectedList: PropTypes.any,
};

const ClearListContent = ({ setMode, selectedList }) => {
  const { clearList } = useUserList();
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MODAL_MODES.NO_SELECTED);
  };
  const handleCreate = async () => {
    await clearList(selectedList.id);
    setMode(MODAL_MODES.NO_SELECTED);
  };
  return (
    <div
      className={cn({
        'w-full': isMobile,
      })}
    >
      <div className="p-5 lg:p-0">
        <span className="block text-gray-700 text-lg font-bold text-center mb-1">
          Вы действительно хотите <br />
          очистить список <span className="text-green">{selectedList.name}</span>?
        </span>
        <span className="block text-center text-gray-400">
          Содержимое списка {isMobile && <br />}безвозвратно удалится
        </span>
      </div>
      {isMobile ? (
        <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
            onClick={handleCancel}
            label="Нет, не надо"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary"
            onClick={handleCreate}
            label="Да, очистить"
          />
        </div>
      ) : (
        <div className="flex space-x-3 justify-center mt-6">
          <button
            className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
            onClick={handleCancel}
          >
            Нет, не надо
          </button>
          <button className="py-2.5 px-3 rounded border bg-green text-white" onClick={handleCreate}>
            Да, очистить
          </button>
        </div>
      )}
    </div>
  );
};
ClearListContent.propTypes = {
  setMode: PropTypes.func,
  selectedList: PropTypes.any,
};

const RemoveListContent = ({ setMode, selectedList, setSelectedList }) => {
  const { deleteList } = useUserList();
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MODAL_MODES.NO_SELECTED);
  };
  const handleCreate = async () => {
    await deleteList(selectedList.id);
    setMode(MODAL_MODES.NO_SELECTED);
    setSelectedList(null);
  };
  return (
    <div
      className={cn({
        'w-full': isMobile,
      })}
    >
      <div className="p-5 lg:p-0">
        <span className="block text-gray-700 text-lg font-bold text-center mb-1">
          Вы действительно хотите <br />
          удалить список <span className="text-green">{selectedList.name}</span>?
        </span>
        <span className="block text-center text-gray-400">Содержимое списка также удалится</span>
      </div>
      {isMobile ? (
        <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
            onClick={handleCancel}
            label="Не удалять"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary"
            onClick={handleCreate}
            label="Да, удалить"
          />
        </div>
      ) : (
        <div className="flex space-x-3 justify-center mt-6">
          <button
            className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
            onClick={handleCancel}
          >
            Не удалять
          </button>
          <button className="py-2.5 px-3 rounded border bg-green text-white" onClick={handleCreate}>
            Да, удалить
          </button>
        </div>
      )}
    </div>
  );
};
RemoveListContent.propTypes = {
  setMode: PropTypes.func,
  selectedList: PropTypes.any,
  setSelectedList: PropTypes.func,
};

const RemoveFavoriteContent = ({ setMode, selectedList, setSelectedList, selectedFavorite }) => {
  const { activeListType, removeFavourite } = useUserList();
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MODAL_MODES.NO_SELECTED);
  };
  const handleCreate = async () => {
    await removeFavourite({
      listId: selectedList.id,
      id: selectedFavorite.id,
      type: activeListType,
      callback: () => {},
    });
    setMode(MODAL_MODES.NO_SELECTED);
    setSelectedList(null);
  };

  const getWordByActiveType = () => {
    switch (activeListType) {
      case 0: {
        return 'товар';
      }
      case 1: {
        return 'продавца';
      }
      case 2: {
        return 'бренд';
      }
      default:
        return 'категорию';
    }
  };
  return (
    <div
      className={cn({
        'w-full': isMobile,
      })}
    >
      <div className="p-5 lg:p-0">
        <span className="block text-gray-700 text-lg font-bold text-center mb-1">
          Вы действительно хотите <br />
          удалить {getWordByActiveType()}{' '}
          <span className="text-green">{selectedFavorite.name}</span>
          <br />
          из списка <span className="text-green">{selectedList.name}</span>?
        </span>
        <span className="block text-center text-gray-400">Содержимое списка также удалится</span>
      </div>
      {isMobile ? (
        <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
            onClick={handleCancel}
            label="Не удалять"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary"
            onClick={handleCreate}
            label="Да, удалить"
          />
        </div>
      ) : (
        <div className="flex space-x-3 justify-center mt-6">
          <button
            className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
            onClick={handleCancel}
          >
            Не удалять
          </button>
          <button className="py-2.5 px-3 rounded border bg-green text-white" onClick={handleCreate}>
            Да, удалить
          </button>
        </div>
      )}
    </div>
  );
};
RemoveFavoriteContent.propTypes = {
  setMode: PropTypes.func,
  selectedList: PropTypes.any,
  setSelectedList: PropTypes.func,
  selectedFavorite: PropTypes.any,
};

const getContentByMode = (mode) => {
  switch (mode) {
    case MODAL_MODES.NEW: {
      return NewContent;
    }
    case MODAL_MODES.RENAME: {
      return RenameListContent;
    }
    case MODAL_MODES.CLEAR: {
      return ClearListContent;
    }
    case MODAL_MODES.DELETE: {
      return RemoveListContent;
    }
    case MODAL_MODES.REMOVE_FAVORITE: {
      return RemoveFavoriteContent;
    }
  }
};

const QuestionContent = ({ mode, setMode, selectedList, setSelectedList, selectedFavorite }) => {
  const Content = getContentByMode(mode);
  if (!selectedList && mode !== MODAL_MODES.NEW) return null;
  return (
    <div className="w-full flex grow items-center justify-center">
      <Content
        selectedList={selectedList}
        setMode={setMode}
        setSelectedList={setSelectedList}
        selectedFavorite={selectedFavorite}
      />
    </div>
  );
};

QuestionContent.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  selectedList: PropTypes.any,
  setSelectedList: PropTypes.func,
  selectedFavorite: PropTypes.any,
};

export default QuestionContent;

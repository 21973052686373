import React, { useState } from 'react';

import { useTable } from 'context/table.context';

const TableTooltipContext = React.createContext();
TableTooltipContext.displayName = 'Table.Tooltip.Context';

const TABLE_TOOLTIP_TARGETS = {
  SORT: 'sort',
  BAR: 'bar',
  INFO: 'info',
};

const DISABLED_FILTER_TOOLTIP =
  'Чтобы использовать эти фильтры выберите один из фильтров:<br/>по категории, бренду или продавцу';

const TableTooltipProvider = (props) => {
  const [target, setTarget] = useState(null);
  const { isTableNeedHideFilter, type } = useTable();

  const isFilterHidden = isTableNeedHideFilter(type);

  const setSortTooltip = () => {
    setTarget(TABLE_TOOLTIP_TARGETS.SORT);
  };

  const setBarTooltip = () => {
    setTarget(TABLE_TOOLTIP_TARGETS.BAR);
  };

  const setInfoTooltip = () => {
    setTarget(TABLE_TOOLTIP_TARGETS.INFO);
  };

  const cleanTooltip = () => {
    setTarget(null);
  };

  const getHeaderTooltip = (colDef) => {
    if (target === TABLE_TOOLTIP_TARGETS.INFO) {
      return colDef?.headerTooltip || '';
    }
    if (
      isFilterHidden &&
      (target === TABLE_TOOLTIP_TARGETS.BAR || target === TABLE_TOOLTIP_TARGETS.SORT)
    ) {
      return DISABLED_FILTER_TOOLTIP;
    }
  };

  const value = {
    setSortTooltip,
    setBarTooltip,
    setInfoTooltip,
    cleanTooltip,
    getHeaderTooltip,
    target,
  };
  return <TableTooltipContext.Provider value={value} {...props} />;
};

const useTableTooltip = () => React.useContext(TableTooltipContext);

export { TableTooltipProvider, useTableTooltip };

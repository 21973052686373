import { Tab } from '@headlessui/react';
import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_MODES } from '../constants';
import { useUserList } from 'context/user.list.context';
import { LIST_DATA } from 'constants/list';

const MobileTabs = ({ setModalMode }) => {
  const { activeListType, handleActivationListType } = useUserList();
  const handleListActivation = (id) => {
    setModalMode(MODAL_MODES.NO_SELECTED);
    handleActivationListType(id);
  };
  return (
    <Tab.Group>
      <Tab.List
        className={`border-b-2 lg:border-b-0 border-gray-150 lg:border-r
      flex-none flex lg:flex-col lg:w-[245px]
      px-5 lg:px-0 lg:pt-6
      overflow-y-hidden`}
      >
        {Object.values(LIST_DATA)
          .reverse()
          .map((list) => ({ id: list.id, label: list.labelMobile }))
          .map(({ id, label }) => {
            const selected = activeListType === id;
            return (
              <Fragment key={`tab-${id}`}>
                <div className="relative w-full h-fit">
                  <button
                    onClick={() => {
                      handleListActivation(id);
                    }}
                    className={cn(
                      'pl-1 lg:pl-6 lg:pr-16 py-4 lg:py-3 text-center hover:text-green w-[calc(100%+1px)]',
                      {
                        ['text-green border-b-2 pr-1 lg:border-b-0 lg:border-r-2 border-green lg:bg-green-100 lg:mb-0']:
                          selected,
                        ['text-black']: !selected,
                      },
                    )}
                  >
                    {label}
                  </button>
                </div>
              </Fragment>
            );
          })}
      </Tab.List>
      {/*      <Tab.Panels*/}
      {/*          className={`h-full lg:max-h-full lg:h-[500px] lg:flex-1 */}
      {/*px-4 lg:p-6 */}
      {/*overflow-y-scroll lg:overflow-hidden`}*/}
      {/*      >*/}
      {/*          {tabs.map(({ available, panel, id }) => (*/}
      {/*              <Fragment key={`panel-${id}`}>*/}
      {/*                  {available && <Tab.Panel className="h-full flex flex-col">{panel}</Tab.Panel>}*/}
      {/*              </Fragment>*/}
      {/*          ))}*/}
      {/*      </Tab.Panels>*/}
    </Tab.Group>
  );
};

MobileTabs.propTypes = {
  tabs: PropTypes.any,
  setModalMode: PropTypes.func,
};

export default MobileTabs;

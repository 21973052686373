const DiagramIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.711381 11.9331L0.0446777 11.022L3.28903 8.71093L5.31128 10.2443L10.2224 4.13321L14.2001 7.13312L17.7779 1.7998L19.9779 3.53313L19.2667 4.42194L18.0445 3.44412L14.4667 8.75521L10.4223 5.68868L5.5112 11.7998L3.26681 10.1108L0.711381 11.9331Z"
        fill="#219653"
      />
    </svg>
  );
};

export default DiagramIcon;

import React from 'react';
import PropTypes from 'prop-types';

const DefaultLayout = ({ children }) => <main className="wrapper">{children}</main>;

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default DefaultLayout;

import AuthorizationForm from 'components/AuthorizationForm';
import { IS_PRODUCTION } from 'constants/environment';
import { useAuth } from 'context/auth.context';
import gtag from 'ga-gtag';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import ym from 'react-yandex-metrika';

const LoginForm = ({ showNavigation }) => {
  const history = useHistory();
  const { login } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    return new Promise((resolve, reject) => {
      login(values)
        .then((data) => {
          if (data) {
            if (typeof data === 'object' && 'success' in data && !data.success) {
              setError(data?.message || 'Что пошло не так. Попробуйте позже ещё раз.');
            } else {
              setError(null);

              try {
                if (IS_PRODUCTION) {
                  gtag('event', 'user_login', {
                    login: 'success',
                  });

                  ym('reachGoal', 'login_success');
                }
              } catch (err) {
                console.error(`Error in LoginForm -> ${err}`);
              }
            }

            //window.location.assign(window.location);
            window.location.pathname = '/navigation';
            resolve(data);
          } else {
            throw new Error('No data from API');
          }
        })
        .catch((e) => {
          console.error(e);

          if (e.response && e?.response?.data?.error === 'Вы не подтвердили свой e-mail') {
            history.push('/send-mail-confirmation');
          }

          setError(e?.response?.data?.error ?? 'Неверный e-mail или пароль');

          reject(error);
        });
    });
  };

  return (
    <>
      <AuthorizationForm
        fields={[
          {
            name: 'username',
            label: 'E-mail',
            type: 'text',
          },
          {
            name: 'password',
            label: 'Пароль',
            type: 'password',
          },
          // {
          //   name: 'remember',
          //   label: 'Запомнить меня',
          //   type: 'checkbox',
          // },
        ]}
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        submitLabel="Войти"
        superError={error}
        showNavigation={showNavigation}
        useCaptcha
      />
      <NavLink to="/recovery-password" className="text-green">
        Забыли пароль?
      </NavLink>
    </>
  );
};

LoginForm.defaultProps = {
  showNavigation: true,
};

LoginForm.propTypes = {
  showNavigation: PropTypes.bool,
};

export default LoginForm;

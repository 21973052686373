import React, { lazy, useCallback, useEffect, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useUserTariffs } from 'context/user.tariffs.context';
import { transformPriceSegmentChartsData } from 'helpers/transformChartsData';
import { NAME_KEY, TYPE_ABSOLUTE, CHART_TYPE } from 'constants/chart';

import { TABLE_TYPE } from 'constants/table';
import Table from 'components/Table';
import { useMultiChartFilteredData } from 'hooks/useMultiChartFilteredData';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import HelpBlock from 'components/HelpBlock';

const PriceSegmentChart = lazy(() => import('components/PriceSegmentChart'));

export const LABELS_KEYS = {
  ORDERS_RUB: 'orders_rub',
  ORDERS_QTY: 'orders_qty',
  SELLERS_WITH_ORDERS: 'sellers_with_orders_qty',
  SELLERS: 'sellers_qty',
  SELLER_ABG_ORDERS: 'avg_orders_1seller_rub',
  AVG_ORDERS: 'avg_orders_1sku_rub',
  BRANDS: 'brands_qty',
  BRANDS_WITH_ORDERS: 'brands_with_orders_qty',
  SKU_QTY: 'sku_qty',
  SKU_WITH_ORDERS: 'sku_with_orders_qty',
};

export const LABELS = {
  [LABELS_KEYS.ORDERS_RUB]: 'Заказы, руб.',
  [LABELS_KEYS.SELLERS_WITH_ORDERS]: 'Продавцы с продажами, шт.',
  [LABELS_KEYS.SELLER_ABG_ORDERS]: 'Заказов у продавца, руб.',
  [LABELS_KEYS.BRANDS]: 'Бренды, шт.',
  [LABELS_KEYS.SKU_QTY]: 'Товары, шт.',
  [LABELS_KEYS.ORDERS_QTY]: 'Заказы, шт.',
  [LABELS_KEYS.SELLERS]: 'Продавцы, шт.',
  [LABELS_KEYS.AVG_ORDERS]: 'Заказов на товар, руб.',
  [LABELS_KEYS.BRANDS_WITH_ORDERS]: 'Бренды с продажами, шт.',
  [LABELS_KEYS.SKU_WITH_ORDERS]: 'Товары с продажами, шт.',
};

const PriceSegments = () => {
  const { isPriceSegmentsAvailable } = useUserTariffs();
  const { priceRange: range, step, setPriceRange } = useFilterReducer();

  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [rangeTouched, setRangeTouched] = useState(false);

  const { data, isLoading, isFetching, error } = useMultiChartFilteredData({
    type: CHART_TYPE.PRICE_SEGMENT,
  });

  const transformedData = transformPriceSegmentChartsData({
    data,
    keys: [TYPE_ABSOLUTE],
    nameKey: NAME_KEY,
  });

  const handleSubmit = ({ range, step }) => {
    setPriceRange({ range, step });
  };

  const onDataReady = useCallback(() => {
    const { step: dataStep, max_price, min_price } = transformedData;
    let minPriceDefault = min_price;
    let maxPriceDefault = max_price;
    let newStep = null;
    let newRange = null;

    // дефолтный интеревал должен быть 0 - 5000,
    // но если бэк как min_price возвращает значение больше 5000
    // дефолтный интервал становится min_price - min_price + 5000
    if (minPriceDefault > 5000) {
      maxPriceDefault =
        maxPriceDefault >= minPriceDefault + 5000 ? minPriceDefault + 5000 : maxPriceDefault;
    } else if (maxPriceDefault >= 5000 && minPriceDefault < 5000) {
      maxPriceDefault = 5000;
    }

    const isMinPrice = (minPriceDefault || minPriceDefault === 0) && minPriceDefault !== min;
    const isMaxPrice = (maxPriceDefault || maxPriceDefault === 0) && maxPriceDefault !== max;

    if (dataStep && +dataStep !== +step) {
      newStep = +dataStep;
    }

    if (isMinPrice && isMaxPrice) {
      newRange = [minPriceDefault, maxPriceDefault];
    }

    if (newStep || newRange) {
      setPriceRange({ step: newStep || step, range: newRange || range });
    }

    isMinPrice && setMin(min_price);
    isMaxPrice && setMax(max_price);
  }, [transformedData, max, min, step, range, setPriceRange]);

  const handleRangeTouched = () => {
    !rangeTouched && setRangeTouched(true);
  };

  useEffect(() => {
    setRangeTouched(false);
  }, [step, range]);

  useEffect(() => {
    onDataReady();
  }, [onDataReady]);

  if (!isPriceSegmentsAvailable) {
    return <Redirect to="/main" />;
  }

  return (
    <>
      <HelpBlock type="price_segments" />
      <div className="bg-gray-100 pt-4 pb-8">
        <div className="wrapper-wide">
          <PriceSegmentChart
            data={transformedData}
            loading={isLoading}
            error={error}
            handleSubmit={handleSubmit}
            step={step}
            range={range}
            min={min}
            max={max}
            rangeTouched={rangeTouched}
            handleRangeTouched={handleRangeTouched}
            labels={LABELS}
          />
        </div>
      </div>
      <Table
        hidePagination={true}
        data={data || []}
        isLoading={isLoading || isFetching}
        type={TABLE_TYPE.PRICE_SEGMENT}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
    </>
  );
};

export default PriceSegments;

const EditIcon = (props) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.2102 6.96466L4.98816 13.1867C4.95285 13.222 4.90495 13.2419 4.855 13.2419H1.355C1.251 13.2419 1.16669 13.1575 1.16669 13.0535L1.16669 9.55355C1.16669 9.5036 1.18653 9.4557 1.22184 9.42039L6.50018 4.14205L7.44389 3.19834M11.2102 6.96466L12.6495 5.52537C12.8946 5.28023 12.8946 4.88278 12.6495 4.63764L9.77091 1.75905C9.52577 1.51391 9.12832 1.51391 8.88318 1.75905L7.44389 3.19834M11.2102 6.96466L7.44389 3.19834"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;

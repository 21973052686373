import { usePayment } from 'context/payment.context';
import { useUI } from 'context/ui.context';

import Alert from 'components/Alert';
import MobilePayment from './MobilePayment';
import DesktopPayment from './DesktopPayment';

const PaymentModal = () => {
  const { errors, removeErrorByKey } = usePayment();
  const { isMobile } = useUI();

  return (
    <>
      {isMobile ? <MobilePayment /> : <DesktopPayment />}
      <Alert hide={removeErrorByKey} items={errors} />
    </>
  );
};

export default PaymentModal;

import { useQuery } from 'react-query';

import { CHART_TYPE } from 'constants/chart';
import { STALE_TIME } from 'constants/query';
import apiClient from 'services/baseClient';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { getFilterParamsForBack } from 'hooks/useFetchFilteredData';
import { useTable } from 'context/table.context';
import { F } from 'constants/filter';

const action = {
  [CHART_TYPE.BRANDS]: (props) => apiClient.getBrandsChart(props),
  [CHART_TYPE.PRODUCTS]: (props) => apiClient.getProductsChart(props),
  [CHART_TYPE.SELLERS]: (props) => apiClient.getSellersChart(props),
  [CHART_TYPE.ORDERS]: (props) => apiClient.getOrdersChart(props),
};

const TYPE_WITH_NEW_ADVERTISING = [CHART_TYPE.ORDERS];

const useFetchFilteredChartsDataOptions = ({ type, date }) => {
  const { filter, isLoaded, isOzonPlatform } = useFilterReducer();
  const { isTableSortedOnBack } = useTable();

  const { isNew, hasAdvertising, sort, order } = filter;

  const filterForBack = getFilterParamsForBack({ filter, type });
  filterForBack.date = date || filter.date;

  const options = {
    filter: filterForBack,
  };

  if (TYPE_WITH_NEW_ADVERTISING.includes(type)) {
    options.isNew = isNew;
    options.hasAdvertising = hasAdvertising;
  }

  if (isTableSortedOnBack(type)) {
    options.sort = sort;
    options.order = order;
  } else {
    options.sortDefault = true;
  }

  if (!isOzonPlatform) {
    options.warehouseType = filter[F.WAREHOUSE_TYPE] || '';
  }

  const queryKey = [type, options];

  return {
    actionOption: options,
    queryOptions: {
      queryKey,
      enabled: isLoaded,
      refetchOnmount: false,
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME,
      retry: 3,
      keepPreviousData: true,
    },
  };
};

export const useFetchFilteredChartsData = ({ type, date = null }) => {
  const { actionOption, queryOptions } = useFetchFilteredChartsDataOptions({
    type,
    date,
  });

  return useQuery({
    ...queryOptions,
    queryFn: action[type] ? () => action[type](actionOption) : null,
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logoVertical.svg';
import ButtonBack from 'components/ButtonBack';

const AuthorizationLayout = ({ children, hideButtonBack }) => {
  const { search } = useLocation();
  const isDemo = search.indexOf('?demo-message') >= 0;
  const showBack = !hideButtonBack && !isDemo;

  return (
    <main className="wrapper flex-auto flex flex-col">
      {showBack && (
        <div className="self-start mt-6">
          <ButtonBack backTo={window.location.pathname === '/reset-password' ? '/' : null} />
        </div>
      )}
      <div
        className={`self-center m-auto flex flex-col items-center max-w-full w-100 ${
          showBack ? '' : 'pt-2'
        }`}
      >
        <NavLink to="/">
          <Logo />
        </NavLink>
        <div className="mt-16 w-full flex flex-col items-center">{children}</div>
      </div>
    </main>
  );
};
AuthorizationLayout.defaultProps = {
  hideButtonBack: false,
};

AuthorizationLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  hideButtonBack: PropTypes.bool,
};

export default AuthorizationLayout;

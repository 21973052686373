import React from 'react';
import PropTypes from 'prop-types';

import { PROMO_CODE_MODAL, useUI } from 'context/ui.context';
import {
  Input,
  Banks,
  BusinessFields,
  PriceWithDiscount,
  DiscountRows,
} from 'components/PaymentModal/components';
import CrossIcon from 'assets/icons/CrossIcon';
import Arrow from 'assets/icons/Arrow';
import { usePayment } from 'context/payment.context';

const Row = ({ children }) => (
  <div className="mb-1 flex items-center text-xs justify-between">{children}</div>
);

Row.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

const InputRadio = ({ checked, onChange, id, label }) => (
  <Row>
    <label htmlFor={id} className="text-gray-500 flex-1 py-1">
      {label}
    </label>
    <input
      type="radio"
      className="accent-green w-4 h-4"
      checked={checked}
      id={id}
      onChange={onChange}
      value={id}
    />
  </Row>
);
InputRadio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

const InputCheckbox = ({ checked, onChange, id, label }) => (
  <Row>
    <label htmlFor={id} className="text-gray-500 flex-1 py-1">
      {label}
    </label>
    <input
      id={id}
      name={id}
      type="checkbox"
      className={`checkbox h-4 w-4 shrink-0 bg-origin-border
        align-middle color-green-bright border-green-bright border
        rounded appearance-none transition-all
        checked:border-transparent checked:bg-contain checked:bg-center checked:bg-no-repeat checked:bg-green-bright`}
      onChange={onChange}
      checked={checked}
    />
  </Row>
);

InputCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

const ButtonArrow = ({ onClick, label }) => (
  <Row>
    <button
      onClick={onClick}
      className="text-gray-250 hover:text-green flex-1 flex justify-between py-1"
    >
      <span className="flex-1 text-gray-500 hover:text-green text-left">{label}</span>
      <Arrow />
    </button>
  </Row>
);

ButtonArrow.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClick: PropTypes.func.isRequired,
};

const Section = ({ title, children }) => (
  <div className="pt-4 px-4 border-t">
    {title && <h3 className="mb-3 font-bold text-sm">{title}</h3>}
    {children}
  </div>
);

Section.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

const PaymentFieldLabel = ({ label, price, percent }) => {
  return (
    <span className="flex">
      {label}
      {(price || price === 0) && (
        <div className="text-green pl-2 ml-2 border-l mr-2">{price} Р/мес</div>
      )}
      {percent && percent !== 0 && <div className="text-red-90">-{percent}%</div>}
    </span>
  );
};
PaymentFieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percent: PropTypes.number,
};

const PaymentTitle = ({ label }) => {
  return <span className="flex justify-between">{label}</span>;
};
PaymentTitle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const MobilePayment = () => {
  const { setActiveModal } = useUI();

  const {
    paymentOptions,
    businessInfoFields,
    businessTypeOptions,
    isBusinessInfoShown,
    paymentRequestFields,
    agreeWithContract,
    toggleAgreeWithContract,
    paymentAmount,
    isUserAgree,
    handlePayment,
    closePayment,
    isOpenPayment,
    validationErrors,
  } = usePayment();

  const {
    discount: { days, percent, rub },
  } = usePayment();

  const handleOpenPromoCode = () => {
    setActiveModal(PROMO_CODE_MODAL);
  };

  if (!isOpenPayment) return <></>;

  return (
    <div className="flex top-0 left-0 fixed overflow-hidden min-w-[100vw] min-h-[100vh] bg-black z-[10100]">
      <div className="flex w-full flex-col relative grow overflow-x-hidden h-auto bg-white">
        <div className="flex absolute w-full h-full left-0 top-0 grow overflow-y-auto flex-col">
          <div className="p-4 flex justify-between items-center">
            <div className="font-bold text-base">Оплата</div>
            <CrossIcon onClick={closePayment} className="h-5 w-5 text-green fill-green" />
          </div>

          {!isBusinessInfoShown &&
            paymentRequestFields.map((field) => {
              return (
                <Section title={<PaymentTitle {...field} />} key={field.id}>
                  {field.options.map((option) => (
                    <InputRadio
                      key={option.value}
                      label={<PaymentFieldLabel {...option} />}
                      checked={+field.value === +option.value}
                      onChange={field.onChange}
                      id={option.value}
                    />
                  ))}
                </Section>
              );
            })}

          {isBusinessInfoShown && (
            <div className="px-4">
              {businessTypeOptions && <BusinessFields businessTypeOptions={businessTypeOptions} />}

              {businessInfoFields.map((field) => (
                <Input
                  key={field.id}
                  label={
                    field.isRequired ? (
                      <span>
                        {field.label}
                        <span className="text-red">*</span>
                      </span>
                    ) : (
                      field.label
                    )
                  }
                  onChange={field.onChange}
                  id={field.id}
                  value={field.value}
                  isInvalid={validationErrors.includes(field.id)}
                />
              ))}
            </div>
          )}

          <Section title="Способ оплаты">
            {paymentOptions.map((option) => (
              <InputRadio
                key={option.value}
                label={option.title}
                checked={option.checked}
                onChange={option.action}
                id={option.value}
              />
            ))}
          </Section>

          <Section>
            <ButtonArrow label="Добавить промокод" onClick={handleOpenPromoCode} />
            <InputCheckbox
              label={
                <>
                  Согласен с{' '}
                  <a
                    className="text-green hover:underline focus:underline"
                    href="https://www.wecheck.ru/agreement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    договором-офертой
                  </a>
                </>
              }
              onChange={toggleAgreeWithContract}
              checked={agreeWithContract}
              className="items-center"
              id="agreeWithContract"
            />
            {!isUserAgree ? (
              <div className="text-orange text-xs -mt-1">Пожалуйста, подтвердите согласие</div>
            ) : (
              <></>
            )}
          </Section>

          <Section>
            <div className="flex justify-between text-lg font-bold mb-1.5">
              <span>Сумма</span>
              <PriceWithDiscount />
            </div>

            <DiscountRows days={days} percent={percent} rub={rub} />
          </Section>

          <div className="px-4 mb-4 mt-4">
            <button className="button-primary w-full py-2 mt-8 mb-3" onClick={handlePayment}>
              Оплатить{' '}
              {paymentAmount || paymentAmount === 0
                ? `${parseInt(paymentAmount).toLocaleString('ru')} ₽`
                : ''}{' '}
            </button>
            <div className="border rounded border-gray-250 box-border p-2 flex justify-center">
              <Banks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobilePayment.propTypes = {
  closePayment: PropTypes.func,
  isOpenAccountFields: PropTypes.bool,
  businessmanType: PropTypes.string,
  setBusinessmanType: PropTypes.func,

  businessmanInfo: PropTypes.object,
  setForm: PropTypes.func,
  paymentAmount: PropTypes.number,

  setAgreeWithContract: PropTypes.func,
  handlePay: PropTypes.func,
  onChangeFormHandler: PropTypes.func,
  data: PropTypes.array,
  form: PropTypes.object,
  onChangeBusinessmanInput: PropTypes.func,
  agreeWithContractValidate: PropTypes.bool,
};

export default MobilePayment;

import { useAuth } from 'context/auth.context';
import { useUI } from 'context/ui.context';

import Dropdown from 'components/Dropdown';
import { PlatformMobileButton } from './';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { F } from 'constants/filter';
import PropTypes from 'prop-types';

const PLATFORMS = {
  1: 'ozon',
  2: 'wb',
};

export const PlatformSelect = ({ callback }) => {
  const { platforms } = useAuth();
  const { isMobile, setActiveModal } = useUI();
  const { filter, setPlatform } = useFilterReducer();

  const onChange = (platform) => {
    setPlatform(platform);
    callback && callback();
  };

  const selectedPlatform = filter?.[F.PLATFORM];
  // Disable ozon on platform select
  const optionsPlatform = platforms
    .filter(({ id }) => id !== 1)
    .map((platform) => ({
      value: `${platform?.id}`,
      label: platform?.name === 'wb' ? (isMobile ? 'WB' : 'Wildberries') : platform?.name,
    }));

  const selected =
    optionsPlatform?.filter((item) => +item?.value === +selectedPlatform)?.[0]?.value || '';

  const label =
    optionsPlatform?.filter((item) => +item?.value === +selectedPlatform)?.[0]?.label || '';

  const platformColor = PLATFORMS[+selected] ? PLATFORMS[+selected] : null;

  const OnMobileClick = () => {
    setActiveModal('platform');
  };

  if (isMobile) {
    return (
      <PlatformMobileButton
        bg={platformColor}
        type="platform"
        label={label}
        onClick={OnMobileClick}
      />
    );
  }

  return (
    <Dropdown
      label={label}
      bgColor={platformColor}
      color="white"
      options={optionsPlatform}
      onChange={onChange}
      selected={selected}
      className="z-10000"
    />
  );
};

PlatformSelect.propTypes = {
  callback: PropTypes.func,
};

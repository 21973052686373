import CheckIcon from 'assets/icons/CheckIcon';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { Switch } from '@headlessui/react';
import { TimerIcon } from 'assets/icons/TimerIcon';
import baseInnerClient from 'services/baseInnerClient';
import { F_INNER } from 'constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { PricesAlertContext } from 'pages/Inner/Prices/Prices';
import BellIcon from 'assets/icons/BellIcon';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';
import ButtonPrimary from '../ButtonPrimary';
import Input from '../Input';

const TableInnerDiscountChanger = (props) => {
  const { filter } = useFilterReducer();
  const { value: propsValue, eGridCell, data } = props;
  const [value, setValue] = useState(propsValue);
  const [isActive, setIsActive] = useState(false);
  const [useDate, setUseDate] = useState(false);
  const [currentFocus, setCurrentFocus] = useState('main');
  const [changed, setChanged] = useState(false);
  const [isChangedHovered, setChangeHovered] = useState(false);
  const [isDateValid, setIsDateValid] = useState(true);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const inputRef = useRef();
  const popUpRef = useRef();
  const cellRef = useRef();
  const addAlertByKey = useContext(PricesAlertContext);
  const { isMobile } = useUI();
  const icon = useMemo(
    () => (
      <div className="relative h-[42px] min-w-[42px] flex justify-center items-center self-center mr-4">
        <div className="absolute rounded-full h-[42px] min-w-[42px] opacity-30 bg-[#6E6E6E]" />
        <BellIcon width={24} height={24} className="absolute mx-auto opacity-100" />
      </div>
    ),
    [],
  );
  const handleSubmit = useCallback(async () => {
    if (isActive) {
      setIsActive(false);
      const requestBody = { id: data.id, discount: +value };
      if (useDate && date && time) {
        requestBody['activate_from'] = `${date.split('-').reverse().join('-')} ${time}:00`;
      }
      try {
        const result = await baseInnerClient.put('/user/in/discounts', {
          data: [requestBody],
          user_api_key_id: filter[F_INNER.API_KEYS][0],
        });
        if (result.status === 200) {
          addAlertByKey({
            key: `success-${value + '-' + data.id}`,
            message: (
              <div>
                <span className="block">Скидка изменена</span>
                <span className="block">{`${propsValue} ₽ → ${value} ₽`}</span>
                <span className="block">Новая скидка отобразится</span>
                <span className="block">на сайте через 5 минут</span>
              </div>
            ),
            type: 'success',
            icon,
          });
          setChanged(true);
        }
      } catch (e) {
        addAlertByKey({
          key: `error-${value + '-' + data.id}`,
          message: 'Не удалось изменить скидку. Повторите позже',
          type: 'error',
          icon,
        });
      }
    }
  }, [addAlertByKey, data.id, date, filter, icon, isActive, propsValue, time, useDate, value]);
  const removePrevPopup = () => {
    const popup = document.getElementById('discountPopup');
    if (popup) {
      popup.remove();
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (
        !popUpRef.current ||
        popUpRef.current.contains(event.target) ||
        !cellRef.current ||
        cellRef.current.contains(event.target)
      ) {
        return;
      }
      setIsActive(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [popUpRef, cellRef]);

  useEffect(() => {
    const prev = document.getElementById('changedPopup');
    if (!isActive && changed && useDate && date && time && isChangedHovered && !isMobile) {
      if (prev) {
        prev.remove();
      }
      const root = document.getElementsByClassName('ag-root-wrapper')[0];
      root.classList.add('relative');
      const cellCoord = eGridCell.getBoundingClientRect();
      const rootCoord = root.getBoundingClientRect();
      const top = Math.round(cellCoord.y - 76 - rootCoord.y);
      const left = Math.round(cellCoord.x - rootCoord.x);
      const popup = document.createElement('div');
      popup.setAttribute('id', 'changedPopup');
      popup.className = `absolute `;
      popup.style.top = `${top}px`;
      popup.style.left = `${left}px`;
      root.appendChild(popup);
      ReactDOM.render(
        <div
          className="rounded bg-[#FFFFFF] p-4"
          style={{
            filter:
              'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))',
          }}
        >
          <span className="block">Скидка начнет действовать</span>
          <span className="block">{`${date.split('-').join('.')}, ${time}`}</span>
        </div>,
        popup,
      );
    } else {
      if (prev) {
        prev.remove();
      }
    }
  }, [isChangedHovered, changed, useDate, date, time, eGridCell, isActive, isMobile]);

  const checkDate = useCallback(() => {
    const splittedDate = date.split('-').reverse();
    const enteredDate = new Date(splittedDate[0], +splittedDate[1] - 1, splittedDate[2]);
    if (time) {
      const splittedTime = time.split(':');
      enteredDate.setHours(splittedTime[0], splittedTime[1]);
    }
    return enteredDate > new Date();
  }, [date, time]);

  const popupContent = useMemo(
    () => (
      <>
        {isMobile && (
          <div className={'pb-4'}>
            <Input
              name="discount"
              onChange={(value) => setValue(value)}
              value={value}
              valid={true}
              className="h-[32px]"
            />
          </div>
        )}
        <span className="block pb-3 border-b border-gray-230">
          {`Задайте размер скидки `}
          {!isMobile && <br />}
          {'целым числом от 3 до 95%'}
        </span>
        <div className="flex mt-3">
          <Switch
            checked={useDate}
            onChange={() => {
              setUseDate(!useDate);
              if (useDate) {
                setDate(null);
                setTime(null);
              } else {
                setCurrentFocus('date');
              }
            }}
            className={`${
              useDate ? 'bg-green' : 'bg-[#D9D9D9]'
            } relative inline-flex h-4 w-8 items-center rounded-full mr-2`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                useDate ? 'translate-x-5' : 'translate-x-1'
              } inline-block h-2 w-2 transform rounded-full bg-white transition`}
            />
          </Switch>
          <span className="block text-gray-400 -mt-[2px]">Задать время начала</span>
        </div>
        {useDate && (
          <div>
            <div className="mt-3 flex space-x-2 max-w-full">
              <input
                value={date}
                onChange={(e) => {
                  if (!isDateValid) {
                    setIsDateValid(true);
                  }
                  let resultValue = e.target.value;
                  if (
                    (resultValue.length === 2 || resultValue.length === 5) &&
                    e.nativeEvent.inputType !== 'deleteContentBackward'
                  ) {
                    resultValue += '-';
                  }
                  setDate(resultValue);
                }}
                autoFocus={currentFocus === 'date'}
                onFocus={() => setCurrentFocus('date')}
                placeholder="ДД-ММ-ГГГГ"
                className={cn(
                  'w-[113px] placeholder:text-gray-300 appearance-none border border-gray-200 rounded py-1 px-3 text-gray-700 leading-[22px] focus:outline-none focus:shadow-outline',
                  {
                    'border-[#EB392D]': !isDateValid,
                  },
                )}
              />
              <input
                value={time}
                onChange={(e) => {
                  if (!isDateValid) {
                    setIsDateValid(true);
                  }
                  let resultValue = e.target.value;
                  if (
                    resultValue.length === 2 &&
                    e.nativeEvent.inputType !== 'deleteContentBackward'
                  ) {
                    resultValue += ':';
                  }
                  setTime(resultValue);
                }}
                autoFocus={currentFocus === 'time'}
                onFocus={() => setCurrentFocus('time')}
                placeholder="ЧЧ:ММ"
                className={cn(
                  'w-[73px] placeholder:text-gray-300 appearance-none border border-gray-200 rounded py-1 px-3 text-gray-700 leading-[22px] focus:outline-none focus:shadow-outline',
                  {
                    'border-[#EB392D]': !isDateValid,
                  },
                )}
              />
            </div>
            {!isMobile && (
              <button
                className="bg-green block rounded mt-3 w-full text-white py-2 text-center leading-[22px]"
                onClick={() => {
                  const isValid = checkDate();
                  if (isValid) {
                    handleSubmit();
                  } else {
                    setIsDateValid(false);
                  }
                }}
              >
                Применить
              </button>
            )}
          </div>
        )}
      </>
    ),
    [checkDate, currentFocus, date, handleSubmit, isDateValid, isMobile, time, useDate, value],
  );

  useEffect(() => {
    if (isActive && !isMobile) {
      removePrevPopup();
      const root = document.getElementsByClassName('ag-root-wrapper')[0];
      root.classList.add('relative');
      const cellCoord = eGridCell.getBoundingClientRect();
      const rootCoord = root.getBoundingClientRect();
      const top = Math.round(cellCoord.y + 40 - rootCoord.y);
      const left = Math.round(cellCoord.x - rootCoord.x);
      const popup = document.createElement('div');
      popup.setAttribute('id', 'discountPopup');
      popup.className = `absolute `;
      popup.style.top = `${top}px`;
      popup.style.left = `${left}px`;
      root.appendChild(popup);
      ReactDOM.render(
        <div
          ref={popUpRef}
          className="rounded bg-[#FFFFFF] p-4"
          style={{
            filter:
              'drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1))',
          }}
        >
          {popupContent}
        </div>,
        popup,
      );
    } else {
      removePrevPopup();
    }
  }, [
    isActive,
    eGridCell,
    useDate,
    setUseDate,
    date,
    time,
    currentFocus,
    handleSubmit,
    checkDate,
    isDateValid,
    isMobile,
    popupContent,
  ]);
  // useEffect(() => {
  //   if (isActive) {
  //     inputRef.current.selectionStart = String(value).length;
  //     inputRef.current.selectionEnd = String(value).length;
  //   }
  // }, [isActive, value]);
  const ResultIcon = useDate ? TimerIcon : CheckIcon;
  return (
    <div className="w-full h-full px-4 py-1" ref={cellRef}>
      <div
        className={cn('flex border border-gray-250 rounded px-1 py-[3px] bg-white', {
          'border-gray-250': !isActive,
          'border-green': isActive,
        })}
      >
        <div className="grow flex mr-1">
          <input
            ref={inputRef}
            className={cn('w-full self-center focus:outline-none text-right h-[22px]', {
              'pr-1': isActive,
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => {
              setIsActive(true);
              if (!isMobile) {
                setCurrentFocus('main');
                inputRef.current.select();
              }
            }}
          />
        </div>
        {((isActive && !useDate) || (changed && useDate && date && time)) && !isMobile && (
          <button
            className={cn(
              'bg-green rounded text-white p-1 relative justify-end focus:outline-none cursor-pointer',
              {
                'bg-green': isActive,
                'bg-[#F0F0F0]': !isActive,
              },
            )}
            onClick={handleSubmit}
            onMouseEnter={() => {
              if (!isActive) {
                setChangeHovered(true);
              }
            }}
            onMouseLeave={() => {
              setChangeHovered(false);
            }}
          >
            <ResultIcon
              width={14}
              height={14}
              className={cn('text-right', {
                'text-white': isActive,
                'text-gray-400': !isActive,
              })}
            />
          </button>
        )}
      </div>
      {isMobile && (
        <MobileInterfaceV2
          onPanelClose={() => {
            setIsActive(false);
          }}
          rootClassName="h-fit bottom-0 absolute"
          isActive={isActive}
          headerText="Скидка, %"
        >
          <div className={'p-4'}>{popupContent}</div>
          <div className="p-4 flex space-x-3 border-t">
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted-red"
              onClick={() => {
                setIsActive(false);
              }}
              label="Отмена"
            />
            <ButtonPrimary
              className="flex-1 py-2 justify-center"
              onClick={handleSubmit}
              label="Сохранить"
            />
          </div>
        </MobileInterfaceV2>
      )}
    </div>
  );
};

TableInnerDiscountChanger.propTypes = {
  value: PropTypes.any,
  data: PropTypes.shape({
    id: PropTypes.number,
  }),
  eGridCell: PropTypes.any,
};

export default TableInnerDiscountChanger;

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useSellerLink } from 'hooks/useLocationPath';

const TableCellSellerLink = ({ value, data }) => {
  const sellerId = data.seller_id;
  const sellerLink = useSellerLink(sellerId);

  if (!sellerId) return null;

  return (
    <span className="flex -ml-1">
      <Link to={sellerLink} target="_blank" className="truncate block">
        {value}
      </Link>
    </span>
  );
};
TableCellSellerLink.defaultProps = {
  data: {},
  valueFormatted: '',
  value: '',
};

TableCellSellerLink.propTypes = {
  data: PropTypes.object,
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellSellerLink;

import PropTypes from 'prop-types';

const getDataByStatus = (status) => {
  switch (status) {
    case 'Возврат': {
      return { label: 'Возврат', color: '#E4663F' };
    }
    case 'Отменен': {
      return { label: 'Отменен', color: '#EEC351' };
    }
    case 'Оплачен': {
      return { label: 'Оплачен', color: '#78EF6E' };
    }
    case 'Оформлен': {
      return { label: 'Оформлен', color: '#D9D9D9' };
    }
  }
};

const TableCellStatus = ({ value }) => {
  const data = getDataByStatus(value);
  return (
    <div className="flex space-x-2.5 items-center">
      <div className={`w-2 h-2 rounded-full`} style={{ backgroundColor: data.color }} />
      <span className="block">{data.label}</span>
    </div>
  );
};

TableCellStatus.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  data: PropTypes.any,
};

export default TableCellStatus;

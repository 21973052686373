import {
  amountShortcutFormatter,
  booleanFormatter,
  currencyFormatter,
} from 'helpers/cellValueFormatters';
import {
  categoryValue,
  imageValue,
  sortDateOnAggridInner,
  rangeFilter,
  sortDateOnAggrid,
  multiFilter,
  rangeDateFilter,
  textFilter,
  multiSetFilter,
  dateComparator,
} from 'helpers/generateGridColumnsHelpers';
import { amountTooltipFormatter, currencyTooltipFormatter } from 'helpers/tooltipValueGetters';
import { COLUMNS } from 'constants';

const columnDefsInnerProducts = [
  {
    headerName: 'Ссылка',
    field: 'url',
    cellRenderer: 'platformLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    pinned: true,
    headerComponentParams: { sortable: false },
    width: 70,
  },
  {
    headerName: 'Фото',
    field: 'images',
    pinned: true,
    width: 55,
    ...imageValue,
  },
  {
    headerName: 'Название товара',
    field: 'product_name',
    width: 250,
    pinned: true,
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
    },
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Артикул wb',
    field: 'external_id',
    width: 95,
    headerComponentParams: { sortable: false },
    //cellRenderer: 'innerTableProductName',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...multiFilter,
  },
  {
    headerName: 'Размер',
    width: 90,
    field: 'size',
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Артикул продавца',
    width: 150,
    field: 'supplier_article',
    //cellRenderer: 'innerTableProductName',
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
    },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Предмет',
    width: 150,
    field: 'subject',
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Бренд',
    width: 100,
    field: 'brand',
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Категория',
    width: 150,
    field: 'category',
    ...categoryValue,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Баркод',
    //cellRenderer: 'innerTableProductName',
    width: 150,
    field: 'barcode',
    initialHide: true,
    hide: true,
    ...multiFilter,
  },
  {
    headerName: 'Рейтинг',
    width: 80,
    field: 'rating',
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Цена без скидки, руб',
    width: 110,
    field: 'total_price',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Скидка',
    width: 80,
    field: 'sale_percent',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'СПП',
    width: 80,
    field: 'ppvz_spcc_prc',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Заказы, руб',
    width: 100,
    field: 'sum_orders_by_period_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    width: 65,
    field: 'count_orders_by_period',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, руб',
    width: 100,
    field: 'sum_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, шт',
    width: 65,
    field: 'count_sales',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Прибыль',
    width: 125,
    field: 'profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Цена к оплате покупателем',
    width: 105,
    field: 'retail_price_withdisc_rub',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  {
    headerName: 'Ср. Прибыль',
    width: 110,
    field: 'avg_profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Скорость заказов',
    width: 95,
    field: 'orders_speed',
    ...rangeFilter,
  },
  {
    headerName: 'Упущенная прибыль',
    width: 105,
    field: 'lost_profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'В среднем заказов сутки, шт',
    width: 65,
    field: 'avg_per_day_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: '% Выкупа',
    width: 100,
    field: 'buyout',
    ...rangeFilter,
  },
  {
    headerName: '% Выкупа с учетом возврата',
    width: 100,
    field: 'buyout_with_refund',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Рентабельность',
    width: 90,
    field: 'profitability',
    valueFormatter: (params) => (params.value === undefined ? '-' : params.value),
    ...rangeFilter,
  },
  {
    headerName: 'Маржинальность',
    width: 90,
    field: 'marginality',
    valueFormatter: (params) => (params.value === undefined ? '-' : params.value),
    ...rangeFilter,
  },
  {
    headerName: 'На складе+в пути',
    width: 90,
    field: 'quantity_full',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'На складе',
    width: 75,
    field: 'quantity',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Закончится к',
    width: 125,
    field: 'out_of_stock_dt',
    // valueFormatter: ({ value }) => {
    //   const date = value?.split('-').reverse() || [];
    //   console.log(date);
    //   return `${date[0]}.${date[1]}.${date[2].slice(2)}`;
    // },
    // tooltipValueGetter: ({ value }) => {
    //   const date = value?.split('-').reverse() || [];
    //   return `Закончится к: ${date[0]}.${date[1]}.${date[2].slice(2)}`;
    // },
    cellRendererParams: {
      suppressRightAlign: true,
      noLimit: true,
    },
    ...sortDateOnAggrid,
    ...rangeDateFilter,
  },
  {
    headerName: 'В пути',
    width: 90,
    field: 'quantity_in_way',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Был в движении',
    width: 70,
    field: 'has_changes',
    valueFormatter: booleanFormatter,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость остатка',
    width: 110,
    field: 'prime_cost_quantity',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Розничная стоимость остатка',
    width: 100,
    field: 'stock_rrc_cost',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  {
    headerName: 'Базовая комиссия, с НДС',
    width: 100,
    field: COLUMNS.NDS_PPVZ_KVW_PER_BASE,
    initialHide: true,
    hide: true,
  },
  {
    headerName: '% комиссии с НДС',
    width: 80,
    field: 'last_ppvz_kvw_prc',
    initialHide: true,
    hide: true,
  },
  /*{
    headerName: 'Средняя комиссия за ед %',
    width: 150,
    field: 'avg_nds_ppvz_kvw_prc',
    ...rangeFilter,
  },
  {
    headerName: 'Комиссия WB, без НДС',
    width: 150,
    field: 'last_ppvz_vw',
    ...rangeFilter,
  },
  {
    headerName: 'НДС Комиссии WB',
    width: 150,
    field: 'last_ppvz_vw_nds',
    ...rangeFilter,
  },*/
  {
    headerName: 'Комиссия WB с НДС',
    width: 100,
    field: 'sum_ppvz_vw_ppvz_vw_nds',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Средняя комиссия за ед Руб',
    width: 100,
    field: 'avg_sum_ppvz_vw_ppvz_vw_nds',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  /*{
    headerName: 'Базовая логистика',
    width: 150,
    field: 'last_ppvz_reward',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },*/
  {
    headerName: 'Логистика',
    width: 115,
    field: 'sum_ppvz_reward',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'ср. Логистика',
    width: 115,
    field: 'avg_ppvz_reward',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Дней в наличии',
    width: 80,
    field: 'days_on_available',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Дней на сайте',
    width: 80,
    field: 'days_on_site',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость',
    width: 110,
    field: 'prime_cost',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Поставщик',
    width: 110,
    field: 'supplier',
    initialHide: true,
    hide: true,
  },
  /*{
    headerName: 'На возврате',
    width: 150,
    field: 'in_way_from_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'На доставке',
    width: 150,
    field: 'in_way_to_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },*/
  /* {
    headerName: 'Остаток по API',
    width: 150,
    field: 'quantity_by_api',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Доступно к заказу на wb, шт',
    width: 150,
    field: 'quantity',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },*/
  // {
  //   headerName: 'Промо',
  //   width: 150,
  //   field: 'supplier_promo',
  //   ...rangeFilter,
  // },
  // {
  //   headerName: 'Цена после всех скидок',
  //   width: 150,
  //   field: 'retail_price_withdisc_rub',
  //   ...rangeFilter,
  //   valueFormatter: currencyFormatter,
  //   tooltipValueGetter: currencyTooltipFormatter(''),
  // },
  /*{
    headerName: 'Цена для выплаты',
    width: 150,
    field: 'price_with_disc',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },*/
  {
    headerName: 'Не заврешено, шт',
    width: 90,
    field: 'count_not_finished_orders',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Не завершено, руб',
    width: 90,
    field: 'sum_not_finished_orders',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Скорость продажи',
    width: 90,
    field: 'sales_speed',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Заказов в сутки, руб',
    width: 110,
    field: 'avg_per_day_rub',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Оборачиваемось по Заказам',
    width: 85,
    field: 'turn_around_orders',
    ...rangeFilter,
  },
  {
    headerName: 'Оборачиваемось по Продажам',
    width: 100,
    field: 'turn_around_sales',
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Возвраты, шт',
    width: 100,
    field: 'count_return_orders',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Возвраты сумма',
    width: 110,
    field: 'sum_return_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость возвратов',
    width: 90,
    field: 'prime_cost_of_returned_orders',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость Продаж',
    width: 90,
    field: 'prime_cost_of_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Сумма продаж без возвратов',
    width: 110,
    field: 'sum_sales_without_returns',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  // {
  //   headerName: 'Упущенная выручка',
  //   width: 150,
  //   field: 'lost_revenue',
  //   valueFormatter: currencyFormatter,
  //   tooltipValueGetter: currencyTooltipFormatter(''),
  //   ...rangeFilter,
  // },
  {
    headerName: 'К выплате',
    width: 110,
    field: 'payoff',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Штрафы',
    width: 100,
    field: 'penalty',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Доплаты',
    width: 90,
    field: 'additional_payment',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Упущенные заказы, шт',
    width: 100,
    field: 'lost_orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Упущенные заказы, руб',
    width: 110,
    field: 'lost_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'ABC по заказам',
    width: 30,
    field: 'order_a_b_c',
    valueFormatter: (params) => (params.value === undefined ? '-' : params.value),
  },
  {
    headerName: 'ABC по продажам',
    width: 30,
    field: 'sale_a_b_c',
    valueFormatter: (params) => (params.value === undefined ? '-' : params.value),
    initialHide: true,
    hide: true,
  },
];

const columnDefsInnerProductsSettings = [
  {
    headerName: 'Ссылка',
    field: 'url',
    cellRenderer: 'platformLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    pinned: true,
    headerComponentParams: { sortable: false },
    width: 70,
  },
  {
    headerName: 'Название товара',
    field: 'name',
    width: 250,
    pinned: true,
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
    },
    ...textFilter,
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Фото',
    field: 'images',
    pinned: true,
    width: 55,
    ...imageValue,
  },
  {
    headerName: 'Себестоимость, руб',
    width: 110,
    field: 'prime_cost',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    cellRenderer: 'primeCostChanger',
    pinned: true,
  },
  {
    headerName: 'Обновлено',
    width: 110,
    field: 'prime_cost_update_time',
    ...rangeFilter,
    comparator: dateComparator('DD.MM.YY hh:mm:ss'),
    valueFormatter: (params) => {
      if (params.value === '-') {
        return params.value;
      }
      const resultValue = params.value.split('');
      const lastIndex = resultValue.lastIndexOf('.');
      resultValue.splice(lastIndex, 1, ':');
      return resultValue.join('');
    },
    tooltipValueGetter: (params) => {
      if (params.value === '-') {
        return params.value;
      }
      const resultValue = params.value.split('');
      const lastIndex = resultValue.lastIndexOf('.');
      resultValue.splice(lastIndex, 1, ':');
      return `Обновлено: ${resultValue.join('')}`;
    },
  },
  {
    headerName: 'Цвет',
    width: 90,
    field: 'color',
    ...multiSetFilter,
  },
  {
    headerName: 'Размер',
    width: 90,
    field: 'size',
    ...multiSetFilter,
  },
  {
    headerName: 'Артикул wb',
    field: 'external_id',
    width: 95,
    headerComponentParams: { sortable: false },
    cellRenderer: 'innerTableProductName',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...textFilter,
  },
  {
    headerName: 'Артикул продавца',
    width: 150,
    field: 'supplier_article',
    cellRenderer: 'innerTableProductName',
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
    },
    ...textFilter,
  },
  {
    headerName: 'Баркод',
    cellRenderer: 'innerTableProductName',
    width: 150,
    field: 'barcode',
    ...textFilter,
  },
  {
    headerName: 'Бренд',
    width: 100,
    field: 'brand',
    ...multiSetFilter,
  },
  {
    headerName: 'Предмет',
    width: 150,
    field: 'subject',
    ...multiSetFilter,
  },
];

const columnDefsInnerInDays = [
  {
    headerName: 'Дата',
    field: 'date_in_period',
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...sortDateOnAggridInner,
  },
  {
    headerName: 'Товаров',
    field: 'products_available',
    width: 150,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Был в движении',
    field: 'products_has_changes',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Доля товаров в движении',
    field: 'goods_in_movement',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, руб',
    field: COLUMNS.SUM_ORDERS_INNER,
    width: 150,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    field: 'count_orders_by_period',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Выкуплено, руб',
    field: 'sum_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Выкуплено, шт',
    field: 'count_sales',
    width: 150,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Себестоимость заказанных товаров',
    field: 'prime_cost_of_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'На складе',
    field: 'quantity_full',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'На возврате',
    field: 'in_way_from_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'На доставке',
    field: 'in_way_to_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Остаток по API',
    field: 'quantity_by_api',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Себестоимость остатка',
    field: 'prime_cost_quantity',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Доступно к заказу, шт',
    field: 'quantity',
    width: 150,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Возвраты, руб',
    field: 'c_sum_orders_by_period_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Возвраты, шт',
    field: 'c_count_orders_by_period',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Себестоимость возвратов',
    field: 'prime_cost_of_returns',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Средняя комиссия за ед Руб',
    field: 'avg_sum_ppvz_vw_ppvz_vw_nds',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика',
    field: 'sum_ppvz_reward',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'ср. Логистика',
    field: 'avg_ppvz_reward',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Не заврешено, шт',
    field: 'count_not_finished_orders',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Самовыкупы',
    field: 'self_sell_count',
    width: 150,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Кол-во продаж, шт',
    field: 'count_sales',
    width: 150,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Сумма продаж, руб',
    field: 'sum_sales_with_returns',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Без возвратов',
    field: 'sum_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Себестоимость проданных товаров',
    field: 'prime_cost_of_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущенная выручка',
    field: 'lost_revenue',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'К выплате',
    field: 'payoff',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Сумма штрафов по товару',
    field: 'penalty',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Доплаты',
    field: 'additional_payment',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Прибыль',
    field: 'profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Ср. Прибыль',
    field: 'avg_profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущенная прибыль',
    field: 'lost_profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Рентабельность',
    field: 'profitability',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Маржинальность',
    field: 'marginality',
    width: 150,
    ...rangeFilter,
  },
];

const columnDefsInnerSales = [
  {
    headerName: 'Ссылка',
    field: 'url',
    cellRenderer: 'platformLinkTableCell',
    pinned: true,
    width: 65,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Название товара',
    field: 'product_name',
    width: 280,
    pinned: true,
    cellRendererParams: {
      truncate: true,
      suppressRightAlign: true,
      from: 'sales',
    },
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Фото',
    field: 'images',
    pinned: true,
    width: 55,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...imageValue,
  },
  {
    headerName: 'Статус',
    field: 'status',
    pinned: true,
    width: 110,
    filter: 'statusFilter',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellRenderer: 'innerTableStatus',
  },
  {
    headerName: 'Артикул wb',
    field: 'external_id',
    width: 95,
    ...multiFilter,
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: true },
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Размер',
    field: 'size',
    width: 95,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Баркод',
    field: 'barcode',
    width: 110,
    ...multiFilter,
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: true },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'ШК',
    field: 'shk_id',
    width: 95,
    ...multiFilter,
    headerComponentParams: { sortable: true },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Категория',
    field: 'category',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    width: 90,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Бренд',
    field: 'brand',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Себестоимость',
    field: COLUMNS.PRIME_COST,
    width: 95,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Цена',
    field: 'price_with_disc',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 95,
    ...rangeFilter,
  },
  {
    headerName: 'Оплачено',
    field: 'retail_amount',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 105,
    ...rangeFilter,
  },
  {
    headerName: 'От клиента логистистика',
    field: 'in_way_from_client',
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'К клиенту логистистика',
    field: 'in_way_to_client',
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Комиссия',
    field: 'sum_ppvz_vw_ppvz_vw_nds',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Комиссия', true),
    cellRenderer: 'innerPredictCellRenderer',
    width: 105,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика',
    field: 'delivery_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Логистика', true),
    cellRenderer: 'innerPredictCellRenderer',
    width: 95,
    ...rangeFilter,
  },
  {
    headerName: 'Возврат',
    field: 'sum_return_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
  },
  {
    headerName: 'К выплате',
    field: 'ppvz_for_pay',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Прибыль',
    field: 'profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
  },
  {
    headerName: 'Заказ',
    field: 'order_dt',
    ...rangeDateFilter,
    width: 95,
    ...sortDateOnAggridInner,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Продажа',
    field: 'sale_dt',
    ...rangeDateFilter,
    width: 110,
    ...sortDateOnAggridInner,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Возврат',
    field: 'return_dt',
    ...rangeDateFilter,
    width: 105,
    ...sortDateOnAggridInner,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Отмена',
    field: 'cancel_dt',
    ...rangeDateFilter,
    width: 105,
    ...sortDateOnAggridInner,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  // {
  //   headerName: 'Обр. Логитиска',
  //   field: 'obr_log_dt',
  //   ...rangeDateFilter,
  //   width: 150,
  //   ...sortDateOnAggridInner,
  //   cellRendererParams: {
  //     suppressRightAlign: true,
  //   },
  // },
  // {
  //   headerName: 'Оплата',
  //   field: 'sold_dt',
  //   width: 150,
  //   ...rangeDateFilter,
  //   ...sortDateOnAggridInner,
  //   cellRendererParams: {
  //     suppressRightAlign: true,
  //   },
  // },
  {
    headerName: 'Склад',
    field: 'warehouse_name',
    width: 110,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Регион',
    field: 'region',
    width: 120,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Номер поставки',
    field: 'income_id',
    width: 100,
    ...multiFilter,
    headerComponentParams: { sortable: true },
  },
  {
    headerName: 'Номер заказа',
    field: 'srid',
    width: 150,
    ...multiFilter,
    headerComponentParams: { sortable: true },
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Тип поставки',
    field: 'income_type',
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Артикул продавца',
    field: 'supplier_article',
    width: 180,
  },
];

const columnDefsInnerWeeks = [
  {
    headerName: 'id отчета',
    field: 'realization_report_id',
    width: 90,
    ...rangeFilter,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Дата начала',
    field: 'date_from',
    width: 95,
    valueFormatter: ({ value }) => {
      if (value.indexOf('.') !== -1) {
        return value;
      }
      const date = value?.split('-').reverse() || [];
      return `${date[0]}.${date[1]}.${date[2].slice(2)}`;
    },
    tooltipValueGetter: ({ value }) => {
      if (value.indexOf('.') !== -1) {
        return value;
      }
      const date = value?.split('-').reverse() || [];
      return `Дата начала: ${date[0]}.${date[1]}.${date[2].slice(2)}`;
    },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...sortDateOnAggrid,
  },
  {
    headerName: 'Дата конца',
    field: 'date_to',
    width: 95,
    valueFormatter: ({ value }) => {
      if (value.indexOf('.') !== -1) {
        return value;
      }
      const date = value?.split('-').reverse() || [];
      return `${date[0]}.${date[1]}.${date[2].slice(2)}`;
    },
    tooltipValueGetter: ({ value }) => {
      if (value.indexOf('.') !== -1) {
        return value;
      }
      const date = value?.split('-').reverse() || [];
      return `Дата конца: ${date[0]}.${date[1]}.${date[2].slice(2)}`;
    },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...sortDateOnAggrid,
  },
  {
    headerName: 'Продажи, руб',
    field: COLUMNS.ORDERS_RUB,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 105,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, шт',
    field: 'sold_number',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 110,
    ...rangeFilter,
  },

  {
    headerName: 'Возвраты, руб',
    field: 'return_money',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 110,
    ...rangeFilter,
  },
  {
    headerName: 'Возвраты, шт',
    field: 'return_number',
    width: 90,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Сумма, руб',
    field: 'general_money',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 140,
    ...rangeFilter,
  },
  {
    headerName: 'Сумма, шт',
    field: 'general_number',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Логистика к клиенту',
    field: 'delivery_to_client',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Логистика от клиента',
    field: 'delivery_from_client',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Прибыль',
    field: 'profit_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика всего',
    field: 'delivery_general',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 125,
    ...rangeFilter,
  },
  {
    headerName: 'Комиссия общая',
    field: 'commission_general',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    field: 'orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 95,
    ...rangeFilter,
  },
  {
    headerName: 'Комиссия продажи',
    field: 'commission_sold',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Комиссия возвраты',
    field: 'commission_return',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Ключ',
    field: 'api_key_name',
    width: 150,
    flex: 1,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
];

const columnDefsInnerRatings = [
  {
    headerName: 'Дата',
    field: 'date',
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    width: 100,
  },
  {
    headerName: 'Заказы, руб',
    field: COLUMNS.SUM_ORDERS_INNER,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Заказы, шт',
    field: COLUMNS.COUNT_ORDERS_INNER,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Продажи, руб',
    field: COLUMNS.SUM_SALES_INNER,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Продажи, шт',
    field: COLUMNS.COUNT_SALES_INNER,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Возвраты, руб',
    field: COLUMNS.SUM_RETURN_INNER,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Возвраты, шт',
    field: COLUMNS.COUNT_RETURN_INNER,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Прибыль',
    field: COLUMNS.PAYOFF,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'Цена',
    field: COLUMNS.PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'На складе + в пути',
    field: COLUMNS.QUANTITY_FULL,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
    width: 125,
  },
  {
    headerName: 'На складе',
    field: COLUMNS.QUANTITY,
    valueFormatter: amountShortcutFormatter,
    ...rangeFilter,
    width: 125,
  },
];

const columnDefsInnerInDays2 = [
  {
    headerName: 'Дата',
    field: 'date',
    width: 75,
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    pinned: true,
  },
  {
    headerName: 'Товаров в наличии',
    field: 'products',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 90,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Товаров в движении',
    field: 'active_products_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 90,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Доля товаров в движении',
    field: 'active_products_percent',
    width: 90,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Заказы, руб',
    field: 'orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 85,
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    field: 'orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 85,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, руб',
    field: 'sold_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, шт',
    field: 'sold_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
  },
  {
    headerName: 'Возвраты, руб',
    field: 'returns_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 120,
    ...rangeFilter,
  },
  {
    headerName: 'Возвраты, шт',
    field: 'returns_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 120,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Доступно к заказу, шт',
    field: 'quantity',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 110,
    ...rangeFilter,
  },
  {
    headerName: 'На складе + на доставке + на возврате',
    field: 'quantity_full',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость склада',
    field: 'quantity_netcost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Рыночная Стоимость склада',
    field: 'quantity_retailcost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость заказанных товаров',
    field: 'orders_rub_netcost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Себестоимость проданных товаров',
    field: 'sold_netprice',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'К выплате',
    field: 'for_pay',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 115,
    ...rangeFilter,
  },
  {
    headerName: 'Прибыль',
    field: 'profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Ср. Прибыль',
    field: 'avg_profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Коммиссия с продаж',
    field: 'commission',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Средняя комиссия за ед, руб',
    field: 'avg_commission',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Логистика',
    field: 'logistic_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 95,
    ...rangeFilter,
  },
  {
    headerName: 'ср. Логистика за один товар',
    field: 'avg_logistic_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 100,
    ...rangeFilter,
    initialHide: true,
    hide: true,
  },
  {
    headerName: 'Штрафы',
    field: 'penalty',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 105,
    ...rangeFilter,
  },
  {
    headerName: 'Доплаты',
    field: 'additional_payment',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 105,
    ...rangeFilter,
  },
  {
    headerName: 'Рентабельность',
    field: 'profitability',
    width: 90,
    ...rangeFilter,
  },
  {
    headerName: 'Маржинальность',
    field: 'marginality',
    ...rangeFilter,
    flex: 1,
    width: 100,
  },
];

const columnDefsInnerPriesTab = [
  {
    headerName: 'Название товара',
    field: 'name',
    width: 300,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Артикул',
    field: 'id',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Бренд',
    field: 'brand',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Категория',
    field: 'category',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    width: 100,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Цена, руб',
    field: 'price',
    width: 200,
    ...rangeFilter,
    cellRenderer: 'innerPriceChanger',
    cellStyle: { border: 'none' },
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Скидка, %',
    field: 'discount',
    width: 200,
    cellRenderer: 'innerDiscountChanger',
    ...rangeFilter,
    cellClass: 'overflow-visible',
    // tooltipComponent: 'discountTooltip',
    // tooltipValueGetter: (props) => props,
    // tooltipField: 'discount',
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Промокод, %',
    field: 'promoCode',
    width: 100,
    ...rangeFilter,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
];

const columnDefsInnerOrdersSales = [
  // {
  //   headerName: 'Артикул wb',
  //   field: 'internal_order_id',
  //   width: 150,
  //   ...rangeFilter,
  //   cellRendererParams: {
  //     suppressRightAlign: true,
  //   },
  // },
  // {
  //   headerName: 'Идентификатор заказа',
  //   field: 'odid',
  //   width: 165,
  //   ...rangeFilter,
  // },
  {
    headerName: 'Заказ',
    field: 'order_dt',
    ...rangeDateFilter,
    width: 150,
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Статус',
    field: 'status',
    width: 110,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellRenderer: 'innerTableStatus',
  },
  {
    headerName: 'Продажа',
    field: 'sale_dt',
    ...rangeDateFilter,
    width: 150,
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Отмена',
    field: 'cancel_dt',
    ...rangeDateFilter,
    width: 150,
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Возврат',
    field: 'return_dt',
    ...rangeDateFilter,
    width: 150,
    ...sortDateOnAggrid,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Название склада',
    field: 'warehouseName',
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Себестоимость',
    field: COLUMNS.PRIME_COST,
    width: 150,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Цена',
    field: COLUMNS.TOTAL_PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    width: 150,
  },
  {
    headerName: 'Регион',
    field: 'region',
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  // {
  //   headerName: 'Комиссия',
  //   field: 'sum_ppvz_vw_ppvz_vw_nds',
  //   valueFormatter: currencyFormatter,
  //   tooltipValueGetter: currencyTooltipFormatter(''),
  //   width: 150,
  //   ...rangeFilter,
  // },
  // {
  //   headerName: 'К выплате',
  //   field: 'ppvz_for_pay',
  //   valueFormatter: currencyFormatter,
  //   tooltipValueGetter: currencyTooltipFormatter(''),
  //   width: 150,
  //   ...rangeFilter,
  // },
  {
    headerName: 'Номер поставки',
    field: 'income_id',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Возврат',
    width: 150,
    field: 'sum_return_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Прибыль',
    field: 'profit',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика к клиенту',
    field: 'in_way_to_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика от клиента',
    field: 'in_way_from_client',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Логистика Всего',
    field: 'delivery_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Коммиссия', true),
    cellRenderer: 'innerPredictCellRenderer',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Оплачено',
    width: 150,
    field: 'price_with_disc',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  {
    headerName: 'К выплате',
    field: 'for_pay',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Коммиссия',
    field: 'commission',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter('Коммиссия', true),
    cellRenderer: 'innerPredictCellRenderer',
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Номер заказа',
    field: 'srid',
    width: 150,
    ...multiFilter,
  },
];

const columnDefsInnerWarehouses = [
  {
    headerName: 'Склады',
    field: 'name',
    width: 150,
    cellRenderer: 'productVariationTableCell',
    pinned: true,
  },
  {
    headerName: 'Заказы, ₽',
    field: 'warehouses_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    field: 'warehouses_orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Остаток, шт',
    field: 'warehouse_stock',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'В сутки, ₽',
    field: 'wh_avg_orders_per_day_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'В сутки, шт',
    field: 'wh_avg_orders_per_day_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, шт',
    field: 'count_sales',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, ₽',
    field: 'sum_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Когда в наличии, ₽',
    field: 'wh_avg_orders_if_in_stock_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Когда в наличии, шт',
    field: 'wh_avg_orders_if_in_stock_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущено, ₽',
    field: 'wh_lost_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущено, шт',
    field: 'wh_lost_orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'На складе, себес',
    field: 'quantity_prime_cost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'На складе, розн',
    field: 'quantity_retail_cost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
];

const columnsDefsInnerDelivery = [
  {
    headerName: 'Товар',
    field: 'name',
    flex: 3,
    width: 300,
    pinned: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellRenderer: 'innerTableProductName',
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Фото',
    field: 'images',
    width: 150,
    ...imageValue,
    pinned: true,
  },
  {
    headerName: 'Ссылка',
    field: 'url',
    cellRenderer: 'platformLinkTableCell',
    width: 150,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Артикул wb',
    field: 'external_id',
    width: 150,
    headerComponentParams: { sortable: false },
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Баркод',
    width: 150,
    field: 'barcode',
  },
  {
    headerName: 'Себестоимость',
    width: 150,
    field: 'prime_cost',
    ...rangeFilter,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
  },
  {
    headerName: 'Цена',
    field: COLUMNS.PRICE,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    flex: 1,
    width: 100,
  },
  {
    headerName: 'Количество товара для поставки',
    field: 'delivery_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'В среднем заказов в сутки',
    field: 'avg_orders_qty_if_in_stock',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    field: 'orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Дней в наличии',
    width: 150,
    field: 'days_on_available',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
  },
  {
    headerName: 'Закончится, через дней',
    width: 150,
    field: 'days_until_stock_out',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
  },
  {
    headerName: 'Прогнозируемый остаток',
    width: 150,
    field: 'calc_quantity',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
  },
  {
    headerName: 'Текущий остаток товара',
    width: 150,
    field: 'quantity',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Количество дней до поставки',
    width: 150,
    field: 'days_to_delivery',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Суммарная стоимость поставки',
    field: 'prime_cost_delivery_qty',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    flex: 1,
    width: 100,
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Розничная стоимость поставки',
    field: 'retail_cost_delivery_qty',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
    flex: 1,
    width: 100,
    headerComponentParams: { sortable: false },
  },
  {
    headerName: 'Закончится к',
    field: 'out_of_stock_dt',
    flex: 1,
    width: 100,
  },
];

const columnDefsInnerProductSizes = [
  {
    headerName: 'Название размера',
    width: 150,
    field: 'size',
    cellRenderer: 'productVariationTableCell',
    pinned: true,
  },
  {
    headerName: 'Заказы, руб',
    width: 150,
    field: 'size_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Заказы, шт',
    width: 150,
    field: 'size_orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, руб',
    field: 'sum_sales',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Продажи, шт',
    width: 150,
    field: 'count_sales',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Остатки, шт',
    field: 'size_stock',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Остатки, розничная стоимость',
    field: 'size_money_retail',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Остатки, себестоимость',
    field: 'size_money_price_cost',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущено, руб',
    field: 'size_lost_orders_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Упущено, шт',
    width: 150,
    field: 'size_lost_orders_qty',
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Заказов в сутки, руб',
    field: 'size_avg_orders_per_day_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Заказов в сутки, шт',
    width: 150,
    field: 'size_avg_orders_per_day_qty',
    // valueFormatter: amountShortcutFormatter,
    // tooltipValueGetter: amountTooltipFormatter(''),
    ...rangeFilter,
  },
  {
    headerName: 'Заказов в сутки, когда в наличии, руб',
    field: 'size_avg_orders_if_in_stock_rub',
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    width: 150,
    ...rangeFilter,
  },
  {
    headerName: 'Заказов в сутки, когда в наличии, шт',
    width: 150,
    field: 'size_avg_orders_if_in_stock_qty',
    ...rangeFilter,
    valueFormatter: amountShortcutFormatter,
    tooltipValueGetter: amountTooltipFormatter(''),
  },
];

export {
  columnDefsInnerProducts,
  columnDefsInnerInDays,
  columnDefsInnerInDays2,
  columnDefsInnerSales,
  columnDefsInnerWeeks,
  columnDefsInnerRatings,
  columnDefsInnerOrdersSales,
  columnDefsInnerPriesTab,
  columnDefsInnerWarehouses,
  columnsDefsInnerDelivery,
  columnDefsInnerProductSizes,
  columnDefsInnerProductsSettings,
};

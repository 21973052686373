import React from 'react';

import ProductListingList from 'components/ProductListingList';
import {
  ProductDataRow,
  ProductHeaderInfo,
  ProductRatingRow,
  ProductTitleRow,
} from '../components';
import PropTypes from 'prop-types';

export const ProductInfo = ({ isInner, barcode }) => {
  return (
    <div>
      <ProductHeaderInfo isInner={isInner} />

      <ProductTitleRow isInner={isInner} barcode={barcode} />

      <div className="divide-y divider-gray-230">
        <ProductRatingRow isInner={isInner} />

        <ProductDataRow isInner={isInner} />
        {!isInner && <ProductListingList />}
      </div>
    </div>
  );
};

ProductInfo.propTypes = {
  isInner: PropTypes.bool,
  barcode: PropTypes.string,
};

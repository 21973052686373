import Dropdown from 'components/Dropdown';
import { CRITERIAS, INNER_CRITERIAS, PLATFORMS_COLOR } from './constants';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useUI } from 'context/ui.context';
import { MobileOpener } from './MobileOpener';
import { MobileContent } from 'components/SearchInput/MobileContent';
import { useAnalyticType } from 'context/analytic.type.context';

const SearchCriteria = ({ criteria, setCriteria, setValue }) => {
  const { filter } = useFilterReducer();
  const { isInner } = useAnalyticType();
  const [isOpen, setIsOpen] = useState(false);
  const [mobileSelect, setMobileSelect] = useState(criteria);
  const { isMobile } = useUI();
  const selectedPlatform = filter?.platform;
  const platformColor = PLATFORMS_COLOR[selectedPlatform] ?? null;
  const criterias = isInner ? INNER_CRITERIAS : CRITERIAS;
  const label = criterias.find((el) => el.value === criteria).label.toUpperCase();
  const onOpen = () => {
    setIsOpen(true);
    setMobileSelect(criteria);
  };
  if (isMobile) {
    return (
      <>
        <MobileOpener label={label} setIsOpen={onOpen} platformColor={platformColor} />
        <MobileContent
          mobileSelect={mobileSelect}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          options={criterias}
          setMobileSelect={setMobileSelect}
          handleApply={(value) => {
            setValue('');
            setCriteria(value);
            setIsOpen(false);
          }}
        />
      </>
    );
  }
  return (
    <Dropdown
      isOutlined
      label={label}
      bgColor={`${platformColor}-bright`}
      color={platformColor}
      options={criterias}
      onChange={(newValue) => {
        setCriteria(newValue);
        setValue('');
      }}
      selected={criteria}
      buttonClassnames={`bg-transparent !px-1 !py-[3px] border border-solid border-${platformColor} h-[18px] !flex`}
      labelClassnames="text-[10px] leading-[10px] font-bold block"
      downIconClassnames="ml-0.5 relative"
    />
  );
};

SearchCriteria.propTypes = {
  criteria: PropTypes.string,
  setCriteria: PropTypes.func,
  setValue: PropTypes.func,
};

export default SearchCriteria;

import { usePayment } from 'context/payment.context';

export const PromoCodePanel = () => {
  const { promoCodePreview, changePromoCode, updatePromoCode, promoCodeMessage } = usePayment();

  const handlePromoCodeSubmit = () => {
    updatePromoCode();
  };

  return (
    <div className="flex flex-col gap-4 grow p-4">
      <input
        type="text"
        value={promoCodePreview}
        onChange={changePromoCode}
        className="border py-1 px-3 border-gray-250 rounded focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green"
        placeholder="Промокод"
      />

      {promoCodeMessage && (
        <div className="text-orange text-xs break-words">{promoCodeMessage}</div>
      )}

      <button
        className="mt-auto bg-green text-white shadow-sm items-center px-3 py-1.5 text-sm rounded"
        onClick={handlePromoCodeSubmit}
      >
        Применить
      </button>
    </div>
  );
};

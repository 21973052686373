const QuestionIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 15 15">
      <path
        fillRule="evenodd"
        d="M12.77 7.49756C12.77 10.6842 10.1867 13.2676 7 13.2676C3.81332 13.2676 1.23 10.6842 1.23 7.49756C1.23 4.31088 3.81332 1.72756 7 1.72756C10.1867 1.72756 12.77 4.31088 12.77 7.49756ZM14 7.49756C14 11.3636 10.866 14.4976 7 14.4976C3.13401 14.4976 0 11.3636 0 7.49756C0 3.63157 3.13401 0.497559 7 0.497559C10.866 0.497559 14 3.63157 14 7.49756ZM7.40777 8.72919H6.15161V8.6165C6.15492 7.45646 6.46648 7.10182 7.0233 6.7505C7.43097 6.49197 7.74583 6.20362 7.74583 5.76944C7.74583 5.30874 7.38457 5.01044 6.93712 5.01044C6.50294 5.01044 6.10189 5.29879 6.08201 5.83904H4.72974C4.75957 4.51328 5.74063 3.88355 6.94375 3.88355C8.25956 3.88355 9.19422 4.55968 9.19422 5.71972C9.19422 6.4986 8.78987 6.98582 8.17008 7.35372C7.6464 7.66858 7.41439 7.97019 7.40777 8.6165V8.72919ZM7.6 10.0549C7.59669 10.4958 7.22879 10.8504 6.80455 10.8504C6.36705 10.8504 6.00578 10.4958 6.00909 10.0549C6.00578 9.62076 6.36705 9.26612 6.80455 9.26612C7.22879 9.26612 7.59669 9.62076 7.6 10.0549Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default QuestionIcon;

/**
 * @return {JSX.Element} Иконку просмотра видео
 *
 * @param {import('react').HTMLAttributes<SVGElement>} props
 * */
export function PlayIcon({ ...svgProps }) {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M2.07735 15.2539C1.43094 15.2539 1 14.7786 1 14.0354V2.47242C1 1.72921 1.43094 1.25391 2.07735 1.25391C2.42749 1.25391 2.71478 1.37489 3.08287 1.57366L13.0214 7.11317C13.7396 7.5107 14 7.79588 14 8.25391C14 8.71193 13.7396 8.99712 13.0214 9.39465L3.08287 14.9342C2.71478 15.1329 2.42749 15.2539 2.07735 15.2539Z"
        stroke="#219653"
        strokeWidth="2"
      />
    </svg>
  );
}

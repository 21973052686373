import PropTypes from 'prop-types';
import cn from 'classnames';
import { useState } from 'react';
import ClockIcon from 'assets/icons/ClockIcon';
import DownIcon from 'assets/icons/DownIcon';

const TableCellDate = ({ value, node }) => {
  const [isExpanded, setIsExpanded] = useState(node.expanded);
  const handleClick = () => {
    if (isExpanded) {
      setIsExpanded(false);
      setTimeout(() => node.setExpanded(false), 0);
    } else {
      setIsExpanded(true);
      setTimeout(() => node.setExpanded(true), 0);
    }
  };
  return (
    <button className="flex pl-2" onClick={handleClick}>
      <div
        className={cn(
          'transition-all self-center mr-2 py-[3px] pl-1 pr-1 rounded border border-gray-100 flex h-fit relative -top-0.5 hover:border-green',
          {
            'text-white bg-green': isExpanded,
            'text-green bg-green-50': !isExpanded,
          },
        )}
      >
        <ClockIcon width={12} height={12} className="self-center" />
        <div className="self-center pl-[3px] ml-1 border-l border-gray-100">
          <DownIcon
            width={10}
            className={cn('self-center', {
              'rotate-180': isExpanded,
            })}
          />
        </div>
      </div>
      <span className="block">{value}</span>
    </button>
  );
};

TableCellDate.propTypes = {
  value: PropTypes.string.isRequired,
  api: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  data: PropTypes.object,
  colDef: PropTypes.shape({
    field: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default TableCellDate;

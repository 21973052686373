import PropTypes from 'prop-types';

import TableLayout from 'components/Layout/TableLayout';
import { TableHeaderGrowth } from 'components/TableHeader';
import Layout from 'components/Layout/Layout';

const TableGrowthLayout = ({ children }) => {
  return (
    <TableLayout isPeriodControlsShown={true} isDateControlsShown={false}>
      <TableHeaderGrowth />

      <Layout>{children}</Layout>
    </TableLayout>
  );
};

TableGrowthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default TableGrowthLayout;

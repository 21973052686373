import React from 'react';

import { useAuth } from 'context/auth.context';
import { isProductPage } from 'utils/pages';
import { DATE_RANGE_BY_TARIFF } from 'context/filter/filter.context.reducer.methods';

const UserTariffsContext = React.createContext();
UserTariffsContext.displayName = 'User.Tariffs.Context';

const UserTariffsProvider = (props) => {
  const { isTrial, isBasic, isPro, isAdmin, noTariff, tariffName, isLoaded } = useAuth();

  const isProductWarehousesAvailable = true;
  const isSellersAvailable = true;
  const isNewFilterAvailable = !isBasic || noTariff;
  const isAdvertisingFilterAvailable = isPro || isTrial || noTariff || isAdmin;
  const isPriceSegmentsAvailable = true;
  const isProductSizesColorsAvailable = true;
  const isProductListingAvailable = true;
  const isProductSearchAvailable = true;
  const isTableExportAvailable = !isBasic || noTariff;
  const isPositionsAvailable = isAdmin;
  // true for all user tariffs
  const isInnerAnalyticsAvailable = true;

  const checkWarehousesAvailability = (filter) => {
    const someBrandsSellersSelected = filter && (filter?.brand?.length || filter?.seller?.length);
    const isAvailableByTariff = isPro || isTrial || noTariff;

    return {
      isWarehousesAvailable: (isAvailableByTariff && !!someBrandsSellersSelected) || isAdmin,
      isWarehousesAvailableByTariff: isAvailableByTariff,
      isWarehousesAvailableByFilter: !!someBrandsSellersSelected,
    };
  };

  const checkDynamicAvailability = (filter) => {
    const someCategoryBrandsSellersSelected =
      filter && (filter?.seller?.length || filter?.brand?.length || filter?.category?.length);
    const isAvailableByTariff = true;

    return {
      isDynamicAvailable: (isAvailableByTariff && !!someCategoryBrandsSellersSelected) || isAdmin,
      isDynamicAvailableByTariff: isAvailableByTariff,
      isDynamicAvailableByFilter: !!someCategoryBrandsSellersSelected,
    };
  };

  const checkDatepickerAvailability = ({ filter, isInner }) => {
    const isProduct = isProductPage();

    const someCategoryBrandsSellersSelected =
      filter && (filter?.seller?.length || filter?.brand?.length || filter?.category?.length);
    const isAvailableByTariff = !noTariff;
    const isAvailableByFilter = !!someCategoryBrandsSellersSelected || isProduct || isInner;

    return {
      isDatepickerAvailable: (isAvailableByTariff && isAvailableByFilter) || isAdmin,
      isDatepickerAvailableByTariff: isAvailableByTariff,
      isDatepickerAvailableByFilter: isAvailableByFilter,
    };
  };

  const getRangePickerAvailability = () => {
    const ranges = [7, 14, 30];

    if (noTariff) {
      return [];
    }

    return ranges.filter((range) => range <= DATE_RANGE_BY_TARIFF[tariffName]);
  };

  const value = {
    isProductWarehousesAvailable,
    isSellersAvailable,
    isProductSizesColorsAvailable,
    isNewFilterAvailable,
    isAdvertisingFilterAvailable,
    isPriceSegmentsAvailable,
    isProductListingAvailable,
    isProductSearchAvailable,
    isTableExportAvailable,
    checkWarehousesAvailability,
    checkDynamicAvailability,
    checkDatepickerAvailability,
    availableRanges: getRangePickerAvailability(),
    isPositionsAvailable,
    isInnerAnalyticsAvailable,
    isLoaded,
  };

  return <UserTariffsContext.Provider value={value} {...props} />;
};

const useUserTariffs = () => {
  return React.useContext(UserTariffsContext);
};

export { UserTariffsProvider, useUserTariffs };

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { SearchInput, Checkbox } from '../components';
import LoadingSpin from 'components/LoadingSpin';
import { CHIP_ID_KEY, CHIP_TITLE_KEY } from 'context/filter/filter.context.reducer';
import { getItemNameById } from 'components/FilterModal/utils/inner';

export const TabWrapper = ({
  searchPlaceholder,
  data,
  checked,
  onChange,
  isLoading,
  outerHandleChange,
  emptyValue,
}) => {
  const [search, setSearch] = useState('');

  const itemsFiltered = useMemo(() => {
    return data.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
  }, [data, search]);

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleOnChange = (e) => {
    const targetId = +e.target.id;

    let newKeys = [];
    if (checked.includes(targetId)) {
      newKeys = checked.filter((id) => id !== targetId);
    } else {
      newKeys = [...checked, targetId];
    }

    const newItems = newKeys.map((key) => ({
      [CHIP_ID_KEY]: key,
      [CHIP_TITLE_KEY]: getItemNameById({ id: key, data }) || `${key}`,
    }));

    onChange(newItems);
  };

  return (
    <>
      <SearchInput
        value={search}
        onChange={onSearchChange}
        placeholder={searchPlaceholder || 'Поиск'}
        isLoading={isLoading}
      />
      <div className="flex-1 sm:h-full">
        {isLoading ? (
          <LoadingSpin isVisible={true} />
        ) : (
          <div className="divide-y divide-gray-150 lg:divide-y-0 overflow-y-auto lg:space-y-2 -m-2 p-2 sm:h-full md:max-h-[400px]">
            {itemsFiltered.map(({ name, id }) => (
              <Checkbox
                key={id}
                label={name || emptyValue || `Категория с ID ${id}`}
                highlighted={search}
                onChange={outerHandleChange || handleOnChange}
                checked={checked.includes(id)}
                id={id}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

TabWrapper.propTypes = {
  isLoading: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  checked: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  outerHandleChange: PropTypes.func,
  emptyValue: PropTypes.string,
};

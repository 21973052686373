const BookmarkCheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9.9 3H5.625C4.58947 3 3.75 3.83947 3.75 4.875V15.0631C3.75 15.3598 4.07822 15.539 4.32778 15.3786L8.79722 12.5054C8.92074 12.426 9.07926 12.426 9.20278 12.5054L13.6722 15.3786C13.9218 15.539 14.25 15.3598 14.25 15.0631V10.3503M9.44993 6.75029L10.7999 8.10029L15.2999 3.60029"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BookmarkCheckedIcon };

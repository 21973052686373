/**
 * @return {JSX.Element} Иконку треуголника, указывающего вправо
 *
 * @param {import('react').HTMLAttributes<SVGElement>} props
 * */
export function TriangleToRightIcon({ ...svgProps }) {
  return (
    <svg
      width="45"
      height="47"
      viewBox="0 0 45 47"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...svgProps}
    >
      <path
        d="M1.40918 43.4371V3.56237C1.40918 2.06273 2.99851 1.09656 4.32982 1.78687L42.7804 21.7242C44.2196 22.4705 44.2196 24.529 42.7804 25.2752L4.32981 45.2126C2.9985 45.9029 1.40918 44.9367 1.40918 43.4371Z"
        stroke="#3A9E62"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

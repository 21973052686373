const NewVkIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 36C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19C2 28.3888 9.61116 36 19 36Z"
        fill="#4D76A1"
        stroke="white"
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4334 25.579H19.6108C19.6108 25.579 19.9664 25.5399 20.1479 25.3442C20.3151 25.1645 20.3097 24.8271 20.3097 24.8271C20.3097 24.8271 20.2867 23.2473 21.0199 23.0147C21.7427 22.7856 22.6708 24.5415 23.6545 25.2167C24.3984 25.7276 24.9636 25.6156 24.9636 25.6156L27.5939 25.579C27.5939 25.579 28.9699 25.4942 28.3175 24.4123C28.264 24.3238 27.9375 23.6121 26.3618 22.1494C24.7125 20.6186 24.9334 20.8662 26.9201 18.2183C28.13 16.6056 28.6138 15.6211 28.4627 15.1994C28.3186 14.7978 27.4287 14.904 27.4287 14.904L24.4671 14.9224C24.4671 14.9224 24.2476 14.8925 24.0847 14.9898C23.9256 15.0853 23.8232 15.3077 23.8232 15.3077C23.8232 15.3077 23.3545 16.5556 22.7293 17.6169C21.4106 19.8563 20.8833 19.9745 20.6677 19.8354C20.1664 19.5114 20.2915 18.5335 20.2915 17.8388C20.2915 15.6687 20.6207 14.7639 19.6506 14.5296C19.3287 14.4518 19.0918 14.4005 18.2685 14.3922C17.2117 14.3812 16.3173 14.3954 15.8109 14.6435C15.474 14.8085 15.2141 15.1762 15.3724 15.1973C15.5681 15.2235 16.0114 15.3168 16.2465 15.6369C16.55 16.0497 16.5393 16.9771 16.5393 16.9771C16.5393 16.9771 16.7136 19.5317 16.132 19.8491C15.7328 20.0667 15.1852 19.6223 14.0095 17.5907C13.4071 16.55 12.9522 15.3997 12.9522 15.3997C12.9522 15.3997 12.8645 15.1847 12.7081 15.0698C12.5183 14.9305 12.253 14.8861 12.253 14.8861L9.43873 14.9045C9.43873 14.9045 9.01626 14.9163 8.86117 15.1C8.7232 15.2634 8.85021 15.6013 8.85021 15.6013C8.85021 15.6013 11.0535 20.7561 13.5482 23.3538C15.8358 25.7354 18.4334 25.579 18.4334 25.579Z"
        fill="white"
      />
    </svg>
  );
};

export default NewVkIcon;

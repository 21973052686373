import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import apiInnerClient from 'services/baseInnerClient';

export const useProductSettings = ({ id }) => {
  const [settings, setSettings] = useState({});
  const { data } = useQuery({
    queryKey: ['productSettings'],
    queryFn: () => apiInnerClient.getInnerProductSettings({ id }),
    enabled: true,
  });

  const setSetting = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  useEffect(() => {
    setSettings(data);
  }, [data]);
  return {
    settings,
    setSettings,
    setSetting,
  };
};

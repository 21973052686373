import { useState } from 'react';

// import { useTable } from 'context/table.context';
// import { useFetchFilteredData } from 'hooks/useFetchFilteredData';
// import { TABLE_TYPE } from 'constants/table';
// import { generateGridRowData, HIERARCHY_KEY } from 'helpers/generateGridRowData';
// import { useFilterReducer } from 'context/filter/filter.context.reducer';
// import { isGrowthPage } from 'utils/pages';

// All commented for ui filtering and because all inner data is coming by first loading
export const useCategoryChildren = ({ /*category, columnDefs, hierarchy,*/ node }) => {
  const [isExpanded, setIsExpanded] = useState(node.expanded);
  //
  // const { gridOptions } = useTable();
  // const {
  //   isFilterApplied,
  //   filter: { period },
  // } = useFilterReducer();

  // const actionType = isGrowthPage() ? TABLE_TYPE.CATEGORIES_GROWTH : TABLE_TYPE.CATEGORIES;
  // const { data: categoriesData } = useFetchFilteredData({
  //   type: actionType,
  //   depth: isFilterApplied ? 0 : 1,
  // });

  // function getRows(data) {
  //   // иногда данные приходят с разделением на периоды
  //   // например для таблиц growth темпы роста
  //   const correctData = data?.[period] || data;
  //   const children = correctData?.[0]?.children;
  //
  //   if (!children) return null;
  //
  //   const transformedData = generateGridRowData({
  //     data: children,
  //     type: TABLE_TYPE.CATEGORIES,
  //     categoriesData,
  //     columnDefs,
  //   });
  //
  //   if (!transformedData || !transformedData.rowData) return;
  //
  //   const rows = transformedData.rowData;
  //
  //   rows.forEach((item) => (item[HIERARCHY_KEY] = [...hierarchy, ...item[HIERARCHY_KEY]]));
  //
  //   return rows;
  // }

  // const handleAddingChildren = (data) => {
  //   const rows = getRows(data);
  //
  //   if (!rows) return;
  //   console.log(rows)
  //   setTimeout(() => gridOptions.api.applyTransaction({ add: rows }), 0);
  // };

  // const {
  //   data: childrenData,
  //   isLoading,
  //   isFetching,
  //   // isFetched,
  //   // refetch,
  // } = useFetchFilteredData({
  //   category,
  //   type: actionType,
  //   depth: isFilterApplied ? 0 : 1,
  //   enabled: false,
  //   onSuccess: handleAddingChildren,
  // });

  const expand = () => {
    // if (!isFetched && !isFilterApplied) {
    //   refetch();
    // } else {
    //   handleAddingChildren(childrenData);
    // }

    setTimeout(() => node.setExpanded(true), 0);

    setIsExpanded(true);
  };

  const collapse = () => {
    setTimeout(() => node.setExpanded(false), 0);

    setIsExpanded(false);
  };

  const handleCategoryChildren = () => {
    if (node.expanded) {
      collapse();
    } else {
      expand();
    }
  };

  return {
    handleCategoryChildren,
    isExpanded,
    isLoading: /*isLoading || isFetching,*/ false,
  };
};

import moment from 'moment';
import { transformProductChangesValue } from 'helpers/generateGridRowData';

export const transformChartsDataForTables = ({ chart_dates, all_data: { plain_values } }) => {
  if (!chart_dates || !plain_values || !plain_values[0]) return null;

  const values = plain_values[0];
  const data = {};

  chart_dates.forEach((period, i) => {
    data[period] = values.data[i] || 0;
  });

  return data;
};

export const transformChartsData = (data) => {
  if (!data) return [];
  return {
    labels: data?.chart_dates.map((item) => item.slice(0, -3)),
    chartData: data?.all_data?.plain_values?.[0]?.data?.map((item) => +item) || [],
  };
};

export const transformDynamicChartsData = ({ data, additionalData, keys, nameKey }) => {
  if (!data || !data.chart_dates || !data.all_data || !data?.all_data.plain_values) return {};

  const {
    chart_dates,
    all_data: { plain_values },
  } = data;

  const result = {};

  keys?.forEach((key) => (result[key] = []));

  chart_dates?.forEach((name, i) => {
    const dataSet = {};

    keys?.forEach((key) => {
      dataSet[key] = {
        [nameKey]: name,
      };
    });

    for (let j = 0; j < plain_values.length; j++) {
      const value = plain_values[j];

      const category = value?.data_type;

      if (!category) continue;

      keys?.forEach((key) => {
        if (category?.endsWith(key)) {
          dataSet[key][category] = +value?.data?.[i];
        }
      });
    }

    if (additionalData && typeof additionalData === 'object') {
      Object.keys(additionalData)?.forEach((additionalKey) => {
        keys?.forEach((key) => {
          if (additionalKey?.endsWith(key) && additionalData[additionalKey]?.[i]) {
            dataSet[key][additionalKey] = +additionalData[additionalKey]?.[i];
          }
        });
      });
    }

    keys?.forEach((key) => result[key].push(dataSet[key]));
  });

  return result;
};

export const transformPriceSegmentChartsData = ({ data, keys, nameKey }) => {
  if (!data) return {};

  const { range, step, max_price, min_price, ...items } = data;

  const result = { step, max_price, min_price };

  keys?.forEach((key) => (result[key] = []));

  range?.forEach((name, i) => {
    const dataSet = {};

    keys?.forEach((key) => {
      dataSet[key] = {
        [nameKey]: name,
      };
    });

    for (let category in items) {
      const categoryData = items[category];

      keys?.forEach((key) => {
        dataSet[key][`${category}_${key}`] = +categoryData?.[i];
      });
    }

    keys?.forEach((key) => result[key].push(dataSet[key]));
  });

  return result;
};

const getScatterData = ({ data, name }) => {
  if (!data[name] || !data[name].length) return {};

  const result = {};

  data[name].forEach((item, i) => {
    if (!item.key) return;

    result[item.key] = i;
  });

  return result;
};

export const transformProductChartsData = ({ labels, data, nameKey, typeKey, scatterData }) => {
  if (!data || !data.chart_dates) return {};

  const { chart_dates } = data;

  const result = [];

  chart_dates?.forEach((name, i) => {
    let dataSet = {
      [nameKey]: name,
    };

    if (labels && labels.length) {
      for (let j = 0; j < labels.length; j++) {
        const key = labels[j];

        const labelData = data?.[key] && data?.[key].length ? data?.[key] : null;

        if (!labelData) continue;

        const value = labelData[i];

        dataSet[`${key}_${typeKey}`] = value !== null ? +value : null;
      }
    }

    if (scatterData && scatterData.data) {
      const scatterItems = getScatterData({ data: scatterData.data, name });

      dataSet = { ...dataSet, ...scatterItems };
    }

    result.push(dataSet);
  });

  return { [typeKey]: result };
};

const transformChangesValue = (value) => {
  if (value === undefined) return '';

  return transformProductChangesValue(value).toLocaleString('ru').toLocaleLowerCase();
};

export const transformScatterChartsData = ({ data, nameKey }) => {
  const result = {};
  const keys = [];

  if (!data || !data.length)
    return {
      data: result,
      keys,
    };

  data?.forEach((item) => {
    if (!item.created_at) return;

    const { created_at, ...changes } = item;

    const date = moment(created_at, 'YYYY-MM-DD').format('DD.MM.YY');

    if (Object.keys(changes) && Object.keys(changes).length) {
      for (let i = 0; i < Object.keys(changes).length; i++) {
        const key = Object.keys(changes)[i];

        if (!result[date]) {
          result[date] = [];
        }

        if (keys.indexOf(key) < 0) {
          keys.push(key);
        }

        const before = transformChangesValue(changes[key]?.old);
        const after = transformChangesValue(changes[key]?.new);

        const value = `${before} → ${after}`;

        if (!value) continue;

        result[date].push({
          [nameKey]: date,
          key,
          value,
        });
      }
    }
  });

  return {
    data: result,
    keys,
  };
};

import { Redirect } from 'react-router-dom';

import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import ListAlerts from 'components/ListAlerts';
import { PAGE_MAIN } from 'constants';
import HelpBlock from 'components/HelpBlock';

const Sellers = () => {
  const { isSellersAvailable } = useUserTariffs();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.SELLERS,
  });

  const { isOzonPlatform } = useFilterReducer();

  if (!isSellersAvailable) {
    return <Redirect to={PAGE_MAIN} />;
  }

  return (
    <>
      <HelpBlock type="sellers" />
      <Table
        data={data}
        type={TABLE_TYPE.SELLERS}
        hideSoldColumn={isOzonPlatform}
        isSellerCellClickable={isSellersAvailable}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Sellers;

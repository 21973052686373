import React, { useCallback, useState } from 'react';
import {
  isOzonPlatform as isOzonPlatformM,
  isWbPlatform as isWbPlatformM,
} from 'context/filter/filter.context.reducer.methods';

const ProductContext = React.createContext();
ProductContext.displayName = 'Product.Context';

const getProductPlatform = (productData) => {
  if (!productData) return null;

  return productData?.platform?.id && `${productData?.platform?.id}`;
};

const ProductProvider = (props) => {
  const [error, setError] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const [visibility, setVisibility] = useState(100);
  const [avgPosition, setAvgPosition] = useState(100);

  const updateProductData = useCallback((data) => {
    setIsLoading(!data);
    setProductData(data);
  }, []);

  const updateProductError = (error) => {
    setError(error);
  };

  const updateLoading = (value) => setIsLoading(value);

  const platformId = getProductPlatform(productData);

  const value = {
    productData,
    updateProductData,
    updateLoading,
    isLoading,
    visibility,
    setVisibility,
    avgPosition,
    setAvgPosition,
    updateProductError,
    error,
    platformId,
    isWbPlatform: isWbPlatformM(platformId),
    isOzonPlatform: isOzonPlatformM(platformId),
  };

  return <ProductContext.Provider value={value} {...props} />;
};

const useProduct = () => {
  return React.useContext(ProductContext);
};

export { ProductProvider, useProduct };

import PropTypes from 'prop-types';

// import TableIsAdvertisingFilter from 'components/TableIsAdvertisingFilter';
import TableIsNewFilter from 'components/TableIsNewFilter';
import VideoIcon from '../../assets/icons/VideoIcon';
import React from 'react';
import { useVideoLessons } from '../../context/video.lessons.context';

const TableHeader = ({ children, isNewAdvertisingShown }) => {
  const { setIsModalOpen } = useVideoLessons();
  const isProd = window.location.hostname === 'go.wecheck.ru';
  return (
    <div className="flex-nowrap touch-auto overflow-x-auto lg:overflow-visible flex items-center px-4 justify-between">
      <div className="flex-nowrap touch-auto overflow-x-auto lg:overflow-visible flex items-center">
        {children}
        {isNewAdvertisingShown && (
          <div className="flex gap-4 pl-3 border-l border-gray-200">
            <TableIsNewFilter />
            {/*<TableIsAdvertisingFilter />*/}
          </div>
        )}
      </div>
      {!isProd && (
        <button
          className="px-4 py-[7px] border border-gray-250 rounded inline-flex items-center hover:text-green hover:border-green bg-white"
          onClick={() => setIsModalOpen(true)}
        >
          <VideoIcon width={24} height={24} className="mr-2" />
          <span>Видеоинструкция</span>
        </button>
      )}
    </div>
  );
};
TableHeader.defaultProps = {
  isNewAdvertisingShown: true,
};
TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isNewAdvertisingShown: PropTypes.bool,
};
export default TableHeader;

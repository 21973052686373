import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoadingSpin from '../LoadingSpin';

const PieChartWithCentralLabel = lazy(() => import('components/PieChart/PieChartWithCentralLabel'));

const PieChartRating = ({ rating, isLoading }) => {
  const showChart = typeof rating === 'number';

  return (
    <div className="overflow-hidden" style={{ height: '44px' }}>
      <div
        className="relative"
        style={{ transform: isLoading ? 'translateY(0rem)' : 'translateY(-30px)' }}
      >
        <LoadingSpin isVisible={isLoading} iconClassNames="!text-gray-250" />
        {!isLoading && showChart && (
          <PieChartWithCentralLabel
            fullValue={5}
            value={rating}
            size={80}
            paddingAngle={5}
            startAngle={180}
            endAngle={0}
            cy={'90%'}
            innerRadius="90%"
          />
        )}
        <span
          className={classNames('absolute inset-x-0 bottom-0 text-center text-lg', {
            ['hidden']: isLoading,
          })}
        >
          {rating}
        </span>
      </div>
    </div>
  );
};

PieChartRating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool,
};

export default PieChartRating;

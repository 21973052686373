const NewTelegramIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 36C28.3888 36 36 28.3888 36 19C36 9.61116 28.3888 2 19 2C9.61116 2 2 9.61116 2 19C2 28.3888 9.61116 36 19 36Z"
        fill="url(#paint0_linear_10048_1690)"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M9.58605 19.7337C11.3396 18.7678 13.2969 17.9617 15.1258 17.1515C18.2722 15.8243 21.4311 14.5202 24.6218 13.3061C25.2426 13.0992 26.3581 12.897 26.4674 13.817C26.4074 15.1192 26.1612 16.4138 25.9922 17.7085C25.5633 20.5555 25.0674 23.3927 24.5841 26.2303C24.4174 27.1755 23.2334 27.6648 22.4758 27.06C20.6551 25.83 18.8203 24.6122 17.0228 23.3537C16.4341 22.7555 16.9801 21.8962 17.5058 21.469C19.0054 19.9911 20.5958 18.7355 22.0171 17.1812C22.4004 16.2555 21.2677 17.0357 20.8941 17.2748C18.8411 18.6896 16.8383 20.1907 14.6738 21.4341C13.5682 22.0427 12.2796 21.5226 11.1744 21.1828C10.1836 20.7727 8.73155 20.3593 9.58593 19.7338L9.58605 19.7337Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10048_1690"
          x1="24.0012"
          y1="9.001"
          x2="16.5012"
          y2="26.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37AEE2" />
          <stop offset="1" stopColor="#1E96C8" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NewTelegramIcon;

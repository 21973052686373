/**
 * @return {JSX.Element} Иконку "Крестик"
 *
 * @param {import('react').HTMLAttributes<SVGElement>} props
 * */
export function CrossIcon({ ...svgProps }) {
  const fill = svgProps ? svgProps.fillProps : '#6E6E6E';

  return (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.192 1.692a.625.625 0 1 0-.884-.884L5 4.116 1.692.808a.625.625 0 1 0-.884.884L4.116 5 .808 8.308a.625.625 0 1 0 .884.884L5 5.884l3.308 3.308a.625.625 0 1 0 .884-.884L5.884 5l3.308-3.308Z"
        fill={fill}
      />
    </svg>
  );
}

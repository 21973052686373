import {
  getDateInitial,
  fixInnerFilterParams,
  getChipsInitial,
  getInitialPlatform,
  getWarehouseInitial,
  getWarehouseType,
  getSortInitial,
} from './filter.context.reducer.methods';
import { F_INNER } from 'constants/filter';
import { ACTIONS } from './filter.context.reducer';
import { F } from 'constants';
import { useCallback, useMemo } from 'react';
import { useAuth } from 'context/auth.context';

export const filterInitial = {
  [F_INNER.CATEGORY]: [],
  [F_INNER.BRAND]: [],
  [F_INNER.SUBJECT]: [],
  [F_INNER.API_KEYS]: [],
  [F.WAREHOUSE]: [],
  [F.PLATFORM]: [],
  [F.WAREHOUSE_TYPE]: null,
  [F.DATE]: [],
  [F_INNER.QUANTITY]: 0,
  [F_INNER.USE_WAREHOUSE]: false,
  [F_INNER.WAREHOUSE]: [],
  [F_INNER.DELIVERY_DATE]: null,
  [F_INNER.DELIVERY_STOCK_DATE]: false,
  [F_INNER.STATUS]: ['Оформлен', 'Оплачен', 'Возврат'],
};

export const CHIPS_KEYS_INNER = [
  F_INNER.CATEGORY,
  F_INNER.BRAND,
  F_INNER.SUBJECT,
  F_INNER.WAREHOUSE,
];

export const useFilterInner = ({ state, dispatch }) => {
  const chipsInitial = useMemo(() => getChipsInitial(CHIPS_KEYS_INNER), []);
  const { platforms } = useAuth();
  const getFilterInitial = () => {
    const platform = getInitialPlatform(platforms);
    const warehouse = getWarehouseInitial({ platform });
    return {
      //здесь будут начальные фильтры
      [F.PLATFORM]: platform,
      [F.WAREHOUSE]: warehouse,
      [F.WAREHOUSE_TYPE]: getWarehouseType(warehouse),
      [F.SORT]: getSortInitial(),
    };
  };

  const getFilterFromParams = (filterParams) => {
    return fixInnerFilterParams({
      params: filterParams,
    });
  };

  const getFilterDefault = () => {
    return {
      //здесь будут начальные фильтры
    };
  };

  const getProductFilterDefault = () => {
    const date = getDateInitial({ isInner: true });
    return {
      [F_INNER.CATEGORY]: filterInitial[F_INNER.CATEGORY],
      [F_INNER.BRAND]: filterInitial[F_INNER.BRAND],
      [F_INNER.SUBJECT]: filterInitial[F_INNER.SUBJECT],
      [F.WAREHOUSE]: filterInitial[F.WAREHOUSE],
      [F.PLATFORM]: filterInitial[F.PLATFORM],
      [F.WAREHOUSE_TYPE]: filterInitial[F.WAREHOUSE_TYPE],
      [F.DATE]: date,
    };
  };

  const resetFilter = () => {
    const date = getDateInitial({ isInner: true });
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F_INNER.CATEGORY]: filterInitial[F_INNER.CATEGORY],
        [F_INNER.BRAND]: filterInitial[F_INNER.BRAND],
        [F_INNER.SUBJECT]: filterInitial[F_INNER.SUBJECT],
        [F.WAREHOUSE]: filterInitial[F.WAREHOUSE],
        [F.PLATFORM]: filterInitial[F.PLATFORM],
        [F.WAREHOUSE_TYPE]: filterInitial[F.WAREHOUSE_TYPE],
        [F.DATE]: date,
      },
      chips: chipsInitial,
    });
  };

  const toggleApiKey = (id) => {
    id = +id;
    const keysExisting = state.filter[F_INNER.API_KEYS];
    let keysNew = [];

    if (keysExisting.includes(id)) {
      //remove key
      keysNew = keysExisting.filter((key) => key !== id);
    } else {
      //add key
      keysNew = [...keysExisting, id];
    }
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F_INNER.API_KEYS]: keysNew,
      },
    });
  };

  const toggleStatus = (id) => {
    const statusesExisting = state.filter[F_INNER.STATUS];
    let statusesNew = [];

    if (statusesExisting.includes(id)) {
      //remove key
      statusesNew = statusesExisting.filter((key) => key !== id);
    } else {
      //add key
      statusesNew = [...statusesExisting, id];
    }
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F_INNER.STATUS]: statusesNew,
      },
    });
  };

  const setApiKeys = useCallback(
    (keysNew) => {
      dispatch({
        type: ACTIONS.UPDATE_FILTER,
        filter: {
          [F_INNER.API_KEYS]: !Array.isArray(keysNew) ? [keysNew] : keysNew,
        },
      });
    },
    [dispatch],
  );

  const updateFromModalFilter = ({ filter, chips }) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter,
      chips,
    });
  };

  const setQuantity = (quantity) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F_INNER.QUANTITY]: quantity,
      },
    });
  };

  const setDeliveryDate = useCallback(
    (deliveryDate) => {
      dispatch({
        type: ACTIONS.UPDATE_FILTER,
        filter: {
          [F_INNER.DELIVERY_DATE]: deliveryDate,
        },
      });
    },
    [dispatch],
  );

  const setDeliveryStockDate = useCallback(
    (deliveryStockDate) => {
      dispatch({
        type: ACTIONS.UPDATE_FILTER,
        filter: {
          [F_INNER.DELIVERY_STOCK_DATE]: deliveryStockDate,
        },
      });
    },
    [dispatch],
  );

  const setUseWarehouse = (useWarehouse) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F_INNER.USE_WAREHOUSE]: useWarehouse,
      },
    });
  };

  const setFilterState = (state) => {
    dispatch({
      type: ACTIONS.SET_FULL_STATE,
      state,
    });
  };

  const isFilterApplied =
    !!state?.filter?.[F_INNER.CATEGORY]?.length ||
    !!state?.filter?.[F_INNER.BRAND]?.length ||
    !!state?.filter?.[F_INNER.SUBJECT]?.length;

  return {
    common: {
      getFilterInitial,
      getFilterFromParams,
      getFilterDefault,
      setDeliveryDate,
      setDeliveryStockDate,
      resetFilter,
      setQuantity,
      setUseWarehouse,
      productFilterDefault: getProductFilterDefault(),
      isFilterApplied,
      setFilterState,
    },
    exceptional: {
      setApiKeys,
      toggleApiKey,
      updateFromModalFilter,
      toggleStatus,
    },
  };
};

import React, { useEffect, useState } from 'react';

const TableTemplatesContext = React.createContext();
TableTemplatesContext.displayName = 'TableTemplates.Context';

const getInitTemplates = () => {
  const isTablesPatched = localStorage.getItem('WECHECK_lastPatchDate') === '18.04.2024';
  if (isTablesPatched) {
    const storageTemplates = localStorage.getItem('WECHECK_table_active_templates');
    if (storageTemplates) {
      return JSON.parse(storageTemplates);
    }
  } else {
    localStorage.removeItem('WECHECK_table_active_templates');
    return {};
  }
  return {};
};

const TableTemplatesProvider = (props) => {
  const [activeTemplates, setActiveTemplates] = useState(getInitTemplates());

  useEffect(() => {
    localStorage.setItem('WECHECK_table_active_templates', JSON.stringify(activeTemplates));
  }, [activeTemplates]);

  const setActiveTemplate = (url, id) => {
    const result = JSON.parse(JSON.stringify(activeTemplates));
    result[url] = id;
    setActiveTemplates(result);
  };

  const deactivateTemplate = (url) => {
    setActiveTemplates((current) => {
      if (url in current) {
        const result = JSON.parse(JSON.stringify(current));
        delete result[url];
        return result;
      }
      return current;
    });
  };

  const isActiveTemplate = (url, id) => activeTemplates[url] === id;

  const value = {
    activeTemplates,
    setActiveTemplate,
    deactivateTemplate,
    isActiveTemplate,
  };

  return <TableTemplatesContext.Provider value={value} {...props} />;
};

const useTableTemplates = () => {
  return React.useContext(TableTemplatesContext);
};

export { TableTemplatesProvider, useTableTemplates };

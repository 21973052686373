const StarCrossedIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 22">
    <path
      d="M18.8,9.3C19.1,9,19,8.5,18.5,8.4l-4.7-0.7c-0.2,0-0.3-0.1-0.4-0.3l-2.1-4.2c-0.2-0.4-0.7-0.4-0.9,0L9.3,5.9
		l6.5,6.5L18.8,9.3z"
    />
    <path
      d="M3.4,8.4C3,8.5,2.9,9,3.2,9.3l3.4,3.3c0.1,0.1,0.2,0.3,0.1,0.4l-0.8,4.6c-0.1,0.4,0.4,0.7,0.7,0.5l4.2-2.2
		c0.1-0.1,0.3-0.1,0.5,0l2.1,1.1L4.5,8.3L3.4,8.4z"
    />
    <path d="M0.3,0.3c0.4-0.4,1-0.4,1.4,0l20,20c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0l-20-20C-0.1,1.3-0.1,0.7,0.3,0.3z" />
  </svg>
);
export default StarCrossedIcon;

const ClockIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="7.00012" r="6" stroke="currentColor" strokeLinejoin="round" />
      <path
        d="M6 4.00024V8.00024H9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;

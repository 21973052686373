const EmptyPhoto = (props) => {
  return (
    <svg {...props} viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2511 9.47873C15.7939 9.47873 17.8722 11.5564 17.8722 14.0989C17.8722 14.3723 17.8449 14.6456 17.7902 14.9191L24.626 21.7536C25.118 21.2616 25.4188 20.5781 25.4188 19.8125V8.90463C25.4188 7.34627 24.1609 6.08882 22.6025 6.08882H18.337L17.2706 3.90173C17.0245 3.40975 16.505 3.08154 15.9582 3.08154H10.5169C9.97004 3.08154 9.47776 3.40954 9.23172 3.90173L8.41138 5.54211L12.4036 9.53355C12.6771 9.50609 12.9504 9.47882 13.2512 9.47882L13.2511 9.47873Z"
        fill="#D9D9D9"
      />
      <path
        d="M25.6925 25.1711L1.82186 1.30505C1.41179 0.895061 0.755476 0.895061 0.317936 1.30505C-0.1196 1.71504 -0.0921347 2.37123 0.317936 2.80869L3.65381 6.14392C2.20462 6.25319 1.0835 7.45609 1.0835 8.93244V19.8403C1.0835 21.3987 2.34138 22.6561 3.89985 22.6561H20.1691L24.2158 26.7021C24.4346 26.9208 24.6806 27.0028 24.9541 27.0028C25.2276 27.0028 25.5009 26.8935 25.6924 26.7021C26.1025 26.2646 26.1025 25.5812 25.6924 25.171L25.6925 25.1711ZM13.2513 18.7193C10.7085 18.7193 8.63027 16.6417 8.63027 14.0992C8.63027 13.2517 8.87632 12.4316 9.28656 11.7481L15.6028 18.0632C14.9193 18.5006 14.0989 18.7193 13.2513 18.7193Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default EmptyPhoto;

import { useRef, useEffect, useState, useLayoutEffect } from 'react';

import { useUI } from 'context/ui.context';
import { useGlobalDataLoading } from 'hooks/useGlobalDataLoading';

export const STICKY_ATTRIBUTE = 'stuck';
export const STICKY_HEIGHT_CSS_VAR = '--panel-sticky-height';

const setStickyHeight = (height) => {
  document.documentElement.style.setProperty(STICKY_HEIGHT_CSS_VAR, `${height}px`);
};

const useStickyObserver = ({ disableOnMobile }) => {
  const loading = useGlobalDataLoading();
  const { isMobile } = useUI();

  const elementRef = useRef(null);
  const callbackFunction = useRef(null);
  const mutationCallbackFunction = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  const setElStickyHeight = () => {
    const height = elementRef?.current?.clientHeight || 0;

    setStickyHeight(height);
  };

  callbackFunction.current = ([entry]) => {
    setIsSticky(entry.boundingClientRect.top < 0 && entry.boundingClientRect.bottom > 0);
  };

  const options = useRef({
    threshold: [0, 1],
  });

  mutationCallbackFunction.current = () => setElStickyHeight();

  const mutationOptions = useRef({
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true,
  });

  useLayoutEffect(() => {
    window.addEventListener('resize', setElStickyHeight, true);

    return () => window.removeEventListener('resize', setElStickyHeight, true);
  }, []);

  useLayoutEffect(() => {
    setElStickyHeight();

    return () => setStickyHeight(0);
  }, [loading]);

  useEffect(() => {
    if (!elementRef.current || (isMobile && disableOnMobile)) return;

    const node = elementRef.current;

    const observer = new IntersectionObserver(callbackFunction.current, options.current);
    observer.observe(node);

    return () => {
      if (!node) return;

      observer.unobserve(node);
    };
  }, [isMobile, disableOnMobile]);

  useEffect(() => {
    if (!elementRef.current || (isMobile && disableOnMobile)) return;

    const node = elementRef.current;

    const observer = new MutationObserver(mutationCallbackFunction.current);
    observer.observe(node, mutationOptions.current);

    return () => {
      if (!node) return;

      observer.disconnect();
    };
  }, [isMobile, disableOnMobile]);

  useEffect(() => {
    if (!elementRef.current) return;

    const node = elementRef.current;

    if (isMobile && disableOnMobile) {
      node.setAttribute(STICKY_ATTRIBUTE, 0);
      node.style.zIndex = '';
      node.style.top = '';
      node.style.position = '';
    } else {
      node.setAttribute(STICKY_ATTRIBUTE, 1);
      node.style.zIndex = '40';
      // top: -1px on the sticky element
      // so that you can easily observe
      // the stuckness of the element based
      // on its overlap with the browser viewport.
      node.style.top = '-1px';
      node.style.position = 'sticky';
    }
  }, [isMobile, elementRef, disableOnMobile]);

  return [elementRef, isSticky];
};

export const useStickyHeight = () => {
  return parseFloat(
    getComputedStyle(document.documentElement).getPropertyValue(STICKY_HEIGHT_CSS_VAR),
  );
};

export default useStickyObserver;

import React, { useRef } from 'react';

import { useTable } from 'context/table.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { NOT_AVAILABLE_BECAUSE_TARIFF } from 'constants/tooltips';
import CSVIcon from 'assets/icons/CSVIcon';
import Popover from 'components/Popover';

const ButtonExportCsv = () => {
  const tooltipRef = useRef();
  const { exportCsv } = useTable();
  const { isTableExportAvailable } = useUserTariffs();

  return (
    <div className="flex items-center">
      <button onClick={exportCsv} ref={tooltipRef}>
        <CSVIcon className="h-6 w-4 transition-all text-gray-300 hover:text-green" />
      </button>
      {!isTableExportAvailable && (
        <Popover tooltip={NOT_AVAILABLE_BECAUSE_TARIFF} tooltipRef={tooltipRef} />
      )}
    </div>
  );
};

export default ButtonExportCsv;

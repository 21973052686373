import PropTypes from 'prop-types';

const Checkbox = ({
  onChange,
  color,
  label,
  name,
  checked,
  disabled,
  labelClass,
  className,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    className={`flex items-start ${className} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <input
      id={name}
      name={name}
      type="checkbox"
      className={`checkbox h-4 w-4 shrink-0 bg-origin-border
        align-middle color-${color}-bright border-${color}-bright border
        rounded appearance-none transition-all cursor-pointer
        focus:ring-2 focus:ring-offset-2 focus:ring-${color}-bright focus:outline-none
        checked:border-transparent checked:bg-contain checked:bg-center checked:bg-no-repeat checked:bg-${color}-bright`}
      onChange={onChange}
      checked={checked}
    />
    <label
      htmlFor={name}
      className={`pl-2 translate-y-px leading-none ${labelClass}`}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  </div>
);

Checkbox.defaultProps = {
  color: 'green',
  disabled: false,
  labelClass: '',
  className: '',
};
Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  labelClass: PropTypes.string,
  className: PropTypes.string,
};

export default Checkbox;

import axios from 'axios';
import { getToken } from 'utils/token';
import { getApi } from 'utils/api';
import { LIST_TYPE } from 'constants/list';

class ProductListClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });

    this.client.interceptors.request.use(function (config) {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });
  }

  async get(endpoint) {
    return this.client
      .get(endpoint)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.message || 'Something went wrong :(');
      });
  }

  async post(endpoint, data) {
    return this.client.post(endpoint, data);
  }

  async put(endpoint, data) {
    return this.client.put(endpoint, data);
  }

  async delete(endpoint) {
    return this.client.delete(endpoint);
  }

  getList(id) {
    return this.get(`/user/list/${id}`);
  }

  deleteList(id) {
    return this.delete(`/user/list/${id}`).then(({ data }) => data);
  }

  renameList({ id, name }) {
    return this.put(`/user/list/${id}?name=${name}`).then(({ data }) => data);
  }

  clearList(id) {
    return this.post(`/user/list/${id}/detach-items`).then(({ data }) => data);
  }

  loadListsInfoByType({ type }) {
    return this.get(`/user/list-with-items?type=${type}`).then((data) => data);
  }

  getUserList() {
    return this.get('/user/list').then(({ data }) => (data.length ? data : []));
  }

  createList({ name, type = LIST_TYPE.BY_PRODUCTS }) {
    return this.post('/user/list', { name, type }).then(({ data }) => data);
  }

  addItemToList({ listId, id, platform_id }) {
    return this.post(`/user/list/${listId}/item?item_id=${id}&platform_id=${platform_id}`).then(
      ({ data }) => {
        return data;
      },
    );
  }

  deleteItemFromList({ listId, id, platform_id }) {
    return this.delete(`/user/list/${listId}/item?item_id=${id}&platform_id=${platform_id}`).then(
      ({ data }) => {
        return data;
      },
    );
  }
}

const productListClient = new ProductListClient(getApi());

export default productListClient;

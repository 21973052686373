import React, { lazy } from 'react';

import { Redirect, useParams } from 'react-router-dom';
import Table from 'components/Table';
import { COLUMNS, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import { useUserTariffs } from 'context/user.tariffs.context';

const ProductChart = lazy(() => import('components/ProductChart'));

import { sortByCategory } from 'utils/table';
import {
  BOUQUET,
  BROWN,
  CEMENT,
  DESERT,
  GREEN,
  MALIBU,
  MOJO,
  MOON,
  PINE,
  ROSE,
  SALMON,
  VIOLET,
  CHARTS_COLORS_WB,
  CHARTS_COLORS_OZON,
  PINK,
  BLUE,
  GRAY_DARK,
} from 'constants/colors';
import { getColor } from 'utils/charts';
import { useProduct } from 'context/product.context';
import HelpBlock from 'components/HelpBlock';
import PropTypes from 'prop-types';
import { useAnalyticType } from 'context/analytic.type.context';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import { dateComparatorOuter } from 'helpers/generateGridColumnsHelpers';

const COLORS = [
  BOUQUET,
  GREEN,
  ROSE,
  MALIBU,
  BROWN,
  DESERT,
  SALMON,
  VIOLET,
  CEMENT,
  PINE,
  MOJO,
  MOON,
];

const INNER_COLORS = [PINK, BLUE, GREEN, GRAY_DARK];

const Listing = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { isInner } = useAnalyticType();
  const { isLoading: productLoading, isWbPlatform } = useProduct();

  const { isProductListingAvailable } = useUserTariffs();

  const { data, isLoading, isFetching, error } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_LISTING,
    id,
    isInner,
    enabled: isProductListingAvailable && fetchEnabled,
  });

  const { data: ratingsData } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.RATINGS,
    data: { id },
    enabled: fetchEnabled && isInner,
  });
  sortByCategory(data);

  const labels = {};
  const categoriesLabels = [];
  const extra = {};
  let colors;
  if (!isInner) {
    colors = isWbPlatform ? CHARTS_COLORS_WB : CHARTS_COLORS_OZON;
    data?.table_data?.forEach((item, i) => {
      if (!item.category || !item.listing) return;

      const index = `category-${i}`;

      labels[index] = item.category;
      categoriesLabels.push(index);
      extra[index] = item.listing;

      colors.push(getColor({ colors: COLORS, index: i }));
    });
  } else {
    colors = [...INNER_COLORS, ...CHARTS_COLORS_WB, ...INNER_COLORS];
  }
  const initialLabels = [COLUMNS.ORDERS_RUB_, ...categoriesLabels.slice(0, 3)];

  if (!isProductListingAvailable) {
    return <Redirect to={`/product/${id}`} />;
  }
  const buildInnerExtra = () => {
    const data = ratingsData?.pages?.[0] || [];
    return data
      .sort((a, b) => dateComparatorOuter(a.date, b.date))
      .reduce((prev, el) => {
        const orders_rub = prev[COLUMNS.ORDERS_RUB_];
        const orders_qty = prev[COLUMNS.ORDERS_QTY];
        const price = prev[COLUMNS.PRICE];
        const stock_qty = prev[COLUMNS.STOCK_QTY];
        return {
          [COLUMNS.ORDERS_RUB_]: !Array.isArray(orders_rub)
            ? [el[COLUMNS.SUM_ORDERS_INNER]]
            : [...orders_rub, el[COLUMNS.SUM_ORDERS_INNER]],
          [COLUMNS.ORDERS_QTY]: !Array.isArray(orders_qty)
            ? [el[COLUMNS.COUNT_ORDERS_INNER]]
            : [...orders_qty, el[COLUMNS.COUNT_ORDERS_INNER]],
          [COLUMNS.PRICE]: !Array.isArray(price)
            ? [el[COLUMNS.PRICE]]
            : [...price, el[COLUMNS.PRICE]],
          [COLUMNS.STOCK_QTY]: !Array.isArray(stock_qty)
            ? [el[COLUMNS.QUANTITY]]
            : [...stock_qty, el[COLUMNS.QUANTITY]],
        };
      }, {});
  };
  return (
    <>
      {!isInner && <HelpBlock type="product_positions" />}
      <div className="bg-gray-100 py-4">
        <div className="wrapper-wide">
          <ProductChart
            fetchEnabled={fetchEnabled}
            loading={isLoading}
            error={error}
            labels={labels}
            extra={isInner ? { ...buildInnerExtra(), ...extra } : extra}
            colors={colors}
            columns={colors.length < 5 ? colors.length + 4 : 8}
            initialLabels={initialLabels}
            showPostfixes={false}
            reversed={categoriesLabels}
            excludeLabelsFromStorage={categoriesLabels}
            chartKey={TABLE_TYPE.PRODUCT_LISTING}
          />
        </div>
      </div>
      {fetchEnabled && (
        <Table
          data={data || []}
          type={TABLE_TYPE.PRODUCT_LISTING}
          autoSize={true}
          isLoading={isLoading || isFetching || productLoading}
          hideFilterPanel={true}
        />
      )}
    </>
  );
};
Listing.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Listing;

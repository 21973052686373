import PropTypes from 'prop-types';

const MAX_AMOUNT = 50;

const InputList = ({ max, min, placeholder, values, onChange, onPaste }) => {
  let amount = values.length;
  let amountEmpty = values.filter((value) => !value).length;
  let count = amount < min ? min : amount >= max ? max : amount + (!amountEmpty ? 1 : 0);

  const inputs = () => {
    const output = [];

    for (let i = 0; i < count; i++) {
      output.push(
        <div className="flex" key={`input-${i}`}>
          <input
            id={`input_${i}`}
            value={values?.[i] || ''}
            onChange={onChange(i)}
            onPaste={onPaste && onPaste(i)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                const nextInput = document.getElementById(`input_${i + 1}`);
                if (nextInput) {
                  nextInput.focus();
                }
              }
            }}
            type="text"
            autoFocus={count === i}
            placeholder={placeholder}
            className="-mx-4 py-3 px-4 rounded-lg transition-all flex-1 leading-4 placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green"
          />
        </div>,
      );
    }

    return output;
  };

  return (
    <div className="rounded-lg border border-gray-250 px-4 divide-y divide-gray-100">
      {inputs()}
    </div>
  );
};

InputList.defaultProps = {
  max: MAX_AMOUNT,
  placeholder: '',
};
InputList.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onPaste: PropTypes.func,
  values: PropTypes.array.isRequired,
};

export default InputList;

const BookmarkInsertIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path
        d="M16.3333 11.8332V17.4933C16.3333 17.823 15.9687 18.0221 15.6914 17.8438L10.7253 14.6514C10.5881 14.5631 10.4119 14.5631 10.2747 14.6514L5.30865 17.8438C5.03136 18.0221 4.66667 17.823 4.66667 17.4933V6.17318C4.66667 5.02258 5.59941 4.08984 6.75001 4.08984H9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.8672 1.55664L13.7994 6.62402M13.7994 6.62402V3.00425M13.7994 6.62402H17.3534"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BookmarkInsertIcon };

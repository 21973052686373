const PasswordRecoverySuccess = () => {
  return (
    <div>
      <span className="font-light text-sm text-[#595959]">
        Если учетная запись существует, вам будет <br />
        отправлен e-mail с дальнейшими инструкциями
      </span>
    </div>
  );
};

export default PasswordRecoverySuccess;

import React from 'react';
import Tariffs from 'components/Tariffs';

const TariffsPage = () => (
  <div className="px-3 lg:px-8">
    <Tariffs />
  </div>
);

export default TariffsPage;

export const TYPE_ABSOLUTE = 'absolute';
export const TYPE_PERCENT = 'percent';
export const PERIOD_DAY = 'day';
export const PERIOD_WEEK = 'week';
export const PERIOD_MONTH = 'month';
export const NAME_KEY = 'name';
export const CHART_TYPE = {
  BRANDS: 'brands',
  BRANDS_WITH_SALES: 'brands_with_sales',
  PRODUCTS: 'products',
  SELLERS: 'sellers',
  SELLERS_WITH_SALES: 'sellers_with_sales',
  SALES_BY_PRODUCT: 'sales_by_product',
  SALES: 'all_sales',
  PRICE: 'price',
  STOCK: 'stock',
  DYNAMIC: 'dynamic',
  PRICE_SEGMENT: 'price_segment',
  ORDERS: 'orders',
};

export const TYPES = [TYPE_ABSOLUTE, TYPE_PERCENT];

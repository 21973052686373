import React from 'react';
import PropTypes from 'prop-types';

const Layout = ({ children }) => <main className="flex-auto">{children}</main>;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Layout;

import SmartDescriptionContent from 'components/SeoSmartDescription';

const SmartDescription = () => {
  return (
    <div>
      <SmartDescriptionContent />
    </div>
  );
};

export default SmartDescription;

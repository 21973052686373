import PropTypes from 'prop-types';
import { BarChart, Bar } from 'recharts';

const TableSeoOrdersChartCell = (props) => {
  const { data, colDef } = props;
  const { prefix = 'byPhraseChart', fromJSON, useFieldPath, field, width } = colDef;
  let resultData = useFieldPath ? data[field] : data;
  if (fromJSON) {
    resultData = JSON.parse(resultData);
  }
  const array = Object.keys(resultData)
    .filter((el) => el.indexOf(prefix) !== -1)
    .map((el) => {
      const date = el.split('/')[1];
      return {
        date,
        value: resultData[el],
      };
    });

  return (
    <div className="">
      <BarChart width={width - 10} height={40} data={array}>
        <Bar dataKey="value" fill="#219653" radius={[2, 2, 2, 2]} barSize={3} />
      </BarChart>
    </div>
  );
};

TableSeoOrdersChartCell.propTypes = {
  data: PropTypes.any,
  colDef: PropTypes.any,
  field: PropTypes.any,
};

export default TableSeoOrdersChartCell;

export const HELP_BLOCK_TYPES = {
  CATEGORIES: 'categories',
  SELLERS: 'sellers',
  BRANDS: 'brands',
  PRODUCTS: 'products',
  TRENDS: 'trends',
  WAREHOUSES: 'warehouses',
  PRICE_SEGMENTS: 'price_segments',
  PRODUCT_ORDERS: 'product_orders', //+
  PRODUCT_WAREHOUSES: 'product_warehouses', //+
  PRODUCT_SIZES: 'product_sizes', //+
  PRODUCT_COLORS: 'product_colors', //+
  PRODUCT_POSITIONS: 'product_positions',
  PRODUCT_SEARCH: 'product_search', //+
  PRODUCT_ADVERTISING: 'product_advertising', //+
  PRODUCT_CHANGES: 'product_changes', //+
  PRODUCT_SIMILAR: 'product_similar', //+
};

export const HELP_BLOCK_INFO = {
  [HELP_BLOCK_TYPES.CATEGORIES]: {
    tabName: 'Подбор ниши / Аналитика по категориям',
    helpText: (
      <span>
        Аналитика по Категориям — детальный анализ всех категорий маркетплейса на одной странице.
        <br />
        Инструмент поможет подобрать новые ниши для торговли на маркетплейсах, а также изучить
        категории, в которых вы работаете.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.SELLERS]: {
    tabName: 'Аналитика по конкурентам для выявление успешных стратегий продаж',
    helpText: (
      <span>
        Аналитика по Продавцам позволит изучить конкуренцию и выявить монополизированные ниши.
        <br />
        Инструмент покажет лидеров в каждой категории. Изучайте стратегии топ-селлеров и опережайте
        их!
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.BRANDS]: {
    tabName: 'Аналитика по торговым маркам для изучения предпочтений покупателей',
    helpText: (
      <span>
        Аналитика по Брендам покажет, какие торговые марки пользуются спросом. Инструмент помогает
        спланировать производство
        <br />
        или закупку. Узнайте, монополизирована ли категория известными марками или noname-бренды
        тоже популярны.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCTS]: {
    tabName: 'Подбор лучших товаров для продажи / Аналитика по товарам',
    helpText: (
      <span>
        Аналитика по Товарам покажет лучшие продукты для торговли на маркетплейсах. Находите
        бестселлеры в категории,
        <br />у продавцов или брендов. Используйте фильтры, чтобы просматривать товары,
        соответствующие вашему запросу.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.TRENDS]: {
    tabName: 'Аналитика трендов ',
    helpText: (
      <span>
        Инструмент покажет динамику заказов в категории, у продавца или бренда. Оцените, как
        меняется обьем заказов, уровень
        <br />
        конкуренции, средний чек и ассортимент. Подбирайте категории с самыми высокими темпами роста
        заказов.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.WAREHOUSES]: {
    tabName: 'Аналитика складов для правильного управления поставками',
    helpText: (
      <span>
        Инструмент поможет правильно распределить товары по регионам. Изучите стратегии поставок у
        лидеров категорий.
        <br />
        Это поможет не заморозить товары на непопулярном складе и не упустить заказы из-за того, что
        товар закончился.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRICE_SEGMENTS]: {
    tabName: 'Тонкая аналитика по ценовым сегментам ',
    helpText: (
      <span>
        Инструмент помогает оценить, в каком ценовом сегменте выгоднее продавать товары.
        <br />
        Изучите, в каком ценовом диапазоне больше заказов и меньше конкуренция, чтобы зарабатывать
        проще.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_ORDERS]: {
    tabName: 'Аналитика заказов, остатков и изменения цены',
    helpText: (
      <span>
        Инструмент помогает проанализировать заказы, изменения цены, остатка, рейтинга и отзывов.
        <br />
        График позволяет отслеживать, как цена влияет на заказы товара и в какой момент продавец
        делает поставки.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_WAREHOUSES]: {
    tabName: 'Аналитика складов для правильного управления поставками',
    helpText: (
      <span>
        Инструмент помогает правильно распределить товары по складам и регионам. Изучите стратегии
        поставок у похожих на ваши
        <br />
        {
          'товары. Это поможет сделать грамотные поставки: не "заморозить" невостребованные товары и не упустить заказы из-за'
        }
        <br />
        {
          'дефицита на одном из складов. Соотнеся между собой показатели "Заказы" и "Остатки", вы сможете оценить, насколько'
        }
        <br />
        предложение товара удовлетворяет спрос по каждому складу.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_SIZES]: {
    tabName: 'Аналитика размеров для правильной закупки товаров',
    helpText: (
      <span>
        Инструмент отражает спрос в разрезе размеров. Это помогает поставить их в оптимальном
        количестве и сэкономить за закупке. <br />
        {
          'Соотнесите "Заказы" и "Остатки", чтобы оценить, насколько предложение товара удовлетворяет спрос.'
        }
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_COLORS]: {
    tabName: 'Аналитика цветов для правильной закупки товаров',
    helpText: (
      <span>
        Аналитика по цветам показывает, какие цвета пользуются большим спросом. Это поможет
        эффективнее спланировать закупку <br />
        {
          'товара по цветам. Соотнесите показатели "Заказы" и "Остатки", чтобы оценить насколько предложение товара удовлетворяет'
        }
        <br />
        спрос.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_POSITIONS]: {
    tabName: 'Аналитика позиций товаров в категориях',
    helpText: (
      <span>
        Инструмент позволяет оценить, в каких категориях и на каких позициях был размещен товар.
        Оцените, как высоко в каталоге
        <br />
        отображается товар и как это влияет на продажи.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_SEARCH]: {
    tabName: 'Аналитика поисковых запросов для оптимизации карточки ',
    helpText: (
      <span>
        Инструмент отображает ежедневную динамику позиций товара в поисковой выдаче по всем
        запросам.
        <br />
        Изучите, по каким поисковым фразам покупатели находят товар и спланируйте seo-оптимизацию.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_ADVERTISING]: {
    tabName: 'Аналитика рекламы, для оценки эффективности рекламных кампаний ',
    helpText: (
      <span>
        Инструмент помогает оценить, как реклама повлияла на продажи у конкурентов, и спланировать
        успешные кампании.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_CHANGES]: {
    tabName: 'Аналитика изменний товара и их влияния на продажи',
    helpText: (
      <span>
        Инструмент отображает все изменения в цене и карточке товара: в описании, характеристиках,
        фотографиях и видео.
        <br />
        Узнайте, как изменения цены и обновления контента повлияли на продажи.
      </span>
    ),
    video: '',
  },
  [HELP_BLOCK_TYPES.PRODUCT_SIMILAR]: {
    tabName: 'Аналитика похожих товаров',
    helpText: (
      <span>
        Инструмент позволяет легко подобрать похожие товары конкурентов для аналитики по складам,
        цветам и размерам.
        <br />
        Вы быстро найдете хиты продаж среди аналогичных товаров других продавцов и изучите их
        стратегии.
      </span>
    ),
    video: '',
  },
};

import cn from 'classnames';
import { PAGE_TITLE_STYLES } from 'components/Panel/components';
import FilterInner from 'components/FilterInner';
import { DeliverySearch } from 'components/Delivery';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { FETCH_INNER } from 'constants/inner';
import { Redirect } from 'react-router-dom';
import { PAGE_API_KEYS } from 'constants';

const DeliveryPage = () => {
  const { isInnerAnalyticsAvailable } = useUserTariffs();
  const { data: apiKeys = [], isFetched } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS,
    enabled: isInnerAnalyticsAvailable,
  });
  if (isFetched && apiKeys.length === 0) {
    return <Redirect to={PAGE_API_KEYS} />;
  }
  return (
    <div>
      <div className={cn('bg-white pt-2 lg:pt-3 pb-2 lg:pb-3')}>
        <div className="wrapper-wide flex justify-between items-center h-[34px]">
          <h1 className={PAGE_TITLE_STYLES}>Расчет поставки</h1>
        </div>

        <div className="pt-2 lg:pt-3">
          <div className="wrapper-wide flex gap-2">{<FilterInner />}</div>
        </div>
      </div>
      <div>
        <DeliverySearch />
      </div>
    </div>
  );
};

export default DeliveryPage;

const BookmarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M4.16675 5.41683C4.16675 4.26624 5.09949 3.3335 6.25008 3.3335H13.7501C14.9007 3.3335 15.8334 4.26624 15.8334 5.41683V16.737C15.8334 17.0666 15.4687 17.2657 15.1914 17.0875L10.2254 13.895C10.0881 13.8068 9.91202 13.8068 9.77477 13.895L4.80873 17.0875C4.53143 17.2657 4.16675 17.0666 4.16675 16.737V5.41683Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BookmarkIcon };

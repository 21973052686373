import { TABLE_TYPE } from 'constants/table';
import { PAGE_BRANDS, PAGE_MAIN, PAGE_PRODUCTS, PAGE_SELLERS } from 'constants/pages';

const WITH_NEW_ADVERTISING = {
  [TABLE_TYPE.BRANDS]: PAGE_BRANDS,
  [TABLE_TYPE.CATEGORIES]: PAGE_MAIN,
  [TABLE_TYPE.SELLERS]: PAGE_SELLERS,
  [TABLE_TYPE.PRODUCTS]: PAGE_PRODUCTS,
};

const TYPES_WITH_NEW_ADVERTISING = Object.keys(WITH_NEW_ADVERTISING);
const PAGES_WITH_NEW_ADVERTISING = Object.values(WITH_NEW_ADVERTISING);

function isTableWithNewAdvertising(type) {
  return TYPES_WITH_NEW_ADVERTISING.includes(type);
}

function isPageWithNewAdvertising(path) {
  return PAGES_WITH_NEW_ADVERTISING.includes(path);
}

export { isTableWithNewAdvertising, isPageWithNewAdvertising };

import PropTypes from 'prop-types';
import { useProduct } from 'context/product.context';
import { useUI } from 'context/ui.context';
import { Fragment, useState } from 'react';
import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';
import { MobileSelectPanel } from 'components/MobileInterface/components/MobileSelectPanel';

const options = [10, 30, 50, 100, 1000];

const TableCellSelect = ({ onClick, onChange, defaultValue }) => {
  const { isMobile } = useUI();

  if (isMobile) {
    return (
      <button type="button" onClick={onClick}>
        <p
          className="
          relative
          ml-2 sm:ml-none
          text-green font-medium text-right w-2 h-2
          "
        >
          {defaultValue}
        </p>
      </button>
    );
  }

  return (
    <select
      defaultValue={defaultValue}
      onChange={onChange}
      className="font-bold bg-transparent text-green"
    >
      {options.map((option) => (
        <option key={`visibility-${option}`} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

TableCellSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const TableCellSelectVisibility = () => {
  const { visibility, setVisibility } = useProduct();
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const onChange = (e) => setVisibility(e.target.value);
  const onClick = () => setIsMobileVisible(true);
  const onPanelClose = () => setIsMobileVisible(false);

  return (
    <Fragment>
      <TableCellSelect onClick={onClick} onChange={onChange} defaultValue={visibility} />
      <MobileInterfaceV2
        onPanelClose={onPanelClose}
        rootClassName="h-3/4 bottom-0 absolute"
        isActive={isMobileVisible}
        headerText="Выберите величину списка"
      >
        <MobileSelectPanel options={options} initialState={visibility} onSubmit={setVisibility} />
      </MobileInterfaceV2>
    </Fragment>
  );
};

export const TableCellSelectAvgPosition = () => {
  const { avgPosition, setAvgPosition } = useProduct();
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  const onChange = (e) => setAvgPosition(e.target.value);
  const onClick = () => setIsMobileVisible(true);
  const onPanelClose = () => setIsMobileVisible(false);

  return (
    <Fragment>
      <TableCellSelect onClick={onClick} onChange={onChange} defaultValue={avgPosition} />
      <MobileInterfaceV2
        onPanelClose={onPanelClose}
        rootClassName="h-3/4 absolute bottom-0"
        isActive={isMobileVisible}
        headerText="Выберите величину списка"
      >
        <MobileSelectPanel options={options} initialState={avgPosition} onSubmit={setAvgPosition} />
      </MobileInterfaceV2>
    </Fragment>
  );
};

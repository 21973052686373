import cn from 'classnames';
import { MODAL_MODES } from '../constants';
import { PlusIcon } from 'assets/icons/PlusIcon';
import React from 'react';
import { useUserList } from 'context/user.list.context';
import PropTypes from 'prop-types';
import DownIcon from 'assets/icons/DownIcon';

const ListsList = ({ setModalMode, setListIndex }) => {
  const { listsByActiveType, selectedList, setSelectedList } = useUserList();
  return (
    <div>
      <button
        className="flex text-green items-center py-4 px-5"
        onClick={() => {
          setModalMode(MODAL_MODES.NEW);
          setSelectedList(null);
        }}
      >
        <PlusIcon width={16} height={16} />
        <span className="ml-2">Добавить список</span>
      </button>
      <div className="overflow-y-auto max-h-[423px] overflow-x-hidden px-5">
        {listsByActiveType.map((list, index) => {
          const { id, name, items } = list;
          const isSelected = selectedList?.id === id;
          return (
            <button
              key={id}
              className={cn('py-4 border-t w-full flex justify-between border-[#E8E8E8]')}
              onClick={() => {
                setSelectedList(list);
                setListIndex(index);
                setModalMode(MODAL_MODES.NO_SELECTED);
              }}
            >
              <span
                className={cn('block text-gray-700 text-left', {
                  '!text-green': isSelected,
                })}
              >
                {name}
              </span>
              <div className="flex space-x-4 text-[#CBCBCB] self-center">
                <span className={cn('block')}>{items.length}</span>
                <div className="self-center">
                  <DownIcon width={20} height={20} className="-rotate-90" />
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

ListsList.propTypes = {
  setModalMode: PropTypes.func,
  setListIndex: PropTypes.func,
};

export default ListsList;

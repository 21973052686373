import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import { useChartDoughnut } from 'context/chart.doughnut.context';
import { COLUMNS } from 'constants/table';

const PieChartWithActiveLabel = lazy(() => import('components/PieChart/PieChartWithActiveLabel'));

const ChartDoughnutWithTable = ({
  tableData,
  pieData,
  tableType,
  postfix,
  isLoading,
  noRowText,
  autoSizeColumns,
  toggler,
}) => {
  const { activeIndex, setActiveIndex, removeActiveIndex, colors } = useChartDoughnut();

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const onLabelLeave = () => removeActiveIndex();

  return (
    <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row lg:border-t border-gray-200 rounded-xl overflow-hidden">
      <div className="hidden lg:block m-5">
        {toggler}
        <PieChartWithActiveLabel
          data={pieData}
          width={200}
          height={200}
          colors={colors}
          activeIndex={activeIndex}
          onPieEnter={onPieEnter}
          onPieLeave={onLabelLeave}
          postfix={postfix}
        />
      </div>
      <div className="flex-1">
        <Table
          data={tableData || []}
          type={tableType}
          noRowsText={noRowText}
          isLoading={isLoading}
          autoSizeColumns={autoSizeColumns}
        />
      </div>
    </div>
  );
};
ChartDoughnutWithTable.propTypes = {
  links: [],
  postfix: '',
};
ChartDoughnutWithTable.propTypes = {
  isLoading: PropTypes.bool,
  noRowText: PropTypes.string.isRequired,
  tableData: PropTypes.object,
  pieData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      link: PropTypes.string,
    }),
  ),
  links: PropTypes.array,
  postfix: PropTypes.string,
  onClick: PropTypes.func,
  tableType: PropTypes.string.isRequired,
  autoSizeColumns: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COLUMNS))),
  toggler: PropTypes.element,
};

export default ChartDoughnutWithTable;

const ChatsOpenerIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.875 5H4.125C3.08947 5 2.25 5.83947 2.25 6.875V12.875C2.25 13.9105 3.08947 14.75 4.125 14.75H5.25V17.3585C5.25 17.3649 5.25759 17.3684 5.26244 17.3641L8.25 14.75H13.875C14.9105 14.75 15.75 13.9105 15.75 12.875V6.875C15.75 5.83947 14.9105 5 13.875 5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="12"
        y="1"
        width="7"
        height="7"
        rx="3.5"
        fill="#F79E1B"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ChatsOpenerIcon;

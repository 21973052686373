import { lazy, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Calendar from 'assets/icons/Calendar';
import { useTable } from 'context/table.context';
import { useUI } from 'context/ui.context';

import ChipList from 'components/ChipList';
import { prefetchTreeCategory } from 'hooks/useSearch';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import WarehouseSelect, { PlatformSelect } from './components';
import ListModeActivateButton from 'components/ListModeActivateButton';
import { FilterModalProvider } from 'context/filter.modal.context';
import ButtonReset from 'components/ButtonReset';
import { useUserList } from '../../context/user.list.context';

const FilterModalOuter = lazy(() => import('components/FilterModal/FilterModalOuter'));

export const CalendarButton = ({ hideBorderRight }) => {
  const { isMobile, setActiveModal } = useUI();

  return (
    <>
      {isMobile && (
        <>
          <button
            onClick={() => setActiveModal('datePicker')}
            className="flex flex-none rounded border border-gray-250 justify-center items-center w-7 h-7 p-1 h-full"
          >
            <Calendar className="w-3 h-3 fill-gray-700" />
          </button>
          {!hideBorderRight && <span className="block w-px h-hull bg-gray-230" />}
        </>
      )}
    </>
  );
};

CalendarButton.propTypes = {
  hideBorderRight: PropTypes.bool,
};

const Filter = ({ setListMode }) => {
  const prefetched = useRef(false);
  const { filter, resetFilter, isLoaded } = useFilterReducer();
  const { isMobile } = useUI();
  const { handleListActive } = useUserList();

  const isFiltered =
    filter?.category?.length > 0 || filter?.brand?.length > 0 || filter?.seller?.length > 0;

  const { dataReady } = useTable();

  useEffect(() => {
    if (!dataReady || !isLoaded || prefetched.current) return;

    prefetchTreeCategory(filter).then(() => (prefetched.current = true));
  }, [dataReady, isLoaded, filter, prefetched]);

  return (
    <div className="flex items-start justify-start lg:justify-between">
      <div className="flex-nowrap touch-auto overflow-x-auto overflow-y-hidden lg:overflow-x-visible lg:overflow-y-visible mr-3 flex lg:items-center">
        <div className="flex-1 lg:flex-auto flex-nowrap lg:items-center flex lg:flex-wrap mr-3 gap-2">
          {isMobile && <ListModeActivateButton onClick={setListMode} />}

          <CalendarButton />

          <PlatformSelect />

          <WarehouseSelect />

          <ChipList />

          <FilterModalProvider>
            <FilterModalOuter />
          </FilterModalProvider>
        </div>
        {isFiltered && (
          <ButtonReset
            onClick={() => {
              resetFilter();
              handleListActive(null);
            }}
          />
        )}
      </div>
    </div>
  );
};
Filter.propTypes = {
  setListMode: PropTypes.func.isRequired,
};
export default Filter;

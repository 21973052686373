import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { TABLE_TYPE } from 'constants/table';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import LoadingSpin from 'components/LoadingSpin';
import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import { useProduct } from 'context/product.context';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';
import HelpBlock from 'components/HelpBlock';
import PropTypes from 'prop-types';

const Warehouses = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { isOzonPlatform } = useProduct();
  const [isOrdersData, setIsOrdersData] = useState(true);
  const { isProductWarehousesAvailable } = useUserTariffs();
  const {
    data: productWarehouses = [],
    isLoading: isProductWarehousesLoading,
    isFetching: isProductWarehousesFetching,
  } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_WAREHOUSES,
    id,
    enabled: fetchEnabled && isProductWarehousesAvailable,
  });

  if (!isProductWarehousesAvailable || isOzonPlatform) {
    return <Redirect to={`/product/${id}`} />;
  }
  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );
  const chartKey = isOrdersData ? 'orders_rub' : 'stock_rub';
  const pieData = productWarehouses.map((warehouse, index) => ({
    index,
    name: warehouse.name,
    value: +warehouse[chartKey],
  }));

  return (
    <>
      <HelpBlock type="product_warehouses" />
      <div className="p-4 bg-gray-100 gap-3">
        {isProductWarehousesLoading || isProductWarehousesFetching ? (
          <LoadingSpin />
        ) : (
          <div className="bg-white rounded-xl">
            <ChartDoughnutWithTable
              tableData={productWarehouses.map((el, index) => ({ ...el, index }))}
              pieData={pieData}
              isLoading={isProductWarehousesLoading || isProductWarehousesFetching}
              tableType={TABLE_TYPE.PRODUCT_WAREHOUSES}
              noRowText="Нет данных"
              postfix="₽"
              key="warehouses-rub"
              toggler={toggler}
            />
          </div>
        )}
      </div>
    </>
  );
};
Warehouses.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Warehouses;

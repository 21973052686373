import baseClient from 'services/baseClient';
import { useQuery } from 'react-query';

export const useFetchCheckCpm = (value = '') => {
  return useQuery(['checkcpm', value], () => baseClient.getCheckCpm(value), {
    refetchOnWindowFocus: false,
    enabled: !!value,
    staleTime: 0,
    cacheTime: 0,
    retry: 3,
  });
};

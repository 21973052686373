import React from 'react';
import PropTypes from 'prop-types';

import { Columns } from './components';

const Tariffs = ({ modalView }) => {
  return <Columns modalView={modalView} />;
};

Tariffs.propTypes = {
  modalView: PropTypes.bool,
};

export default Tariffs;

import { useQuery } from 'react-query';

import apiClient from 'services/baseClient';

import { STALE_TIME } from 'constants/query';
import { PRODUCT_SORT, TABLE_TYPE } from 'constants/table';
import { useProduct } from 'context/product.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import { F } from 'constants/filter';

const action = {
  [TABLE_TYPE.PRODUCT_DATA]: (props) => apiClient.getProduct(props),
  [TABLE_TYPE.PRODUCT_LISTING]: (props) => apiClient.getProductListing(props),
  [TABLE_TYPE.PRODUCT_HOURS]: (props) => apiClient.getProductHours(props),
  [TABLE_TYPE.PRODUCT_SEARCH]: (props) => apiClient.getProductSearchListing(props),
  [TABLE_TYPE.PRODUCT_SALES]: (props) => apiClient.getProductSales(props),
  [TABLE_TYPE.PRODUCT_DAYS]: (props) => apiClient.getProductDays(props),
  [TABLE_TYPE.EXT_PRODUCT_SUM]: (props) => apiClient.getProductDaysSum(props),
  [TABLE_TYPE.PRODUCT_COLORS]: (props) => apiClient.getProductColors(props),
  [TABLE_TYPE.PRODUCT_SIZES]: (props) => apiClient.getProductSizes(props),
  [TABLE_TYPE.PRODUCT_WAREHOUSES]: (props) => apiClient.getProductWarehouses(props),
  [TABLE_TYPE.PRODUCT_SIMILAR]: (props) => apiClient.getProductSimilar(props),
  [TABLE_TYPE.PRODUCT_CHANGES]: (props) => apiClient.getProductChanges(props),
  [TABLE_TYPE.PRODUCT_ADVERTISING]: (props) => apiClient.getProductAdvertising(props),
  [TABLE_TYPE.PRODUCT_PRICE]: (props) => apiClient.getProductPrice(props),
  [TABLE_TYPE.PRODUCT_RATING]: (props) => apiClient.getProductRatings(props),
  [TABLE_TYPE.PRODUCT_STOCK]: (props) => apiClient.getProductStock(props),
};

const TYPE_WITHOUT_PLATFORM_PARAM = [TABLE_TYPE.PRODUCT_DATA, TABLE_TYPE.EXT_PRODUCT_SUM];
const ifHasPlatformParam = (type) => {
  return !TYPE_WITHOUT_PLATFORM_PARAM.includes(type);
};

const TYPE_WITH_PRODUCT_PARAM = [
  TABLE_TYPE.PRODUCT_RATING,
  TABLE_TYPE.PRODUCT_STOCK,
  TABLE_TYPE.PRODUCT_WAREHOUSES,
];
const ifHasProductParam = (type) => {
  return TYPE_WITH_PRODUCT_PARAM.includes(type);
};

const TYPE_WITH_VISIBILITY_PARAM = [TABLE_TYPE.PRODUCT_SEARCH];
const ifHasVisibilityParam = (type) => {
  return TYPE_WITH_VISIBILITY_PARAM.includes(type);
};

const getPayloadParamsForBack = ({
  type,
  filter,
  setDateInitial,
  dateInitial,
  date,
  id,
  platformId,
}) => {
  const filterForBack = {};

  filterForBack.date = setDateInitial && dateInitial ? dateInitial : date || filter.date;
  if (ifHasPlatformParam(type)) {
    filterForBack.platform = platformId ? [platformId] : [filter?.platform];
  }

  if (ifHasProductParam(type)) {
    filterForBack.product = [`${id}`];
  }
  return {
    filterForBack,
    warehouseType: filter[F.WAREHOUSE_TYPE],
  };
};

const useFetchFilteredProductDataOptions = ({ type, enabled, setDateInitial, date, id }) => {
  const { filter, isLoaded, dateInitial } = useFilterReducer();
  const { isTableSortedOnBack } = useTable();
  const { visibility, avgPosition, isLoading, platformId } = useProduct();
  const { sort, order } = filter;

  const { filterForBack, warehouseType } = getPayloadParamsForBack({
    type,
    filter,
    setDateInitial,
    dateInitial,
    date,
    id,
    platformId,
  });
  const options = {
    filter:
      platformId !== '1' ? filterForBack : { ...filterForBack, warehouse: filter[F.WAREHOUSE] },
    id,
  };
  if (warehouseType) options['warehouseType'] = warehouseType;

  if (isTableSortedOnBack(type)) {
    options.sort = sort;
    options.order = order;
  } else {
    options.sortDefault = true;
  }

  if (ifHasVisibilityParam(type)) {
    options.visibility = visibility;
    options.avgPosition = avgPosition;
  }

  const queryKey = [type, id, options];

  const sortAdditional = PRODUCT_SORT[type] ?? null;

  const isEnabled = () => {
    if (ifHasPlatformParam(type)) {
      return enabled && isLoaded && !!filter.platform && !isLoading;
    }

    return enabled && filter.date.length > 0 && isLoaded;
  };

  return {
    actionOption: { ...options, sortAdditional },
    queryOptions: {
      queryKey,
      enabled: isEnabled(),
      refetchOnmount: false,
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME,
      retry: 3,
      keepPreviousData: true,
    },
  };
};

export const useFetchFilteredProductData = ({
  type,
  id,
  date,
  isInner = false,
  enabled = true,
  setDateInitial = false,
}) => {
  const { actionOption, queryOptions } = useFetchFilteredProductDataOptions({
    type,
    enabled,
    setDateInitial,
    date,
    id,
  });

  return useQuery({
    ...queryOptions,
    queryFn: action[type] ? () => action[type]({ ...actionOption, isInner }) : null,
  });
};

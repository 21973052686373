import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart as ReBarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { GRAY_DARK } from 'constants/colors';
import { Skeleton } from 'components/Skeleton';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const barChartsColors = {
  wb: {
    background: '#F3D3ED',
    border: '#E178D0',
  },
  ozon: {
    border: 'rgba(0, 105, 255, 0.5)',
    background: 'rgba(0, 105, 255, 0.2)',
  },
};

const geDomain = (data, key) => {
  const max =
    data && data.length ? data.reduce((prev, current) => Math.max(prev || 0, current[key])) : 0;

  return [0, max];
};
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return <div className="text-xs">{payload[0].value.toLocaleString('ru')}</div>;
  }

  return null;
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

const BarChart = ({ data, valueKey, nameKey, isLoading, useWbColor }) => {
  const { isWbPlatform } = useFilterReducer();

  const LocalSkeleton = () => (
    <div className="flex items-end gap-2 h-full py-2">
      <Skeleton classNames="w-1/6 !h-full" />
      <Skeleton classNames="w-1/6 !h-1/2" />
      <Skeleton classNames="w-1/6 !h-1/5" />
      <Skeleton classNames="w-1/6 !h-1/2" />
      <Skeleton classNames="w-1/6 !h-full" />
      <Skeleton classNames="w-1/6 !h-1/6" />
    </div>
  );

  return (
    <Suspense fallback={<LocalSkeleton />}>
      <div style={{ width: '100%', height: 100 }}>
        {isLoading ? (
          <LocalSkeleton />
        ) : (
          <ResponsiveContainer width="100%" height={100} minWidth={'100%'}>
            <ReBarChart
              data={data}
              margin={{
                top: 6,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Tooltip
                offset={10}
                cursor={{ fill: 'rgba(243, 211, 237, 0.5)' }}
                content={<CustomTooltip />}
              />

              <YAxis domain={geDomain(data, valueKey)} hide={true} />

              <XAxis
                dataKey={nameKey}
                angle={0}
                tickLine={false}
                stroke="#F0F0F0"
                interval={0}
                tick={{ fontSize: '10px', fill: GRAY_DARK }}
                padding={{ left: 0, right: 0 }}
              />

              <Bar
                dataKey={valueKey}
                stroke={
                  isWbPlatform || useWbColor
                    ? barChartsColors.wb.border
                    : barChartsColors.ozon.border
                }
                fill={
                  isWbPlatform || useWbColor
                    ? barChartsColors.wb.background
                    : barChartsColors.ozon.background
                }
                height={100}
              />
            </ReBarChart>
          </ResponsiveContainer>
        )}
      </div>
    </Suspense>
  );
};
export const barChartPropTypes = {
  data: PropTypes.array.isRequired,
  valueKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  useWbColor: PropTypes.bool,
};
BarChart.propTypes = barChartPropTypes;

export default BarChart;

import { ApiKeysHeader, ApiKeysList } from 'components/ApiKeys';

const ApiKeys = () => {
  return (
    <div className="flex grow bg-gray-50">
      <div className="mt-4 px-3 lg:mt-10 lg:px-56 w-full">
        <ApiKeysHeader />
        <ApiKeysList />
      </div>
    </div>
  );
};

export default ApiKeys;

export const QUANTITY_OPTIONS = [
  { value: 0, label: 'Все товары' },
  { value: 2, label: 'Товары без наличия' },
  { value: 1, label: 'Товары с наличием' },
];

export const WAREHOUSE_OPTIONS = [
  { value: false, label: 'Расчет без учета складов' },
  { value: true, label: 'Расчет с учетом складов' },
];

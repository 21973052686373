import { useQuery } from 'react-query';

import baseClient from 'services/baseClient';
import baseInnerClient from 'services/baseInnerClient';
import { STALE_TIME } from 'constants';

import { FETCH_INNER } from 'constants/inner';

const actions = {
  [FETCH_INNER.API_KEYS]: baseClient.getApiKeys,
  [FETCH_INNER.API_KEYS_DETAILS]: baseClient.getApiKeysDetails,
  [FETCH_INNER.API_KEYS_WITH_TYPE]: baseClient.getApiKeys,
  [FETCH_INNER.BRANDS]: baseInnerClient.getInnerBrands,
  [FETCH_INNER.CATEGORIES]: baseInnerClient.getInnerCategories,
  [FETCH_INNER.SUBJECTS]: baseInnerClient.getInnerSubjects,
  [FETCH_INNER.WAREHOUSE]: baseInnerClient.getInnerFilterWarehouses,
};

export const useFetchInnerData = ({ type, enabled = true, filter }) => {
  const getActionOptions = (props) => {
    const actionOptions = { ...props };
    if (filter) {
      actionOptions['filter'] = filter;
    }
    return actionOptions;
  };
  return useQuery({
    queryKey: [type, filter],
    queryFn: (props) => (actions[type] ? actions[type](getActionOptions(props)) : null),
    refetchOnmount: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME,
    cacheTime: STALE_TIME,
    retry: 3,
    keepPreviousData: true,
    enabled,
  });
};

import { Plot, Venn, Tooltip, colors } from '@semcore/d3-chart';
import PropTypes from 'prop-types';

const CompareDiagram = ({ groupA, groupB, cross }) => {
  return (
    <div className="w-fit mx-auto cursor-pointer pt-[52px]">
      <Plot height={300} width={400} data={{ A: groupA, B: groupB, 'A/B': cross }}>
        <Venn orientationOrder={() => 1}>
          <Venn.Circle dataKey="A" name="A" color={colors['green-100']} />
          <Venn.Circle dataKey="B" name="B" color={colors['orange-01']} />
          <Venn.Intersection dataKey="A/B" name="A/B" />
        </Venn>
        <Tooltip className="!bg-black !py-1 !px-3">
          {({ dataKey, data }) => {
            return {
              children: (
                <div className="flex">
                  <span className="!text-white">{`${dataKey} : ${data[dataKey]}`}</span>
                  <span className="block ml-1 text-gray-400">поисковых фраз</span>
                </div>
              ),
            };
          }}
        </Tooltip>
      </Plot>
    </div>
  );
};

CompareDiagram.propTypes = {
  groupA: PropTypes.number,
  groupB: PropTypes.number,
  cross: PropTypes.number,
};

export default CompareDiagram;

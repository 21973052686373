import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import ListAlerts from 'components/ListAlerts';
import HelpBlock from 'components/HelpBlock';

const Brands = () => {
  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.BRANDS,
  });
  const { isOzonPlatform } = useFilterReducer();

  return (
    <>
      <HelpBlock type="brands" />
      <Table
        data={data}
        type={TABLE_TYPE.BRANDS}
        hideSoldColumn={isOzonPlatform}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Brands;

import React from 'react';
import PropTypes from 'prop-types';

import { DynamicProvider } from 'context/dynamic.context';

const DynamicLayout = ({ children }) => <DynamicProvider>{children}</DynamicProvider>;

DynamicLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default DynamicLayout;

import {
  PAGE_BRANDS,
  PAGE_MAIN,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_SELLERS,
  PAGE_PRODUCT,
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_ADV,
  PAGE_PRODUCT_END_CHANGES,
  PAGE_PRODUCT_END_SALES,
  PAGE_TRENDS,
  PAGE_PRICE_SEGMENTS,
  PAGE_PROFILE,
  PAGE_SEO,
  PAGE_WAREHOUSES,
  PAGE_GROWTH,
  PAGE_GROWTH_BRANDS,
  PAGE_GROWTH_SELLERS,
  PAGE_GROWTH_PRODUCTS,
  PAGE_POSITIONS,
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_IN_DAYS,
  PAGE_INNER_SALES,
  PAGE_INNER_PRODUCT,
  PAGE_INNER_WEEKS,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRICES_TAB,
  PAGE_INNER_WAREHOUSES,
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_PRODUCTS_SETTINGS,
} from 'constants/pages';
import {
  PAGE_ADVERTISING_RATES,
  PAGE_API_KEYS,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_COMPARE,
  PAGE_SEO_MONITORING,
} from 'constants';
import { PAGE_SEO_HINTS, PAGE_SEO_SMART, PAGE_SEO_SMART_DESCRIPTION } from '../constants';

const MAIN_TABLE_PAGES = [
  PAGE_MAIN,
  PAGE_BRANDS,
  PAGE_SELLERS,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_PRICE_SEGMENTS,
  PAGE_TRENDS,
  PAGE_WAREHOUSES,
];
const PRODUCT_TABLE_PAGES = [
  PAGE_PRODUCT_END_LISTING,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_ADV,
  PAGE_PRODUCT_END_CHANGES,
  PAGE_PRODUCT_END_SALES,
];
const GROWTH_TABLE_PAGES = [
  PAGE_GROWTH,
  PAGE_GROWTH_BRANDS,
  PAGE_GROWTH_SELLERS,
  PAGE_GROWTH_PRODUCTS,
];
export const INNER_PAGES = [
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_IN_DAYS,
  PAGE_INNER_SALES,
  PAGE_INNER_WEEKS,
  PAGE_INNER_PRODUCT,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRICES_TAB,
  PAGE_INNER_WAREHOUSES,
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_PRODUCTS_SETTINGS,
];

export const isProductPage = () => window.location.pathname.startsWith(PAGE_PRODUCT);
export const isProductsPage = () => window.location.pathname.startsWith(PAGE_PRODUCTS);

export const isProductTablePage = () => {
  const pathname = window.location.pathname;

  if (!isProductPage()) {
    return false;
  }

  for (let i = 0; i < PRODUCT_TABLE_PAGES.length; i++) {
    if (pathname.endsWith(PRODUCT_TABLE_PAGES[i])) {
      return true;
    }
  }

  return false;
};

export const isMainPage = () => {
  const pathname = window.location.pathname;

  return MAIN_TABLE_PAGES.includes(pathname);
};

export const isGrowthPage = () => {
  const pathname = window.location.pathname;

  return GROWTH_TABLE_PAGES.includes(pathname);
};

export const isSeoResultPage = () => {
  const search = window.location.search;

  return (
    isSeoPage() &&
    !!search &&
    window.location.pathname !== PAGE_SEO_COMPARE &&
    window.location.pathname !== PAGE_SEO_MONITORING &&
    window.location.pathname !== PAGE_SEO_BY_PHRASE &&
    window.location.pathname !== PAGE_SEO_HINTS &&
    window.location.pathname !== PAGE_SEO_SMART &&
    window.location.pathname !== PAGE_SEO_SMART_DESCRIPTION
  );
};

export const isTablePage = () => {
  return isMainPage() || isProductTablePage() || isSeoResultPage();
};

export const isTrendsPage = () => window.location.pathname === PAGE_TRENDS;

export const isSeoPage = () => {
  const pathname = window.location.pathname;

  return pathname.indexOf(PAGE_SEO) >= 0;
};

export const isFaqPage = () => {
  const pathname = window.location.pathname;

  return pathname.indexOf('/faq') >= 0;
};

export const isAdvertisingRatesPage = () => {
  const pathname = window.location.pathname;

  return pathname.indexOf(PAGE_ADVERTISING_RATES) >= 0;
};

export const isApiKeysPage = () => window.location.pathname.startsWith(PAGE_API_KEYS);

export const isPositionsPage = () => window.location.pathname.startsWith(PAGE_POSITIONS);

export const isProfilePage = () => window.location.pathname.startsWith(PAGE_PROFILE);

export const isInnerProductsPage = () => window.location.pathname.startsWith(PAGE_INNER_PRODUCTS);

export const isInnerInDaysPage = () => window.location.pathname.startsWith(PAGE_INNER_IN_DAYS);

export const isInnerSalesPage = () => window.location.pathname.startsWith(PAGE_INNER_SALES);

export const isSeoByPhrasePage = () => window.location.pathname.startsWith(PAGE_SEO_BY_PHRASE);

export const isInnerProductPage = () => window.location.pathname.startsWith(PAGE_INNER_PRODUCT);

export const isInnerPage = () => {
  const pathname = window.location.pathname;
  return [...INNER_PAGES, PAGE_API_KEYS].includes(pathname) || isInnerProductPage();
};

export const isInnerPricesTabPage = () => {
  return window.location.pathname.startsWith(PAGE_INNER_PRICES_TAB);
};

export const isInnerProductSettings = () => {
  return window.location.pathname.startsWith(PAGE_INNER_PRODUCTS_SETTINGS);
};

export const isInnerDeliveryTabPage = () => {
  return window.location.pathname.startsWith(PAGE_INNER_DELIVERY_TAB);
};

export const isInnerTablePage = () => {
  const pathname = window.location.pathname;

  return INNER_PAGES.includes(pathname) || isInnerProductPage();
};

export const generateTemplateName = (prefix) => {
  const date = new Date();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let hours = date.getHours();
  let mins = date.getMinutes();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  if (hours < 10) hours = '0' + hours;
  if (mins < 10) mins = '0' + mins;
  return `${prefix} ${dd}.${mm} ${hours}:${mins}`;
};

export const TEMPLATE_TYPES = {
  MONITORING: 1,
};

import { ArrowLeft } from '../../assets/icons/ArrowLeft';
import TrashIcon from '../../assets/icons/TrashIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import cn from 'classnames';
import CheckIcon from '../../assets/icons/CheckIcon';
import EditIcon from '../../assets/icons/EditIcon';
import { useTableTemplates } from '../../context/table.templates.context';
import { useTable } from '../../context/table.context';
import baseClient from '../../services/baseClient';
import { useFilterReducer } from '../../context/filter/filter.context.reducer';
import LoadingIcon from '../../assets/icons/LoadingIcon';

const TableTemplate = ({ templ, templates, setTemplates, index }) => {
  const { isEdit, name, isNew } = templ;
  const [hover, setIsHover] = useState(false);
  const [templName, setTemplName] = useState(name);
  const { isActiveTemplate, setActiveTemplate, deactivateTemplate } = useTableTemplates();
  const { setFilterState } = useFilterReducer();
  const { gridOptions } = useTable();
  const [isActivating, setIsActivating] = useState(false);

  const isActive = isActiveTemplate(templ.url, templ.id);
  const handleSaveEdit = (e) => {
    e.stopPropagation();
    const beforeChanging = JSON.parse(JSON.stringify(templates));
    const result = JSON.parse(JSON.stringify(templates));
    templ.name = templName;
    templ.isEdit = false;
    if (isNew) {
      templ.isNew = false;
      baseClient
        .createTemplate({
          data: {
            name: templ.name,
            colDefs: templ.colDefs,
            filter: templ.filter,
            url: templ.url,
          },
        })
        .then((data) => {
          if (data.status !== 201) {
            setTemplates(beforeChanging);
          } else {
            templ.id = data.data.data.id;
          }
        });
    } else {
      baseClient
        .updateTemplate({
          id: templ.id,
          data: {
            name: templ.name,
            colDefs: templ.colDefs,
            filter: templ.filter,
            url: templ.url,
          },
        })
        .then((data) => {
          if (data.status !== 200) {
            setTemplates(beforeChanging);
          }
        });
    }
    result[index] = templ;
    setTemplates(result);
  };

  const handleMakeEdit = (e) => {
    e.stopPropagation();
    const result = JSON.parse(JSON.stringify(templates));
    templ.isEdit = true;
    result[index] = templ;
    setTemplates(result);
  };

  const handleDeleteTemplate = () => {
    const beforeChanging = JSON.parse(JSON.stringify(templates));
    const result = JSON.parse(JSON.stringify(templates));
    if (!templ.isNew) {
      baseClient
        .deleteTemplate({
          id: templ.id,
        })
        .then((data) => {
          if (data.status !== 200) {
            setTemplates(beforeChanging);
          }
        });
    }
    result.splice(index, 1);
    setTemplates(result);
    if (isActive) {
      deactivateTemplate(templ.url);
    }
  };

  const handleActivate = () => {
    //changing colDefs
    if (!isActive) {
      setIsActivating(() => true);
      const fullDefsInfo = gridOptions.api.getColumnDefs();
      const templDefs = JSON.parse(templ.colDefs);
      const resultDefs = templDefs.map((el) => {
        const searched = fullDefsInfo.find(({ field }) => field === el.field);
        if (searched) {
          return { ...searched, ...el };
        }
        return el;
      });
      gridOptions.columnApi.columnModel.setColumnDefs(resultDefs);
      setTimeout(() => {
        setActiveTemplate(templ.url, templ.id);
        const templFilter = JSON.parse(templ.filter);
        setFilterState(templFilter);
        setIsActivating(() => false);
        window.location.reload();
      }, 0);
    }
  };

  return (
    <div className="flex space-x-2">
      <div
        className={cn(
          'p-2 border border-[#D9D9D9] rounded grow flex hover:border-green !hover:text-green items-center cursor-pointer',
          {
            '!border-green !text-green': isActive || isActivating,
          },
        )}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handleActivate}
      >
        <div className={cn('text-[#C4C4C4] pr-2', { '!text-green': hover || isActive })}>
          {isActivating ? (
            <LoadingIcon className="h-4 w-4 flex-none animate-spin text-green" />
          ) : (
            <ArrowLeft />
          )}
        </div>
        {isEdit ? (
          <div className="flex grow items-center">
            <input
              value={templName}
              onChange={(e) => setTemplName(e.target.value)}
              onFocus={(e) => {
                if (isNew) {
                  e.target.select();
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSaveEdit(e);
                }
              }}
              className={cn(
                'border-none focus:outline-none bg-transparent text-sm leading-[16px] flex grow',
              )}
              autoFocus={isEdit}
            />
            <div className="ml-2 pl-2 border-l border-[#E7EDF0] flex items-center">
              <button
                className="flex items-center text-[#C4C4C4] hover:text-[#219653]"
                onClick={handleSaveEdit}
              >
                <CheckIcon width={'16'} height={'16'} />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex grow">
            <span className="flex text-sm leading-[16px] grow cursor-pointer">{templName}</span>
            <div className="ml-2 pl-2 border-l border-[#E7EDF0] flex items-center">
              <button
                className="flex items-center text-[#C4C4C4] hover:text-[#219653]"
                onClick={handleMakeEdit}
              >
                <EditIcon width={'16'} height={'16'} />
              </button>
            </div>
          </div>
        )}
      </div>
      <button
        className="p-2 text-[#C4C4C4] border border-[#D9D9D9] rounded hover:text-green hover:border-green"
        onClick={handleDeleteTemplate}
      >
        <TrashIcon width={16} height={16} />
      </button>
    </div>
  );
};

TableTemplate.propTypes = {
  templ: PropTypes.shape({
    name: PropTypes.string,
    isEdit: PropTypes.bool,
    isNew: PropTypes.bool,
    url: PropTypes.string,
    id: PropTypes.any,
    colDefs: PropTypes.any,
    filter: PropTypes.string,
  }),
  templates: PropTypes.array,
  setTemplates: PropTypes.func,
  index: PropTypes.number,
};

export { TableTemplate };

import PropTypes from 'prop-types';

const TableCellInfoTooltip = ({ data, location, colDef, valueFormatted }) => {
  const value = valueFormatted || data?.[colDef?.field];
  if (location === 'header' || !value) {
    return <div />;
  }

  return (
    <div className={`opacity-100 -mt-[65px]`}>
      <div
        className={`
        max-h-full max-w-full
        bg-white p-2
        shadow-black-bright shadow rounded-md
        flex items-center justify-center
        `}
      >
        {value}
      </div>
    </div>
  );
};

TableCellInfoTooltip.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  location: PropTypes.string.isRequired,
  reactContainer: PropTypes.object,
  colDef: PropTypes.object,
  valueFormatted: PropTypes.any,
};

export default TableCellInfoTooltip;

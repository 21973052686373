import { WAREHOUSE_OPTIONS } from '../constants';
import Dropdown from 'components/Dropdown';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { F_INNER } from 'constants';
import baseInnerClient from 'services/baseInnerClient';

const WarehouseSelector = () => {
  const { filter, setUseWarehouse, updateFromModalFilter } = useFilterReducer();
  const selected = filter[F_INNER.USE_WAREHOUSE];
  const label = WAREHOUSE_OPTIONS.find((el) => el.value === selected)?.label;

  const onChange = async (value) => {
    setUseWarehouse(value);
    if (value) {
      const result = await baseInnerClient.getInnerFilterWarehouses();
      updateFromModalFilter({
        filter: {
          inner_warehouse: result.map(({ id }) => id),
        },
        chips: {
          inner_warehouse: result.map(({ id, name }) => ({ key: id, title: name })),
        },
      });
    } else {
      updateFromModalFilter({
        filter: {
          inner_warehouse: [],
        },
        chips: {
          inner_warehouse: [],
        },
      });
    }
  };
  return (
    <Dropdown
      label={label}
      bgColor="green"
      color="green"
      options={WAREHOUSE_OPTIONS}
      onChange={onChange}
      selected={selected}
      buttonClassnames="border-solid border px-[11px] py-[5px] z-[100]"
      optionClassnames="whitespace-nowrap z-[100]"
      optionsWrapperClassnames="w-fit z-[100]"
      isOutlined
    />
  );
};

export default WarehouseSelector;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTable } from 'context/table.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import { NOT_AVAILABLE_BECAUSE_TARIFF } from 'constants/tooltips';
import ExcelIcon from 'assets/icons/ExcelIcon';
import Popover from 'components/Popover';

const ButtonExportExcel = () => {
  const tooltipRef = useRef();
  const { exportExcel } = useTable();
  const { isTableExportAvailable } = useUserTariffs();

  return (
    <div className="flex items-center">
      <button
        ref={tooltipRef}
        onClick={exportExcel}
        disabled={!isTableExportAvailable}
        className={!isTableExportAvailable ? 'cursor-not-allowed' : ''}
      >
        <ExcelIcon className="h-6 w-4 transition-all text-gray-300 hover:text-green" />
      </button>
      {!isTableExportAvailable && (
        <Popover tooltipRef={tooltipRef} tooltip={NOT_AVAILABLE_BECAUSE_TARIFF} />
      )}
    </div>
  );
};
ButtonExportExcel.propTypes = {
  rowData: PropTypes.array,
  nestedParentColumn: PropTypes.string,
};
export default ButtonExportExcel;

import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Collapse from 'components/Collapse';

import Arrow from 'assets/icons/Arrow';
import CrossIcon from 'assets/icons/CrossIcon';
import {
  Banks,
  BusinessFields,
  DiscountRows,
  DropDiscount,
  Input,
  PriceWithDiscount,
} from 'components/PaymentModal/components';
import AngleIcon from 'assets/icons/AngleIcon';
import Checkbox from 'components/Checkbox';
import { usePayment } from 'context/payment.context';
import LoadingIcon from 'assets/icons/LoadingIcon';

const Divider = () => <div className="border-t border-gray-200 my-5" />;

const Select = ({ value, onChange, options, label }) => (
  <div className="mb-6 relative">
    <div className="font-bold mb-2 flex">{label}</div>
    <Arrow className="absolute right-4 top-2/3 rotate-90 text-gray-250" />
    <select
      value={value}
      className="appearance-none accent-green block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-250 rounded transition ease-in-out m-0 ring-green focus:text-gray-700 focus:bg-white focus:border-green focus:outline-none focus:ring-green"
      onChange={onChange}
    >
      {options?.map(({ value, label }) => (
        <option className="ring-green hover:bg-green" key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const InputRadio = ({ checked, onChange, id, label }) => {
  return (
    <div className="cursor-pointer flex items-center mt-3">
      <input
        type="radio"
        className={`appearance-none border-white border-[0.2rem] w-4 h-4 rounded-[50%] 
        shadow-lg shadow-[0_0_0_1px_var(--color-green)]
        checked:bg-green
        focus:outline-4 focus:outline-offset-[5px] focus:outline-green`}
        checked={checked}
        id={id}
        onChange={onChange}
      />
      <label htmlFor={id} className="flex flex-wrap ml-3" style={{ width: 230 }}>
        {label}
      </label>
    </div>
  );
};

InputRadio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

const DesktopPayment = () => {
  const {
    paymentOptions,
    businessInfoFields,
    businessTypeOptions,
    isBusinessInfoShown,
    hideBusinessInfo,
    paymentRequestFields,
    // arbitraryAmount,
    // isArbitraryAmountShown,
    // toggleArbitraryAmount,
    // changeArbitraryAmount,
    promoCodePreview,
    changePromoCode,
    updatePromoCode,
    showPromoField,
    hidePromoField,
    isPromoFieldShown,
    agreeWithContract,
    toggleAgreeWithContract,
    handlePayment,
    isUserAgree,
    isOpenPayment,
    closePayment,
    isLoading,
    promoCodeMessage,
    promoCode,
    validationErrors,
  } = usePayment();

  const handlePromoCodeSubmit = () => {
    updatePromoCode();
  };

  const handleClosePayment = () => {
    closePayment();
  };

  return (
    <Modal
      isOpen={isOpenPayment}
      closeModal={handleClosePayment}
      disableCloseOnClickOutside={true}
      footer={false}
      title={
        isBusinessInfoShown ? (
          <div className="flex items-center">
            <button
              onClick={hideBusinessInfo}
              className="flex text-xl font-bold cursor-pointer items-center"
            >
              <AngleIcon className="w-3.5 h-2.5 mr-2 -rotate-90" />
            </button>
            Оплата с помощью юр лица
          </div>
        ) : (
          <>Оплата</>
        )
      }
    >
      <div className="flex">
        {isBusinessInfoShown ? (
          <div className="w-1/2">
            <div className="text-gray">
              Пожалуйста, заполните ваши реквизиты для выставления счета
            </div>

            {businessTypeOptions && <BusinessFields businessTypeOptions={businessTypeOptions} />}

            {businessInfoFields.map((field) => (
              <Input
                key={field.id}
                label={
                  field.isRequired ? (
                    <span>
                      {field.label}
                      <span className="text-red">*</span>
                    </span>
                  ) : (
                    field.label
                  )
                }
                onChange={field.onChange}
                id={field.id}
                value={field.value}
                isInvalid={validationErrors.includes(field.id)}
              />
            ))}
          </div>
        ) : (
          <div className="w-1/2">
            <Collapse
              collapsedClassName="text-green"
              wrapperClassName="mb-6"
              title={<h2 className="text-lg">Тарифы</h2>}
              defaultCollapsed={false}
            >
              {paymentRequestFields.map((field) => (
                <Select
                  key={field.id}
                  label={field.label}
                  value={field.value}
                  className="appearance-none accent-green block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-250 rounded transition ease-in-out m-0 ring-green focus:text-gray-700 focus:bg-white focus:border-green focus:outline-none focus:ring-green"
                  onChange={field.onChange}
                  options={field.options || []}
                />
              ))}
            </Collapse>
          </div>
        )}
        <div className="ml-auto w-2/5 relative">
          <div className="flex justify-between text-lg font-bold mb-1.5 mt-2.5">
            <span>Сумма</span>
            <PriceWithDiscount />
          </div>

          <DiscountRows />
          {promoCode ? (
            <DropDiscount />
          ) : (
            <button
              className="mt-4 text-green font-light focus:outline-none focus:underline"
              onClick={showPromoField}
            >
              Есть промокод?
            </button>
          )}
          {isPromoFieldShown && (
            <div className="flex w-[290px] flex-col absolute p-3 left-0 top-10 bg-gray-50 rounded shadow-md">
              <div className="w-full flex justify-between mb-3">
                <span>Есть промокод?</span>
                <button
                  onClick={hidePromoField}
                  className="w-3 h-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green"
                >
                  <CrossIcon className="w-3 h-3" />
                </button>
              </div>
              <div className="flex h-6 items-center justify-center">
                <Input
                  onChange={changePromoCode}
                  value={promoCodePreview}
                  id="promo-code"
                  placeholder="Промокод"
                  className=""
                  classNameInput="py-1 px-2"
                />
                <button
                  onClick={handlePromoCodeSubmit}
                  className="button-primary ml-3 flex-1 text-xs p-1 flex justify-center"
                >
                  {isLoading ? (
                    <LoadingIcon className="z-100 w-4 h-4 animate-spin text-white" />
                  ) : (
                    'Применить'
                  )}
                </button>
              </div>
              {promoCodeMessage && (
                <div className="mt-2 text-orange text-xs break-words">{promoCodeMessage}</div>
              )}
            </div>
          )}
          <Divider />

          <h3 className="font-bold mt-6 mb-7">Выберите способ оплаты</h3>

          {paymentOptions.map((option) => (
            <InputRadio
              key={option.value}
              checked={option.checked}
              onChange={option.action}
              id={option.value}
              label={option.title}
            />
          ))}

          <Divider />

          <div>
            <Checkbox
              label={`<span>
                  Согласен с 
                  <a
                    class="text-green hover:underline focus:underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green"
                    href="https://www.wecheck.ru/agreement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    договором-офертой
                  </a>
                </span>`}
              onChange={toggleAgreeWithContract}
              checked={agreeWithContract}
              className="items-center"
              name="agreeWithContract"
            />
            {!isUserAgree && (
              <div className="text-orange text-xs mt-2">Пожалуйста, подтвердите согласие</div>
            )}
          </div>

          <button
            className="button-primary w-full my-6 border-green text-white bg-green"
            onClick={handlePayment}
          >
            {isBusinessInfoShown ? 'Сформировать счет' : 'Оплатить'}
          </button>

          <Banks />

          <div className="text-gray-400 font-light text-xs mt-5">
            При оплате все данные передаются в зашифрованном протоколе SSl, что гарантирует
            максимально возможную безопасность
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DesktopPayment;

import { Link } from 'react-router-dom';
import { PAGE_API_KEYS } from 'constants';
import { PlusIcon } from 'assets/icons/PlusIcon';

const EmptyKeys = () => {
  return (
    <div className="grow bg-gray-50 flex justify-center">
      <div className="flex flex-col self-center text-center">
        <span className="text-gray-400 leading-[22px]">
          Для того, чтобы анализировать
          <br /> собственные товары, добавьте API-ключ
        </span>
        <Link to={PAGE_API_KEYS} className="mt-[22px] mx-auto">
          <div className="flex text-green">
            <PlusIcon width={14} height={14} className="self-center relative top-[1px]" />
            <span className="block ml-2">Добавить ключ</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EmptyKeys;

import PropTypes from 'prop-types';

const TableToggleIcon = (props) => {
  const { isActive, ...other } = props;
  return (
    <svg {...other} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="5.25"
        y="6.25006"
        width="13.5"
        height="0.5"
        strokeWidth="0.5"
        stroke={!isActive ? 'black' : '#219653'}
      />
      <rect
        x="5.25"
        y="17.2501"
        width="13.5"
        height="0.5"
        strokeWidth="0.5"
        stroke={!isActive ? 'black' : '#219653'}
      />
      <path d="M5 12.0001H19" stroke={!isActive ? 'black' : '#219653'} />
      <rect
        x="0.5"
        y="0.500061"
        width="23"
        height="23"
        rx="3.5"
        stroke={!isActive ? '#D9D9D9' : '#219653'}
      />
    </svg>
  );
};

TableToggleIcon.propTypes = {
  isActive: PropTypes.bool,
};

export default TableToggleIcon;

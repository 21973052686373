import propTypes from 'prop-types';

/**
 * @typedef IProps
 *
 * @property {string} [upperLeftCircleColor] Необязательный цвет верхнего левого кружка
 * @property {string} [upperRightCircleColor] Необязательный цвет верхнего правого кружка
 * @property {string} [bottomCenterCircleColor] Необязательный цвет центрального нижнего кружка
 * @property {string} [borderColor] Необязательный цвет ободка, обрамляющего 3 цветных круга
 * */

/**
 * @return {JSX.Element} Иконка 3 разных цветов внутри полого круга
 *
 * @param {IProps & import('react').HTMLAttributes<SVGElement>} props
 * */
export function DropdownColorsIcon({
  upperLeftCircleColor = '#CC4342',
  upperRightCircleColor = '#6AA567',
  bottomCenterCircleColor = '#BE20A5',
  borderColor = '#219653',
  ...svgProps
}) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="5" cy="10.619" r="2" fill={upperLeftCircleColor} />
      <circle cx="8" cy="5.61902" r="2" fill={upperRightCircleColor} />
      <circle cx="11" cy="10.619" r="2" fill={bottomCenterCircleColor} />
      <rect x="0.5" y="1.11902" width="15" height="15" rx="7.5" stroke={borderColor} />
    </svg>
  );
}

DropdownColorsIcon.propTypes = {
  upperLeftCircleColor: propTypes.string,
  upperRightCircleColor: propTypes.string,
  bottomCenterCircleColor: propTypes.string,
  borderColor: propTypes.string,
};

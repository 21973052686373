import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import Checkbox from '../Checkbox';
import { F_INNER } from '../../constants';

const options = [
  {
    value: 'Оформлен',
    label: 'Оформлен',
  },
  {
    value: 'Оплачен',
    label: 'Оплачен',
  },
  {
    value: 'Возврат',
    label: 'Возврат',
  },
];

const SetStatusFilter = forwardRef(({ filterChangedCallback }, ref) => {
  const { filter, toggleStatus } = useFilterReducer();
  const statusesChecked = filter?.[F_INNER.STATUS];
  // It's simple solution for hidding filter after cancel and submit
  // It's made because ag-grid sometimes doesn't hide filter by itself
  // const hideFilter = () => {
  //     const filterPopup = document.getElementsByClassName('ag-tabs')[0];
  //     if (filterPopup) {
  //         filterPopup.style.display = 'none';
  //     }
  // };
  // const [selected, setSelected] = useState(options.map(el => el.value))
  const [instanceParams, setInstanceParams] = useState(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass() {
        return true;
      },

      isFilterActive() {
        return true;
      },

      getModel() {
        return null;
      },

      setModel() {},

      hidePopup() {
        if (!instanceParams || !instanceParams?.hidePopup) return;

        instanceParams.hidePopup();
      },

      afterGuiAttached(params) {
        setInstanceParams(params);
      },
    };
  });

  // const handleFilter = () => {
  //     hideFilter();
  // };

  const handleChange = (e) => {
    const id = e.target.name;
    if (!(statusesChecked.length === 1 && statusesChecked.includes(id))) {
      toggleStatus(id);
    }
  };

  useEffect(() => {
    filterChangedCallback();
    return () => {
      filterChangedCallback();
    };
  }, [filterChangedCallback]);

  return (
    <div className={`p-4 px-0 w-full`}>
      <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper mb-4">
        {options.map(({ label, value }) => {
          const isChecked =
            statusesChecked.includes(`${value}`) || statusesChecked.includes(+value);

          return (
            <Checkbox
              key={`checkbox-${value}`}
              className="mx-4 pl-4 lg:mx-0 items-center transition-all lg:hover:bg-gray-100 lg:focus:bg-gray-100"
              label={label}
              onChange={handleChange}
              name={value}
              checked={isChecked}
              labelClass="text-gray-450 text-sm flex-1 py-4 pr-4 lg:py-2"
              color={isChecked ? 'green' : 'gray'}
            />
          );
        })}
      </div>
      {/*<div className="flex justify-between space-x-3">*/}
      {/*    <button*/}
      {/*        type="button"*/}
      {/*        onClick={handleFilter}*/}
      {/*        className="w-full inline-flex justify-center py-2.5 border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:bg-transparent focus:text-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"*/}
      {/*    >*/}
      {/*        Применить*/}
      {/*    </button>*/}
      {/*</div>*/}
    </div>
  );
});

SetStatusFilter.displayName = 'SetStatusFilter';
SetStatusFilter.propTypes = {
  hidePopup: PropTypes.func,
  colDef: PropTypes.shape({
    field: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  filterChangedCallback: PropTypes.func.isRequired,
};
export default SetStatusFilter;

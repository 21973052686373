import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { NavListWrapper, tableNavLiPropTypes } from 'components/NavList/components';

const NavList = ({ items, classList }) => {
  const history = useHistory();

  const goToPage = ({ link, search }) => {
    history.push({
      pathname: link,
      search: search,
    });
  };

  return <NavListWrapper callback={goToPage} items={items} classList={classList} />;
};

NavList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(tableNavLiPropTypes)),
  classList: PropTypes.string,
};
export default NavList;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useAuth } from 'context/auth.context';

import 'react-datepicker/dist/react-datepicker.css';
import 'assets/styles/react-datepicker.css';
import 'assets/styles/calendar-input.css';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { checkDate, getRangePickerLimits } from 'context/filter/filter.context.reducer.methods';

import { isProductPage, isTrendsPage } from 'utils/pages';
import { RadioButton, RangePicker } from './components';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useAnalyticType } from 'context/analytic.type.context';

const DATE_FORMAT = 'YYYY-MM-DD';

const getDifference = ({ startDate, endDate }) => {
  const lastDate = moment().subtract(1, 'days').endOf('day').toDate();

  const correctStartDate = moment(startDate).endOf('day');
  const correctEndDate = moment(endDate).endOf('day');
  const isEndDateIsLastDate = moment(lastDate).diff(correctEndDate, 'days') === 0;

  if (!isEndDateIsLastDate) {
    return null;
  }

  const duration = moment.duration(correctEndDate.diff(correctStartDate));

  return Math.ceil(duration.asDays()) + 1;
};

const getRangeInitial = ({ startDate, endDate }) => {
  const difference = getDifference({ startDate, endDate });

  if (difference === 1 || difference === 7 || difference === 14 || difference === 30) {
    return `${difference}`;
  }

  return null;
};

const TableHeaderDateControls = ({ checkIsAvailable, daysRange, usePeriods = true }) => {
  const {
    filter: { date },
    setDate,
  } = useFilterReducer();
  const { isInner } = useAnalyticType();

  const { tariffName } = useAuth();
  const isTrends = isTrendsPage();
  const isProduct = isProductPage();

  const { availableRanges } = useUserTariffs();

  const [startDate, setStartDate] = useState(moment(date[0]).toDate());
  const [endDate, setEndDate] = useState(moment(date[1]).toDate());
  const [dateTouched, setDateTouched] = useState(false);

  const [radioValue, setRadioValue] = useState(getRangeInitial({ startDate, endDate }));

  const handleRadioApply = ({ startDate, endDate, value }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setRadioValue(`${value}`);
  };

  const onRadioChange = (e) => {
    const value = e.target.value;

    const endDate = moment().subtract(1, 'days');
    const startDate = moment().subtract(+value, 'days');

    handleRadioApply({ startDate: startDate.toDate(), endDate: endDate.toDate(), value });

    setDate([startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)]);
  };

  const handleRangeUpdate = useCallback(() => {
    const difference = getDifference({ startDate, endDate });

    if (difference === 1 || difference === 7 || difference === 14 || difference === 30) {
      handleRadioApply({ startDate, endDate, value: difference });
    } else {
      setRadioValue(null);
    }
  }, [startDate, endDate]);

  const handleRangePickerApply = () => {
    handleRangeUpdate();

    setDate([
      moment(startDate).endOf('day').format(DATE_FORMAT),
      moment(endDate).endOf('day').format(DATE_FORMAT),
    ]);
  };

  const handleStartChange = (date) => {
    const { isValid, correctDateEnd } = checkDate({
      date: [date, endDate],
      tariffName,
      isTrends,
      isProduct,
      daysRange,
    });

    setStartDate(date);
    setEndDate((prev) => (isValid ? prev : correctDateEnd));
    setDateTouched(true);
  };

  const handleEndChange = (date) => {
    const { isValid, correctDateStart } = checkDate({
      date: [startDate, date],
      tariffName,
      isTrends,
      isProduct,
    });

    setStartDate((prev) => (isValid ? prev : correctDateStart));
    setEndDate(date);
    setDateTouched(true);
  };

  const { minStartDate, maxStartDate, minEndDate, maxEndDate } = getRangePickerLimits({
    tariffName,
    startDate,
    endDate,
    daysRange,
  });

  useEffect(() => {
    setStartDate(moment(date[0]).toDate());
    setEndDate(moment(date[1]).toDate());
  }, [date]);

  useEffect(() => {
    handleRangeUpdate();
  }, [handleRangeUpdate]);

  return (
    <>
      {usePeriods && (
        <div className="button-group">
          {isInner ? (
            <RadioButton
              name="1-day"
              value="1"
              label="Вчера"
              isChecked={radioValue === '1'}
              onChange={onRadioChange}
              first
            />
          ) : (
            <RadioButton
              name="7-days"
              value="7"
              label="7&nbsp;дн"
              isChecked={radioValue === '7'}
              onChange={onRadioChange}
              first
              disabled={!availableRanges.includes(7)}
            />
          )}
          {isInner ? (
            <RadioButton
              name="7-days"
              value="7"
              label="7&nbsp;дн"
              isChecked={radioValue === '7'}
              onChange={onRadioChange}
              first
              disabled={!availableRanges.includes(7)}
            />
          ) : (
            <RadioButton
              name="14-days"
              value="14"
              label="14&nbsp;дн"
              isChecked={radioValue === '14'}
              onChange={onRadioChange}
              disabled={!availableRanges.includes(14)}
            />
          )}
          <RadioButton
            name="30-days"
            value="30"
            label="30&nbsp;дн"
            isChecked={radioValue === '30'}
            onChange={onRadioChange}
            last
            disabled={!availableRanges.includes(30)}
          />
        </div>
      )}
      <RangePicker
        startDate={startDate}
        minStartDate={minStartDate}
        maxStartDate={maxStartDate}
        onStartChange={handleStartChange}
        endDate={endDate}
        minEndDate={minEndDate}
        maxEndDate={maxEndDate}
        onEndChange={handleEndChange}
        onSubmit={handleRangePickerApply}
        disabledSubmit={!dateTouched}
        checkIsAvailable={checkIsAvailable}
        daysRange={daysRange}
      />
    </>
  );
};

TableHeaderDateControls.propTypes = {
  checkIsAvailable: PropTypes.func,
  daysRange: PropTypes.number,
  usePeriods: PropTypes.bool,
};

export default TableHeaderDateControls;

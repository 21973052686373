const NewLogo = (props) => {
  return (
    <svg {...props} viewBox="0 0 58 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 41.1936L0.358462 23.5683L1.50879 22.418L19.1341 40.0433L17.9837 41.1936Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 1.53739L17.9848 41.1943L16.8345 40.0439L56.4914 0.387066L57.6417 1.53739Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 46.7014L0.358462 29.0761L1.50879 27.9258L19.1341 45.5511L17.9837 46.7014Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 7.04521L17.9848 46.7021L16.8345 45.5518L56.4914 5.89488L57.6417 7.04521Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 52.2092L0.358462 34.5839L1.50879 33.4336L19.1341 51.0589L17.9837 52.2092Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 12.553L17.9848 52.2099L16.8345 51.0596L56.4914 11.4027L57.6417 12.553Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 57.718L0.358462 40.0927L1.50879 38.9424L19.1341 56.5677L17.9837 57.718Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 18.0608L17.9848 57.7177L16.8345 56.5674L56.4914 16.9105L57.6417 18.0608Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 63.2258L0.358462 45.6005L1.50879 44.4502L19.1341 62.0755L17.9837 63.2258Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 23.5686L17.9848 63.2255L16.8345 62.0752L56.4914 22.4183L57.6417 23.5686Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9837 68.6125L0.358462 50.9872L1.50879 49.8369L19.1341 67.4622L17.9837 68.6125Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.6417 28.9554L17.9848 68.6122L16.8345 67.4619L56.4914 27.805L57.6417 28.9554Z"
        fill="#219653"
      />
    </svg>
  );
};

export default NewLogo;

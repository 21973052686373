import PropTypes from 'prop-types';
import cn from 'classnames';

const ButtonLabel = ({ onClick, label, active, disabled }) => {
  return (
    <button
      className={cn('text-xs py-1 px-2 rounded leading-none', {
        ['text-gray-400 bg-gray-200 cursor-not-allowed']: disabled,
        ['text-green bg-green-light']: active,
        ['text-gray-400 hover:text-green']: !active && !disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
ButtonLabel.defaultProps = {
  active: false,
  disabled: false,
};
ButtonLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  disableActiveTitle: PropTypes.bool,
};
export default ButtonLabel;

import PropTypes from 'prop-types';

import cn from 'classnames';
import EditIcon from 'assets/icons/EditIcon';
import TrashIcon from 'assets/icons/TrashIcon';

const InfoDropdown = ({
  isAllActionsAvailable,
  isDropdownExpanded,
  options,
  onSelect,
  value,
  handleAddClick,
  handleEditIconClick,
  handleDeleteIconClick,
}) => {
  if (!isDropdownExpanded) {
    return null;
  }
  const hoverClass = 'group cursor-pointer focus:outline-none hover:bg-green-50';
  const selectedClass = 'font-bold bg-gray-50 !text-gray-700';

  const onOptionSelect = (value) => {
    onSelect(value);
  };

  const onClearValue = () => {
    onSelect(null);
  };
  return (
    <div className={'absolute right-0 rounded bg-white top-5 shadow-[0_2px_8px_rgba(0,0,0,0.1)]'}>
      {options?.map((option, index) => (
        <div
          key={option.value}
          onClick={() => onOptionSelect({ id: option.value, name: option.name })}
          className={cn(
            'flex text-left w-full pl-3 py-2 text-gray-500 justify-between',
            hoverClass,
            {
              'rounded-t': index === 0,
              [selectedClass]: option.value === value?.id,
              'pr-3': isAllActionsAvailable,
              'pr-5': !isAllActionsAvailable,
            },
          )}
        >
          <div className="group-hover:text-green">{option.name}</div>
          {isAllActionsAvailable && (
            <div className="flex space-x-2 ml-4">
              <button
                className="block hover:text-green"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditIconClick(option.value, option.name);
                }}
              >
                <EditIcon height={12} width={12} />
              </button>
              <button
                className="block hover:text-green"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteIconClick(option.value);
                }}
              >
                <TrashIcon height={12} width={12} />
              </button>
            </div>
          )}
        </div>
      ))}
      {isAllActionsAvailable && (
        <button
          className={cn('block pl-3 pr-5 py-2 text-left text-green', {
            'rounded-t': !options || options.length === 0,
          })}
          onClick={handleAddClick}
        >
          Добавить
        </button>
      )}
      {value !== '-' && (
        <button
          className={cn('block pl-3 pr-5 py-2 text-left text-green rounded-b')}
          onClick={onClearValue}
        >
          Очистить поле
        </button>
      )}
    </div>
  );
};

InfoDropdown.propTypes = {
  isDropdownExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
  isAllActionsAvailable: PropTypes.bool,
  options: PropTypes.any,
  onSelect: PropTypes.func,
  value: PropTypes.any,
  handleAddClick: PropTypes.func,
  handleEditIconClick: PropTypes.func,
  handleDeleteIconClick: PropTypes.func,
};

export default InfoDropdown;

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PLATFORMS, PLATFORMS_COLOR } from './constants';
import { useProductLink, useSellerLink, useBrandLink } from 'hooks/useLocationPath';
import { useUI } from 'context/ui.context';
import { PAGE_INNER_PRODUCT } from '../../constants';
const commonType = {
  el: PropTypes.any,
  platform: PropTypes.string,
};

const PlatformLogo = ({ platform, platformColor }) => {
  const { isMobile } = useUI();
  return (
    <div
      className={`flex ml-2 text-${platformColor} h-[18px] rounded border border-${platformColor} text-[10px] font-bold p-1`}
    >
      <span className="self-center">
        {(isMobile ? platformColor : PLATFORMS[platform]).toUpperCase()}
      </span>
    </div>
  );
};
PlatformLogo.propTypes = {
  platform: PropTypes.string,
  platformColor: PropTypes.string,
};

export const SearchedInnerProduct = ({ el }) => {
  const { name, external_id, price_with_disc, barcode, images } = el;
  const image = images?.[0];
  const { isMobile } = useUI();

  const priceBlock = (
    <div className="mt-1 text-green flex pr-2 lg:mt-0 lg:border-r lg:border-gray-230 mr-2 whitespace-nowrap">
      {`${price_with_disc} ₽`}
    </div>
  );
  return (
    <div className="py-3 lg:py-4 flex">
      <div className="grow flex">
        <div className="self-center">
          <Link
            to={`${PAGE_INNER_PRODUCT}/${external_id}/${barcode}`}
            target="_blank"
            className="font-bold leading-5 block"
          >
            {name}
          </Link>
          {isMobile && priceBlock}
          <div className="flex mt-1">
            {!isMobile && priceBlock}
            <div className="text-gray-400 flex pr-2 border-r border-gray-230 mr-2">
              <span>Арт.</span>
              <span className="ml-1">{external_id}</span>
            </div>
            <div className="text-gray-400 flex">
              <span>Баркод</span>
              <span className="ml-1">{barcode}</span>
            </div>
          </div>
        </div>
      </div>
      {image && (
        <div className="self-center">
          <img
            className="w-[52px] h-[52px] object-cover rounded-sm border border-gray-250"
            src={image}
            alt="no-img"
          />
        </div>
      )}
    </div>
  );
};
SearchedInnerProduct.propTypes = commonType;

export const SearchedProduct = ({ el, platform }) => {
  const { name, inner_product_id, id } = el;
  const platformColor = PLATFORMS_COLOR[platform] ?? null;
  const productLink = useProductLink(id);
  return (
    <div className="py-3 lg:py-4">
      <Link to={productLink} target="_blank" className="font-bold leading-5 block">
        {name}
      </Link>
      <div className="flex mt-1">
        <div className="text-gray-400 flex">
          <span>Арт.</span>
          <span className="ml-1">{inner_product_id}</span>
        </div>
        <PlatformLogo platform={platform} platformColor={platformColor} />
      </div>
    </div>
  );
};
SearchedProduct.propTypes = commonType;

export const SearchedBrandSeller = ({ el, platform, isBrand }) => {
  const { title, count_products, key } = el;
  const platformColor = PLATFORMS_COLOR[platform] ?? null;
  const sellerLink = useSellerLink(key);
  const brandLink = useBrandLink(key);

  return (
    <div className="py-3 lg:py-4">
      <Link
        to={isBrand ? brandLink : sellerLink}
        target="_blank"
        className="font-bold leading-5 block"
      >
        {title}
      </Link>
      <div className="flex mt-1">
        <span className="pr-2 text-gray-400 block border-r border-gray-230">
          {isBrand ? 'Бренд' : 'Продавец'}
        </span>
        <div className="px-2 text-gray-400 flex border-r border-gray-230">
          <span>Артикулов в наличии:</span>
          <span className="ml-1 text-gray-700">{count_products.toLocaleString()}</span>
        </div>
        <PlatformLogo platform={platform} platformColor={platformColor} />
      </div>
    </div>
  );
};
SearchedBrandSeller.propTypes = {
  ...commonType,
  isBrand: PropTypes.bool,
};

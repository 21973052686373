import classNames from 'classnames';
import DownIcon from 'assets/icons/DownIcon';
import React from 'react';
import PropTypes from 'prop-types';

const MobileOpener = (props) => {
  const { platformColor, label, setIsOpen } = props;
  return (
    <button
      className={classNames(
        'whitespace-nowrap inline-flex items-center px-3 py-1.5 text-sm font-medium rounded hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer',
        `bg-transparent !px-1.5 !py-0 border border-solid border-${platformColor} h-[18px]`,
        {
          [`text-${platformColor} border-${platformColor}-bright`]: platformColor,
        },
      )}
      onClick={() => setIsOpen(true)}
    >
      <span className="text-[10px] font-bold">{label}</span>
      <DownIcon className={'w-3 h-3 ml-2 ml-0.5'} aria-hidden="true" />
    </button>
  );
};
MobileOpener.propTypes = {
  platformColor: PropTypes.string,
  label: PropTypes.string,
  setIsOpen: PropTypes.func,
};
export { MobileOpener };

import PropTypes from 'prop-types';

const ArrowDownIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.446251 2.04644C0.641513 1.85118 0.958096 1.85118 1.15336 2.04644L3.9998 4.89289L6.84625 2.04644C7.04151 1.85118 7.3581 1.85118 7.55336 2.04644C7.74862 2.2417 7.74862 2.55829 7.55336 2.75355L4.35336 5.95355C4.1581 6.14881 3.84151 6.14881 3.64625 5.95355L0.446251 2.75355C0.250989 2.55829 0.250989 2.2417 0.446251 2.04644Z"
        fill={color}
      />
    </svg>
  );
};

ArrowDownIcon.propTypes = {
  color: PropTypes.string,
};

export { ArrowDownIcon };

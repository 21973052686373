import moment from 'moment';
import { BACK_DATE_FORMAT, TABLE_DATE_FORMAT } from 'utils/table';
import { percentageFormatter } from 'helpers/cellValueFormatters';
import { DISABLED_FILTER_TOOLTIP } from 'helpers/generateGridConfig';
import { splitByCommasTooltipFormatter } from 'helpers/tooltipValueGetters';

const dateConverter = (date) => {
  const splitted = date.split('.');
  return '20' + splitted[2] + '-' + splitted[1] + '-' + splitted[0];
};

const nullComparator = (valueA = '', valueB = '') => {
  const valueATransform = valueA === '-' ? 0 : +valueA;
  const valueBTransform = valueB === '-' ? 0 : +valueB;

  return valueATransform > valueBTransform ? 1 : -1;
};

const dateComparator = (format) => (date1, date2) => {
  const date1Moment = moment(date1, format);
  const date2Moment = moment(date2, format);

  if (!date1Moment.isValid() && !date2Moment.isValid()) {
    return 0;
  }

  if (!date1Moment.isValid()) {
    return -1;
  }
  if (!date2Moment.isValid()) {
    return 1;
  }

  if (date1Moment > date2Moment) {
    return 1;
  } else {
    return -1;
  }
};

const dateComparatorOuter = dateComparator(TABLE_DATE_FORMAT);
const dateComparatorInner = dateComparator(BACK_DATE_FORMAT);

const oneFilterTab = {
  filter: true,
  menuTabs: ['filterMenuTab'],
};

const textFilter = {
  filter: 'searchFilter',
};

const rangeDateFilter = {
  filter: 'customRangeDateFilter',
  headerComponentParams: { sortable: true },
  filterParams: {
    buttons: ['reset', 'apply'],
  },
  comparator: nullComparator,
};

const rangeFilter = {
  filter: 'rangeFilter',
  headerComponentParams: { sortable: true },
  filterParams: {
    buttons: ['reset', 'apply'],
  },
  comparator: nullComparator,
};

const multiFilter = {
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [textFilter, rangeFilter],
  },
};

const setFilter = {
  filter: 'agSetColumnFilter',
};

const multiSetFilter = {
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [textFilter, setFilter],
  },
};

const sortable = {
  headerComponentParams: {
    sortable: true,
  },
  comparator: nullComparator,
};

const sortDateOnAggrid = {
  headerComponentParams: {
    sortable: true,
  },
  comparator: dateComparatorOuter,
};

const sortDateOnAggridInner = {
  headerComponentParams: {
    sortable: true,
  },
  comparator: dateComparatorInner,
};

const percentageValue = {
  valueFormatter: percentageFormatter,
  cellRendererParams: {
    percentageColored: true,
  },
};

const imageValue = {
  cellClass: 'overflow-visible',
  cellRenderer: 'platformImageTableCell',
  tooltipComponent: 'imageTooltip',
  headerComponentParams: { sortable: false },
  tooltipValueGetter: (props) => props,
  valueFormatter: ({ value }) => {
    if (!value) return null;

    if (Array.isArray(value)) {
      return value?.[0] || null;
    }
    if (Array.isArray(value.split(','))) {
      return value.split(',')?.[0] || null;
    }

    return value;
  },
};

const headerTooltipDecorator = (showFilterHiddenTooltip, headerTooltip) => ({
  headerTooltip: showFilterHiddenTooltip ? DISABLED_FILTER_TOOLTIP : headerTooltip,
});

const categoryValue = {
  cellRendererParams: {
    overflowHiddenLeft: true,
  },
  tooltipValueGetter: splitByCommasTooltipFormatter,
};

export {
  nullComparator,
  oneFilterTab,
  textFilter,
  rangeFilter,
  sortable,
  sortDateOnAggrid,
  sortDateOnAggridInner,
  percentageValue,
  imageValue,
  headerTooltipDecorator,
  categoryValue,
  multiFilter,
  dateConverter,
  rangeDateFilter,
  dateComparatorOuter,
  multiSetFilter,
  dateComparator,
};

import { useQuery } from 'react-query';

import apiClient from 'services/baseClient';
import { STALE_TIME } from 'constants/query';
import { ACTION_TYPE } from 'constants/profile';
import { useAuth } from 'context/auth.context';

const action = {
  [ACTION_TYPE.ORDERS_LIST]: (props) => apiClient.getOrdersList(props),
  [ACTION_TYPE.SUBSCRIPTIONS_LIST]: (props) => apiClient.getSubscriptions(props),
  [ACTION_TYPE.TARIFFS_LIST]: (props) => apiClient.getTariffsList(props),
};

export const useFetchProfileData = ({ type, enabled = true }) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: [type],
    queryFn: action[type],
    refetchOnmount: false,
    staleTime: STALE_TIME,
    enabled: !!user && enabled,
  });
};

import PropTypes from 'prop-types';

import { useTable } from 'context/table.context';

import Checkbox from 'components/Checkbox';

const Button = ({ label, onClick, disabled }) => (
  <button
    onClick={onClick}
    className={`${
      disabled ? 'opacity-50 pointer-events-none focus:border-gray-250' : ''
    } border border-gray-250 px-2 py-1 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green focus:border-green rounded`}
  >
    {label}
  </button>
);
Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const AddNestedCheckbox = () => {
  const { isNestedIncluded, switchIsNestedIncluded } = useTable();

  return (
    <div
      className="
      flex justify-center pt-3
      sm:px-8 sm:items-center
      "
    >
      <Checkbox
        label="Включить в экспорт вложенные таблицы"
        onChange={switchIsNestedIncluded}
        name="isNestedIncluded"
        checked={isNestedIncluded}
        className="items-center"
      />
    </div>
  );
};

AddNestedCheckbox.propTypes = {
  footerRef: PropTypes.object.isRequired,
};

export default AddNestedCheckbox;

// Иконка с ШЕСТЕРЕНКОЙ и ГАЛОЧКОЙ (для isFiltered && !isSorted) is либо СТРЕЛОЧКОЙ (для isSorted)
import PropTypes from 'prop-types';

export function FilterWithIcon({ isFiltered, ...svgProps }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      {isFiltered && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.66971 1.65697C8.68361 1.67835 8.69824 1.69907 8.71355 1.71911L7.43941 3.75694C7.30992 3.74133 7.17809 3.7333 7.04439 3.7333C5.24026 3.7333 3.77772 5.19584 3.77772 6.99997C3.77772 8.8041 5.24026 10.2666 7.04439 10.2666C7.24134 10.2666 7.43422 10.2492 7.62157 10.2158L8.73636 12.2306C8.71265 12.2594 8.69038 12.2897 8.66971 12.3215L7.90383 13.4995C7.50947 14.106 6.62142 14.106 6.22706 13.4995L5.46118 12.3215C5.2355 11.9743 4.81923 11.8022 4.41428 11.8885L3.0363 12.1823C2.32697 12.3335 1.69761 11.703 1.85015 10.994L2.14241 9.6355C2.22995 9.22858 2.05629 8.80998 1.7064 8.58455L0.535044 7.82984C-0.0761342 7.43606 -0.0761347 6.54238 0.535044 6.14859L1.70639 5.39389C2.05629 5.16845 2.22995 4.74986 2.14241 4.34294L1.85015 2.98446C1.69761 2.27542 2.32697 1.64489 3.0363 1.79612L4.41428 2.08991C4.81923 2.17624 5.2355 2.00409 5.46118 1.65697L6.22706 0.478969C6.62142 -0.127592 7.50947 -0.127593 7.90383 0.478969L8.66971 1.65697ZM13.8223 5.74518C14.12 5.34211 14.0346 4.77403 13.6315 4.47635C13.2285 4.17867 12.6604 4.2641 12.3627 4.66717L11.3944 5.97833L10.9102 6.63391L10.6671 7.37911H10.26L9.89562 6.69275L9.36523 6.096C9.01091 5.74169 8.43645 5.74169 8.08214 6.09601C7.72783 6.45033 7.72783 7.02479 8.08215 7.37911L10.0372 9.46688C10.3511 9.78073 10.8706 9.74188 11.1343 9.38484L13.8223 5.74518Z"
          fill="currentColor"
        />
      )}
    </svg>
  );
}
FilterWithIcon.defaultProps = {
  isFiltered: true,
};
FilterWithIcon.propTypes = {
  isFiltered: PropTypes.bool,
};

import React, { lazy } from 'react';

import { useProduct } from 'context/product.context';
import { getReviewsDeclension } from 'helpers/utils';
import PropTypes from 'prop-types';
import DiagramIcon from '../../../assets/icons/DiagramIcon';

const RatingStars = lazy(() => import('components/RatingStars'));

export const ProductRatingRow = ({ isInner }) => {
  const { productData } = useProduct();
  const rating = productData?.rating;
  const reviews = isInner ? productData?.reviews_count : productData?.reviews_qty;
  const price = productData?.price ? productData?.price?.toLocaleString('ru') : '';

  return (
    <div className="flex items-center py-3 lg:py-2">
      <div className="flex items-center gap-3">
        {price && (
          <span className="font-bold text-xl leading-none lg:text-2xl">{price}&nbsp;₽</span>
        )}
        {!isInner && (
          <>
            {productData?.discount_percent && (
              <span className="text-[#EB392D] flex self-start !-ml-2">
                -{Math.floor(productData?.discount_percent)}%
              </span>
            )}
            <span className="text-[#858585] block !-ml-2 line-through">
              {productData?.price_before_disc}₽
            </span>
            <div className="flex border-x border-[#E7EDF0] px-3 items-center space-x-2 h-[23px]">
              <DiagramIcon width={20} height={17} />
              <span className="block text-[#858585]">{`${productData?.price_min} - ${productData?.price_max} ₽`}</span>
            </div>
          </>
        )}
        {!!rating && (
          <div className="flex items-center">
            {rating === 0 || rating === '-' ? (
              <span className="leading-none">Нет отзывов</span>
            ) : (
              <>
                <RatingStars value={productData?.rating} />
                <span className="pl-2 leading-none">{productData?.rating}</span>
              </>
            )}
          </div>
        )}

        {reviews !== null && reviews !== undefined && (
          <div className="text-gray-400 leading-none">{getReviewsDeclension(reviews)}</div>
        )}
      </div>
    </div>
  );
};

ProductRatingRow.propTypes = {
  isInner: PropTypes.bool,
};

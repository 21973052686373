import React, { useEffect } from 'react';

import client from 'services/activates';
import ActivationCard from 'components/ActivationCard';

const ActivateDemonstration = () => {
  useEffect(() => {
    client.postActiveFormRequest();
  }, []);

  return (
    <ActivationCard
      title={`Мы получили вашу заявку<br>на индивидуальную демонстрацию`}
      description="В течение рабочего дня мы свяжемся с&nbsp;вами и предложим опции по времени для&nbsp;проведения демонстрации."
    />
  );
};

export default ActivateDemonstration;

import PropTypes from 'prop-types';
import GridToggleIcon from 'assets/icons/GridToggleIcon';
import TableToggleIcon from 'assets/icons/TableToggleIcon';
import { SHOW_MODES } from './constans';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import Popover from '../../components/Popover';
import { useRef } from 'react';

const AdvertisingWBInfo = ({ showMode, setShowMode, count, blocks_per_page, prioritySubjects }) => {
  const priorityRef = useRef();
  const advertisingRef = useRef();
  return (
    <div className="flex justify-between">
      <div>
        <div className="flex leading-[22px] items-center text-center">
          <span className="block text-gray-400 self-center">Рекламных блоков</span>
          <div className="ml-3 flex self-center">
            {blocks_per_page.map(({ page, count }) => (
              <div className="flex mr-2 self-center" key={page}>
                <span className="block text-gray-400 mr-1 self-center">{`${page} стр.`}</span>
                <span className="text-black self-center">{count}</span>
              </div>
            ))}
          </div>
          <div
            ref={advertisingRef}
            className="text-gray-300 self-center relative top-[1px] cursor-pointer"
          >
            <QuestionIcon width={15} height={15} />
          </div>
          <Popover
            tooltipRef={advertisingRef}
            className="text-left bg-opacity-100"
            tooltip={`
                            <span class="whitespace-nowrap">Узнайте, сколько слотов для рекламы</span><br /><span class="whitespace-nowrap">доступно по данному запросу. </span><br/><span class="whitespace-nowrap">Чем выше показатель,</span><br/><span class="whitespace-nowrap">тем больше шанс получить показы.</span>`}
          />
        </div>
        <div className="flex mt-1 leading-[22px] items-center self-center">
          <span className="block text-gray-400 self-center">Товары в приоритете</span>
          <div className="ml-3 flex self-center">
            {prioritySubjects.map(({ position, name }) => (
              <div className="flex mr-2 self-center" key={name}>
                <span className="block text-green mr-1 self-center">{`#${position}`}</span>
                <span className="text-black self-center">{name}</span>
              </div>
            ))}
            <div
              ref={priorityRef}
              className="text-gray-300 self-center relative top-[1px] cursor-pointer"
            >
              <QuestionIcon width={15} height={15} />
            </div>
            <Popover
              tooltipRef={priorityRef}
              className="text-left bg-opacity-100"
              tooltip={`
                            <span class="whitespace-nowrap">Узнайте, товары каких подкатегорий</span><br /><span class="whitespace-nowrap"> займут первые рекламные блоки</span><br/><span class="whitespace-nowrap">по запросу, а какие будут ниже.</span><br/><span class="whitespace-nowrap">Товары из более приоритетной</span><br/><span class="whitespace-nowrap">категории будут выше в выдаче даже</span><br /> с более низким CPM.`}
            />
          </div>
        </div>
      </div>
      <div className="flex h-fit">
        <span className="text-gray-400 mr-4">{`Найдено ${count} товаров`}</span>
        <div className="flex space-x-2 items-start border-l border-gray-250 pl-4">
          <button onClick={() => setShowMode(SHOW_MODES.GRID)}>
            <GridToggleIcon width={24} height={24} isActive={showMode === SHOW_MODES.GRID} />
          </button>
          <button onClick={() => setShowMode(SHOW_MODES.TABLE)}>
            <TableToggleIcon width={24} height={24} isActive={showMode === SHOW_MODES.TABLE} />
          </button>
        </div>
      </div>
    </div>
  );
};

AdvertisingWBInfo.propTypes = {
  showMode: PropTypes.string,
  setShowMode: PropTypes.func,
  count: PropTypes.number,
  blocks_per_page: PropTypes.any,
  prioritySubjects: PropTypes.any,
};

export default AdvertisingWBInfo;

import { COLUMNS } from 'constants/table';
import moment from 'moment';

export const sortByCategory = (data) => {
  if (!data?.table_data) return;

  data?.table_data?.sort((a, b) => {
    const mainA = a?.[COLUMNS.CATEGORY]?.toLowerCase();
    const mainB = b?.[COLUMNS.CATEGORY]?.toLowerCase();

    if (!mainA || !mainB) return -1;

    if (mainA.indexOf(mainB) === 0) {
      return 1;
    }

    return mainA > mainB ? 1 : -1;
  });
};

export const TABLE_DATE_FORMAT = 'DD.MM.YY';
export const BACK_DATE_FORMAT = 'YYYY-MM-DD';

export const dateFormatter = (value) => {
  if (!value) return '';

  return moment(value, BACK_DATE_FORMAT).format(TABLE_DATE_FORMAT);
};

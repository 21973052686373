import ButtonLabel from 'components/ButtonLabel';

import { TYPE_ABSOLUTE, TYPE_PERCENT } from 'constants/chart';
import { useDynamic } from 'context/dynamic.context';

const LABELS = {
  [TYPE_ABSOLUTE]: 'Абсолютные',
  [TYPE_PERCENT]: 'Относительные',
};

const TypeSwitcher = () => {
  const { types, handleTypeClick, typeActive, isDataLoading } = useDynamic();

  if (!types.length) {
    return <></>;
  }

  return (
    <div className="flex">
      {types.map((type) => (
        <ButtonLabel
          key={type}
          onClick={() => handleTypeClick(type)}
          label={LABELS[type]}
          active={type === typeActive}
          disabled={isDataLoading}
        />
      ))}
    </div>
  );
};

export default TypeSwitcher;

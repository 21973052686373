import cn from 'classnames';
import { PAGE_TITLE_STYLES } from 'components/Panel/components';
import FilterInner from 'components/FilterInner';
import React, { useState } from 'react';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { TABLE_INNER_TYPE, F_INNER, PAGE_API_KEYS } from 'constants';
import Table from 'components/Table';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import Alert from 'components/Alert';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { FETCH_INNER } from 'constants/inner';
import { Redirect } from 'react-router-dom';

export const PricesAlertContext = React.createContext({});

const Prices = () => {
  const { isInnerAnalyticsAvailable } = useUserTariffs();
  const { error, filter } = useFilterReducer();
  const { data: apiKeys = [], isFetched } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS,
    enabled: isInnerAnalyticsAvailable,
  });
  const { data, isLoading, isFetching } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.PRICES_TAB,
    enabled: filter[F_INNER.API_KEYS] && filter[F_INNER.API_KEYS].length !== 0,
  });
  const [alerts, setAlerts] = useState([]);
  const addAlertByKey = ({ key, message, type, icon }) => {
    setAlerts((prev) => [...prev, { key, message, type, icon }]);
  };

  const removeAlertByKey = (key) => {
    setAlerts((prev) => prev.filter((error) => error.key !== key));
  };

  if (isFetched && apiKeys.length === 0) {
    return <Redirect to={PAGE_API_KEYS} />;
  }

  return (
    <div>
      <div className={cn('bg-white pt-2 lg:pt-3 pb-2 lg:pb-4')}>
        <div className="wrapper-wide flex justify-between items-center h-[34px]">
          <h1 className={PAGE_TITLE_STYLES}>Управление ценами</h1>
        </div>

        <div className="pt-2 lg:pt-3">
          <div className="wrapper-wide flex gap-2">{!error && <FilterInner showQuantity />}</div>
        </div>
      </div>
      <PricesAlertContext.Provider value={addAlertByKey}>
        <Table
          data={data}
          type={TABLE_INNER_TYPE.PRICES_TAB}
          paginationOnBack={true}
          isLoading={isLoading || isFetching}
          noRowsText={`По вашему запросу нет данных.`}
        />
      </PricesAlertContext.Provider>
      <Alert
        hide={removeAlertByKey}
        items={alerts}
        placement="top"
        timeout={3000}
        getClassByType={(type) => `text-white ${type === 'error' ? 'bg-[#EB392D]' : 'bg-gray-700'}`}
        closeButtonClassName="text-gray-300"
      />
    </div>
  );
};

export default Prices;

import { ProductPhotos, ProductInfo } from './components';

const ProductHeader = (props) => (
  <>
    <div className="wrapper-wide">
      <div className="lg:grid lg:grid-cols-[164px,1fr] lg:gap-4 mt-6 lg:mt-5">
        <ProductPhotos {...props} />

        <ProductInfo {...props} />
      </div>
    </div>
  </>
);

export default ProductHeader;

import ButtonPrimary from '../ButtonPrimary';
import RefreshIcon from 'assets/icons/RefreshIcon';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TABS } from './constants';
import { COLUMNS, TABLE_TYPE } from '../../constants';
import Table from '../Table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

const HintsResult = ({ data, handleNewHints, isLoading }) => {
  const { setSort } = useFilterReducer();
  const [activeTab, setActiveTab] = useState(TABS.REQUESTS);
  const [resultData, setResultData] = useState([]);
  useEffect(() => {
    setSort({ sort: activeTab === TABS.REQUESTS ? 'frequency' : 'frequency_wb', order: 'desc' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
  useEffect(() => {
    setTimeout(() => {
      setResultData(data[activeTab] || []);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, data]);
  return (
    <div>
      <div className="bg-[#F0F0F0] py-4 px-8 border-t border-[#E8E8E8]">
        <div className="flex">
          <ButtonPrimary
            onClick={handleNewHints}
            label={
              <div className="flex space-x-2 items-center py-[3px]">
                <RefreshIcon width={16} height={16} />
                <span className="block">Новый запрос</span>
              </div>
            }
            className="text-base group !pl-[14px]"
          />
        </div>
      </div>
      <div className="p-4 bg-white">
        <div className="flex cursor-pointer">
          <button
            className={cn('border border-grey rounded-l py-1 px-2', {
              'text-green bg-[#F1FCF6]': activeTab === TABS.REQUESTS,
            })}
            onClick={() => setActiveTab(TABS.REQUESTS)}
          >
            Запросы
          </button>
          <button
            className={cn('border border-grey rounded-r py-1 px-2', {
              'text-green bg-[#F1FCF6]': activeTab === TABS.WORDS,
            })}
            onClick={() => setActiveTab(TABS.WORDS)}
          >
            Слова
          </button>
        </div>
      </div>
      <Table
        data={resultData}
        type={
          activeTab === TABS.REQUESTS ? TABLE_TYPE.SEO_HINTS_REQUESTS : TABLE_TYPE.SEO_HINTS_WORDS
        }
        typeNested={
          activeTab === TABS.REQUESTS
            ? TABLE_TYPE.SEO_HINTS_REQUESTS_INNER
            : TABLE_TYPE.SEO_HINTS_WORDS_INNER
        }
        nested={activeTab === TABS.REQUESTS ? 'children' : 'similar_phrases'}
        isLoading={isLoading}
        noRowsText={`По вашему запросу нет данных.`}
        hideFilterPanel={true}
        autoSizeNestedColumns={[COLUMNS.NAME]}
        nestedCheckbox={true}
        nestedParentColumn={'phrase'}
      />
    </div>
  );
};

HintsResult.propTypes = {
  handleNewHints: PropTypes.func,
  data: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default HintsResult;

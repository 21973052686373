import { useCallback, useMemo } from 'react';

import { COLUMNS } from 'constants/table';
import { useAuth } from 'context/auth.context';
import {
  getInitialPlatform,
  getDateInitial,
  getWarehouseInitial,
  fixFilterParams,
  isPlatformAvailable,
  isOzonPlatform,
  isWbPlatform,
  getSortInitial,
  // getDateInitialProduct,
  getWarehouseType,
  getNewAdvertisingRewrite,
  getNestedSortInitial,
  getChipsInitial,
} from './filter.context.reducer.methods';
import { useUserTariffs } from 'context/user.tariffs.context';
import { isProductPage, isTrendsPage } from 'utils/pages';
import { useProduct } from 'context/product.context';
import { FILTER_TYPE } from 'constants/list';
import { F } from 'constants/filter';
import { PERIOD_DAY, PERIOD_MONTH, PERIOD_WEEK } from 'constants';
import { ACTIONS } from './filter.context.reducer';

export const filterInitial = {
  [F.CATEGORY]: [],
  [F.BRAND]: [],
  [F.SELLER]: [],
  [F.PLATFORM]: null,
  [F.PRODUCT]: [],
  [F.DATE]: [],
  [F.IS_NEW]: 0,
  [F.HAS_ADVERTISING]: 0,
  [F.SORT_NESTED]: null,
  [F.ORDER_NESTED]: 'desc',
  [F.WAREHOUSE_TYPE]: null,
  [F.WAREHOUSE]: [12],
  [F.PERIOD]: null,
};

export const CHIPS_KEYS_OUTER = [F.CATEGORY, F.BRAND, F.SELLER];

export const useFilterOuter = ({ state, dispatch }) => {
  const { platforms, tariffName } = useAuth();
  const { platformId: productPlatformId } = useProduct();
  const { isSellersAvailable } = useUserTariffs();
  const chipsInitial = useMemo(() => getChipsInitial(CHIPS_KEYS_OUTER), []);

  const getFilterInitial = () => {
    const isProduct = isProductPage();

    const platform = !isProduct ? getInitialPlatform(platforms) : productPlatformId;
    const warehouse = getWarehouseInitial({ platform });
    return {
      [F.PLATFORM]: platform,
      [F.WAREHOUSE]: warehouse,
      [F.WAREHOUSE_TYPE]: getWarehouseType(warehouse),
    };
  };

  const getFilterFromParams = (filterParams) => {
    return fixFilterParams({
      params: filterParams,
      platforms,
      isSellersAvailable,
      tariffName,
      productPlatformId,
    });
  };

  const getFilterDefault = ({ nextLink }) => {
    return {
      [F.HAS_ADVERTISING]: getNewAdvertisingRewrite({
        value: state.filter[F.HAS_ADVERTISING],
        nextLink: nextLink,
      }),
      [F.IS_NEW]: getNewAdvertisingRewrite({
        value: state.filter[F.IS_NEW],
        nextLink: nextLink,
      }),
    };
  };

  // задать платформу
  // автоматически меняется параметры warehouse
  // (для каждой платформы свой набор складов)
  // date, isNew сохраняются между платформами
  const setPlatform = (platformId) => {
    // check if platform is available for user
    if (!isPlatformAvailable({ platformId, platforms })) return;

    const warehouse = getWarehouseInitial({ platform: platformId });

    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...filterInitial,
        [F.PLATFORM]: platformId,
        [F.WAREHOUSE]: warehouse,
        [F.WAREHOUSE_TYPE]: getWarehouseType(warehouse),
        [F.SORT]: getSortInitial(),
        [F.DATE]: state.filter[F.DATE],
        [F.IS_NEW]: state.filter[F.IS_NEW],
        [F.PERIOD]: state.filter[F.PERIOD],
      },
      chips: chipsInitial,
    });
  };

  // отдельно задать платформу
  // когда данные по продукту подгрузятся
  const setProductPlatform = useCallback(() => {
    // check if platform is available for user
    if (!isPlatformAvailable({ platformId: productPlatformId, platforms })) return;

    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.PLATFORM]: productPlatformId,
        [F.SORT]: getSortInitial(),
      },
    });
  }, [productPlatformId, platforms, dispatch]);

  // перед тем как перейти на страницу товара
  // надо задать фильтр под продукт
  const setProductFilter = () => {
    const warehouse = getWarehouseInitial({
      platform: state.filter[F.PLATFORM],
    });

    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...filterInitial,
        [F.WAREHOUSE]: warehouse,
        [F.WAREHOUSE_TYPE]: getWarehouseType(warehouse),
        [F.DATE]: state.filter[F.DATE],
        [F.PLATFORM]: state.filter[F.PLATFORM],
      },
      chips: chipsInitial,
    });
  };

  const setWarehouse = (arr) => {
    const warehouse = Array.isArray(arr) ? arr : [arr];
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.WAREHOUSE]: warehouse,
        [F.WAREHOUSE_TYPE]: getWarehouseType(warehouse),
      },
    });
  };

  const addBrands = ({ key, title, nextLink }) => {
    dispatch({
      type: ACTIONS.ADD_FILTER_PARAM_WITH_CHIP,
      value: {
        type: F.BRAND,
        key,
        title,
      },
      nextLink,
    });
  };

  const addSeller = ({ key, title, nextLink }) => {
    dispatch({
      type: ACTIONS.ADD_FILTER_PARAM_WITH_CHIP,
      value: {
        type: F.SELLER,
        key,
        title,
      },
      nextLink,
    });
  };

  const setCategory = ({ key, title, nextLink }) => {
    dispatch({
      type: ACTIONS.SET_FILTER_PARAM_WITH_CHIP,
      value: {
        type: [F.CATEGORY],
        key,
        title,
      },
      nextLink,
    });
  };

  const toggleIsNew = () => {
    dispatch({
      type: ACTIONS.TOGGLE_FILTER_BOOL_PARAM,
      key: [F.IS_NEW],
    });
  };

  const setIsNew = (value) => {
    dispatch({
      type: ACTIONS.SET_FILTER_BOOL_PARAM,
      key: [F.IS_NEW],
      value,
    });
  };

  const toggleHasAdvertising = () => {
    dispatch({
      type: ACTIONS.TOGGLE_FILTER_BOOL_PARAM,
      key: [F.HAS_ADVERTISING],
    });
  };

  const setHasAdvertising = (value) => {
    dispatch({
      type: ACTIONS.SET_FILTER_BOOL_PARAM,
      key: [F.HAS_ADVERTISING],
      value,
    });
  };

  const resetFilter = () => {
    const isTrends = isTrendsPage();
    const date = getDateInitial({ tariffName, isTrends });

    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.CATEGORY]: filterInitial[F.CATEGORY],
        [F.BRAND]: filterInitial[F.BRAND],
        [F.SELLER]: filterInitial[F.SELLER],
        [F.DATE]: date,
      },
      chips: chipsInitial,
    });
  };

  // для вложенных таблиц запоминается своя сортировка
  // страницы seo - поисковые фразы
  const setSortNested = ({ sort, order }) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.SORT_NESTED]: sort,
        [F.ORDER_NESTED]: order,
      },
    });
  };

  const setSortNestedDefault = () => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [F.SORT_NESTED]: getNestedSortInitial(),
        [F.ORDER_NESTED]: filterInitial[F.ORDER_NESTED],
      },
    });
  };

  const isFilterApplied =
    !!state?.filter?.[F.CATEGORY]?.length ||
    !!state?.filter?.[F.BRAND]?.length ||
    !!state?.filter?.[F.SELLER]?.length;

  const getProductFilterDefault = () => {
    return {
      [F.CATEGORY]: filterInitial[F.CATEGORY],
      [F.BRAND]: filterInitial[F.BRAND],
      [F.SELLER]: filterInitial[F.SELLER],
      [F.WAREHOUSE]: state.filter[F.WAREHOUSE],
      [F.WAREHOUSE_TYPE]: state.filter[F.WAREHOUSE_TYPE],
      [F.PLATFORM]: state.filter[F.PLATFORM],
      [F.DATE]: state.filter[F.DATE],
      // [F.DATE]: getDateInitialProduct({ tariffName }),
      [F.SORT]: COLUMNS.DATE,
      [F.ORDER]: filterInitial[F.ORDER],
    };
  };

  const setFilterByUserList = useCallback(
    (data) => {
      const filterNewValues = {};
      const chipsNewValues = {};

      Object.values(FILTER_TYPE).forEach((param) => {
        filterNewValues[param] =
          FILTER_TYPE[data?.type] === param
            ? data?.items?.map((el) => el.id) || []
            : filterInitial?.[param] || [];
      });

      CHIPS_KEYS_OUTER.forEach((key) => {
        if (key !== FILTER_TYPE[data?.type]) {
          chipsNewValues[key] = chipsInitial[key];
        } else {
          const chips = data?.items?.map((el) => el.id) || [];
          chipsNewValues[key] = chips.map((id) => ({
            key: id,
          }));
        }
      });

      dispatch({
        type: ACTIONS.UPDATE_FILTER,
        filter: filterNewValues,
        chips: chipsNewValues,
      });
    },
    [chipsInitial, dispatch],
  );

  const setDayPeriod = () => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...state.filter,
        [F.PERIOD]: PERIOD_DAY,
      },
    });
  };

  const setWeekPeriod = () => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...state.filter,
        [F.PERIOD]: PERIOD_WEEK,
      },
    });
  };

  const setMonthPeriod = () => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...state.filter,
        [F.PERIOD]: PERIOD_MONTH,
      },
    });
  };

  const setFilterState = (state) => {
    dispatch({
      type: ACTIONS.SET_FULL_STATE,
      state,
    });
  };

  return {
    common: {
      getFilterInitial,
      getFilterFromParams,
      resetFilter,
      getFilterDefault,
      isFilterApplied,
      setFilterState,
    },
    exceptional: {
      setPlatform,
      setProductPlatform,
      setProductFilter,
      setWarehouse,
      addBrands,
      addSeller,
      setCategory,
      toggleIsNew,
      setIsNew,
      toggleHasAdvertising,
      setHasAdvertising,
      setSortNested,
      setSortNestedDefault,
      productFilterDefault: getProductFilterDefault(),
      setFilterByUserList,
      setDayPeriod,
      setWeekPeriod,
      setMonthPeriod,
      isOzonPlatform: isOzonPlatform(state.filter.platform),
      isWbPlatform: isWbPlatform(state.filter.platform),
    },
  };
};

import baseInnerClient from 'services/baseInnerClient';
import { useQuery } from 'react-query';
import { STALE_TIME } from 'constants/query';
import { useEffect, useState } from 'react';

export const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const { data } = useQuery(['getSuppliers'], () => baseInnerClient.getSuppliers(), {
    refetchOnWindowFocus: false,
    enabled: true,
    staleTime: STALE_TIME,
    retry: 3,
  });

  useEffect(() => {
    if (data) {
      setSuppliers(data.map((el) => ({ value: el.id, name: el.name })));
    }
  }, [data]);

  const addSupplier = (value, name) => {
    const newSuppliers = [...suppliers];
    newSuppliers.push({ value, name });
    setSuppliers(newSuppliers);
  };

  const updateSupplier = (value, name) => {
    const index = suppliers.findIndex((el) => el.value === value);
    if (index !== -1) {
      const newSuppliers = [...suppliers];
      newSuppliers.splice(index, 1, { value, name });
      setSuppliers(newSuppliers);
    }
  };

  const deleteSupplier = (value) => {
    const index = suppliers.findIndex((el) => el.value === value);
    if (index !== -1) {
      const newSuppliers = [...suppliers];
      newSuppliers.splice(index, 1);
      setSuppliers(newSuppliers);
    }
  };

  return {
    suppliers,
    addSupplier,
    updateSupplier,
    deleteSupplier,
  };
};

import moment from 'moment';

import { roundValue } from 'helpers/shortcuts';

export const getAxisYDomain = ({ key, data, steps }) => {
  if (!data || !data?.[0]?.[key]) return [];

  let [bottom, top] = [data[0][key], data[0][key]];

  data.forEach((d) => {
    if (d[key] > top) top = d[key];
    if (d[key] < bottom) bottom = d[key];
  });

  bottom = Math.ceil(bottom) || 0;
  top = Math.ceil(top) || 0;

  const offset = Math.ceil(Math.abs(Math.abs(bottom - top)) / steps / 2);
  bottom = bottom - offset;

  return ['auto', top];
};

export const formatYAxis = (tickItem) => roundValue(tickItem, true);
export const getStrokeDasharray = ({ index, columns }) => (index >= columns ? '6 3' : '');

export const isLineAvailable = ({ data, key, type }) => {
  return data?.[type]?.[0]?.[key] !== undefined;
};

export const formatXAxisDate = (tickItem) => {
  return tickItem ? (tickItem?.indexOf('.') > -1 ? tickItem?.slice(0, -3) : tickItem) : '0';
};

export const formatXAxisDateRange = (tickItem) => {
  if (!tickItem) return '-';

  return tickItem
    ?.split(' - ')
    ?.map((range) => formatXAxisDate(range))
    .join('-');
};

export const formatXAxisRange = (tickItem) => {
  if (!tickItem) return '-';

  return tickItem
    ?.split('-')
    ?.map((range) => roundValue(range, true, true))
    .join('-');
};

const DATE_FORMAT = 'DD MMMM, YYYY';

export const formatDateLabel = (label) =>
  typeof label === 'string' && label?.indexOf('.') > -1 && !label.includes('-')
    ? moment(label, 'DD.MM.YY').format(DATE_FORMAT)
    : label;

export const formatRangeLabel = (tickItem) => {
  if (!tickItem) return '-';

  return (
    'Цена товара: ' +
    tickItem
      ?.split('-')
      ?.map((range) => {
        const item = +range;

        return item?.toLocaleString('ru') || range;
      })
      .join(' - ')
  );
};

export const MODES = {
  INSERT: 'insert',
  RESULT: 'result',
};

export const TABS_GROUP = {
  GROUP_A: { label: 'Только А', value: 'group_a' },
  GROUP_B: { label: 'Только В', value: 'group_b' },
  CROSS: { label: 'Есть А и В', value: 'cross' },
};

export const TAB_ONLY_A = { label: 'Есть в А, но нет в B', value: 'only_a' };

export const TAB_ONLY_B = { label: 'Есть в B, но нет в A', value: 'only_b' };

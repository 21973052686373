import { useQuery } from 'react-query';
import baseClient from '../services/baseInnerClient';
import { STALE_TIME } from '../constants';
import { useAuth } from 'context/auth.context';

export const useFetchProblemsSettings = () => {
  const { user } = useAuth();
  return useQuery(['problemsSettings'], () => baseClient.problemsSettings(), {
    refetchOnWindowFocus: false,
    enabled: !!user,
    staleTime: STALE_TIME,
    retry: 3,
  });
};

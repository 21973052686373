import React from 'react';
import { useLocation } from 'react-router-dom';

import Form from 'pages/SEO/Form';
import Search from 'pages/SEO/Search';
import { SEOSearchProvider } from 'context/seo.search.context';

const SEO = () => {
  const { search } = useLocation();

  return <SEOSearchProvider>{search ? <Search /> : <Form />}</SEOSearchProvider>;
};

export default SEO;

import Table from 'components/Table';
import { TABLE_INNER_TYPE } from 'constants/table';

import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import ProblemsProductDetails from 'components/ProblemsProductsDetails/ProblemsProductDetails';

const Products = () => {
  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.PRODUCTS,
  });

  return (
    <>
      <ProblemsProductDetails />
      <Table
        data={data}
        type={TABLE_INNER_TYPE.PRODUCTS}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </>
  );
};

export default Products;

import { getApi } from 'utils/api';
import axios from 'axios';

class ProfileClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });
  }

  async get(endpoint, token) {
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    };

    return this.client
      .get(endpoint, config)
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  async post(endpoint, data, { token } = {}) {
    const config = {
      body: data ? data : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.post(endpoint, data, config);
  }

  changePassword(password, newPassword, repeatPassword, token) {
    return this.post(
      `/api/auth/changePassword`,
      {
        password: password,
        new_password: newPassword,
        repeat_password: repeatPassword,
      },
      { token },
    );
  }

  getTariffPrice(info, token) {
    const filter = {
      type: [info.tariff],
      platform: info.platform,
      period_id: [info.period],
      promocode: info.promoCode,
      quantile: [info.quantile],
    };

    return this.get(`/user/tariffPrices?filter=${JSON.stringify(filter)}`, token);
  }

  getPaymentLink({ info, businessmanInfo, arbitraryAmount, paymentTypeId, token }) {
    const filter = {
      type_id: info.tariff,
      platforms: info.platform,
      payment_type_id: paymentTypeId,
      period_id: info.period,
      credentials: businessmanInfo,
      ...(info.promoCode
        ? {
            promocode: info.promoCode,
          }
        : {}),
      ...(arbitraryAmount?.length
        ? {
            custom_payment: '1',
            sum: arbitraryAmount,
          }
        : {}),
    };

    return this.post(`/user/submitOrder`, filter, { token });
  }
}

const client = new ProfileClient(getApi());

export default client;

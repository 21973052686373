import { useState } from 'react';
import { useUI } from '../../context/ui.context';
import cn from 'classnames';
import ButtonPrimary from '../ButtonPrimary';
import { MONITORING_GROUPS_MODAL_MODES } from './constants';
import PropTypes from 'prop-types';
import baseClient from '../../services/baseClient';

const GroupRename = ({
  setMode,
  modalSelected,
  setModalSelected,
  monitoringGroups,
  setMonitoringGroups,
}) => {
  const [newName, setNewName] = useState(modalSelected.name || '');
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
    setNewName('');
  };
  const handleRename = async () => {
    const groupsBeforeChanging = JSON.parse(JSON.stringify(monitoringGroups));
    const selectedBefore = JSON.parse(JSON.stringify(modalSelected));
    baseClient.updateCommonTemplate({ id: modalSelected.id, name: newName }).then((data) => {
      if (data.status !== 200) {
        setMonitoringGroups(() => groupsBeforeChanging);
        setModalSelected(selectedBefore);
      }
    });
    const resultGroups = [...monitoringGroups];
    const resultGroupIndex = monitoringGroups.findIndex((el) => el.id === modalSelected.id);
    resultGroups[resultGroupIndex] = { ...resultGroups[resultGroupIndex], name: newName };
    setMonitoringGroups(resultGroups);
    setModalSelected({ ...modalSelected, name: newName });
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
    setNewName('');
  };
  const handleNewNameChange = (e) => {
    setNewName(e.target.value);
  };
  return (
    <div className="w-full flex grow items-center justify-center">
      <div
        className={cn({
          'w-full': isMobile,
        })}
      >
        <div className="p-5 lg:p-0">
          <input
            className="w-full lg:w-72 text-2xl font-bold focus:outline-none border-b border-gray-250 pb-1 text-center"
            value={newName}
            onChange={handleNewNameChange}
            autoFocus
          />
        </div>
        {isMobile ? (
          <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
              onClick={handleCancel}
              label="Отмена"
            />
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary"
              onClick={handleRename}
              label="Переименовать"
            />
          </div>
        ) : (
          <div className="flex space-x-3 justify-center mt-6">
            <button
              className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
              onClick={handleCancel}
            >
              Отмена
            </button>
            <button
              className="py-2.5 px-3 rounded border bg-green text-white"
              onClick={handleRename}
            >
              Переименовать
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

GroupRename.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  modalSelected: PropTypes.any,
  setModalSelected: PropTypes.func,
  monitoringGroups: PropTypes.array,
  setMonitoringGroups: PropTypes.func,
};

export { GroupRename };

import PropTypes from 'prop-types';
import cn from 'classnames';
import { useUI } from 'context/ui.context';

const WAREHOUSES_ORDERS = [
  'Коледино',
  'Электросталь',
  'Казань',
  'МЛП-Подольск',
  'Санкт-Петербург',
  'Санкт-Петербург 2',
  'Краснодар 2',
  'Краснодар',
  'Новосибирск',
  'Алексин',
  'Екатеринбург',
  'СЦ Белая дача',
  'Хабаровск',
  'Нур-Султан',
  'Подольск',
];

const sortWarehouses = (warehouses) => {
  const resultWarehouses = [...warehouses].filter((el) => el.id !== 35);
  resultWarehouses.sort((a, b) => {
    const aIndex = WAREHOUSES_ORDERS.indexOf(a.name);
    const bIndex = WAREHOUSES_ORDERS.indexOf(b.name);
    return aIndex - bIndex;
  });
  return resultWarehouses;
};

const DeliveryWarehouseSelector = ({ activeTab, setActiveTab, warehouses }) => {
  const { isMobile } = useUI();
  const allWarehouses = [{ id: 'all', name: 'Все склады' }, ...sortWarehouses(warehouses)];
  return (
    <div className="py-3 px-4 overflow-x-auto flex">
      <div
        className={cn('flex justify-between items-center grow', {
          ['flex-nowrap touch-auto']: isMobile,
        })}
      >
        {allWarehouses.map((warehouse, index) => {
          const isActive = activeTab === warehouse.id;
          return (
            <button
              key={warehouse.id}
              onClick={() => setActiveTab(warehouse.id)}
              className={cn(
                'block whitespace-nowrap z-0 relative border-t border-b border-r border-gray-250 px-3 pb-1.5 pt-2.5 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green',
                {
                  '!text-green bg-[#F1FCF6]': isActive,
                  'rounded-l border-l': index === 0,
                  'rounded-r': index === allWarehouses.length - 1,
                },
              )}
            >
              {warehouse.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

DeliveryWarehouseSelector.propTypes = {
  activeTab: PropTypes.any,
  setActiveTab: PropTypes.func,
  warehouses: PropTypes.any,
};

export { DeliveryWarehouseSelector };

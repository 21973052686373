import PropTypes from 'prop-types';

import { ReactComponent as Icon } from 'assets/images/logout.svg';

const ButtonLogout = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <Icon />
    </button>
  );
};

ButtonLogout.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ButtonLogout;

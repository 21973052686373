import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ReactComponent as PlusIcon } from 'assets/images/plus.svg';

const collapsePropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    ),
  ]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  bordered: PropTypes.bool,
};
export const CollapseControllable = ({
  isCollapsed,
  toggleCollapsed,
  children,
  title,
  bordered,
}) => {
  return (
    <div
      className={cn('py-2.5', {
        'border-b': bordered,
      })}
    >
      <div
        onClick={() => toggleCollapsed(!isCollapsed)}
        className={cn('flex justify-between font-bold items-center cursor-pointer', {
          'mb-2.5 text-black': !isCollapsed,
          'text-green': isCollapsed,
        })}
      >
        {title}
        {!isCollapsed && <div className="border border-black w-[14px]" />}
        {isCollapsed && (
          <PlusIcon
            className={cn('w-[14px] h-[14px]', {
              'text-green': isCollapsed,
              'text-black': !isCollapsed,
            })}
          />
        )}
      </div>
      {!isCollapsed && children}
    </div>
  );
};
CollapseControllable.propTypes = {
  ...collapsePropTypes,
  isCollapsed: PropTypes.bool.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
};
const Collapse = ({ children, defaultCollapsed, title, bordered }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <CollapseControllable
      isCollapsed={isCollapsed}
      toggleCollapsed={setIsCollapsed}
      title={title}
      bordered={bordered}
    >
      {children}
    </CollapseControllable>
  );
};
Collapse.defaultProps = {
  defaultCollapsed: true,
  bordered: false,
};
Collapse.propTypes = {
  ...collapsePropTypes,
  defaultCollapsed: PropTypes.bool,
};

export default Collapse;

import React, { useEffect, useState } from 'react';
import baseClient from '../services/baseClient';

const MonitoringGroupsContext = React.createContext();
MonitoringGroupsContext.displayName = 'MonitoringGroups.Context';

// const KEY = 'WECHECK_MONITORING_TEMPLATE';

const MonitoringGroupsProvider = (props) => {
  const [monitoringGroups, setMonitoringGroups] = useState([]);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  // const [activeGroupId, setActiveGroupId] = useState();
  const [editGroup, setEditGroup] = useState();

  useEffect(() => {
    baseClient.getCommonTemplates().then((data) => setMonitoringGroups(data));
    // const cached = localStorage.getItem(KEY);
    // setActiveGroupId(cached ? +cached : undefined);
  }, []);

  // const setActiveGroup = (id) => {
  //   setActiveGroupId(id);
  //   localStorage.setItem(KEY, id);
  // };

  // const deactivateActiveGroup = () => {
  //   setActiveGroupId();
  //   localStorage.removeItem(KEY);
  // };

  const value = {
    monitoringGroups,
    setMonitoringGroups,
    showGroupsModal,
    setShowGroupsModal,
    // activeGroupId,
    // setActiveGroup,
    // deactivateActiveGroup,
    editGroup,
    setEditGroup,
  };

  return <MonitoringGroupsContext.Provider value={value} {...props} />;
};

const useMonitoringGroups = () => {
  return React.useContext(MonitoringGroupsContext);
};

export { MonitoringGroupsProvider, useMonitoringGroups };

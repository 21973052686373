import { MobileInterfaceV2 } from '../MobileInterface/MobileInterface';
import ButtonPrimary from '../ButtonPrimary';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-mobile-datepicker';
import cn from 'classnames';
import moment from 'moment';

const dateConfig = {
  date: {
    format: 'DD',
    caption: 'Day',
    step: 1,
  },
  month: {
    format: (value) => value.toLocaleString('ru', { month: 'long' }),
    caption: 'Mon',
    step: 1,
  },
  year: {
    format: 'YYYY',
    caption: 'Year',
    step: 1,
  },
};

const getHeader = ({ deliveryDateExpanded, deliveryPeriodExpanded }) => {
  if (deliveryDateExpanded) {
    return 'Дата поставки';
  } else if (deliveryPeriodExpanded) {
    return 'Период расчета';
  }
  return 'Загрузка склада до';
};

const getMin = ({
  deliveryLoadDateExpanded,
  deliveryDateExpanded,
  deliveryStartDate,
  isFirst,
  deliveryPeriodExpanded,
}) => {
  if (deliveryDateExpanded || deliveryLoadDateExpanded) {
    return new Date();
  }
  if (deliveryPeriodExpanded && !isFirst) {
    return deliveryStartDate;
  }
};

const getMax = ({ deliveryPeriodExpanded, isFirst, deliveryEndDate }) => {
  if (deliveryPeriodExpanded) {
    if (isFirst) {
      return deliveryEndDate || new Date();
    }
    return new Date();
  }
};

const getInit = ({
  deliveryDate,
  deliveryStartDate,
  deliveryLoadDate,
  deliveryDateExpanded,
  deliveryLoadDateExpanded,
}) => {
  if (deliveryDateExpanded) {
    return deliveryDate || new Date();
  } else if (deliveryLoadDateExpanded) {
    return deliveryLoadDate || new Date();
  } else {
    return deliveryStartDate || new Date();
  }
};

const getDateForToggler = (date) => {
  const resultDate = moment(date).format('DD MMM YYYY').split(' ');
  let month = resultDate[1];
  if (month.charAt(month.length - 1) === '.') {
    month = month.split('').slice(0, -1).join('');
  }
  resultDate.splice(1, 1, month);
  return resultDate.join(' ');
};

const MobileDatePicker = ({
  deliveryDate,
  deliveryStartDate,
  deliveryEndDate,
  deliveryLoadDate,
  deliveryDateExpanded,
  deliveryPeriodExpanded,
  deliveryLoadDateExpanded,
  onClose,
  onSubmit,
}) => {
  const [active, setActive] = useState('first');
  const [valueFirst, setValueFirst] = useState(
    getInit({
      deliveryDate,
      deliveryStartDate,
      deliveryLoadDate,
      deliveryDateExpanded,
      deliveryLoadDateExpanded,
    }),
  );
  const [valueSecond, setValueSecond] = useState(deliveryEndDate);
  const isFirst = active === 'first';
  const commonTogglerClass = 'px-3 py-2 border border-[#D9D9D9] leading-[16px]';
  return (
    <>
      <MobileInterfaceV2
        isActive={deliveryDateExpanded || deliveryPeriodExpanded || deliveryLoadDateExpanded}
        onPanelClose={onClose}
        rootClassName="h-fit bottom-0 absolute"
        headerText={getHeader({
          deliveryDateExpanded,
          deliveryPeriodExpanded,
        })}
      >
        <div className="p-4">
          {deliveryPeriodExpanded && (
            <div className="flex justify-center mb-3">
              <button
                className={cn(commonTogglerClass, 'rounded-l', { '!border-green': isFirst })}
                onClick={() => setActive('first')}
              >
                {getDateForToggler(valueFirst)}
              </button>
              <button
                className={cn(commonTogglerClass, 'rounded-r', { '!border-green': !isFirst })}
                onClick={() => setActive('second')}
              >
                {getDateForToggler(valueSecond)}
              </button>
            </div>
          )}
          <div className="mobile">
            <DatePicker
              theme="ios"
              showHeader={false}
              showFooter={false}
              dateConfig={dateConfig}
              value={isFirst ? valueFirst : valueSecond}
              min={getMin({
                deliveryDateExpanded,
                deliveryLoadDateExpanded,
                deliveryStartDate,
                isFirst,
                deliveryPeriodExpanded,
              })}
              max={getMax({
                deliveryPeriodExpanded,
                isFirst,
                deliveryEndDate,
              })}
              isOpen
              onChange={(value) => (isFirst ? setValueFirst(value) : setValueSecond(value))}
              isPopup={false}
            />
          </div>
        </div>
        <div className="p-4 flex space-x-3">
          <ButtonPrimary
            className="flex-1 py-2 justify-center button-primary__inverted-red"
            onClick={onClose}
            label="Сбросить все"
          />
          <ButtonPrimary
            className="flex-1 py-2 justify-center"
            onClick={() => {
              if (deliveryDateExpanded || deliveryLoadDateExpanded) {
                onSubmit(valueFirst);
              } else {
                onSubmit([valueFirst, valueSecond]);
              }
            }}
            label="Применить"
          />
        </div>
      </MobileInterfaceV2>
    </>
  );
};

MobileDatePicker.propTypes = {
  deliveryDateExpanded: PropTypes.bool,
  deliveryPeriodExpanded: PropTypes.bool,
  deliveryLoadDateExpanded: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  deliveryDate: PropTypes.any,
  deliveryStartDate: PropTypes.any,
  deliveryEndDate: PropTypes.any,
  deliveryLoadDate: PropTypes.any,
};

export default MobileDatePicker;

import React from 'react';
import PropTypes from 'prop-types';

import { TYPE_ABSOLUTE, TYPE_PERCENT } from 'constants/chart';
import { getKey, getName } from './CustomizedLegend';
import { productChangesTypes } from 'helpers/generateGridRowData';
import cn from 'classnames';
const getDataByType = ({ data, key, type, label, labelKey }) => {
  const item = data?.[type] || null;
  if (!item) return '';

  for (let i in item) {
    if (item[i]?.[labelKey] === label) {
      return item[i][key] || item[i][key] === 0 ? item[i][key] : '';
    }
  }
  return '';
};

const getItem = ({ key, payload }) => {
  for (let i = 0; i < payload.length; i++) {
    const item = payload[i];

    if (item.dataKey === key) {
      return item;
    }
  }
};

const Li = ({ label, absolute, percent, dashed, color, className = '' }) => (
  <div className={'table-row-group items-center leading-none'}>
    <div className="table-cell">
      <span className={cn('block mt-2', className)}>{label}</span>
    </div>
    <div className="table-cell align-middle">
      <div className={className}>
        <span
          style={dashed ? { borderColor: color, borderWidth: '1px' } : { backgroundColor: color }}
          className={'min-w-2 w-2 h-2 min-w-[0.5rem] block rounded-full mx-4 mt-2'}
        />
      </div>
    </div>
    <div className="table-cell align-middle">
      <div className={cn('flex mt-2', className)}>
        <span className={`block pr-3 mr-3 ${percent ? 'border-r border-gray-400' : ''}`}>
          {absolute}
        </span>
        {percent && (
          <span className="block grow">
            {percent > 0 ? '+' : ''}
            {percent}%
          </span>
        )}
      </div>
    </div>
  </div>
);
Li.defaultProps = {
  percent: '',
  dashed: false,
};
Li.propTypes = {
  label: PropTypes.string.isRequired,
  absolute: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string.isRequired,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dashed: PropTypes.bool,
  className: PropTypes.string,
};

const ScatterItems = ({ scatterActive, payload }) => {
  if (!scatterActive.length) {
    return <></>;
  }

  return (
    <>
      <div className="table-row-group items-center">
        <div className="table-cell border-t border-gray-450">
          <h4 className="font-bold text-white mt-2">Изменения</h4>
        </div>
        <div className="table-cell border-t border-gray-450" />
        <div className="table-cell border-t border-gray-450" />
      </div>
      {scatterActive.map((scatter) => {
        if (!scatter.key || !scatter.value) return <></>;

        const item = getItem({ key: scatter.key, payload });
        const label = productChangesTypes?.[scatter.key] || scatter.key;
        const value = scatter.value;

        if (item) {
          return <Li key={item?.dataKey} color={item?.color} label={label} absolute={value} />;
        }
      })}
    </>
  );
};
ScatterItems.propTypes = {
  payload: PropTypes.array,
  scatterActive: PropTypes.array.isRequired,
};
const CustomizedTooltip = ({
  payload,
  label,
  type,
  data,
  nameKey,
  isLoading,
  labels,
  formatLabel,
  activeLabels,
  dynamicLabels,
  colors,
  dynamicColors,
  scatterData,
}) => {
  const getScatterActive = () => {
    if (!payload || !Object.keys(payload).length || !scatterData) {
      return [];
    }

    const name = payload?.[0]?.payload?.[nameKey];

    return scatterData[name] || [];
  };

  const scatterActive = getScatterActive();

  const getStyles = () => {
    if (!payload || !payload.length) return {};

    const visibleLines = payload.filter((line) => line.stroke !== 'transparent');

    if (!visibleLines.length) return {};

    let margin = visibleLines.length * 22;

    if (scatterActive.length) {
      margin += 35;
    }

    return {
      marginTop: `-${margin}px`,
    };
  };

  const style = getStyles();

  const permanentShownLabels = Object.keys(labels)?.filter((label) => activeLabels.includes(label));
  const dynamicShownLabels = activeLabels.filter((label) => !permanentShownLabels.includes(label));
  const orderedLabels = [...permanentShownLabels, ...dynamicShownLabels];

  return (
    <div
      className="z-10000 relative top-24 rounded-md bg-gray-900 py-3 px-4 text-white max-w-[360px]"
      style={style}
    >
      <div className="font-bold pb-2 border-b border-gray-450 mb-1">
        {formatLabel ? formatLabel(label) : label}
      </div>

      {!isLoading && payload && Object.keys(payload).length && (
        <div className="table">
          {orderedLabels?.map((name, labelIndex) => {
            const key = getKey({ name, type });
            const item = getItem({ key, payload });

            if (item) {
              const { dataKey, payload } = item;

              const name = getName({ key: dataKey, type });
              const label = labels[name] || dynamicLabels[name] || name;
              const index = Object.keys(labels).indexOf(name);
              const color = dynamicColors[name] || colors[index];

              const absolute = getDataByType({
                data,
                key: getKey({ name, type: TYPE_ABSOLUTE }),
                type: TYPE_ABSOLUTE,
                label: payload.name,
                labelKey: nameKey,
              });

              const percent = getDataByType({
                data,
                key: getKey({ name, type: TYPE_PERCENT }),
                type: TYPE_PERCENT,
                label: payload.name,
                labelKey: nameKey,
              });

              const hide = !activeLabels.includes(name);
              if (hide) return <React.Fragment key={item.dataKey} />;

              return (
                <Li
                  key={item.dataKey}
                  label={label}
                  absolute={absolute.toLocaleString('ru')}
                  percent={percent}
                  color={color}
                  dashed={!!item.strokeDasharray}
                  className={
                    orderedLabels.length - 1 === labelIndex && scatterActive.length !== 0
                      ? 'pb-2'
                      : ''
                  }
                />
              );
            }
          })}
          <ScatterItems scatterActive={scatterActive} payload={payload} />
        </div>
      )}
    </div>
  );
};
CustomizedTooltip.defaultProps = {
  payload: [],
  title: '',
  label: '',
  data: {},
  isLoading: false,
  formatLabel: null,
  scatterData: {},
};
CustomizedTooltip.propTypes = {
  title: PropTypes.string,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([TYPE_ABSOLUTE, TYPE_PERCENT]).isRequired,
  data: PropTypes.object,
  nameKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  formatLabel: PropTypes.func,
  activeLabels: PropTypes.array.isRequired,
  dynamicLabels: PropTypes.object,
  colors: PropTypes.array,
  dynamicColors: PropTypes.object,
  scatterData: PropTypes.object,
};

export default CustomizedTooltip;

import { useFetchFilteredInnerData } from 'hooks/useFetchFilteredInnerData';
import { TABLE_INNER_TYPE } from 'constants';
import {
  isInnerDeliveryTabPage,
  isInnerPricesTabPage,
  isInnerProductSettings,
} from '../utils/pages';
import { useAnalyticType } from '../context/analytic.type.context';

export const useFetchProblemsProductsSum = () => {
  const { isInner } = useAnalyticType();
  return useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.PROBLEMS_SUM,
    enabled:
      !isInnerProductSettings() && !isInnerPricesTabPage() && !isInnerDeliveryTabPage() && isInner,
  });
};

import LoadingSpin from 'components/LoadingSpin';
import Modal from 'components/Modal';
import React, { Suspense, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { PositionsCategories } from './PositionsCategories';
import { PositionsBrands } from './PositionsBrands';
import { PositionsSellers } from './PositionsSellers';
import { useModalFilter } from 'context/filter.modal.context';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import { PositionsContext } from 'pages/Positions/context';

const SelectionModal = ({
  mode,
  isOpen,
  setModalOpen,
  setSelectedCategory,
  selectedCategory,
  setSelectedBrand,
  selectedBrand,
  setSelectedSeller,
  selectedSeller,
}) => {
  const { setSearchedBrand, setSearchedSeller } = useContext(PositionsContext);
  const [selected, setSelected] = useState();
  const { updateCategoriesList, cleanSellers, cleanBrands } = useModalFilter();
  const { isMobile } = useUI();

  useEffect(() => {
    if (mode === 'category') {
      setSelected(selectedCategory);
    } else if (mode === 'brand') {
      setSelected(selectedBrand);
    } else if (mode === 'seller') {
      setSelected(selectedSeller);
    }
  }, [mode, selectedCategory, selectedBrand, selectedSeller]);
  const TABS = [
    {
      id: 'category',
      label: 'Категория',
      available: true,
      panel: <PositionsCategories selected={selected} setSelected={setSelected} />,
      title: 'Выберите одну категорию',
    },
    {
      id: 'brand',
      label: 'Бренд',
      available: true,
      panel: <PositionsBrands isOpen={isOpen} selected={selected} setSelected={setSelected} />,
      title: 'Выберите один бренд',
    },
    {
      id: 'seller',
      label: 'Продавец',
      available: true,
      panel: <PositionsSellers isOpen={isOpen} selected={selected} setSelected={setSelected} />,
      title: 'Выберите одного продавца',
    },
  ];
  const handleClickCancel = () => {
    if (mode === 'brand') {
      setSearchedBrand('');
    } else if (mode === 'seller') {
      setSearchedSeller('');
    }
    setModalOpen(false);
  };
  const handleApply = () => {
    if (mode === 'category') {
      setSelectedCategory(selected);
    } else if (mode === 'brand') {
      setSelectedBrand(selected);
    } else if (mode === 'seller') {
      setSelectedSeller(selected);
    }
    setModalOpen(false);
  };
  const handleClearClick = () => {
    if (mode === 'category') {
      updateCategoriesList([]);
    } else if (mode === 'brand') {
      cleanBrands();
    } else if (mode === 'seller') {
      cleanSellers();
    }
    setSelected(null);
  };
  const modalData = TABS.find((el) => el.id === mode);
  if (isMobile) {
    return (
      <MobileInterfaceV2
        onPanelClose={handleClickCancel}
        rootClassName="h-3/4 bottom-0 absolute"
        isActive={isOpen}
        headerText={modalData?.title}
      >
        <div className="flex flex-col px-3 pt-2">
          <div className="h-[510px] overflow-y-auto">{modalData?.panel}</div>
          <div className="p-4 flex justify-between">
            <button
              className={`rounded text-red px-8 py-2 border border-gray-230 ${
                !selected ? 'opacity-50' : 'opacity-100'
              }`}
              disabled={!selected}
              onClick={handleClearClick}
            >
              Сбросить все
            </button>
            <button
              className={`rounded text-white bg-green px-8 py-2 ${
                !selected ? 'opacity-50' : 'opacity-100'
              }`}
              disabled={!selected}
              onClick={handleApply}
            >
              Применить
            </button>
          </div>
        </div>
      </MobileInterfaceV2>
    );
  }
  return (
    <Suspense fallback={<LoadingSpin />}>
      <Modal
        isOpen={isOpen}
        title={modalData?.title}
        closeModal={handleClickCancel}
        submitModal={handleApply}
        submitButtonDisabled={!selected}
        width={760}
      >
        <div className="flex -m-6">
          <div className="h-full lg:max-h-full lg:h-[500px] lg:p-6 lg:flex-1 overflow-hidden">
            <div className="h-full flex flex-col">{modalData?.panel}</div>
          </div>
        </div>
      </Modal>
    </Suspense>
  );
};

SelectionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelectedBrand: PropTypes.func.isRequired,
  selectedBrand: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelectedSeller: PropTypes.func.isRequired,
  selectedSeller: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  mode: PropTypes.any,
};

export default SelectionModal;

import { useEffect, useState } from 'react';
import { MODES } from './constants';
import CompareInsert from './CompareInsert';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { SEO_COMPARE } from '../../constants';
import { useHistory, useLocation } from 'react-router';
import CompareResult from './CompareResult';
import { PAGE_SEO_COMPARE } from 'constants';

const getInit = (url) => {
  if (url) {
    const splitted = url.split(',');
    for (let i = splitted.length; i < 5; i++) {
      splitted.push('');
    }
    return splitted;
  }
  return ['', '', '', '', ''];
};

const CompareContent = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const groupAURl = searchParams.get('groupA');
  const groupBURl = searchParams.get('groupB');
  const [mode, setMode] = useState(groupAURl ? MODES.RESULT : MODES.INSERT);
  const [groupA, setGroupA] = useState(getInit(groupAURl));
  const [groupB, setGroupB] = useState(getInit(groupBURl));
  const location = useLocation();
  const history = useHistory();

  const {
    data = {},
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_COMPARE,
    filter: {
      platform: '2',
      group_a: groupA.filter((el) => el),
      group_b: groupB.filter((el) => el),
    },
  });
  useEffect(() => {
    if (groupAURl && groupBURl) {
      refetch();
    }
  }, [groupAURl, groupBURl, refetch]);
  const handleCompare = () => {
    const params = new URLSearchParams({
      ['groupA']: groupA.filter((el) => el).join(','),
      ['groupB']: groupB.filter((el) => el).join(','),
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setMode(MODES.RESULT);
    refetch();
  };
  const handleNewCompare = () => {
    setGroupA(getInit());
    setGroupB(getInit());
    history.push(PAGE_SEO_COMPARE);
    setMode(MODES.INSERT);
  };
  if (mode === MODES.INSERT) {
    return (
      <CompareInsert
        handleCompare={handleCompare}
        groupA={groupA}
        setGroupA={setGroupA}
        groupB={groupB}
        setGroupB={setGroupB}
      />
    );
  }
  return (
    <CompareResult
      data={data}
      handleNewCompare={handleNewCompare}
      isLoading={isLoading || isFetching}
    />
  );
};

export default CompareContent;

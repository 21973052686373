import { MONITORING_GROUPS_MODAL_MODES } from './constants';
import { GroupInfo } from './GroupInfo';
import PropTypes from 'prop-types';
import { GroupDelete } from './GroupDelete';
import { GroupRename } from './GroupRename';

const getContentByMode = (mode) => {
  switch (mode) {
    case MONITORING_GROUPS_MODAL_MODES.INFO: {
      return GroupInfo;
    }
    case MONITORING_GROUPS_MODAL_MODES.RENAME: {
      return GroupRename;
    }
    case MONITORING_GROUPS_MODAL_MODES.DELETE: {
      return GroupDelete;
    }
  }
};
const MonitoringGroupsMonitoringContent = ({
  mode,
  setMode,
  modalSelected,
  setModalSelected,
  monitoringGroups,
  setMonitoringGroups,
}) => {
  const Content = getContentByMode(mode);
  return (
    <Content
      mode={mode}
      setMode={setMode}
      modalSelected={modalSelected}
      setModalSelected={setModalSelected}
      monitoringGroups={monitoringGroups}
      setMonitoringGroups={setMonitoringGroups}
    />
  );
};

MonitoringGroupsMonitoringContent.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  modalSelected: PropTypes.any,
  setModalSelected: PropTypes.func,
  monitoringGroups: PropTypes.array,
  setMonitoringGroups: PropTypes.func,
};

export { MonitoringGroupsMonitoringContent };

import PropTypes from 'prop-types';
import cn from 'classnames';

import useColumnSort from 'hooks/useColumnSort';
import SortIcon from 'assets/icons/SortIcon';

export const SortButton = ({ column, sortable }) => {
  const { isSorted, order, onSortClick } = useColumnSort({ column, sortable });

  return (
    <button
      onClick={onSortClick}
      className={cn(
        'p-2 border border-gray-250 transition-all hover:border-green rounded-md focus:border-green focus:outline-none',
        {
          ['text-green']: isSorted,
          ['text-gray-400 hover:text-green focus:text-green']: !isSorted,
        },
      )}
    >
      <SortIcon order={order} className="w-3.5 h-3.5" />
    </button>
  );
};
SortButton.propTypes = {
  column: PropTypes.object.isRequired,
  sortable: PropTypes.bool,
};

import { useQuery } from 'react-query';
import baseClient from 'services/baseClient';
import { STALE_TIME } from 'constants/query';

export const useFetchPositions = ({
  date,
  platform,
  selectedCategory,
  selectedBrand,
  selectedSeller,
}) => {
  return useQuery(
    ['positions', date, platform, selectedCategory?.key, selectedSeller?.key, selectedBrand?.key],
    () =>
      baseClient.getPositions({
        date: date,
        platform: [platform],
        category: [selectedCategory?.key],
        brand: selectedBrand?.key ? [selectedBrand?.key] : [],
        seller: selectedSeller?.key ? [selectedSeller?.key] : [],
      }),
    {
      enabled: !!(selectedCategory && platform && date),
      staleTime: STALE_TIME,
      retry: 3,
    },
  );
};

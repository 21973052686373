import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import SortIcon from 'assets/icons/SortIcon';

export const SortButton = ({ isSorted, columnOrder, onSortClick, isSortable }) => {
  return (
    <button
      onClick={onSortClick}
      disabled={!isSortable}
      className={cn('relative p-1 pr-0 transition-all', {
        ['cursor-not-allowed']: !isSortable,
        ['text-gray-300 hover:text-green focus:text-green transition-all']: !isSorted,
        ['text-green']: isSorted,
      })}
    >
      <SortIcon order={columnOrder} width={16} height={16} isSorted={isSorted} />
    </button>
  );
};

SortButton.propTypes = {
  onSortClick: PropTypes.func,
  isSorted: PropTypes.bool,
  isSortable: PropTypes.bool,
  columnOrder: PropTypes.oneOf(['asc', 'desc']),
};

import PropTypes from 'prop-types';
import NewLogo from 'assets/icons/NewLogo';
const SignUpSuccess = ({ state }) => {
  return (
    <div>
      <NewLogo width={58} height={67} className="mx-auto" />
      <span className="mt-8 block text-base font-light text-[#333333] text-center">
        Для завершения регистрации, пожалуйста,
        <br /> перейдите по ссылке, которую мы отправили <br />
        на<span className="font-bold">{` ${state.email}`}</span>
      </span>
    </div>
  );
};

SignUpSuccess.propTypes = {
  state: PropTypes.any,
};

export default SignUpSuccess;

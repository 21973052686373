import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// import LoginForm from 'components/LoginForm';
import { useAuth } from 'context/auth.context';
import Loading from 'components/Loading';

const RegistrationVerification = () => {
  const [verified, setVerified] = useState(false);
  const [verifying, setVerifying] = useState(true);
  const { code } = useParams();
  const { verify, setToken } = useAuth();

  useEffect(() => {
    verify(code)
      .then((result) => {
        setVerified(result.success);
        setVerifying(false);
        if (result.success) {
          setToken({ access_token: result.token });
          setTimeout(() => {
            window.location.pathname = '/';
          }, 3000);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [verify, code, setToken]);

  return (
    <>
      {verifying ? (
        <Loading />
      ) : verified ? (
        <>
          <h1 className="pb-3 text-xl max-w-full w-72 text-center">
            Ваш пользователь успешно зарегистрирован!
          </h1>
          {/*<div className="mb-6 pb-3 text-md max-w-full w-72 text-center">*/}
          {/*  Авторизуйтесь, для начала работы с платформой Wecheck.*/}
          {/*</div>*/}
          {/*<LoginForm showNavigation={false} />*/}
        </>
      ) : (
        <div className="mb-6 pb-3 text-xl max-w-full w-72 text-center">
          При регистрации произошла ошибка, пожалуйста напишите на почту{' '}
          <a href="mailto:info@wecheck.ru">info@wecheck.ru</a>.
        </div>
      )}
    </>
  );
};

export default RegistrationVerification;

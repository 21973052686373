import React, { useCallback, useEffect, useState } from 'react';

import { isInnerPage } from 'utils/pages';
import { useUserTariffs } from 'context/user.tariffs.context';

const AnalyticTypeContext = React.createContext();
AnalyticTypeContext.displayName = 'Analytic.Type.Context';

const INNER = 'inner';
const OUTER = 'outer';

const ANALYTICS_TYPES = [
  {
    value: OUTER,
    label: 'Аналитика конкурентов',
  },
  {
    value: INNER,
    label: 'Управление продажами',
  },
  {
    value: 'advertising',
    label: 'Рекламные ставки',
  },
  {
    value: 'seo',
    label: 'SEO продвижение',
  },
];

const AnalyticTypeProvider = (props) => {
  const isInner = isInnerPage();
  const { isInnerAnalyticsAvailable, isLoaded } = useUserTariffs();
  const [analyticType, setAnalyticType] = useState(null);

  // первая загрузка типа
  // для которой надо дождаться загрузки тарифа юзера
  useEffect(() => {
    if (!isLoaded || analyticType) return;

    setAnalyticType(
      isInner && isInnerAnalyticsAvailable
        ? INNER
        : window.location.pathname.includes('/seo')
        ? 'seo'
        : OUTER,
    );
  }, [isLoaded, isInnerAnalyticsAvailable, isInner, analyticType]);

  const setInnerType = useCallback(() => {
    if (analyticType === INNER) return;

    setAnalyticType(INNER);
  }, [analyticType]);

  const setOuterType = useCallback(() => {
    if (analyticType === OUTER) return;

    setAnalyticType(OUTER);
  }, [analyticType]);

  const handleTypeChange = (value) => {
    // if (value !== INNER && value !== OUTER) return;

    setAnalyticType(value);
  };

  const setOuterTypeChange = () => {
    if (analyticType === OUTER) return;

    setAnalyticType(OUTER);
  };

  const value = {
    analyticType,
    types: ANALYTICS_TYPES,
    isInner: analyticType === INNER,
    setInnerType,
    setOuterType,
    handleTypeChange,
    setOuterTypeChange,
    isLoaded: !!analyticType,
  };

  return <AnalyticTypeContext.Provider value={value} {...props} />;
};

const useAnalyticType = () => React.useContext(AnalyticTypeContext);

export { AnalyticTypeProvider, useAnalyticType };

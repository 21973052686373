const CSVIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 22">
    <path
      d="M5.855 16.811c-.145.108-.33.185-.555.232-.22.047-.449.07-.683.07-.284 0-.555-.044-.812-.133a1.782 1.782 0 0 1-.67-.443 2.272 2.272 0 0 1-.466-.793c-.113-.328-.169-.724-.169-1.187 0-.483.063-.885.19-1.208.13-.328.297-.59.5-.787.207-.201.437-.344.69-.428.252-.09.505-.134.757-.134.27 0 .499.019.683.056.19.038.345.082.467.134l-.182.814a1.306 1.306 0 0 0-.372-.112 3.18 3.18 0 0 0-.515-.035c-.374 0-.676.138-.906.414-.225.277-.338.705-.338 1.286 0 .252.027.484.081.695.054.206.135.384.244.534.112.145.25.26.412.344.167.08.359.12.575.12.203 0 .374-.022.514-.064s.262-.096.365-.161l.19.786ZM8.808 15.694a.46.46 0 0 0-.163-.365 1.577 1.577 0 0 0-.399-.253 8.309 8.309 0 0 0-.52-.238 3.052 3.052 0 0 1-.521-.302 1.634 1.634 0 0 1-.4-.45c-.103-.178-.155-.405-.155-.681 0-.239.038-.445.115-.618a1.17 1.17 0 0 1 .325-.443c.144-.117.313-.204.507-.26a2.33 2.33 0 0 1 .65-.084c.274 0 .534.026.777.077.244.047.444.117.602.211l-.29.808a1.535 1.535 0 0 0-.447-.176 2.35 2.35 0 0 0-.643-.084c-.216 0-.383.044-.5.133a.433.433 0 0 0-.17.359c0 .14.053.257.156.35.109.094.242.181.4.26.162.08.335.162.52.246.19.085.363.188.521.31.162.117.295.264.399.442.108.173.162.39.162.653 0 .262-.042.49-.128.681a1.24 1.24 0 0 1-.352.471c-.149.127-.331.22-.548.281-.212.06-.446.091-.703.091-.338 0-.636-.032-.893-.098a2.662 2.662 0 0 1-.568-.197l.298-.822a1.922 1.922 0 0 0 .46.183c.099.028.202.052.31.07.113.02.228.029.346.029.275 0 .484-.047.629-.141a.495.495 0 0 0 .223-.443ZM12.245 14.985l.122.836h.034l.135-.843.907-2.88h.974l-1.759 4.952h-.663l-1.772-4.952H11.3l.947 2.887Z"
      fill="currentColor"
    />
    <path
      d="M9.5 1h-7a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-7-7Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 1v7h7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
export default CSVIcon;

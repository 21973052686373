import { useQuery } from 'react-query';

import { STALE_TIME } from 'constants/query';
import productListClient from 'services/productList';
import { LIST_FETCH_TYPE } from 'constants/list';

const action = {
  [LIST_FETCH_TYPE.LOAD_BY_TYPE]: (props) => productListClient.loadListsInfoByType(props),
  [LIST_FETCH_TYPE.ALL_TYPES]: () => productListClient.getUserList(),
};

const useFetchFilteredDataOptions = ({ type, enabled, by }) => {
  const options = {
    type: by,
  };

  const queryKey = ['list-items', type, options];

  return {
    actionOption: options,
    queryOptions: {
      queryKey,
      enabled: enabled,
      refetchOnmount: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME,
      retry: 3,
      keepPreviousData: true,
    },
  };
};

export const useFetchListItems = ({ type, by, enabled = true, onSuccess }) => {
  const { actionOption, queryOptions } = useFetchFilteredDataOptions({
    type,
    by,
    enabled,
  });

  return useQuery({
    ...queryOptions,
    queryFn: action[type] ? () => action[type](actionOption) : null,
    onSuccess,
  });
};

import { useQuery } from 'react-query';

import apiClient from 'services/baseClient';
import Table from 'components/Table';
import { STALE_TIME, TABLE_TYPE } from 'constants';

const ReferralsRegistrationsTable = () => {
  const { data, isLoading } = useQuery(
    ['referrals_registrations'],
    () => apiClient.getReferralsMyRefs(),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      staleTime: STALE_TIME,
      retry: 3,
    },
  );
  return (
    <Table
      data={data}
      type={TABLE_TYPE.REFERRALS_REGISTRATIONS}
      isLoading={isLoading}
      noRowsText={`По вашему запросу нет данных.`}
      showSidebar={false}
    />
  );
};

export { ReferralsRegistrationsTable };

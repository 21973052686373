import { useState, useEffect } from 'react';

import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { PlusIcon } from 'assets/icons/PlusIcon';
import { useUI } from 'context/ui.context';
import baseClient from 'services/baseClient';
import { ApiKey } from './components';
import LoadingSpin from 'components/LoadingSpin';
import { FETCH_INNER } from 'constants/inner';

const ApiKeysList = () => {
  const {
    data: apiKeys = [],
    refetch,
    isLoading,
  } = useFetchInnerData({ type: FETCH_INNER.API_KEYS });
  const { isMobile } = useUI();
  const [newApiKeys, setNewApiKeys] = useState([]);
  // const [keysInfo, setKeysInfo] = useState([]);
  const apiKeysIds = apiKeys.map(({ id }) => id).join(',');
  const { data: keysInfo = [] } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS_DETAILS,
    filter: { apiKeysIds },
  });
  const handleAddNewKeyClick = () => {
    setNewApiKeys([...newApiKeys, { name: '', api_key: '' }]);
  };
  console.log(keysInfo);
  const handleNewDelete = (index, setName, setApiKey) => {
    const newKeys = [...newApiKeys];
    if (index === 0 && apiKeys.length === 0) {
      newKeys.splice(index, 1, { name: '', api_key: '' });
      setName('');
      setApiKey('');
    } else {
      newKeys.splice(index, 1);
    }
    setNewApiKeys(newKeys);
  };
  const handleOldDelete = async (id) => {
    const { status } = await baseClient.deleteApiKey({ id });
    if (status === 204) {
      refetch();
    }
  };
  useEffect(() => {
    if (apiKeys.length === 0) {
      setNewApiKeys([{ name: '', api_key: '' }]);
    } else {
      setNewApiKeys([]);
    }
  }, [apiKeys.length, setNewApiKeys]);
  const addButton = (
    <button
      className="flex text-green items-center hover:text-gray-900 focus:text-gray-900 transition-all"
      onClick={handleAddNewKeyClick}
    >
      <PlusIcon width={18} height={18} />
      <span className="ml-2">Добавить ключ</span>
    </button>
  );
  return (
    <div className="w-full border border-gray-150 bg-white rounded-lg p-6 divide-y">
      <div className="flex pb-4 justify-between">
        <div className="grow justify-center lg:grow-0 text-lg flex font-medium lg:text-base divide-x">
          <span className="block mr-4">API-ключи</span>
          <span className="block pl-4 text-purple">Wildberries</span>
        </div>
        {!isMobile && addButton}
      </div>
      <div className="divide-y">
        {isLoading ? (
          <LoadingSpin />
        ) : (
          <>
            {newApiKeys.map((apiKey, newIndex) => (
              <ApiKey
                key={apiKey.name + `.${newIndex}`}
                apiKey={apiKey}
                isNew
                canDelete={(name, api_key) =>
                  newIndex === 0 && apiKeys.length === 0 ? name || api_key : true
                }
                handleDelete={(setName, setApiKey) => handleNewDelete(newIndex, setName, setApiKey)}
                refetch={refetch}
              />
            ))}
            {apiKeys.map((apiKey) => (
              <ApiKey
                key={apiKey.id}
                apiKey={apiKey}
                handleDelete={() => handleOldDelete(apiKey.id)}
                refetch={refetch}
              />
            ))}
          </>
        )}
      </div>
      {isMobile && (
        <div className="border-t border-gray-150 py-4 flex justify-center">{addButton}</div>
      )}
    </div>
  );
};

export default ApiKeysList;

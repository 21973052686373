const API_URL = process.env.REACT_APP_API_URL;
const API_TEST_URL = process.env.REACT_APP_API_TEST_URL;
const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;
const API_AUTH_TEST_URL = process.env.REACT_APP_AUTH_TEST_URL;

export const getApi = () => {
  return window.location.hostname === 'test.wecheck.dev' ? API_TEST_URL : API_URL;
};

export const getAuthApi = () => {
  return window.location.hostname === 'test.wecheck.dev' ? API_AUTH_TEST_URL : API_AUTH_URL;
};

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import Dropdown from 'components/Dropdown';
import { F_INNER } from 'constants';
import { QUANTITY_OPTIONS } from '../constants';
import React, { useEffect, useState } from 'react';
import { isInnerPricesTabPage } from 'utils/pages';
import PropTypes from 'prop-types';
import { useUI } from 'context/ui.context';
import { RadioButton } from '../../MobileInterface/components/PlatformPanel';
import ButtonPrimary from '../../ButtonPrimary';
import { MobileInterfaceV2 } from '../../MobileInterface/MobileInterface';
import cn from 'classnames';
import DownIcon from '../../../assets/icons/DownIcon';

const QuantityFilter = ({ showQuantity }) => {
  const { filter, setQuantity } = useFilterReducer();
  const { isMobile } = useUI();
  const [open, setIsOpen] = useState(false);
  const selected = filter[F_INNER.QUANTITY];
  const [mobileSelected, setMobileSelected] = useState(selected);
  const label = QUANTITY_OPTIONS.find((el) => el.value === selected)?.label;
  const pathname = window.location.pathname;
  useEffect(() => {
    if (!isInnerPricesTabPage() && selected !== 0 && setQuantity) {
      setQuantity(0);
    }
  }, [pathname, setQuantity, selected]);

  useEffect(() => {
    setMobileSelected(selected);
  }, [selected]);
  const onChange = (value) => {
    setQuantity(+value);
  };

  if (!showQuantity) {
    return null;
  }
  const isOutlined = true,
    bgColor = 'green',
    color = 'green';
  const handleMobileChange = (value) => {
    setMobileSelected(value);
  };
  if (isMobile) {
    return (
      <div>
        <button
          className={cn(
            'whitespace-nowrap inline-flex h-[28px] items-center w-full px-3 py-1.5 text-sm font-medium rounded hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer',
            {
              [`bg-${bgColor}`]: !isOutlined && bgColor,
              [`text-${color}`]: color,
              [`border border-${bgColor}`]: isOutlined && bgColor,
            },
          )}
          onClick={() => setIsOpen(!open)}
        >
          <span>Наличие</span>
          <DownIcon
            className={cn('w-3 h-3 ml-2 transition-all', {
              ['scale-y-[-1]']: open,
            })}
            aria-hidden="true"
          />
        </button>
        <MobileInterfaceV2
          onPanelClose={() => setIsOpen(false)}
          rootClassName="h-fit bottom-0 absolute"
          isActive={open}
          headerText="Наличие"
        >
          <div className="px-4 divide-y">
            {QUANTITY_OPTIONS.map((el) => (
              <div key={el.value}>
                <RadioButton
                  onChange={() => handleMobileChange(el.value)}
                  label={el.label}
                  isChecked={el.value === mobileSelected}
                  containerCn="border-none mx-0"
                />
              </div>
            ))}
            <ButtonPrimary
              onClick={() => {
                setIsOpen(false);
                onChange(mobileSelected);
              }}
              label={<span className="block mx-auto">Применить</span>}
              className="w-full text-center py-2 mb-4 items-center"
            />
          </div>
        </MobileInterfaceV2>
      </div>
    );
  }
  return (
    <Dropdown
      label={label}
      bgColor="green"
      color="green"
      options={QUANTITY_OPTIONS}
      onChange={onChange}
      selected={selected}
      buttonClassnames="border-solid border px-[11px] py-[5px]"
      isOutlined
    />
  );
};

QuantityFilter.propTypes = {
  showQuantity: PropTypes.bool,
};

export { QuantityFilter };

import PropTypes from 'prop-types';

import StarIcon from 'assets/icons/StarIcon';
import DownIcon from 'assets/icons/DownIcon';
import { useUserList } from 'context/user.list.context';
import { useUI } from 'context/ui.context';

const ListModeActivateButton = ({ onClick }) => {
  const { activeList, activeListData, setSelectedList, listsByActiveType } = useUserList();
  const { isMobile } = useUI();
  const handleClick = () => {
    onClick && onClick();
    if (!activeList && !isMobile) {
      setSelectedList(listsByActiveType?.[0]);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`border border-gray-250 flex items-center self-stretch
    px-1.5 lg:px-2 py-1 space-x-0.5 lg:space-x-1.5 rounded 
    transition-all hover:text-green hover:border-green focus:text-green focus:border-green
    `}
    >
      <StarIcon className="text-yellow-bright h-5 w-5" />

      <span className="hidden lg:inline">
        {activeList ? (
          <div className="flex">
            <span className="block text-green">Применен список:</span>
            <span className="block ml-1 text-gray-700 max-w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
              {activeListData.name}
            </span>
          </div>
        ) : (
          `Избранное`
        )}
      </span>

      <DownIcon height={14} width={14} className="rotate-[-90deg]" />
    </button>
  );
};
ListModeActivateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
export default ListModeActivateButton;

import React from 'react';
import CompareContent from 'components/SeoCompare/CompareContent';

const Compare = () => {
  return (
    <div className="pt-4">
      <div className="px-8">
        <h1>Сравнение групп товаров</h1>
        <div className="text-gray-400 mt-1 mb-4">
          <span className="block">
            Инструмент помогает сравнить видимость по поисковым фразам ваших товаров с товарами
            конкурентов.
          </span>
          <span className="block">
            Сервис покажет по каким поисковым запросам необходимо улучшить видимость
          </span>
        </div>
      </div>
      <CompareContent />
    </div>
  );
};

export default Compare;

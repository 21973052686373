import React, { useState } from 'react';

import { useAuth } from 'context/auth.context';
import AuthorizationForm from 'components/AuthorizationForm';

const SendMailConfirmation = () => {
  const { sendMailConfirmation } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = (values) => {
    setError(null);
    return sendMailConfirmation(values.email)
      .then((data) => {
        if (('success' in data && !data.success) || data.status !== 200) {
          setError(data?.status || 'Что пошло не так. Попробуйте позже ещё раз.');
        } else {
          setError(null);
        }
        return data;
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <AuthorizationForm
      fields={[
        {
          name: 'email',
          label: 'E-mail',
          type: 'text',
        },
      ]}
      initialValues={{
        email: '',
      }}
      onSubmit={handleSubmit}
      submitLabel="Запросить ссылку"
      showNavigation={false}
      title="Ваш e-mail не подтвержден"
      successMessage="Ссылка успешно отправлена вам на почту."
      messageText={
        <div className="mb-6 pb-3 max-w-full w-72 mb-8 text-gray-500 text-center sm:text-left">
          Проверьте почту и перейдите <br />
          по ссылке подтверждения, либо запросите ссылку подтверждения повторно
        </div>
      }
      superError={error}
      showButtonLoginOnMessage={true}
    />
  );
};

export default SendMailConfirmation;

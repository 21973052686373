import { PLATFORM_OZON, PLATFORM_WB } from 'context/filter/filter.context.reducer.methods';

export const PLATFORMS_COLOR = {
  [PLATFORM_OZON]: 'ozon',
  [PLATFORM_WB]: 'wb',
};

export const CRITERIAS = [
  { value: 'products', label: 'Товарам' },
  { value: 'sellers', label: 'Продавцам' },
  { value: 'brands', label: 'Брендам' },
];

export const INNER_CRITERIAS = [
  { value: 'external_id', label: 'Артикулу WB' },
  { value: 'barcode', label: 'Баркоду' },
];

export const PLATFORMS = {
  [PLATFORM_OZON]: 'Ozon',
  [PLATFORM_WB]: 'Wildberries',
};

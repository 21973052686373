import axios from 'axios';
import { getToken } from 'utils/token';
import { getApi } from 'utils/api';
import { getPrefix } from 'services/baseClient';

class BaseInnerClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });

    this.client.interceptors.request.use(function (config) {
      const token = getToken();
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
      return config;
    });

    this.get = this.get.bind(this);
    this.getInnerBrands = this.getInnerBrands.bind(this);
    this.getInnerCategories = this.getInnerCategories.bind(this);
    this.getInnerInDays = this.getInnerInDays.bind(this);
    this.getInnerFilterWarehouses = this.getInnerFilterWarehouses.bind(this);
    this.getInnerProducts = this.getInnerProducts.bind(this);
    this.getInnerSubjects = this.getInnerSubjects.bind(this);
    this.getInnerSales = this.getInnerSales.bind(this);
    this.getInnerWeeks = this.getInnerWeeks.bind(this);
    this.deliveryCalculating = this.deliveryCalculating.bind(this);
    this.problemsProductSum = this.problemsProductSum.bind(this);
    this.problemsSettings = this.problemsSettings.bind(this);
    this.setProblemsSetting = this.setProblemsSetting.bind(this);
  }

  async post(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.post(endpoint, data, config);
  }

  async delete(endpoint) {
    return this.client.delete(endpoint);
  }

  async put(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.put(endpoint, data, config);
  }

  async get(endpoint, options = {}) {
    const { filter, sort, page, quantity, date_delivery, date_stock_out } = options;

    let uri = '';

    if (filter) {
      uri = `${getPrefix({ uri, endpoint })}filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (sort) {
      uri = `${getPrefix({ uri, endpoint })}sort=${encodeURIComponent(JSON.stringify(sort))}`;
    }

    if (quantity) {
      uri = `${getPrefix({ uri, endpoint })}quantity=${quantity}`;
    }

    if (page) {
      uri = `${getPrefix({ uri, endpoint })}page=${page}&per_page=${250}`;
    }

    if (date_stock_out) {
      uri = `${getPrefix({ uri, endpoint })}date_stock_out=${date_stock_out}`;
    }

    if (date_delivery) {
      uri = `${getPrefix({ uri, endpoint })}date_delivery=${date_delivery}`;
    }

    if (!uri) {
      uri = endpoint;
    }

    return this.client
      .get(uri, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      })
      .then((res) => res.data)
      .catch((error) => {
        const status = error?.response?.status || null;
        const message = error.message || 'Something went wrong :(';

        if (status === 403 || status === 423) {
          return {
            error: {
              message:
                error?.response?.data?.status ||
                error?.response?.message ||
                'Something went wrong :(',
              showLoginButton: status === 403,
            },
          };
        }

        throw new Error(message);
      });
  }

  getInnerBrands(props) {
    return this.get('/user/in/brands', props).then((data) => data?.data?.data || []);
  }

  getInnerCategories(props) {
    return this.get('/user/in/categories', props).then((data) => data?.data?.data || []);
  }

  getInnerInDays(props) {
    return this.get('/user/in/days', props).then((data) => data?.data || []);
  }

  getInnerProducts(props) {
    return this.get('/user/in/products', props).then((data) => data?.data || []);
  }

  getInnerSubjects(props) {
    return this.get('/user/in/subjects', props).then((data) => data?.data?.data || []);
  }

  getInnerFilterWarehouses(props) {
    return this.get('user/in/filter/warehouse', props).then((data) => data?.data || []);
  }

  getInnerSales(props) {
    return this.get('/user/in/sales', props).then((data) => data?.data || []);
  }

  getInnerWeeks(props) {
    return this.get('/user/in/weeks', props).then((data) => data?.data || []);
  }

  getInnerProduct(props) {
    return this.get(`/user/in/${props.barcode}/sales`, props).then((data) => data?.data || []);
  }

  getInnerProductRatings(props) {
    return this.get(`/user/in/${props.barcode}/days`, props).then((data) => data?.data || []);
  }

  getInnerProductOrdersSales(props) {
    return this.get(`/user/in/${props.barcode}/orders`, props).then((data) => data?.data || []);
  }

  getInnerInDays2(props) {
    return this.get(`/user/in/days2`, props).then((data) => data?.data || []);
  }

  getInnerPricesTab(props) {
    return this.get(`/user/in/prices`, props).then((data) => data?.data || []);
  }

  searchInner(props) {
    const { criteria, value } = props;
    return this.get(
      `/user/in/search/product?search[field]=${criteria}&search[query]=${value}`,
    ).then((data) => data?.data || []);
  }

  getInnerWarehouses(props) {
    return this.get(`/user/in/warehouse`, props).then((data) => data || []);
  }

  getInnerProductWarehouses(props) {
    return this.get(`/user/in/${props.barcode}/warehouse`, props).then((data) => data || []);
  }

  getInnerProductSizes(props) {
    return this.get(`/user/in/${props.id}/size`, props).then((data) => data || {});
  }

  getInnerProductSettings(props) {
    return this.get(`/user/in/products/${props.id}/settings`, props).then(
      (data) => data?.data || [],
    );
  }

  updateInnerProductSettings(props) {
    const { data, id } = props;
    return this.put(`/user/in/products/${id}/settings`, data).then((data) => data);
  }

  getSuppliers(props) {
    return this.get(`/user/in/dictionaries/user/suppliers`, props).then((data) => data?.data || []);
  }

  createSupplier(props) {
    const { data } = props;
    return this.post(`/user/in/dictionaries/user/suppliers`, data).then((data) => data);
  }

  updateSupplier(props) {
    const { id, data } = props;
    return this.put(`/user/in/dictionaries/user/suppliers/${id}`, data).then((data) => data);
  }

  deleteSupplier(props) {
    const { id } = props;
    return this.delete(`/user/in/dictionaries/user/suppliers/${id}`).then((data) => data);
  }

  getCardListInfo(props) {
    return this.get(`/user/in/charts`, props).then((data) => data?.data || {});
  }

  deliveryCalculating(props) {
    return this.get(`/user/in/delivery_calculation`, props).then((data) => data.data || {});
  }

  problemsProductSum(props) {
    return this.get(`/user/in/product_sum`, props).then((data) => data || {});
  }

  productsSettings(props) {
    return this.get(`/user/in/products_setting`, props).then((data) => data?.data || []);
  }

  problemsSettings(props) {
    return this.get('/user/in/settings', props).then((data) => data.data || []);
  }

  setProblemsSetting(props) {
    const { data } = props;
    return this.post('/user/in/settings', data).then((data) => data);
  }
}

const apiClient = new BaseInnerClient(getApi());

export default apiClient;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Pagination from 'components/Pagination';
import { useTable } from 'context/table.context';
import { TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import {
  useFetchFilteredInnerDataPaginated,
  actionTypesInner,
} from 'hooks/useFetchFilteredInnerData';

const PaginationOnAggrid = ({
  className,
  paginationOnBack,
  type,
  isLoading,
  totalPages,
  paginationData,
}) => {
  const [pageState, setPageState] = useState(1);
  const { gridOptions } = useTable();
  const page = paginationData?.page || pageState;
  const setPage = paginationData?.setPage || setPageState;

  const paginationAction = actionTypesInner.includes(type)
    ? useFetchFilteredInnerDataPaginated
    : useFetchFilteredDataPaginated;

  const { fetchNextPage } = paginationAction({
    type,
    enabled: paginationOnBack,
  });

  const getPrevPage = () => {
    setPage((prev) => prev - 1);
  };

  const getNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const getPage = (page) => {
    setPage(page);
  };

  const showNextButton =
    (paginationOnBack && totalPages % 5 === 0) || (!paginationOnBack && totalPages !== page);

  useEffect(() => {
    if (!gridOptions || isLoading) return;

    gridOptions.api.paginationGoToPage(page - 1);
  }, [page, gridOptions, isLoading]);

  useEffect(() => {
    if (page % 5 === 0 && paginationOnBack && page === totalPages) {
      fetchNextPage();
    }
  }, [page, paginationOnBack, fetchNextPage, totalPages]);

  if (totalPages <= 1) {
    return <></>;
  }

  return (
    <Pagination
      getNextPage={getNextPage}
      getPage={getPage}
      currentPage={page}
      getPrevPage={getPrevPage}
      totalPages={totalPages}
      className={className}
      showNextButton={showNextButton}
    />
  );
};
PaginationOnAggrid.propTypes = {
  paginationOnBack: PropTypes.bool,
  isLoading: PropTypes.bool,
  totalPages: PropTypes.number,
  className: PropTypes.string,
  paginationData: PropTypes.any,
  type: PropTypes.oneOf([...Object.values(TABLE_TYPE), ...Object.values(TABLE_INNER_TYPE)])
    .isRequired,
};
export default PaginationOnAggrid;

import { F } from 'constants/filter';
import { PAGE_BRANDS, PAGE_MAIN, PAGE_PRODUCTS, PAGE_SELLERS } from 'constants/pages';

export const LIST_FETCH_TYPE = {
  LOAD_BY_TYPE: 'by_type',
  ALL_TYPES: 'all_types',
};

export const LIST_TYPE = {
  BY_PRODUCTS: 0,
  BY_SELLERS: 1,
  BY_BRANDS: 2,
  BY_CATEGORIES: 3,
};

export const FILTER_TYPE = {
  [LIST_TYPE.BY_PRODUCTS]: F.PRODUCT,
  [LIST_TYPE.BY_SELLERS]: F.SELLER,
  [LIST_TYPE.BY_BRANDS]: F.BRAND,
  [LIST_TYPE.BY_CATEGORIES]: F.CATEGORY,
};

export const PAGE_TYPE = {
  [LIST_TYPE.BY_PRODUCTS]: PAGE_PRODUCTS,
  [LIST_TYPE.BY_SELLERS]: PAGE_SELLERS,
  [LIST_TYPE.BY_BRANDS]: PAGE_BRANDS,
  [LIST_TYPE.BY_CATEGORIES]: PAGE_MAIN,
};

export const LIST_TYPE_BY_FIELD = {
  brand: LIST_TYPE.BY_BRANDS,
  category: LIST_TYPE.BY_CATEGORIES,
  seller: LIST_TYPE.BY_SELLERS,
};

export const LIST_DATA = {
  [LIST_TYPE.BY_CATEGORIES]: {
    id: LIST_TYPE.BY_CATEGORIES,
    label: 'категорий',
    labelMobile: 'Категория',
    alertElement: { add: 'Категория добавлена\nв список', remove: 'Категория удалена\nиз списка' },
  },
  [LIST_TYPE.BY_BRANDS]: {
    id: LIST_TYPE.BY_BRANDS,
    label: 'брендов',
    labelMobile: 'Бренд',
    alertElement: { add: 'Бренд добавлен\nв список', remove: 'Бренд удален\nиз списка' },
  },
  [LIST_TYPE.BY_SELLERS]: {
    id: LIST_TYPE.BY_SELLERS,
    label: 'продавцов',
    labelMobile: 'Продавец',
    alertElement: { add: 'Продавец добавлен\nв список', remove: 'Продавец удален\nиз списка' },
  },
  [LIST_TYPE.BY_PRODUCTS]: {
    id: LIST_TYPE.BY_PRODUCTS,
    label: 'товаров',
    labelMobile: 'Товары',
    alertElement: { add: 'Товар добавлен\nв список', remove: 'Товар удален\nиз списка' },
  },
};

import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * @typedef IProps
 *
 * @property {import("react").ReactNode} children
 * @property {string | undefined} [classes] Опциональный параметр, если передан - будет добавлен к списку классов
 * */
/**
 * @returns {JSX.Element} Компонент-всплывашка, который отрисует переданный ему children
 *
 * @param {IProps & import("react").HTMLAttributes<HTMLDivElement>} props
 * */
export function PopoverV2({ children, classes, style, ...htmlAttributes }) {
  return (
    <div
      style={style}
      className={classNames(
        `
        p-3 absolute z-10000
        rounded-lg bg-white shadow-md shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.1)]
        `,
        {
          [classes]: classes,
        },
      )}
      {...htmlAttributes}
    >
      {children}
    </div>
  );
}
PopoverV2.defaultTypes = {
  style: {},
};
PopoverV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    ),
  ]),
  classes: PropTypes.string,
  style: PropTypes.object,
};

const TAB_FIRST = 'requests';
const TAB_SECOND = 'words';
const BY_PRODUCTS = 'products';
const BY_PHRASES = 'phrases';
const BY_HINTS = 'hints';
const NESTED = 'nested';
const SEO_COMPARE = 'seo_compare';
const SEO_MONITORING_PRODUCTS = 'seo_monitoring_products';
const SEO_MONITORING_PHRASES = 'seo_monitoring_phrases';
const SEO_BY_PHRASE = 'seo_by_phrase';
const PAGE_SEO_COMPARE = '/seo/compare';
const PAGE_SEO_MONITORING = '/seo/monitoring';
const PAGE_SEO_BY_PHRASE = '/seo/by-phrase';
const PAGE_SEO_HINTS = '/seo/hints';
const PAGE_SEO_SMART = '/seo/smart';
const PAGE_SEO_SMART_DESCRIPTION = '/seo/smart-description';
const SEO_HINTS = 'seo_hints';
const SEO_SMART = 'seo_smart';
const SEO_SMART_DESCRIPTION_WORDS = 'seo_smart_description_words';

export {
  TAB_FIRST,
  TAB_SECOND,
  BY_PRODUCTS,
  BY_PHRASES,
  BY_HINTS,
  NESTED,
  PAGE_SEO_COMPARE,
  SEO_COMPARE,
  PAGE_SEO_MONITORING,
  SEO_MONITORING_PRODUCTS,
  SEO_MONITORING_PHRASES,
  PAGE_SEO_BY_PHRASE,
  SEO_BY_PHRASE,
  PAGE_SEO_HINTS,
  SEO_HINTS,
  PAGE_SEO_SMART,
  SEO_SMART,
  PAGE_SEO_SMART_DESCRIPTION,
  SEO_SMART_DESCRIPTION_WORDS,
};

import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredData } from 'hooks/useFetchFilteredData';
import ListAlerts from 'components/ListAlerts';
import HelpBlock from 'components/HelpBlock';

const Categories = () => {
  const { isOzonPlatform, isFilterApplied } = useFilterReducer();

  const { data, isFetching, isLoading, /*isFetched,*/ isRefetching } = useFetchFilteredData({
    type: TABLE_TYPE.CATEGORIES,
    depth: isFilterApplied ? 0 : 1,
  });
  return (
    <>
      <HelpBlock type="categories" />
      {/*{isFetched && (*/}
      <Table
        data={data || []}
        type={TABLE_TYPE.CATEGORIES}
        categoriesData={data}
        hideSoldColumn={isOzonPlatform}
        isLoading={isLoading || isFetching || isRefetching}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      {/*)}*/}
      <ListAlerts />
    </>
  );
};

export default Categories;

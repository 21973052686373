const BookmarkAdd = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M16.3333 10.8332V16.4933C16.3333 16.823 15.9686 17.0221 15.6913 16.8438L10.7253 13.6514C10.5881 13.5631 10.4119 13.5631 10.2747 13.6514L5.30865 16.8438C5.03135 17.0221 4.66666 16.823 4.66666 16.4933V5.17318C4.66666 4.02258 5.5994 3.08984 6.75 3.08984H9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.2876 4.33516V1.6377M15.2876 4.33516H18.0248M15.2876 4.33516V6.94778M15.2876 4.33516H12.6439"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export { BookmarkAdd };

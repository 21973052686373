import moment from 'moment';
import 'moment/locale/ru';
import PropTypes from 'prop-types';

import { transformDate } from 'helpers/utils';

import CardList from 'components/CardList';
import { COLUMNS } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useProduct } from 'context/product.context';
import { declOfNum } from 'utils/client';

moment.locale('ru');

const ProductCardList = ({ isLoading }) => {
  const { filter } = useFilterReducer();

  const { productData } = useProduct();

  const data = { ...productData };

  const daysInStock = data?.[COLUMNS.DAYS_IN_STOCK] || '-';
  const daysInPeriod = data?.[COLUMNS.DAYS_IN_PERIOD] || '-';
  const daysWithOrders = data?.[COLUMNS.DAYS_WITH_ORDERS] || '-';

  const valueFirst =
    daysInStock === '-'
      ? '-'
      : `${daysInStock}&nbsp;${declOfNum(daysInStock, [
          'день',
          'дня',
          'дней',
        ])}&nbsp;из&nbsp;${daysInPeriod}`;
  const valueSecond =
    daysWithOrders === '-'
      ? '-'
      : `${daysWithOrders}&nbsp${declOfNum(daysWithOrders, ['день', 'дня', 'дней'])}`;
  const valueThird = !data?.['days_with_orders_percent'] ? '-' : data?.['days_with_orders_percent'];

  return (
    <CardList
      data={data || {}}
      isLoading={isLoading}
      width={'100%'}
      colClassName="w-1/5"
      columns={[
        // column first
        [
          {
            title: 'Заказы',
            labels: ['Руб', 'Шт'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'За&nbsp;30&nbsp;дней',
                    key: COLUMNS.MONTH_ORDERS_RUB,
                    type: 'currency',
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: COLUMNS.WEEK_ORDERS_RUB,
                    type: 'currency',
                  },
                  {
                    label: transformDate(filter.date),
                    key: 'orders_rub',
                    highlighted: true,
                    type: 'currency',
                    shortcut: true,
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'За&nbsp;30&nbsp;дней',
                    key: COLUMNS.MONTH_ORDERS_QTY,
                    type: 'amount',
                  },
                  {
                    label: 'За&nbsp;7&nbsp;дней',
                    key: COLUMNS.WEEK_ORDERS_QTY,
                    type: 'amount',
                  },
                  {
                    label: transformDate(filter.date),
                    key: 'orders_qty',
                    highlighted: true,
                    shortcut: true,
                    type: 'amount',
                  },
                ],
              },
            ],
          },
        ],
        // column second
        [
          {
            tabs: [
              {
                coloredBack: [
                  {
                    label: 'В&nbsp;наличии',
                    value: valueFirst,
                  },
                  {
                    label: 'С&nbsp;заказами',
                    value: valueSecond,
                    color: 'green',
                  },
                  {
                    label: 'Доля&nbsp;дней&nbsp;с&nbsp;заказами',
                    value: valueThird,
                    percent: valueThird !== '-',
                  },
                ],
              },
            ],
          },
        ],
        // column third
        [
          {
            title: 'Возможности',
            labels: ['Руб', 'Шт', '%'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'Упущенные&nbsp;заказы',
                    key: 'lost_orders_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText:
                      'Среднее кол-во заказов за дни когда товар был в наличии * кол-во дней, когда товар не был в наличии',
                  },
                  {
                    label: 'Потенциал',
                    key: COLUMNS.POTENTIAL_RUB,
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Упущенные заказы + заказы',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Упущенные&nbsp;заказы',
                    key: COLUMNS.LOST_ORDERS_QTY,
                    type: 'amount',
                  },
                  {
                    label: 'Потенциал',
                    key: COLUMNS.POTENTIAL_QTY,
                    type: 'amount',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'Упущенные&nbsp;заказы',
                    key: 'lost_orders_percent',
                    type: 'percent',
                  },
                ],
              },
            ],
          },
        ],
        // column fourth
        [
          {
            title: 'В среднем заказов',
            labels: ['Руб', 'Шт'],
            shortcut: true,
            tabs: [
              {
                info: [
                  {
                    label: 'В&nbsp;сутки',
                    key: 'avg_orders_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Сумма заказов / дней в периоде',
                  },
                  {
                    label: 'Когда&nbsp;в&nbsp;наличии',
                    key: 'avg_orders_if_in_stock_rub',
                    type: 'currency',
                    isQuestion: true,
                    questionText: 'Сумма заказов / дней когда товар был в наличии',
                  },
                ],
              },
              {
                info: [
                  {
                    label: 'В&nbsp;сутки',
                    key: 'avg_orders_qty',
                    type: 'amount',
                  },
                  {
                    label: 'Когда&nbsp;в&nbsp;наличии',
                    key: 'avg_orders_if_in_stock_qty',
                    type: 'amount',
                  },
                ],
              },
            ],
          },
        ],
        // column fifth
        [
          {
            title: 'Качество контента',
            tabs: [
              {
                info: [
                  // {
                  //   label: 'Слов&nbsp;в&nbsp;описании',
                  //   key: 'words_qty',
                  // },
                  {
                    label: 'Фотографий',
                    key: 'picture_qty',
                  },
                  {
                    label: 'Характеристик',
                    key: COLUMNS.SPECIFICATION,
                  },
                  {
                    label: 'Видео',
                    key: COLUMNS.VIDEO,
                    type: 'boolean',
                  },
                ],
              },
            ],
          },
        ],
      ]}
    />
  );
};

ProductCardList.propTypes = {
  salesError: PropTypes.string,
  salesData: PropTypes.shape({
    avg_counts: PropTypes.shape({}),
  }),
  salesLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ProductCardList;

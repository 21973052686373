import { useState } from 'react';

import { useUserList } from 'context/user.list.context';

export const useFavourite = (by, id) => {
  const { getListByType, addFavourite, removeFavourite } = useUserList();

  const [isLoading, setIsLoading] = useState(false);

  const listData = getListByType(by);

  const selected = listData
    ? listData.filter(({ items }) => items.map(({ id }) => id).includes(+id))
    : [];

  const handleFavourite = (listId, action) => {
    setIsLoading(true);
    if (action === 'remove') {
      removeFavourite({ id, listId, type: by, callback: () => setIsLoading(false) });
    } else {
      addFavourite({ id, listId, type: by, callback: () => setIsLoading(false) });
    }
  };

  return { listData, selected, handleFavourite, isLoading: isLoading };
};

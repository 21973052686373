import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { getPaymentLink, getTariffPrice } from 'context/profile.methods';
import { useAuth } from 'context/auth.context';
import { useTariffs } from 'context/tariffs.context';
import PaymentModal from 'components/PaymentModal';
import { useFetchProfileData } from 'hooks/useFetchProfileData';
import { generatePaymentsRequestOptions } from 'utils/payment';
import { PAGE_SUCCESS_PAYMENT_UL } from 'constants/pages';
import { ACTION_TYPE } from 'constants/profile';
import Arrow from 'assets/icons/Arrow';
import { isProfilePage } from 'utils/pages';
import TariffsModal from 'components/TariffsModal';
import { PROMO_CODE_MODAL, useUI } from 'context/ui.context';
import ExtTariffModal from '../components/ExtTariffModal';

export const PAYMENT_FIELDS_NAMES = {
  TARIFF: 'types',
  PLATFORM: 'platforms',
  PERIOD: 'periods',
};

const TRIAL_TARIFF_ID = 7;

const PAYMENT_AGREE_KEY = 'agree';

const PAYMENT_REQUEST_FIELDS = [
  {
    name: PAYMENT_FIELDS_NAMES.TARIFF,
    label: 'Выберите тариф',
    tariffsButtonShown: true,
  },
  // {
  //   name: PAYMENT_FIELDS_NAMES.PLATFORM,
  //   label: 'Выберите платформу',
  // },
  {
    name: PAYMENT_FIELDS_NAMES.PERIOD,
    label: 'Укажите период',
  },
];

const BUSINESSMAN_INFO_FIELDS_UL = [
  {
    name: 'phone_number',
    label: 'Телефон',
    isRequired: true,
  },
  {
    name: 'inn',
    label: 'ИНН',
    isRequired: true,
  },
  {
    name: 'organisationName',
    label: 'Полное название организации',
  },
  {
    name: 'legalAddress',
    label: 'Юридический адрес',
  },
  {
    name: 'actualAddress',
    label: 'Фактический адрес',
  },
  {
    name: 'kpp',
    label: 'КПП',
  },
  {
    name: 'ogrn',
    label: 'ОГРН',
  },
  {
    name: 'pc',
    label: 'Р/С',
  },
  {
    name: 'bik',
    label: 'БИК банка',
  },
];

const BUSINESSMAN_INFO_FIELDS_IP = [
  ...BUSINESSMAN_INFO_FIELDS_UL.filter((item) => item.name !== 'ogrn' && item.name !== 'kpp'),
  {
    name: 'ogrn',
    label: 'ОГРНИП',
  },
];

const PAYMENT_TYPES = {
  BY_CARD: 'BY_CARD',
  BY_BUSINESS: 'BY_BUSINESS',
};

const PAYMENT_TYPES_IDS = {
  BY_CARD: 1,
  BY_BUSINESS: 2,
};

const BUSINESS_TYPE = {
  UL: 'UL',
  IP: 'IP',
};

const ACTIONS = {
  PAYMENT_BY_CARD: 'PAYMENT_BY_CARD',
  PAYMENT_BY_BUSINESS: 'PAYMENT_BY_BUSINESS',
  CHANGE_BUSINESS_FIELD: 'CHANGE_BUSINESS_FIELD',
  CLEAR_BUSINESS_INFO: 'CLEAR_BUSINESS_INFO',
  HIDE_BUSINESS_INFO: 'HIDE_BUSINESS_INFO',
  BUSINESS_BY_IP: 'BUSINESS_BY_IP',
  BUSINESS_BY_UL: 'BUSINESS_BY_UL',
  CHANGE_PAYMENT_REQUEST: 'CHANGE_PAYMENT_REQUEST',
  CHANGE_PAYMENT_REQUEST_TARIFF_PERIOD: 'CHANGE_PAYMENT_REQUEST_TARIFF_PERIOD',
  SET_PAYMENT_REQUEST_INITIAL: 'SET_PAYMENT_REQUEST_INITIAL',
  CHANGE_ARBITRARY_AMOUNT: 'CHANGE_ARBITRARY_AMOUNT',
  TOGGLE_ARBITRARY_AMOUNT: 'TOGGLE_ARBITRARY_AMOUNT',
  CHANGE_PROMO_CODE: 'CHANGE_PROMO_CODE',
  DROP_PROMO_CODE: 'DROP_PROMO_CODE',
  UPDATE_PROMO_CODE: 'UPDATE_PROMO_CODE',
  SHOW_PROMO_FIELD: 'SHOW_PROMO_FIELD',
  HIDE_PROMO_FIELD: 'HIDE_PROMO_FIELD',
  TOGGLE_AGREE_WITH_CONTRACT: 'TOGGLE_AGREE_WITH_CONTRACT',
  CHANGE_PAYMENT_AMOUNT: 'CHANGE_PAYMENT_AMOUNT',
  REMOVE_ERROR_BY_KEY: 'REMOVE_ERROR_BY_KEY',
  ADD_ERROR: 'ADD_ERROR',
  ADD_PAYMENT_ERROR: 'ADD_PAYMENT_ERROR',
  REMOVE_PAYMENT_ERROR: 'REMOVE_PAYMENT_ERROR',
  SET_LOADING: 'SET_LOADING',
};

const BUSINESS_INFO_INITIAL = BUSINESSMAN_INFO_FIELDS_UL.reduce((acc, field) => {
  acc[field.name] = '';
  return acc;
}, {});

const PAYMENT_REQUEST_PARAMS = PAYMENT_REQUEST_FIELDS.reduce((acc, field) => {
  acc[field.name] = '';
  return acc;
}, {});

const initialPaymentModal = {
  businessInfo: BUSINESS_INFO_INITIAL,
  businessType: BUSINESS_TYPE.UL,
  isBusinessInfoShown: false,
  paymentType: PAYMENT_TYPES.BY_CARD,
  paymentTypeId: PAYMENT_TYPES_IDS.BY_CARD,
  paymentRequestParams: PAYMENT_REQUEST_PARAMS,
  arbitraryAmount: '',
  isArbitraryAmountShown: false,
  promoCode: '',
  promoCodePreview: '',
  isPromoCodeValid: false,
  isPromoFieldShown: false,
  agreeWithContract: false,
  paymentAmount: null,
  errors: [],
  paymentsRequestOptions: [],
  paymentErrors: {},
  isLoading: false,
  promoCodeMessage: '',
  discount: {
    days: null,
    percent: null,
    rub: null,
    fullPrice: null,
  },
};

const getPromoCodeMessage = (status, promoCode) => {
  if (!promoCode) return '';

  // валидный промокод
  if (status === 3) {
    return '';
  }

  if (status === 0) {
    return `Промокода ${promoCode} нет`;
  }

  if (status === 1) {
    return `Промокода ${promoCode} уже применён`;
  }

  if (status === 2) {
    return 'Промокод не существует (из-за приватности промокода)';
  }

  if (status === 4) {
    return 'Недоступный тариф';
  }

  if (status === 5) {
    return 'Недоступный период, то выводим';
  }

  return 'Ошибка промокода';
};

const paymentModalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.PAYMENT_BY_CARD: {
      return {
        ...state,
        paymentType: PAYMENT_TYPES.BY_CARD,
        paymentTypeId: PAYMENT_TYPES_IDS.BY_CARD,
        isBusinessInfoShown: false,
      };
    }
    case ACTIONS.PAYMENT_BY_BUSINESS: {
      return {
        ...state,
        paymentType: PAYMENT_TYPES.BY_BUSINESS,
        paymentTypeId: PAYMENT_TYPES_IDS.BY_BUSINESS,
        isBusinessInfoShown: true,
      };
    }
    case ACTIONS.BUSINESS_BY_UL: {
      return {
        ...state,
        businessType: BUSINESS_TYPE.UL,
      };
    }
    case ACTIONS.BUSINESS_BY_IP: {
      return {
        ...state,
        businessType: BUSINESS_TYPE.IP,
      };
    }
    case ACTIONS.CHANGE_BUSINESS_FIELD: {
      return {
        ...state,
        businessInfo: {
          ...state.businessInfo,
          ...(action.field && {
            [action.field]: action.value || '',
          }),
        },
      };
    }
    case ACTIONS.CLEAR_BUSINESS_INFO: {
      return {
        ...state,
        businessInfo: BUSINESS_INFO_INITIAL,
      };
    }
    case ACTIONS.CHANGE_PAYMENT_REQUEST: {
      return {
        ...state,
        paymentRequestParams: {
          ...state.paymentRequestParams,
          ...(action.field &&
            action.value && {
              [action.field]: action.value,
            }),
          ...(action.additional &&
            action.additional.field &&
            action.additional.value && {
              [action.additional.field]: action.additional.value,
            }),
        },
      };
    }
    case ACTIONS.CHANGE_PAYMENT_REQUEST_TARIFF_PERIOD: {
      return {
        ...state,
        paymentRequestParams: {
          ...state.paymentRequestParams,
          [PAYMENT_FIELDS_NAMES.TARIFF]: action.tariff,
          [PAYMENT_FIELDS_NAMES.PERIOD]: action.period,
        },
      };
    }
    case ACTIONS.SET_PAYMENT_REQUEST_INITIAL: {
      return {
        ...state,
        paymentsRequestOptions: action.paymentsRequestOptions,
        paymentRequestParams: {
          ...state.paymentRequestParams,
          ...action.values,
        },
      };
    }
    case ACTIONS.HIDE_BUSINESS_INFO: {
      return {
        ...state,
        isBusinessInfoShown: false,
        paymentType: PAYMENT_TYPES.BY_CARD,
      };
    }
    case ACTIONS.CHANGE_ARBITRARY_AMOUNT: {
      return {
        ...state,
        arbitraryAmount: action.value || '',
      };
    }
    case ACTIONS.TOGGLE_ARBITRARY_AMOUNT: {
      return {
        ...state,
        isArbitraryAmountShown: !state.isArbitraryAmountShown,
        arbitraryAmount: '',
      };
    }
    case ACTIONS.CHANGE_PROMO_CODE: {
      return {
        ...state,
        promoCodePreview: action.value || '',
        isPromoFieldShown: true,
      };
    }
    case ACTIONS.DROP_PROMO_CODE: {
      return {
        ...state,
        promoCode: '',
        promoCodePreview: '',
        isPromoFieldShown: false,
      };
    }
    case ACTIONS.UPDATE_PROMO_CODE: {
      return {
        ...state,
        promoCode: state.promoCodePreview || '',
        isPromoFieldShown: false,
      };
    }
    case ACTIONS.SHOW_PROMO_FIELD: {
      return {
        ...state,
        isPromoFieldShown: true,
      };
    }
    case ACTIONS.HIDE_PROMO_FIELD: {
      return {
        ...state,
        isPromoFieldShown: false,
        promoCodePreview: '',
      };
    }
    case ACTIONS.TOGGLE_AGREE_WITH_CONTRACT: {
      return {
        ...state,
        agreeWithContract: !state.agreeWithContract,
        paymentErrors: !state.agreeWithContract
          ? Object.keys(state.paymentErrors)
              .filter((key) => key !== PAYMENT_AGREE_KEY)
              .reduce((obj, key) => {
                obj[key] = state.paymentErrors[key];
                return obj;
              }, {})
          : state.paymentErrors,
      };
    }
    case ACTIONS.CHANGE_PAYMENT_AMOUNT: {
      return {
        ...state,
        paymentAmount: action.value || action.value === 0 ? action.value : null,
        isPromoCodeValid: action.promoCodeStatus?.success || state.isPromoCodeValid,
        promoCodeMessage: getPromoCodeMessage(action.promoCodeStatus?.status, state.promoCode),
        discount: {
          days: action.promoCodeStatus?.free_days || null,
          percent: action.discountPercent,
          rub: action.discountRub,
          fullPrice: action.fullPrice,
        },
        isLoading: false,
        errors: action.message
          ? [
              ...state.errors,
              { key: `error-${++state.errors.length}`, message: action.message, type: 'error' },
            ]
          : state.errors,
      };
    }
    case ACTIONS.ADD_ERROR: {
      // error without .filter(error => !!error) ?????
      const newError = action.error
        ? [
            ...state.errors.filter((error) => !!error),
            { key: `error-${++state.errors.length}`, message: action.error, type: 'error' },
          ]
        : state.errors;

      return {
        ...state,
        errors: newError,
      };
    }
    case ACTIONS.REMOVE_ERROR_BY_KEY: {
      return {
        ...state,
        errors: state.errors.filter((error) => error.key !== action.key),
      };
    }
    case ACTIONS.ADD_PAYMENT_ERROR: {
      return {
        ...state,
        paymentErrors: { ...state.paymentErrors, ...action.error },
      };
    }
    case ACTIONS.REMOVE_PAYMENT_ERROR: {
      return {
        ...state,
        paymentErrors: Object.keys(state.paymentErrors)
          .filter((key) => key !== action.key)
          .reduce((obj, key) => {
            obj[key] = state.paymentErrors[key];
            return obj;
          }, {}),
      };
    }
    case ACTIONS.SET_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading ?? false,
      };
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
};

const PaymentContext = React.createContext();
PaymentContext.displayName = 'MobilePayment.Context';

const PaymentProvider = (props) => {
  const { user } = useAuth();
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [isShowConsulting, setIsShowConsulting] = useState(false);
  const [isConsultLoading, setIsConsultLoading] = useState(false);
  const isProfile = isProfilePage();
  const { setActiveModal, activeModal } = useUI();
  const [errors, setErrors] = useState([]);

  const history = useHistory();
  const { data: tariffs } = useFetchProfileData({
    type: ACTION_TYPE.TARIFFS_LIST,
    enabled: isProfile,
  });

  const [state, dispatch] = useReducer(paymentModalReducer, initialPaymentModal);

  const getFullPeriod = useCallback(() => {
    if (
      !state.paymentsRequestOptions ||
      !state.paymentsRequestOptions?.[PAYMENT_FIELDS_NAMES.PERIOD]
    ) {
      return null;
    }

    return state.paymentsRequestOptions?.[PAYMENT_FIELDS_NAMES.PERIOD]?.find(
      (item) => item.value === parseInt(state.paymentRequestParams[PAYMENT_FIELDS_NAMES.PERIOD]),
    );
  }, [state.paymentsRequestOptions, state.paymentRequestParams]);

  const getTariffPeriods = useCallback(() => {
    if (
      !state.paymentsRequestOptions ||
      !state.paymentsRequestOptions?.[PAYMENT_FIELDS_NAMES.TARIFF]
    ) {
      return null;
    }

    return state.paymentsRequestOptions?.[PAYMENT_FIELDS_NAMES.TARIFF]?.find(
      (item) => item.value === parseInt(state.paymentRequestParams[PAYMENT_FIELDS_NAMES.TARIFF]),
    )?.periods;
  }, [state.paymentsRequestOptions, state.paymentRequestParams]);

  const getPaymentInfo = useCallback(() => {
    const fullPeriod = getFullPeriod();

    const platform = state.paymentRequestParams[PAYMENT_FIELDS_NAMES.PLATFORM];
    const period = state.paymentRequestParams[PAYMENT_FIELDS_NAMES.PERIOD];

    if (!platform || !period) return null;

    return {
      tariff: state.paymentRequestParams[PAYMENT_FIELDS_NAMES.TARIFF],
      period,
      platform,
      promoCode: state.promoCode,
      quantile: fullPeriod?.quantile || 'month',
    };
  }, [getFullPeriod, state.paymentRequestParams, state.promoCode]);

  // DISPATCH
  const changeBusinessField = (field) => (e) => {
    const resultValue = field === 'phone_number' ? handlePhoneChange(e) : e.target.value;
    const index = errors.indexOf(field);
    if (index !== -1) {
      const resultErrors = [...errors];
      resultErrors.splice(index, 1);
      setErrors(resultErrors);
    }
    dispatch({
      type: ACTIONS.CHANGE_BUSINESS_FIELD,
      field,
      value: resultValue,
    });
  };

  const clearBusinessInfo = () => {
    dispatch({
      type: ACTIONS.CLEAR_BUSINESS_INFO,
    });
  };

  const hideBusinessInfo = () => {
    dispatch({
      type: ACTIONS.HIDE_BUSINESS_INFO,
    });
  };

  const setPaymentTypeByCard = () => {
    dispatch({
      type: ACTIONS.PAYMENT_BY_CARD,
    });
    clearBusinessInfo();
  };

  const setPaymentTypeByBusiness = () => {
    dispatch({
      type: ACTIONS.PAYMENT_BY_BUSINESS,
    });
  };

  const setBusinessByUl = () => {
    dispatch({
      type: ACTIONS.BUSINESS_BY_UL,
    });
  };

  const setBusinessByIP = () => {
    dispatch({
      type: ACTIONS.BUSINESS_BY_IP,
    });
  };

  const setLoading = () => {
    dispatch({
      type: ACTIONS.SET_LOADING,
      isLoading: true,
    });
  };

  const isBusinessPayment = state.paymentType === PAYMENT_TYPES.BY_BUSINESS;

  const changePaymentAmount = useCallback(() => {
    const paymentInfo = getPaymentInfo();

    // activeModal !== PROMO_CODE_MODAL временный костыль для мобильной версии
    if (!user || !paymentInfo || (!isOpenPaymentModal && activeModal !== PROMO_CODE_MODAL)) return;

    setLoading();

    getTariffPrice(paymentInfo)
      .then(
        ({
          price,
          message = null,
          promocode_status,
          discount_in_percent = null,
          discount_in_rub = null,
          old_price = null,
        }) => {
          dispatch({
            type: ACTIONS.CHANGE_PAYMENT_AMOUNT,
            value: price,
            error: message,
            promoCodeStatus: promocode_status,
            discountPercent: discount_in_percent,
            discountRub: discount_in_rub,
            fullPrice: old_price,
          });
        },
      )
      .catch((e) => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getPaymentInfo]);

  const checkUserAgreement = () => {
    if (!state.agreeWithContract) {
      dispatch({
        type: ACTIONS.ADD_PAYMENT_ERROR,
        error: { [PAYMENT_AGREE_KEY]: false },
      });
    } else {
      dispatch({
        type: ACTIONS.REMOVE_PAYMENT_ERROR,
        key: PAYMENT_AGREE_KEY,
      });
    }

    return state.agreeWithContract;
  };

  const handlePayment = () => {
    const paymentInfo = getPaymentInfo();

    const isUserAgree = checkUserAgreement();

    if (!user || !paymentInfo || !isUserAgree) return;

    if (!state.isPromoCodeValid && paymentInfo.promoCode) {
      paymentInfo.promoCode = null;
    }
    const businessObj = { ...state.businessInfo };
    if (isBusinessPayment) {
      const buffErrors = [];
      if (!businessObj['inn']) {
        buffErrors.push('inn');
      }
      if (!businessObj['phone_number'] || businessObj['phone_number']?.length < 16) {
        buffErrors.push('phone_number');
      }
      if (buffErrors.length !== 0) {
        setErrors(buffErrors);
        return;
      }
      let resultNumber = businessObj['phone_number']?.replaceAll('-', '') || '';
      resultNumber = resultNumber.replaceAll(')', '');
      resultNumber = resultNumber.replaceAll('(', '');
      businessObj['phone_number'] = resultNumber;
    }
    getPaymentLink({
      info: paymentInfo,
      businessmanInfo: isBusinessPayment ? businessObj : {},
      paymentTypeId: state.paymentTypeId,
      arbitraryAmount: state.arbitraryAmount,
    })
      .then((/*{ data }*/) => {
        /* if (isBusinessPayment) {*/
        handleClosePayment();
        history.push(PAGE_SUCCESS_PAYMENT_UL);
        /*  return;
        }

        if (data.redirect_url) {
          window.location = data.redirect_url;
        }

        if (data.message && !data.redirect_url) {
          dispatch({
            type: ACTIONS.ADD_ERROR,
            error: data.message,
          });
        }*/
      })
      .catch((e) => {
        console.error(e);

        dispatch({
          type: ACTIONS.ADD_ERROR,
          error: 'Что-то пошло не так, попробуйте позже',
        });
      });
  };

  const changePaymentRequest = (field) => (e) => {
    const value = +e?.target?.value;

    let additional = {};
    if (field === PAYMENT_FIELDS_NAMES.TARIFF) {
      const possiblePeriods = state.paymentsRequestOptions?.[PAYMENT_FIELDS_NAMES.TARIFF]?.find(
        (item) => +item.value === +value,
      )?.periods;
      const currentPeriodValue = state.paymentRequestParams[PAYMENT_FIELDS_NAMES.PERIOD];

      if (!possiblePeriods?.find((period) => +period.value === +currentPeriodValue)) {
        additional = {
          field: PAYMENT_FIELDS_NAMES.PERIOD,
          value: +possiblePeriods?.[0]?.value,
        };
      }
    }

    dispatch({
      type: ACTIONS.CHANGE_PAYMENT_REQUEST,
      field,
      value,
      additional,
    });
  };

  const changePaymentRequestTariffPeriod = ({ tariff, period }) => {
    dispatch({
      type: ACTIONS.CHANGE_PAYMENT_REQUEST_TARIFF_PERIOD,
      tariff,
      period,
    });
  };

  const setPaymentRequestInitial = useCallback((data) => {
    const transformedData = generatePaymentsRequestOptions(data, TRIAL_TARIFF_ID);

    dispatch({
      type: ACTIONS.SET_PAYMENT_REQUEST_INITIAL,
      paymentsRequestOptions: transformedData,
      values: {
        [PAYMENT_FIELDS_NAMES.TARIFF]: transformedData?.[PAYMENT_FIELDS_NAMES.TARIFF]?.[0]?.value,
        [PAYMENT_FIELDS_NAMES.PERIOD]: transformedData?.[PAYMENT_FIELDS_NAMES.PERIOD]?.[0]?.value,
        [PAYMENT_FIELDS_NAMES.PLATFORM]: transformedData?.[PAYMENT_FIELDS_NAMES.PLATFORM],
      },
    });
  }, []);

  const changeArbitraryAmount = (e) => {
    dispatch({
      type: ACTIONS.CHANGE_ARBITRARY_AMOUNT,
      value: e?.target?.value,
    });
  };

  const toggleArbitraryAmount = () => {
    dispatch({
      type: ACTIONS.TOGGLE_ARBITRARY_AMOUNT,
    });
  };

  const changePromoCode = (e) => {
    dispatch({
      type: ACTIONS.CHANGE_PROMO_CODE,
      value: e?.target?.value,
    });
  };

  const dropPromoCode = () => {
    dispatch({
      type: ACTIONS.DROP_PROMO_CODE,
    });
  };

  const updatePromoCode = () => {
    dispatch({
      type: ACTIONS.UPDATE_PROMO_CODE,
    });
  };

  const showPromoField = () => {
    dispatch({
      type: ACTIONS.SHOW_PROMO_FIELD,
    });
  };

  const hidePromoField = () => {
    dispatch({
      type: ACTIONS.HIDE_PROMO_FIELD,
    });
  };

  const toggleAgreeWithContract = () => {
    dispatch({
      type: ACTIONS.TOGGLE_AGREE_WITH_CONTRACT,
    });
  };

  const removeErrorByKey = (key) => {
    dispatch({
      type: ACTIONS.REMOVE_ERROR_BY_KEY,
      key,
    });
  };
  // DISPATCH END

  // OPTIONS AND INPUTS
  const PAYMENT_OPTIONS = [
    {
      checked: state.paymentType === PAYMENT_TYPES.BY_CARD,
      value: PAYMENT_TYPES.BY_CARD,
      title: 'Безопасная оплата на сайте',
      action: setPaymentTypeByCard,
    },
    {
      checked: state.paymentType === PAYMENT_TYPES.BY_BUSINESS,
      value: PAYMENT_TYPES.BY_BUSINESS,
      title: 'Счет для оплаты юр. лицом или ИП',
      action: setPaymentTypeByBusiness,
    },
  ];
  const handlePhoneChange = (e) => {
    let content = e.target.value;
    if (!content) return '';
    content = Array.from(content).filter((ltr) => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58);
    switch (content[0]) {
      case '8':
        content[0] = '7';
        break;
      case '9':
        content.unshift('7');
        break;
      default:
        break;
    }

    let [countryCode, operatorCode, number3, number21, number22] = [
      content[0],
      content.slice(1, 4).join(''),
      content.slice(4, 7).join(''),
      content.slice(7, 9).join(''),
      content.slice(9, 11).join(''),
    ];

    e.target.value = countryCode?.length ? `+${countryCode}` : '';
    if (operatorCode.length) e.target.value += `(${operatorCode}`;
    if (number3.length) e.target.value += `)${number3}`;
    if (number21.length) e.target.value += `-${number21}`;
    if (number22.length) e.target.value += `-${number22}`;
    return e.target.value;
  };
  const BUSINESS_INFO_INPUTS_UL = BUSINESSMAN_INFO_FIELDS_UL.map((field) => ({
    id: field.name,
    label: field.label,
    isRequired: !!field.isRequired,
    onChange: changeBusinessField(field.name),
    value: state.businessInfo[field.name],
  }));

  const BUSINESS_INFO_INPUTS_IP = BUSINESSMAN_INFO_FIELDS_IP.map((field) => ({
    id: field.name,
    label: field.label,
    isRequired: !!field.isRequired,
    onChange: changeBusinessField(field.name),
    value: state.businessInfo[field.name],
  }));

  const BUSINESS_TYPES = [
    {
      label: 'ЮЛ',
      onChange: setBusinessByUl,
      checked: state.businessType === BUSINESS_TYPE.UL,
      id: BUSINESS_TYPE.UL,
    },
    {
      label: 'ИП',
      onChange: setBusinessByIP,
      checked: state.businessType === BUSINESS_TYPE.IP,
      id: BUSINESS_TYPE.IP,
    },
  ];

  const PAYMENT_REQUEST_OPTIONS = useMemo(() => {
    if (!state.paymentsRequestOptions) {
      return {
        [PAYMENT_FIELDS_NAMES.PLATFORM]: [],
        [PAYMENT_FIELDS_NAMES.PERIOD]: [],
        [PAYMENT_FIELDS_NAMES.TARIFF]: [],
      };
    }

    const platformOptions = state.paymentsRequestOptions?.platforms;
    const tariffOptions = state.paymentsRequestOptions?.types;
    const periodOptions = state.paymentsRequestOptions?.periods;

    const tariffPeriods = getTariffPeriods();

    const periodValues = tariffPeriods && tariffPeriods.map((period) => period.value);
    const periodOptionsForTariff = periodOptions
      ?.filter((period) => periodValues.includes(period.value))
      ?.map((period) => ({
        ...period,
        price: tariffPeriods.find(({ value }) => value === period.value)?.price,
        percent: tariffPeriods.find(({ value }) => value === period.value)?.percent,
      }));

    return {
      [PAYMENT_FIELDS_NAMES.PLATFORM]: platformOptions,
      [PAYMENT_FIELDS_NAMES.PERIOD]: periodOptionsForTariff,
      [PAYMENT_FIELDS_NAMES.TARIFF]: tariffOptions,
    };
  }, [state.paymentsRequestOptions, getTariffPeriods]);

  // OPTIONS AND INPUTS END
  const { openTariffsModal, closeTariffsModal, activePeriod } = useTariffs();

  const handleOpenPaymentWithTariff = (id) => {
    dispatch({
      type: ACTIONS.CHANGE_PAYMENT_REQUEST,
      field: PAYMENT_FIELDS_NAMES.TARIFF,
      value: id ?? state.paymentRequestParams[PAYMENT_FIELDS_NAMES.TARIFF],
    });

    setIsOpenPaymentModal(true);
  };

  const handleOpenPayment = () => {
    setIsOpenPaymentModal(true);
  };

  const handleClosePayment = () => {
    setIsOpenPaymentModal(false);
    hidePromoField();
  };

  const selectedPeriod = state.paymentRequestParams.periods;
  const selectedTariff = state.paymentRequestParams.types;

  const handleOpenTariffsModalPayment = () => {
    handleClosePayment();
    openTariffsModal({
      tariff: selectedTariff,
      period: selectedPeriod,
    });
  };

  const onSelectTariff = (tariff) => {
    changePaymentRequestTariffPeriod({ tariff, period: activePeriod });
    closeTariffsModal();
    handleOpenPayment();
  };

  useEffect(() => {
    if (!tariffs) return;

    setPaymentRequestInitial(tariffs);
  }, [tariffs, setPaymentRequestInitial]);

  useEffect(() => {
    changePaymentAmount();
  }, [changePaymentAmount]);

  useEffect(() => {
    if (state.isPromoCodeValid) {
      hidePromoField();

      //для мобильной версии
      setActiveModal();
    }
  }, [state.isPromoCodeValid, setActiveModal]);

  const PAYMENT_REQUEST_INPUTS = PAYMENT_REQUEST_FIELDS.map((field) => {
    return {
      id: field.name,
      label: field.tariffsButtonShown ? (
        <>
          {field.label}
          {
            <button
              className="text-green ml-auto flex items-center focus:underline focus:outline-none"
              onClick={handleOpenTariffsModalPayment}
            >
              Сравнение тарифов <Arrow className="ml-2" />
            </button>
          }
        </>
      ) : (
        field.label
      ),
      onChange: changePaymentRequest(field.name),
      value: state.paymentRequestParams[field.name],
      options: PAYMENT_REQUEST_OPTIONS[field.name],
      tariffsButtonShown: field.tariffsButtonShown,
    };
  });

  const value = {
    arbitraryAmount: state.arbitraryAmount,
    isArbitraryAmountShown: state.isArbitraryAmountShown,
    toggleArbitraryAmount,
    changeArbitraryAmount,
    isBusinessInfoShown: state.isBusinessInfoShown,
    paymentOptions: PAYMENT_OPTIONS,
    businessInfoFields:
      state.businessType === BUSINESS_TYPE.UL ? BUSINESS_INFO_INPUTS_UL : BUSINESS_INFO_INPUTS_IP,
    businessTypeOptions: BUSINESS_TYPES,
    hideBusinessInfo,
    paymentRequestFields: PAYMENT_REQUEST_INPUTS,
    promoCode: state.promoCode,
    promoCodePreview: state.promoCodePreview,
    changePromoCode,
    updatePromoCode,
    dropPromoCode,
    isPromoFieldShown: state.isPromoFieldShown,
    showPromoField,
    hidePromoField,
    agreeWithContract: state.agreeWithContract,
    toggleAgreeWithContract,
    paymentAmount: state.paymentAmount,
    handlePayment,
    errors: state.errors,
    removeErrorByKey,
    changePaymentRequest,
    isUserAgree: state.paymentErrors?.agree === undefined || !!state.paymentErrors?.agree,
    openPaymentWithTariff: handleOpenPaymentWithTariff,
    openPayment: handleOpenPayment,
    closePayment: handleClosePayment,
    isOpenPayment: isOpenPaymentModal,
    isShowConsulting,
    setIsShowConsulting,
    isConsultLoading,
    setIsConsultLoading,
    periods: state.paymentsRequestOptions?.periods?.map((period) => period.value) || [],
    changePaymentRequestTariffPeriod,
    onSelectTariff,
    isLoading: state.isLoading,
    promoCodeMessage: state.promoCodeMessage,
    discount: state.discount,
    validationErrors: errors,
  };

  return (
    <PaymentContext.Provider value={value} {...props}>
      {props.children}
      <PaymentModal />
      <TariffsModal />
      <ExtTariffModal />
    </PaymentContext.Provider>
  );
};

const usePayment = () => {
  return React.useContext(PaymentContext);
};

PaymentProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export { PaymentProvider, usePayment };

import AdvertisingRatesGrid from 'components/AdvertisingRates/AdvertisingRatesGrid';
import AdvertisingRatesHeader from 'components/AdvertisingRates/AdvertisingRatesHeader';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { SHOW_MODES } from './constans';
import AdvertisingRatesTable from 'components/AdvertisingRates/AdvertisingRatesTable';

const AdvertisingRates = () => {
  const [searchValue, setSearchValue] = useState('');
  const [showMode, setShowMode] = useState(SHOW_MODES.GRID);
  const location = useLocation();
  const { search } = location;
  const history = useHistory();
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const searchParam = searchParams.get('search');
    setSearchValue(searchParam || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const handleSearchChange = (value) => {
    if (!value) {
      history.replace({ pathname: location.pathname });
    } else {
      const params = new URLSearchParams({ ['search']: value });
      history.replace({ pathname: location.pathname, search: params.toString() });
    }
    setSearchValue(value);
  };
  return (
    <main className="flex grow">
      <div className="grow bg-gray-50 w-screen">
        <AdvertisingRatesHeader
          searchValue={searchValue}
          setSearchValue={handleSearchChange}
          showMode={showMode}
          setShowMode={setShowMode}
        />
        {showMode === SHOW_MODES.GRID ? (
          <AdvertisingRatesGrid searchValue={searchValue} />
        ) : (
          <AdvertisingRatesTable searchValue={searchValue} />
        )}
      </div>
    </main>
  );
};

export default AdvertisingRates;

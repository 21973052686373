import React from 'react';
import { useLocation } from 'react-router-dom';

import ButtonPrimary from 'components/ButtonPrimary';
import { useAuth } from 'context/auth.context';

const ErrorAccess = () => {
  const location = useLocation();
  const { cleanToken } = useAuth();

  const reLogin = () => {
    cleanToken();
    window.location.assign(window.location);
  };

  return (
    <main role="alert" className="flex wrapper flex-auto flex flex-col items-center justify-center">
      <h1 className="mb-4">{location.state?.error?.message || 'Something went wrong :('}</h1>

      {location.state?.error?.showLoginButton && <ButtonPrimary onClick={reLogin} label="Войти" />}
    </main>
  );
};

export default ErrorAccess;

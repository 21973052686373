import { REFERRALS_URL_KEY } from 'constants/referrals';
import { setCookie } from 'hooks/useCookie';
import authClient from 'services/auth';
import apiClient from 'services/baseClient';

const LOCAL_STORAGE_AUTH_KEY = 'WECHECK_TOKEN';
const COOKIES_REFERRAL_KEY_TOKEN = 'WECHECK_COOKIES_REFERRAL_TOKEN';

const getToken = () => window.localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);

const setToken = (data) => {
  window.localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, data.access_token);
  return data.access_token;
};

const cleanToken = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
};

// credentials: {email, name, password}
const register = (credentials) => {
  let transformedCredentials = credentials;

  const searchParameters = new URLSearchParams(window.location.search);
  const objectedSearchParameters = Object.fromEntries(searchParameters);

  if (REFERRALS_URL_KEY in objectedSearchParameters) {
    const referredToID = objectedSearchParameters[REFERRALS_URL_KEY];

    transformedCredentials.referral = referredToID;

    setCookie(COOKIES_REFERRAL_KEY_TOKEN, referredToID, {
      // 30 дней в секундах
      'max-age': 2592000,
    });
  }

  return authClient.signup(transformedCredentials).then((res) => {
    return res.data;
  });
};

const login = ({ username, password, captcha }) =>
  authClient
    .login({ username, password, captcha })
    .then((res) => res.data)
    .then(setToken)
    .finally(() => {
      getToken();
    });

const recoverForEmail = (email) => {
  const token = getToken();

  return authClient.recoverForEmail(email, token);
};

const recoverPassword = (credentials) => {
  return authClient.recoverPassword(credentials).catch((error) => {
    throw new Error(error?.response?.data?.message || error.message);
  });
};

const logout = async () => {
  const token = getToken();
  await authClient.logout(token);
  cleanToken();
};

const verify = async (code) =>
  await authClient.verify(code).catch((error) => {
    throw new Error(error.message);
  });

const activateDemo = async ({ tariffId, token }) =>
  await apiClient.activateDemo(tariffId, token).catch((error) => {
    throw new Error(error.message);
  });

const getUser = async (token) =>
  authClient.getUser(token).catch((error) => {
    throw new Error(error.message);
  });

const sendMailConfirmation = async (email) =>
  authClient.sendMailConfirmation(email).catch((error) => {
    throw new Error(error.message);
  });

export {
  activateDemo,
  cleanToken,
  getToken,
  getUser,
  login,
  logout,
  recoverForEmail,
  recoverPassword,
  register,
  sendMailConfirmation,
  setToken,
  verify,
};

import PropTypes from 'prop-types';

import SearchIcon from 'assets/icons/SearchIcon';
import cn from 'classnames';

export const SearchInput = ({ value, onChange, placeholder, onBlur, className, onKeyDown }) => (
  <div
    className={cn('relative lg:mb-6', {
      [className]: className,
    })}
  >
    <span className="absolute top-[18px] lg:top-3 left-3 text-gray-300">
      <SearchIcon className="text-green lg:text-gray-300 w-4 h-4 lg:w-[3.5] lg:h-[3.5]" />
    </span>
    <input
      className={`w-full lg:rounded appearance-none lg:border lg:border-gray-300
      pl-10 lg:pl-8 pr-1.5 py-5 lg:py-0.5 lg:h-[40px]
      placeholder:text-gray-300 leading-none transition-all
      focus:outline-none focus:shadow-outline lg:focus:ring-2 lg:focus:ring-offset-2 lg:focus:ring-green
      border-b border-gray-150 focus:border-b-green`}
      type="text"
      value={value}
      onChange={onChange}
      autoFocus={true}
      placeholder={placeholder}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  </div>
);

SearchInput.defaultProps = {
  placeholder: '',
};
SearchInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
};

import { useParams } from 'react-router-dom';
import { useFetchFilteredInnerDataPaginated } from 'hooks/useFetchFilteredInnerData';
import { useState } from 'react';
import { TABLE_INNER_TYPE } from 'constants';
import OrdersStockToggler from 'pages/Product/OrdersStockToggler';
import ChartDoughnutWithTable from 'components/ChartDoughnutWithTable';
import PropTypes from 'prop-types';

const InnerProductSizes = ({ fetchEnabled }) => {
  const { id } = useParams();
  const [isOrdersData, setIsOrdersData] = useState(true);
  const { data: sizesData = {}, isLoading } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.PRODUCT_SIZES,
    data: {
      id,
    },
    enabled: fetchEnabled,
  });
  const data = sizesData?.pages?.[0] || [];

  const toggler = (
    <OrdersStockToggler isOrdersData={isOrdersData} setIsOrdersData={setIsOrdersData} />
  );
  const pieData = data.map((el, index) => ({
    index: index,
    name: el.warehouse,
    value: +el[isOrdersData ? `size_orders_rub` : `size_stock`],
  }));
  return (
    <>
      <ChartDoughnutWithTable
        tableData={data.map((el, index) => ({ ...el, index }))}
        pieData={pieData}
        key={TABLE_INNER_TYPE.PRODUCT_SIZES}
        postfix={isOrdersData ? '₽' : 'шт'}
        isLoading={isLoading}
        tableType={TABLE_INNER_TYPE.PRODUCT_SIZES}
        noRowText="Нет данных"
        toggler={toggler}
      />
    </>
  );
};

InnerProductSizes.propTypes = {
  fetchEnabled: PropTypes.bool,
};

export default InnerProductSizes;

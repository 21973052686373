import Table from 'components/Table';
import { COLUMNS, TABLE_INNER_TYPE, TABLE_TYPE } from 'constants/table';

import {
  useFetchFilteredInnerData,
  useFetchFilteredInnerDataPaginated,
} from 'hooks/useFetchFilteredInnerData';
import MultiChart, { formatDateLabel, formatXAxisDate } from 'components/MultiChart';
import { TYPE_ABSOLUTE } from 'constants';
import React from 'react';
import {
  BLUE,
  CHARTS_COLORS_WB,
  CONTESSA,
  GREEN,
  HELIOTROPE,
  HOT_PINK,
  LEAF,
  PINK,
  VIKING,
  YELLOW,
} from 'constants';
import { useUI } from 'context/ui.context';

const MOBILE_LABELS = {
  [COLUMNS.ORDERS_RUB_]: 'Заказы, руб.',
  ['sold_rub']: 'Продажи, руб.',
  ['returns_rub']: 'Возвраты, руб.',
  [COLUMNS.ORDERS_QTY_]: 'Заказы, шт.',
  ['sold_qty']: 'Продажи, шт.',
  ['returns_qty']: 'Возвраты, шт.',
  ['profit']: 'Прибыль',
  [COLUMNS.QUANTITY]: 'Остаток',
};

const LABELS = {
  [COLUMNS.ORDERS_RUB_]: 'Заказы, руб.',
  ['sold_rub']: 'Продажи, руб.',
  ['returns_rub']: 'Возвраты, руб.',
  ['profit']: 'Прибыль',
  [COLUMNS.QUANTITY]: 'Остаток',
  [COLUMNS.ORDERS_QTY_]: 'Заказы, шт.',
  ['sold_qty']: 'Продажи, шт.',
  ['returns_qty']: 'Возвраты, шт.',
};

const INIT_LABELS = [COLUMNS.ORDERS_RUB_, 'sold_rub', 'profit'];
const COLORS = [PINK, BLUE, GREEN];
const COLORS_SCATTER = [VIKING, HELIOTROPE, YELLOW, LEAF, CONTESSA, HOT_PINK];

const InDays2 = () => {
  const {
    data: ratingsChartData,
    isLoading: isRatingChartLoading,
    error: isRatingChartError,
  } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.IN_DAYS_2_CHART,
  });
  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    error: isRatingError,
  } = useFetchFilteredInnerDataPaginated({
    type: TABLE_INNER_TYPE.IN_DAYS_2,
  });
  const {
    data: chartCommonData = {},
    isLoading: chartCommonLoading,
    isRefetching: chartCommonIsRefetching,
  } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.CARD_LIST,
  });

  const { isMobile } = useUI();
  const platformColors = CHARTS_COLORS_WB;
  // This made for duplicate colors for rows price/count
  let transformedColors = [...platformColors, ...COLORS, ...platformColors, ...COLORS];

  const convertForMobile = (array) => {
    const resultArr = [...array];
    resultArr.splice(3, 0, ...array.slice(0, 3));
    return resultArr;
  };

  const transformedData = {
    absolute: ratingsChartData
      ? Object.values(ratingsChartData).map((el) => {
          const { date, ...other } = el;
          const resultNote = { name: date };
          Object.entries(other).forEach(([key, value]) => {
            if (Object.keys(LABELS).includes(key)) {
              if (key === 'returns_rub') {
                resultNote[`${key}_absolute`] = Math.abs(+value);
              } else {
                resultNote[`${key}_absolute`] = +value;
              }
            } else {
              resultNote[`${key}_absolute`] = value;
            }
          });
          return resultNote;
        })
      : [],
  };
  let transformedLabelsPostfix = {
    [COLUMNS.ORDERS_RUB_]: `${chartCommonData?.['orders_rub']?.toLocaleString('ru') || '-'} ₽`,
    ['sold_rub']: `${chartCommonData?.['sales_rub']?.toLocaleString('ru') || '-'} ₽`,
    ['returns_rub']: `${
      (
        chartCommonData?.['returns_rub'] && Math.abs(+chartCommonData?.['returns_rub'])
      )?.toLocaleString('ru') || '-'
    } ₽`,
    ['profit']: `${chartCommonData?.['profit']?.toLocaleString('ru') || '-'} ₽`,
    [COLUMNS.QUANTITY]: `${chartCommonData?.['quantity']?.toLocaleString('ru') || '-'} шт`,
    [COLUMNS.ORDERS_QTY_]: `${chartCommonData?.['orders_qty']?.toLocaleString('ru') || '-'} шт`,
    ['sold_qty']: `${chartCommonData?.['sales_qty']?.toLocaleString('ru') || '-'} шт`,
    ['returns_qty']: `${chartCommonData?.['returns_qty']?.toLocaleString('ru') || '-'} шт`,
  };

  return (
    <div className="relative z-0">
      <div className="bg-gray-100 py-4">
        <div className="wrapper-wide">
          <MultiChart
            colors={isMobile ? convertForMobile(transformedColors) : transformedColors}
            data={transformedData}
            isLoading={isRatingChartLoading || chartCommonLoading || chartCommonIsRefetching}
            error={isRatingChartError || isRatingError}
            periods={[]}
            labelsPostfix={transformedLabelsPostfix}
            type={TYPE_ABSOLUTE}
            types={[TYPE_ABSOLUTE]}
            labels={isMobile ? MOBILE_LABELS : LABELS}
            //labelsPostfix={transformedLabelsPostfix}
            initialLabels={INIT_LABELS}
            columns={isMobile ? 3 : 5}
            formatXAxis={formatXAxisDate}
            formatTooltipLabel={formatDateLabel}
            yAxisAngle={-30}
            scatterColors={COLORS_SCATTER}
            chartKey={TABLE_TYPE.PRODUCT_SALES}
          />
        </div>
      </div>
      <Table
        data={data}
        type={TABLE_INNER_TYPE.IN_DAYS_2}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </div>
  );
};

export default InDays2;

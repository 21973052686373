import { useSearchSellers } from 'hooks/useSearch';
import { useModalFilter } from 'context/filter.modal.context';
import { SearchInput } from 'components/FilterModal/components';
import LoadingSpin from 'components/LoadingSpin';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Chip from 'components/Chip';
import { ReactComponent as SearchIcon } from 'assets/images/searchCurrent.svg';
import { TreeView } from 'components/Tree';
import { PositionsContext } from 'pages/Positions/context';

const PositionsSellers = ({ isOpen, selected, setSelected }) => {
  const { searchedSeller, setSearchedSeller } = useContext(PositionsContext);
  const { query, onChange, value } = useSearchSellers({
    enabled: isOpen,
    initialValue: searchedSeller,
  });

  const { isLoading, data = [] } = query;
  const { chips } = useFilterReducer();

  const handleInputChange = (e) => {
    setSearchedSeller(e.target.value);
    onChange(e);
  };
  const {
    addSeller,
    removeSeller,
    setSeller,
    cleanSellers,
    cleanBrands,
    state: { touched, localChips, localFilter },
  } = useModalFilter();
  useEffect(() => {
    if (selected) {
      setSeller(selected);
    } else {
      cleanSellers();
      cleanBrands();
    }
  }, [selected, cleanSellers, setSeller, cleanBrands]);

  const onCheck = ({ key, title }) => {
    addSeller({
      key,
      title,
      titleWithCount: title,
    });
    setSelected({ key, title });
  };
  const onUncheck = ({ key, title }) => {
    removeSeller({
      key,
      title,
    });
    setSelected();
  };
  const sellersList =
    typeof data === 'string'
      ? []
      : data
          .filter((item) => !localChips.seller.some((seller) => seller.key === item.key))
          .map((data) => ({
            ...data,
            title: data.title,
          }));

  const selectedTreeProps = touched.seller
    ? { data: localChips.seller, checkedKeys: localFilter.seller }
    : { data: chips.seller, checkedKeys: chips.seller.map((item) => item.key) };

  const selectedSellersList = {
    data: sellersList,
    checkedKeys: [],
  };

  return (
    <>
      {selected ? (
        <div className="relative mb-6">
          <span className="absolute top-[12px] left-[10px]">
            <SearchIcon />
          </span>
          <div className="w-full appearance-none border border-gray-250 pl-8 pr-1.5 py-0.5 h-[40px] leading-none">
            <Chip
              title={selected.title}
              action={() => {
                setSelected();
                cleanSellers();
              }}
              color="bg-yellow"
              className="w-min"
            />
          </div>
        </div>
      ) : (
        <SearchInput value={value} onChange={handleInputChange} placeholder="Поиск по продавцам" />
      )}
      <div className="flex-1">
        {isLoading ? (
          <LoadingSpin isVisible={true} />
        ) : (
          <div className="overflow-y-auto max-h-[400px]">
            {typeof data === 'string' && data}
            <TreeView
              onCheck={(data, checked) => {
                checked ? onUncheck(data) : onCheck(data);
              }}
              data={[...selectedTreeProps.data, ...selectedSellersList.data]}
              checkedKeys={selectedTreeProps.checkedKeys}
            />
          </div>
        )}
      </div>
    </>
  );
};
PositionsSellers.propTypes = {
  isOpen: PropTypes.bool,
  selected: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelected: PropTypes.func.isRequired,
};
export { PositionsSellers };

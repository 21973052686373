import { useEffect, useState } from 'react';
import { MODES } from './constants';
import { useHistory, useLocation } from 'react-router';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { F, PAGE_SEO_BY_PHRASE, SEO_BY_PHRASE } from '../../constants';
import ByPhraseInsert from './ByPhraseInsert';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import ByPhraseResult from './ByPhraseResult';

const ByPhraseContent = () => {
  const { filter, setSort } = useFilterReducer();
  const searchParams = new URLSearchParams(document.location.search);
  const phraseURL = searchParams.get('phrase');
  const filterURL = searchParams.get('filter');
  const [mode, setMode] = useState(phraseURL ? MODES.RESULT : MODES.INSERT);
  const [phrase, setPhrase] = useState(phraseURL || '');
  const location = useLocation();
  const history = useHistory();

  const {
    data = [],
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_BY_PHRASE,
    filter: {
      platform: '2',
      date: filter.date,
    },
    warehouseType: filter[F.WAREHOUSE_TYPE],
    restricts: {
      search: phrase.toLowerCase(),
    },
  });

  useEffect(() => {
    if (filter.date) {
      setSort({ sort: `${filter.date[1]}_sort_position`, order: 'asc' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.date, mode]);

  useEffect(() => {
    if (phraseURL && filter.date.length !== 0) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phraseURL, refetch, filter.date, filter[F.WAREHOUSE_TYPE]]);

  const handleByPhrase = () => {
    const params = new URLSearchParams({
      ['phrase']: phrase,
      filter: filterURL,
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setMode(MODES.RESULT);
    refetch();
  };

  const handleNewByPhrase = () => {
    setPhrase('');
    history.push(PAGE_SEO_BY_PHRASE);
    setMode(MODES.INSERT);
  };

  if (mode === MODES.INSERT) {
    return <ByPhraseInsert phrase={phrase} setPhrase={setPhrase} handleByPhrase={handleByPhrase} />;
  }
  return (
    <ByPhraseResult
      data={data}
      isLoading={isLoading || isFetching}
      handleNewByPhrase={handleNewByPhrase}
    />
  );
};

export default ByPhraseContent;

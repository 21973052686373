import PropTypes from 'prop-types';
import React from 'react';

const SignUp = ({ state, setState }) => {
  const handleChange = (e, key) => {
    setState({ ...state, [key]: e.target.value });
  };
  const { error = {} } = state;
  return (
    <div>
      <div>
        <label className="block">
          Представьтесь
          <span className="text-red">*</span>
        </label>
        <input
          className=" px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
          value={state.name || ''}
          onChange={(e) => handleChange(e, 'name')}
        />
        {error.name && <p className="text-red-500 text-sm mt-2">Обязательное поле</p>}
      </div>
      <div className="mt-4">
        <label className="block">
          Email
          <span className="text-red">*</span>
        </label>
        <input
          className=" px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
          value={state.email || ''}
          onChange={(e) => handleChange(e, 'email')}
        />
        {error.email && <p className="text-red-500 text-sm mt-2">{error.email}</p>}
      </div>
      <div className="mt-4">
        <label className="block">
          Пароль
          <span className="text-red">*</span>
        </label>
        <input
          type="password"
          className="px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
          value={state.password || ''}
          onChange={(e) => handleChange(e, 'password')}
        />
        {error.password && <p className="text-red-500 text-sm mt-2">Не менее 6 символов</p>}
      </div>
      <div className="mt-4">
        <label className="block">
          Повторите пароль
          <span className="text-red">*</span>
        </label>
        <input
          type="password"
          className="px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
          value={state.passwordConfirm || ''}
          onChange={(e) => handleChange(e, 'passwordConfirm')}
        />
        {error.passwordConfirm && <p className="text-red-500 text-sm mt-2">Пароли не совпадают</p>}
      </div>
      <div className="flex mt-4">
        <input
          type="checkbox"
          checked={state.confirm}
          onChange={(e) => setState({ ...state, confirm: e.target.checked })}
        />
        <span className="block ml-2 mt-1">
          {'Принимаю '}
          <a
            href="https://www.wecheck.ru/agreement"
            target="_blank"
            rel="noreferrer"
            className="text-green"
          >
            условия публичной оферты
          </a>
        </span>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.func,
};

export default SignUp;

import React, { useState } from 'react';
import ButtonLabel from 'components/ButtonLabel';
import { ButtonTitle } from 'components/Card';
import CardList from 'components/CardList';
import { COLUMNS, DATE_FORMAT } from '../../constants';
import { useFetchFilteredInnerData } from 'hooks/useFetchFilteredInnerData';
import { TABLE_INNER_TYPE } from 'constants';
import cn from 'classnames';
import moment from 'moment/moment';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { Skeleton } from '../Skeleton';
import ProblemsProductsInnerCard from '../ProblemsProductsInnerCard/ProblemsProductsInnerCard';

// const BarChart = lazy(() => import('components/BarChart'));

const shortcuts = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'тыс' },
  { value: 1e6, symbol: 'млн' },
  { value: 1e9, symbol: 'млрд' },
  { value: 1e12, symbol: 'трлн' },
  { value: 1e15, symbol: 'квадр' },
  { value: 1e18, symbol: 'квинт' },
];
const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

export const roundValue = (value, noPadding = false, noShortcut = false) => {
  const short = shortcuts
    .slice()
    .reverse()
    .find((short) => Math.abs(value) >= short.value);
  return short
    ? `${(value / short.value).toFixed(1).replace('.0', '').replace('.', ',').replace(rx, '$1')}${
        noPadding || short.value === 1 ? '' : ' '
      }${!noShortcut ? short.symbol : ''}`
    : '0';
};

const CHART_LABELS = ['Шт', 'Руб'];

const CHART_TABS_MAIN = ['Заказы', 'Продажи', 'Прибыль', 'Возвраты'];

const getChartDataInfo = (data, tab, subTab) => {
  switch (tab) {
    case 0: {
      if (subTab === 0) {
        return [data['orders_yesterday_qty'], data['orders_7d_qty'], data['orders_qty']];
      }
      return [data['orders_yesterday_rub'], data['orders_7d_rub'], data['orders_rub']];
    }
    case 1: {
      if (subTab === 0) {
        return [data['sales_yesterday_qty'], data['sales_7d_qty'], data['sales_qty']];
      }
      return [data['sales_yesterday_rub'], data['sales_7d_rub'], data['sales_rub']];
    }
    case 2: {
      if (subTab === 0) {
        return [undefined, undefined, undefined];
      }
      return [data['profit_yesterday'], data['profit_7d'], data['profit']];
    }
    case 3: {
      if (subTab === 0) {
        return [data['returns_yesterday_qty'], data['returns_7d_qty'], data['returns_qty']];
      }
      return [data['returns_yesterday_rub'], data['returns_7d_rub'], data['returns_rub']];
    }
  }
};
function toDivide(numb) {
  if (numb === undefined) {
    return '-';
  }
  var int = String(Math.trunc(numb));
  if (int.length <= 3) return int;
  var space = 0;
  var number = '';

  for (var i = int.length - 1; i >= 0; i--) {
    if (space === 3) {
      number = ' ' + number;
      space = 0;
    }
    number = int.charAt(i) + number;
    space++;
  }

  return number;
}

const getPercentValue = (value) => {
  if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      return value;
    } else {
      return value.toFixed(2);
    }
  }
};

const MAX_SYMBOLS_LENGTH = 10;

const CardListInner = () => {
  const { filter } = useFilterReducer();
  const [activeChartTab, setActiveChartTab] = useState(0);
  const [activeChartSubTab, setActiveChartSubTab] = useState(1);
  const {
    data = {},
    isLoading,
    isRefetching,
  } = useFetchFilteredInnerData({
    type: TABLE_INNER_TYPE.CARD_LIST,
  });
  const chartDataInfo = getChartDataInfo(data, activeChartTab, activeChartSubTab);
  const [isYesterdayHover, setIsYesterdayHover] = useState(false);
  const [is7dHover, setIs7dHover] = useState(false);
  const [isPeriodHover, setIsPeriodHover] = useState(false);
  const getResultValue = (value) => {
    const rounded = roundValue(value);
    return rounded.indexOf(' ') !== -1 ? rounded : toDivide(value);
  };
  const getPeriod = (date = filter.date) => {
    if (!date) return '';
    const start = moment(date[0], DATE_FORMAT);
    const end = moment(date[1], DATE_FORMAT);
    const showFirstMonth = start.format('MMM') !== end.format('MMM');
    return `${start.format('DD')} ${
      showFirstMonth ? start.format('MMM').slice(0, 3) : ''
    } — ${end.format('DD')} ${end.format('MMM').slice(0, 3)}`;
  };
  const isDataLoading = isLoading || isRefetching;
  return (
    <div className="bg-[#F0F0F0] flex border border-l-0 border-r-0 border-gray-200 overflow-x-auto overflow-y-hidden xl:overflow-visible">
      <div>
        <div className="bg-white min-w-min border h-fit border-gray-200 rounded-lg px-3 py-3.5 m-4 mr-0 flex flex-col w-[450px]">
          <div className="flex justify-between">
            <div className="flex divide-x">
              <div>
                {CHART_TABS_MAIN.map((tab, i) => (
                  <ButtonTitle
                    key={i}
                    label={tab}
                    active={activeChartTab === i}
                    onClick={() => setActiveChartTab(i)}
                  />
                ))}
              </div>
            </div>
            <div className="flex justify-between ml-2 h-5">
              {CHART_LABELS.map((label, i) => (
                <ButtonLabel
                  key={i}
                  label={label}
                  disabled={i === 0 && activeChartTab === 2}
                  active={i === activeChartSubTab}
                  onClick={() => setActiveChartSubTab(i)}
                />
              ))}
            </div>
          </div>
          {/*<div className="mt-8">*/}
          {/*  <BarChart data={CHART_DATA} nameKey={'name'} valueKey={'value'} useWbColor />*/}
          {/*</div>*/}
          <div className="flex space-x-2 mt-4">
            <div
              onMouseEnter={() => setIsYesterdayHover(true)}
              onMouseLeave={() => setIsYesterdayHover(false)}
              className="py-4 flex flex-col items-center justify-center border border-[#E8E8E8] bg-[#FAFAFA] rounded-lg h-fit w-[136px]"
            >
              <div className="w-full px-4">
                <span className="whitespace-nowrap text-gray-400 self-center pb-4 block text-center">
                  За вчера
                </span>
                <div className="border-t border-dashed border-[#D9D9D9] h-[1px] w-full" />
              </div>
              <span
                className={cn(
                  'relative whitespace-nowrap font-medium text-2xl self-center leading-7 w-full pt-4 text-center',
                )}
              >
                {isDataLoading ? (
                  <div className="px-4">
                    <Skeleton
                      classNames={`w-full h-[28px] ${isDataLoading ? 'visible' : 'hidden'}`}
                    />
                  </div>
                ) : (
                  <>
                    {isYesterdayHover ? (
                      <span
                        className={cn('w-full text-center block bg-[#FAFAFA]', {
                          '!text-sm !leading-7 !font-bold':
                            toDivide(chartDataInfo[0]).length > MAX_SYMBOLS_LENGTH,
                        })}
                      >
                        {toDivide(chartDataInfo[0])}
                      </span>
                    ) : (
                      <span className="text-center w-full block">
                        {getResultValue(chartDataInfo[0])}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
            <div
              onMouseEnter={() => setIs7dHover(true)}
              onMouseLeave={() => setIs7dHover(false)}
              className="py-4 flex flex-col items-center justify-center border border-[#E8E8E8] bg-[#FAFAFA] rounded-lg h-fit w-[136px]"
            >
              <div className="w-full px-4">
                <span className="whitespace-nowrap text-gray-400 self-center pb-4 block text-center">
                  За 7 дней
                </span>
                <div className="border-t border-dashed border-[#D9D9D9] h-[1px] w-full" />
              </div>
              <span
                className={cn(
                  'relative whitespace-nowrap font-medium text-2xl self-center leading-7 w-full pt-4',
                )}
              >
                {isDataLoading ? (
                  <div className="px-4">
                    <Skeleton
                      classNames={`w-full h-[28px] ${isDataLoading ? 'visible' : 'hidden'}`}
                    />
                  </div>
                ) : (
                  <>
                    {is7dHover ? (
                      <span
                        className={cn('w-full text-center block bg-[#FAFAFA]', {
                          '!text-sm !leading-7 !font-bold':
                            toDivide(chartDataInfo[1]).length > MAX_SYMBOLS_LENGTH,
                        })}
                      >
                        {toDivide(chartDataInfo[1])}
                      </span>
                    ) : (
                      <span className="text-center w-full block">
                        {getResultValue(chartDataInfo[1])}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
            <div
              onMouseEnter={() => setIsPeriodHover(true)}
              onMouseLeave={() => setIsPeriodHover(false)}
              className="py-4 flex flex-col items-center justify-center border border-[#E8E8E8] bg-[#FAFAFA] rounded-lg h-fit w-[136px]"
            >
              <span className="whitespace-nowrap self-center bg-green-lightest text-green px-1 rounded-sm mb-4 block">
                {getPeriod()}
              </span>
              <div className="w-full px-4">
                <div className="border-t border-dashed border-[#D9D9D9] h-[1px] w-full" />
              </div>
              <span
                className={cn(
                  'relative whitespace-nowrap font-medium text-2xl self-center leading-7 pt-4 w-full',
                )}
              >
                {isDataLoading ? (
                  <div className="px-4">
                    <Skeleton
                      classNames={`w-full h-[28px] ${isDataLoading ? 'visible' : 'hidden'}`}
                    />
                  </div>
                ) : (
                  <>
                    {isPeriodHover ? (
                      <span
                        className={cn('w-full text-center block bg-[#FAFAFA]', {
                          '!text-sm !leading-7 !font-bold':
                            toDivide(chartDataInfo[2]).length > MAX_SYMBOLS_LENGTH,
                        })}
                      >
                        {toDivide(chartDataInfo[2])}
                      </span>
                    ) : (
                      <span className="text-center w-full block">
                        {getResultValue(chartDataInfo[2])}
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
        <ProblemsProductsInnerCard />
      </div>
      <CardList
        data={data}
        useWrapperStyles={false}
        isLoading={isLoading || isRefetching}
        width={300}
        columns={[
          [
            {
              title: 'Остатки',
              labels: ['Розн', 'Себес'],
              shortcut: true,
              tabs: [
                {
                  info: [
                    {
                      label: `<span>На складе <span class='text-green'>&middot;</span> <span class='text-green'>
                            ${toDivide(data?.[COLUMNS.QUANTITY]) || '-'} шт
                        </span></span>`,
                      key: 'quantity_rrc_cost',
                      type: 'currency',
                    },
                    {
                      label: `<span>В пути <span class='text-green'>&middot;</span> <span class='text-green'>
                          ${toDivide(data?.[COLUMNS.QUANTITY_IN_WAY]) || '-'} шт
                      </span></span>`,
                      key: 'quantity_in_way_rrc_cost',
                      type: 'currency',
                    },
                    {
                      label: `<span>Всего <span class='text-green'>&middot;</span> <span class='text-green'>
                        ${toDivide(data?.[COLUMNS.QUANTITY_FULL]) || '-'} шт
                        </span></span>`,
                      key: 'quantity_full_rrc_cost',
                      type: 'currency',
                    },
                  ],
                },
                {
                  info: [
                    {
                      label: `<span>На складе <span class='text-green'>&middot;</span> <span class='text-green'>
                            ${toDivide(data?.[COLUMNS.QUANTITY]) || '-'} шт
                        </span></span>`,
                      key: 'quantity_net_cost',
                      type: 'currency',
                    },
                    {
                      label: `<span>В пути <span class='text-green'>&middot;</span> <span class='text-green'>
                          ${toDivide(data?.[COLUMNS.QUANTITY_IN_WAY]) || '-'} шт
                      </span></span>`,
                      key: 'quantity_in_way_net_cost',
                      type: 'currency',
                    },
                    {
                      label: `<span>Всего <span class='text-green'>&middot;</span> <span class='text-green'>
                        ${toDivide(data?.[COLUMNS.QUANTITY_FULL]) || '-'} шт
                        </span></span>`,
                      key: 'quantity_full_net_cost',
                      type: 'currency',
                    },
                  ],
                },
              ],
            },
            {
              title: 'Среднее в сутки',
              labels: ['Шт', 'Руб'],
              shortcut: true,
              tabs: [
                {
                  info: [
                    {
                      label: 'Заказов',
                      key: 'avg_orders_per_day_qty',
                      type: 'amount',
                    },
                    {
                      label: 'Продаж',
                      key: 'avg_sales_per_day_qty',
                      type: 'amount',
                    },
                  ],
                },
                {
                  info: [
                    {
                      label: 'Заказов',
                      key: 'avg_orders_per_day_rub',
                      type: 'currency',
                    },
                    {
                      label: 'Продаж',
                      key: 'avg_sales_per_day_rub',
                      type: 'currency',
                    },
                  ],
                },
              ],
            },
          ],
          [
            {
              title: 'Возможности',
              labels: ['Шт', 'Руб'],
              shortcut: true,
              activeDefault: 1,
              tabs: [
                {
                  info: [
                    {
                      label: `<span>Упущенные заказы</span>`,
                      key: 'lost_orders_qty',
                      type: 'amount',
                      isQuestion: true,
                      questionText:
                        'Общее количество заказов, которые могли быть сделаны, когда товар отсутствовал на складе',
                      externalLabel: `<span class='text-green'>&middot;</span><span class='text-green'>
                        ${getPercentValue(data?.['lost_orders_percent']) || '-'} %
                        </span>`,
                    },
                    {
                      label: `<span>Упущенная прибыль</span>`,
                      key: 'lost_profit_qty',
                      type: 'amount',
                      isQuestion: true,
                      questionText:
                        'Сумма недополученной прибыли с учётом расходов, которая могла быть получена, когда товар отсутствовал на складе.',
                      externalLabel: `<span class='text-green'>&middot;</span><span class='text-green'>
                        ${getPercentValue(data?.['lost_profit_percent']) || '-'} %
                        </span>`,
                    },
                  ],
                },
                {
                  info: [
                    {
                      label: `<span>Упущенные заказы</span>`,
                      key: 'lost_orders_rub',
                      type: 'currency',
                      isQuestion: true,
                      questionText:
                        'Общее количество заказов, которые могли быть сделаны, когда товар отсутствовал на складе',
                      externalLabel: `<span class='text-green'>&middot;</span><span class='text-green'>
                        ${getPercentValue(data?.['lost_orders_percent']) || '-'} %
                        </span>`,
                    },
                    {
                      label: `<span>Упущенная прибыль</span>`,
                      key: 'lost_profit_rub',
                      type: 'currency',
                      isQuestion: true,
                      questionText:
                        'Сумма недополученной прибыли с учётом расходов, которая могла быть получена, когда товар отсутствовал на складе.',
                      externalLabel: `<span class='text-green'>&middot;</span><span class='text-green'>
                        ${getPercentValue(data?.['lost_profit_percent']) || '-'} %
                        </span>`,
                    },
                  ],
                },
              ],
            },
            {
              title: 'Эффективность',
              labels: ['%'],
              // shortcut: true,
              tabs: [
                {
                  info: [
                    {
                      label: 'Рентабельность',
                      key: 'profitability',
                      type: 'percent',
                      isQuestion: true,
                      questionText: (
                        <div>
                          <div className="whitespace-nowrap">
                            (Прибыль всех заказов / Себестоимость всех заказов)*100%
                          </div>
                          <div>Считается только для товаров, у которых назначена себестоимость</div>
                        </div>
                      ),
                    },
                    {
                      label: 'Маржинальность',
                      key: 'marginality_with_prime_cost',
                      type: 'percent',
                      isQuestion: true,
                      questionText: (
                        <div>
                          <div className="whitespace-nowrap">
                            (Прибыль всех заказов / Сумму цен всех заказов)*100%
                          </div>
                          <div>Считается только для товаров, у которых назначена себестоимость</div>
                        </div>
                      ),
                    },
                  ],
                },
              ],
            },
          ],
          [
            {
              labels: ['Товары'],
              hideActiveTitle: true,
              tabs: [
                {
                  coloredBack: [
                    {
                      label: 'В&nbsp;наличии',
                      key: 'sku_qty',
                      type: 'amount',
                    },
                    {
                      label: 'С&nbsp;заказами',
                      key: 'active_sku_qty',
                      type: 'amount',
                      color: 'green',
                    },
                    {
                      label:
                        '<span>Доля товаров, у которых была хотя бы одна продажа за выбранный период</span>',
                      key: 'active_sku_percent',
                      pie: true,
                      percent: true,
                    },
                  ],
                },
              ],
            },
          ],
        ]}
      />
    </div>
  );
};

export default CardListInner;

import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { TriangleToRightIcon } from 'assets/icons/TriangleToRightIcon';

/**
 * @typedef IProps
 *
 * @property {string} videoId ID видео, которое будет воспроизведено
 * */

/**
 * @return {JSX.Element} Самописный компонент для воспроизведения видео
 *
 * @param {import('react').HTMLAttributes<HTMLDivElement> & IProps} props
 * */
export function CustomVideoPlayer({ videoId, maxHeight, maxWidth, ...divProps }) {
  const ref = useRef(null);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const onClickPlay = () => {
    if (isVideoPlay) setIsVideoPlay(false);
    if (!isVideoPlay) setIsVideoPlay(true);
  };
  //
  const getResultSize = (real, max) => (!max || max > real ? real : max);
  useEffect(() => {
    setHeight(ref.current !== null ? getResultSize(ref.current.offsetHeight, maxHeight) : 0);
    setWidth(ref.current !== null ? getResultSize(ref.current.offsetWidth, maxWidth) : 0);
  }, [ref, maxHeight, maxWidth]);
  return (
    <div
      className={cn(
        'transition-all flex items-center justify-center rounded-2xl w-full h-96 md:h-[40rem] overflow-hidden relative z-[9]',
        {
          ['bg-transparent']: isVideoPlay,
          ['bg-green']: !isVideoPlay,
        },
      )}
      ref={ref}
      {...divProps}
      style={{ height: maxHeight || null, width: maxWidth || null }}
    >
      <button
        type="button"
        className={cn(
          'transition-opacity p-14 flex items-center justify-center rounded-full bg-white group',
          {
            ['-z-10 opacity-0']: isVideoPlay,
          },
        )}
        onClick={onClickPlay}
      >
        <TriangleToRightIcon className="relative left-1 h-11 w-11 transition-all group-hover:fill-green" />
      </button>
      {isVideoPlay && (
        <iframe
          allowFullScreen
          className={cn('absolute inset-0', { ['z-10']: isVideoPlay, ['-z-10']: !isVideoPlay })}
          height={height}
          width={width}
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/${videoId}?autoplay=1&controls=1&fs=1`}
        />
      )}
    </div>
  );
}

CustomVideoPlayer.propTypes = {
  videoId: propTypes.string.isRequired,
  maxHeight: propTypes.number,
  maxWidth: propTypes.number,
};

CustomVideoPlayer.defaultProps = {
  maxHeight: 0,
  maxWidth: 0,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonLabel from 'components/ButtonLabel';
import cn from 'classnames';
import QuestionIcon from '../../assets/icons/QuestionIcon';
import { PopoverV2 } from '../PopoverV2';

export const ButtonTitle = ({ onClick, label, active, disabled, disableActiveTitle }) => {
  return (
    <button
      className={cn('leading-none text-sm mr-2.5 font-bold lg:text-base lg:mr-3.5 lg:font-normal', {
        ['text-green']: active,
        ['text-gray-400 text-opacity-50 hover:text-green']: !active,
        ['!text-black']: disableActiveTitle,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};
ButtonTitle.defaultProps = {
  active: false,
};
ButtonTitle.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  disableActiveTitle: PropTypes.bool,
};

const Card = ({
  isQuestionToRight,
  isQuestion,
  questionText,
  title,
  labels,
  tabs,
  double,
  setActiveTitle,
  activeTitle,
  labelsDisabled,
  hideActiveTitle: disableActiveTitle,
  activeDefault,
}) => {
  const [active, setActive] = useState(activeDefault);

  if (!Array.isArray(tabs)) {
    tabs = [tabs];
  }
  const resultTitle = Array.isArray(title) ? (
    <div className="flex">
      {title.map((tab, i) => (
        <ButtonTitle
          key={i}
          label={tab}
          active={activeTitle === i}
          disableActiveTitle={disableActiveTitle}
          onClick={() => setActiveTitle(i)}
        />
      ))}
    </div>
  ) : (
    title
  );
  return (
    <div className="bg-white min-w-min border border-gray-200 rounded-lg px-3 py-3.5">
      {(resultTitle || labels) && (
        <div
          className={cn('flex items-center mb-[13px]', {
            ['justify-between']: !double,
            ['justify-end']: double,
          })}
        >
          {!isQuestion && resultTitle && (
            <h3 className="text-sm lg:text-base flex items-center mb-0 leading-none">
              {resultTitle}
            </h3>
          )}
          {resultTitle && isQuestion && questionText && (
            <div className="relative group flex items-center gap-1">
              <h3 className="text-sm lg:text-base flex items-center mb-0 leading-none">
                {resultTitle}
              </h3>
              <QuestionIcon className="h-4 w-4 cursor-pointer text-gray-300" />
              <PopoverV2
                classes={`opacity-0 !-z-10000 group-hover:opacity-100 group-hover:!z-10000 transition-opacity top-6 ${
                  questionText.length < 150 ? 'w-60' : 'w-[35rem]'
                } ${!isQuestionToRight && questionText.length < 150 ? '-left-1' : ''} ${
                  !isQuestionToRight && questionText.length >= 150 ? '-left-32' : ''
                } ${isQuestionToRight ? '-top-20 -right-20' : ''}`}
              >
                <div className="text-gray-400 font-SofiaPro text-sm">{questionText}</div>
              </PopoverV2>
            </div>
          )}
          {labels && (
            <div className="flex justify-between">
              {labels.map((label, i) => {
                const isDisabled = labelsDisabled ? labelsDisabled(activeTitle, i) : false;
                return !title && !double ? (
                  <ButtonTitle
                    key={i}
                    label={label}
                    active={active === i}
                    onClick={() => setActive(i)}
                    disableActiveTitle={disableActiveTitle}
                    disabled={isDisabled}
                  />
                ) : (
                  <ButtonLabel
                    key={i}
                    label={label}
                    active={active === i}
                    onClick={() => setActive(i)}
                    disableActiveTitle={disableActiveTitle}
                    disabled={isDisabled}
                  />
                );
              })}
            </div>
          )}
          {!title && labels && isQuestion && questionText && (
            <div className="relative group">
              <QuestionIcon className="h-4 w-4 cursor-pointer text-gray-300" />
              <PopoverV2
                classes={`opacity-0 !-z-10000 group-hover:opacity-100 group-hover:!z-10000 transition-opacity top-6 ${
                  questionText.length < 150 ? 'w-60' : 'w-[35rem]'
                } ${!isQuestionToRight && questionText.length < 150 ? '-left-1' : ''} ${
                  !isQuestionToRight && questionText.length >= 150 ? '-left-32' : ''
                } ${isQuestionToRight ? '-top-5 -right-[36rem]' : ''}`}
              >
                <div className="text-gray-400 font-SofiaPro text-sm font-medium">
                  {questionText}
                </div>
              </PopoverV2>
            </div>
          )}
        </div>
      )}

      {tabs[active] && tabs[active]}
    </div>
  );
};

Card.defaultProps = {
  title: null,
  labels: null,
};

Card.propTypes = {
  title: PropTypes.any,
  tabs: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  double: PropTypes.bool,
  isQuestion: PropTypes.bool,
  isQuestionToRight: PropTypes.bool,
  questionText: PropTypes.node,
  setActiveTitle: PropTypes.func,
  activeTitle: PropTypes.any,
  labelsDisabled: PropTypes.func,
  hideActiveTitle: PropTypes.bool,
  activeDefault: PropTypes.number,
};

export default Card;

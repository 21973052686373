import { useQuery } from 'react-query';

import { CHART_TYPE } from 'constants/chart';
import { STALE_TIME } from 'constants/query';
import apiClient from 'services/baseClient';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { getFilterParamsForBack } from 'hooks/useFetchFilteredData';

const action = {
  [CHART_TYPE.DYNAMIC]: (props) => apiClient.getDynamicChart(props),
  [CHART_TYPE.PRICE_SEGMENT]: (props) => apiClient.getPriceSegmentsChart(props),
};

const TYPE_WITH_PRICE_RANGE_PARAM = [CHART_TYPE.PRICE_SEGMENT];

const ifHasPriceRangeParam = (type) => {
  return TYPE_WITH_PRICE_RANGE_PARAM.includes(type);
};

export const useMultiChartFilteredData = ({ type, extraParams = {}, enabled = true }) => {
  const { filter, isLoaded, priceRange, step } = useFilterReducer();

  // const { isNew, hasAdvertising } = filter;

  const filterForBack = getFilterParamsForBack({ filter, type });

  const options = {
    filter: filterForBack,
    // isNew,
    // hasAdvertising,
    ...extraParams,
  };

  if (ifHasPriceRangeParam(type)) {
    options.priceRange = { range: priceRange };
    options.step = step;
  }

  const queryKey = [type, options];

  return useQuery({
    queryKey,
    queryFn: () => action[type](options),
    refetchOnmount: false,
    staleTime: STALE_TIME,
    retry: 1,
    enabled: enabled && isLoaded,
  });
};

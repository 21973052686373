import { RadioButton } from 'components/MobileInterface/components/PlatformPanel';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import React from 'react';
import PropTypes from 'prop-types';

const MobileContent = ({
  options,
  setIsOpen,
  isOpen,
  setMobileSelect,
  mobileSelect,
  handleApply,
}) => {
  return (
    <MobileInterfaceV2
      onPanelClose={() => setIsOpen(false)}
      rootClassName="bottom-0 absolute"
      isActive={isOpen}
      headerText={'Поиск по'}
    >
      <div className="px-4 divide-y">
        {options.map(({ label, value }) => (
          <div key={value}>
            <RadioButton
              onChange={() => setMobileSelect(value)}
              label={label}
              isChecked={mobileSelect === value}
              containerCn="border-none"
            />
          </div>
        ))}
      </div>
      <div className="p-4">
        <button
          className="w-full bg-green text-white shadow-sm items-center px-3 py-2 rounded"
          onClick={() => handleApply(mobileSelect)}
        >
          Применить
        </button>
      </div>
    </MobileInterfaceV2>
  );
};

MobileContent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  setMobileSelect: PropTypes.func,
  mobileSelect: PropTypes.string,
  handleApply: PropTypes.func,
};

export { MobileContent };

import PropTypes from 'prop-types';
import ButtonPrimary from '../ButtonPrimary';
import RefreshIcon from 'assets/icons/RefreshIcon';
import React from 'react';
import { TABLE_TYPE } from '../../constants';
import Table from '../Table';
import { Skeleton } from '../Skeleton';

const ByPhraseResult = ({ data, isLoading, handleNewByPhrase }) => {
  const cardData = data[0] || {};
  const getData = () => {
    return data.map(({ positions, orders_rub, ...other }) => {
      const resultData = { positions, ...other };
      positions.forEach((el, index) => {
        const { sort_position, diff } = el;
        resultData[`${el.date}_sort_position`] = sort_position;
        resultData[`${el.date}_diff`] = index === 0 ? '-' : diff;
      });
      orders_rub.forEach((el) => {
        const { date, orders_rub } = el;
        resultData[`byPhraseChart/${date}`] = orders_rub;
      });
      return resultData;
    });
  };
  return (
    <div>
      <div className="bg-[#F0F0F0] py-4 px-8 border-t border-[#E8E8E8]">
        <div>
          <div className="flex space-x-3">
            <ButtonPrimary
              onClick={handleNewByPhrase}
              label={
                <div className="flex space-x-2 items-center py-[3px]">
                  <RefreshIcon width={16} height={16} />
                  <span className="block">Новый запрос</span>
                </div>
              }
              className="text-base group !pl-[14px]"
            />
          </div>
          <div className="mt-8 flex space-x-4">
            <div className="rounded-lg block flex p-4 bg-white border border-[#E8E8E8] items-center">
              <span className="block text-2xl leading-[29px]">
                {isLoading ? <Skeleton classNames="h-[29px] w-[80px]" /> : cardData.phrase}
              </span>
              <div className="w-px h-[34px] bg-[#E8E8E8] mx-3" />
              <span className="leading-[17px] text-[#858585]">
                Поисковая <br /> фраза
              </span>
            </div>
            <div className="rounded-lg block flex p-4 bg-white border border-[#E8E8E8] items-center">
              <span className="block text-2xl leading-[29px]">
                {isLoading ? (
                  <Skeleton classNames="h-[29px] w-[80px]" />
                ) : (
                  Intl.NumberFormat('ru-RU').format(+cardData.frequency)
                )}
              </span>
              <div className="w-px h-[34px] bg-[#E8E8E8] mx-3" />
              <span className="leading-[17px] text-[#858585]">
                Частотность <br /> Wildberries
              </span>
            </div>
            <div className="rounded-lg block flex p-4 bg-white border border-[#E8E8E8] items-center">
              <span className="block text-2xl leading-[29px]">
                {isLoading ? (
                  <Skeleton classNames="h-[29px] w-[80px]" />
                ) : (
                  Intl.NumberFormat('ru-RU').format(+cardData.sku_qty)
                )}
              </span>
              <div className="w-px h-[34px] bg-[#E8E8E8] mx-3" />
              <span className="leading-[17px] text-[#858585]">
                Количество <br /> товаров
              </span>
            </div>
          </div>
        </div>
      </div>
      <Table
        data={getData()}
        type={TABLE_TYPE.SEO_BY_PHRASE_RESULTS}
        isLoading={isLoading}
        noRowsText={`По вашему запросу нет данных.`}
      />
    </div>
  );
};

ByPhraseResult.propTypes = {
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  handleNewByPhrase: PropTypes.func,
};

export default ByPhraseResult;

import PropTypes from 'prop-types';

const PasswordRecovery = ({ state, setState }) => {
  const handleChange = (e, key) => {
    setState({ ...state, [key]: e.target.value });
  };
  return (
    <div>
      <div>
        <label className="block">
          Email
          <span className="text-red">*</span>
        </label>
        <input
          className=" px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
          value={state.email || ''}
          onChange={(e) => handleChange(e, 'email')}
        />
      </div>
    </div>
  );
};

PasswordRecovery.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.func,
};

export default PasswordRecovery;

import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { PAGE_SIGNUP } from 'constants';

import {
  RecoveryPassword,
  SignUp,
  ResetPassword,
  RegistrationVerification,
  LogIn,
  ActivateDemo,
  SendMailConfirmation,
  UnauthorizedAdvertisingRates,
} from 'pages';
import { AuthorizationLayout } from 'components/Layout';
import { ActivationRoutes } from './authenticated-app';
import Footer from 'components/Footer';
import { PAGE_ADVERTISING_RATES } from 'constants/pages';

const AuthorizationRoutes = () => (
  <AuthorizationLayout>
    <Switch>
      <Route path={PAGE_SIGNUP} component={SignUp} />
      <Route path="/sign-in/:code" component={RegistrationVerification} />
      <Route path="/recovery-password" component={RecoveryPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/send-mail-confirmation" component={SendMailConfirmation} />
      <Route path="/" component={LogIn} exact />
      <Redirect to="/" />
    </Switch>
  </AuthorizationLayout>
);

const UnauthenticatedApp = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Switch>
        <Route path={PAGE_ADVERTISING_RATES} component={UnauthorizedAdvertisingRates} />
        <Route path="/activate-account" component={ActivationRoutes} />
        <Route exact path="/activate-demo/:tariffId/:token" component={ActivateDemo} />
        <Route path="/" component={AuthorizationRoutes} />
      </Switch>
      <Footer />
    </div>
  );
};

export default UnauthenticatedApp;

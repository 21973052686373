import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';

import DownIcon from 'assets/icons/DownIcon';
import classNames from 'classnames';

const Dropdown = ({
  bgColor,
  color,
  label,
  selected,
  options,
  onChange,
  isHideDropdownArrow,
  isOutlined,
  buttonClassnames,
  labelClassnames,
  downIconClassnames,
  optionClassnames,
  optionsWrapperClassnames,
  ...divProps
}) => {
  return (
    <div className="" {...divProps}>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={Fragment}>
          <button
            className={classNames(
              'whitespace-nowrap inline-flex items-center w-full px-3 py-1.5 text-sm font-medium rounded hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer',
              {
                [`bg-${bgColor}`]: !isOutlined && bgColor,
                [`text-${color}`]: color,
                [`border-${bgColor}`]: isOutlined && bgColor,
                [buttonClassnames]: buttonClassnames,
              },
            )}
          >
            <span className={labelClassnames}>{label}</span>
            <DownIcon
              className={classNames('w-3 h-3 ml-2', {
                ['hidden']: isHideDropdownArrow,
                [downIconClassnames]: downIconClassnames,
              })}
              aria-hidden="true"
            />
          </button>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              `absolute border border-gray-200 left-0 w-40 mt-2 origin-top-right bg-white rounded shadow-lg focus:outline-none z-10`,
              {
                [optionsWrapperClassnames]: optionsWrapperClassnames,
              },
            )}
          >
            {options.map(({ label, value }, i) => (
              <Fragment key={`${value}-${i}`}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        'text-gray-900 group flex rounded items-center w-full px-2 py-1 text-sm',
                        {
                          'bg-gray-200': active,
                          'bg-green-lightest': value === selected,
                          [optionClassnames]: optionClassnames,
                        },
                      )}
                      onClick={() => onChange(value)}
                    >
                      {label}
                    </button>
                  )}
                </Menu.Item>
              </Fragment>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
Dropdown.defaultProps = {
  bgColor: 'white',
  color: 'black',
  label: 'Выбрать',
  isHideDropdownArrow: false,
  isOutlined: false,
  labelClassnames: '',
  downIconClassnames: '',
  optionClassnames: '',
  optionsWrapperClassnames: '',
};
Dropdown.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  isHideDropdownArrow: PropTypes.bool,
  isOutlined: PropTypes.bool,
  buttonClassnames: PropTypes.string,
  optionClassnames: PropTypes.string,
  optionsWrapperClassnames: PropTypes.string,
  labelClassnames: PropTypes.string,
  downIconClassnames: PropTypes.string,
};

export default Dropdown;

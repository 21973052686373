import PropTypes from 'prop-types';

import { NavListWrapper, tableNavLiPropTypes } from 'components/NavList/components';

export const NavListOnCallback = ({ items, classList, callback }) => {
  return <NavListWrapper callback={callback} items={items} classList={classList} />;
};

NavListOnCallback.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(tableNavLiPropTypes)),
  classList: PropTypes.string,
  callback: PropTypes.func.isRequired,
};

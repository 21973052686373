import PropTypes from 'prop-types';

import { PERIOD_WEEK, PERIOD_MONTH } from 'constants';
import ButtonLabel from 'components/ButtonLabel';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

export const TABLE_HEADER_AVAILABLE_PERIODS = [PERIOD_WEEK, PERIOD_MONTH];

const TableHeaderPeriodControls = () => {
  const {
    filter: { period },
    setWeekPeriod,
    setMonthPeriod,
  } = useFilterReducer();

  return (
    <div className="flex items-center">
      {TABLE_HEADER_AVAILABLE_PERIODS.map((key) => (
        <ButtonLabel
          key={key}
          onClick={
            key === PERIOD_WEEK ? setWeekPeriod : key === PERIOD_MONTH ? setMonthPeriod : null
          }
          label={key === PERIOD_WEEK ? 'Неделя' : key === PERIOD_MONTH ? 'Месяц' : null}
          active={period === key}
        />
      ))}
    </div>
  );
};

TableHeaderPeriodControls.propTypes = {
  fromPanel: PropTypes.bool,
};

export default TableHeaderPeriodControls;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useProductSettings } from 'context/product.settings.context';

const TableInnerPrimeCostChanger = (props) => {
  const { primeCost, isChanged, updatePrimeCost } = useProductSettings();
  const { value: propsValue, data } = props;
  const { barcode } = data;
  const isPrimeCostChanged = isChanged(barcode);
  const [value, setValue] = useState(
    isPrimeCostChanged
      ? primeCost.find((el) => el.barcode === barcode)['prime_cost'] === ''
        ? propsValue === '-'
          ? ''
          : propsValue
        : primeCost.find((el) => el.barcode === barcode)['prime_cost']
      : propsValue === '-'
      ? ''
      : propsValue,
  );
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isActive) {
      inputRef.current.selectionStart = String(value).length;
      inputRef.current.selectionEnd = String(value).length;
    }
  }, [isActive, value]);

  const handleChange = (e) => {
    updatePrimeCost({
      barcode,
      prime_cost: e.target.value,
    });
    setValue(e.target.value);
  };

  return (
    <div className="w-full h-full px-4 py-1">
      <div
        className={cn('flex border border-gray-250 rounded px-1 py-[3px] bg-white', {
          'border-gray-250': !isActive,
          '!border-green': isActive || isPrimeCostChanged,
        })}
      >
        <div className="grow flex mr-1">
          <input
            ref={inputRef}
            className={cn('w-full self-center focus:outline-none text-right h-[22px]', {
              '!text-gray-400': isPrimeCostChanged,
            })}
            value={value}
            onChange={handleChange}
            onFocus={() => {
              setIsActive(true);
            }}
            onBlur={() => {
              setIsActive(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

TableInnerPrimeCostChanger.propTypes = {
  value: PropTypes.any,
  data: PropTypes.shape({
    id: PropTypes.number,
    barcode: PropTypes.string,
  }),
};

export default TableInnerPrimeCostChanger;

import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import PropTypes from 'prop-types';

export const Captcha = ({ onChange, visible, onChallengeHidden, onChallengeVisible, key }) => {
  return (
    <InvisibleSmartCaptcha
      key={key}
      sitekey={process.env.REACT_APP_YANDEX_CAPTCHA_KEY}
      onSuccess={onChange}
      onChallengeHidden={onChallengeHidden}
      onChallengeVisible={onChallengeVisible}
      visible={visible}
    />
  );
};

Captcha.propTypes = {
  key: PropTypes.number,
  visible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChallengeHidden: PropTypes.func.isRequired,
  onChallengeVisible: PropTypes.func.isRequired,
};

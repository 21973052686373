import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getDocumentTitle = (path) => {
  switch (path) {
    case '/seo/products':
    case '/seo/phrases':
    case '/seo/hints':
      return 'Подбор слов - WeCheck';
    case '/seo/compare':
      return 'Сравнение товаров - WeCheck';
    case '/seo/monitoring':
      return 'Мониторинг фраз - WeCheck';
    case '/seo/by-phrase':
      return 'Позиция товаров - WeCheck';
    case '/seo/smart':
      return 'Умный подбор - WeCheck';
    case '/seo/smart-description':
      return 'Умное описание - WeCheck';

    case '/promotion-position':
      return 'Рекламные ставки – WeCheck';

    case '/main':
      return 'Аналитика по категориям - WeCheck';
    case '/main/sellers':
      return 'Аналитика по продавцам - WeCheck';
    case '/main/brands':
      return 'Аналитика по брендам - WeCheck';
    case '/main/products':
      return 'Аналитика по товарам - WeCheck';
    case '/main/warehouses':
      return 'Аналитика по складам - WeCheck';
    case '/main/dynamic':
      return 'Тренды - WeCheck';
    case '/main/price-segments':
      return 'Ценовая сегментация - WeCheck';
    case '/main/sales':
      return 'По дням - WeCheck';

    case '/faq':
      return 'FAQ - WeCheck';

    case '/inner/in-days-2':
      return 'Динамика по дням - WeCheck';
    case '/inner/products':
      return 'Аналитика по товарам - WeCheck';
    case '/inner/sales':
      return 'Заказы. Продажи. Возвраты - WeCheck';
    case '/inner/warehouses':
      return 'Аналитика по складам - WeCheck';
    case '/inner/weeks':
      return 'Еженедельные отчеты - WeCheck';
    case '/inner/delivery':
      return 'Расчет поставки - WeCheck';
    case '/inner/products-settings':
      return 'Настройка себестоимости - WeCheck';
    case '/inner/prices':
      return 'Управление ценами - WeCheck';
    case '/api-keys':
      return 'API Ключи - WeCheck';

    case '/profile':
    case '/profile/subscriptions':
    case '/profile/referrals':
    case '/profile/tariffs':
      return 'Профиль – WeCheck';

    case '/':
      return 'Вход – WeCheck';
    case '/signup':
      return 'Регистрация – WeCheck';
    case '/recovery-password':
      return 'Восстановление пароля – WeCheck';
    case '/send-mail-confirmation':
      return 'Почта подтверждена - WeCheck';

    default:
      return 'WeCheck - аналитика маркетплейсов';
  }
};
export const useDynamicTitle = () => {
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    if (!pathname.startsWith('/product') && !pathname.startsWith('/inner/inner_product')) {
      document.title = getDocumentTitle(pathname);
    }
  }, [location]);
};

// хук для отслеживания загрузки всего контента для страницы
import { useTable } from 'context/table.context';
import { isTablePage } from 'utils/pages';

export function useGlobalDataLoading() {
  const { tableDataLoading } = useTable();

  const isTable = isTablePage();

  return isTable && tableDataLoading;
}

import React, { useState } from 'react';
import { InDaysContent } from '../components/VideoLessonsContent';

const VideoLessonsContext = React.createContext();
VideoLessonsContext.displayName = 'VideoLessons.Context';

const TABS = [
  {
    id: 'in_days',
    label: 'Аналитика по дням',
    available: true,
    lvl: 1,
    panel: InDaysContent,
  },
  {
    id: 'assortiment',
    label: 'Аналитика ассортимента',
    available: true,
    lvl: 2,
    panel: InDaysContent,
  },
  // {
  //   id: 'product_card',
  //   label: 'Карточка товара',
  //   available: true,
  //   lvl: 2,
  //   panel: <div>Test content</div>,
  // },
  // {
  //   id: 'sales',
  //   label: 'Заказы. Продажи. Возвраты',
  //   available: true,
  //   lvl: 3,
  //   panel: <div>Test content</div>,
  // },
  // {
  //   id: 'warehouses',
  //   label: 'Склады',
  //   available: true,
  //   lvl: 3,
  //   panel: <div>Test content</div>,
  // },
  // {
  //   id: 'products',
  //   label: 'Предметы',
  //   available: true,
  //   lvl: 1,
  //   panel: <div>Test content</div>,
  // },
  // {
  //   id: 'brands',
  //   label: 'Бренды',
  //   available: true,
  //   lvl: 1,
  //   panel: <div>Test content</div>,
  // },
];

const VideoLessonsProvider = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const value = {
    tabs: TABS,
    isModalOpen,
    setIsModalOpen,
    selectedTab,
    setSelectedTab,
  };
  return <VideoLessonsContext.Provider value={value} {...props} />;
};

const useVideoLessons = () => {
  return React.useContext(VideoLessonsContext);
};

export { VideoLessonsProvider, useVideoLessons };

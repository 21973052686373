import { useFetchCheckCpm } from 'hooks/useFetchCheckCpm';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AdvertisingRatesGridCard from './AdvertisingRatesGridCard';

const AdvertisingRatesGrid = ({ searchValue }) => {
  const { data: allData = {}, isLoading, isFetching } = useFetchCheckCpm(searchValue);
  const { data = {} } = allData;
  const { items: searchData = [] } = data;
  if (searchData.length === 0 || isLoading || isFetching) return null;
  return (
    <div className="flex justify-center">
      <div
        className={cn(
          'lg:grow lg:mt-8 sm:mt-[3.75rem] pb-8 lg:pb-5 grid gap-x-4 gap-y-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 max-w-[1200px]',
        )}
      >
        {searchData.map((el) => (
          <AdvertisingRatesGridCard key={el.id} cardInfo={el} />
        ))}
      </div>
    </div>
  );
};
AdvertisingRatesGrid.propTypes = {
  searchValue: PropTypes.string,
};
export default AdvertisingRatesGrid;

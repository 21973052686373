import React, { useRef } from 'react';

import Checkbox from 'components/Checkbox';
import { useUserTariffs } from 'context/user.tariffs.context';
import Popover from 'components/Popover';
import { NOT_AVAILABLE_BECAUSE_TARIFF } from 'constants/tooltips';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';
import { isTableWithNewAdvertising } from 'helpers/filter';
import QuestionIcon from 'assets/icons/QuestionIcon';

const TableIsNewFilter = () => {
  const {
    filter: { isNew },
    toggleIsNew,
  } = useFilterReducer();
  const { isNewFilterAvailable } = useUserTariffs();
  const tooltipRef = useRef();
  const tooltipHelpRef = useRef();

  const { type } = useTable();
  const filterEnabled = isTableWithNewAdvertising(type);

  return (
    <div className="flex">
      <div ref={tooltipRef}>
        <Checkbox
          label="Только новинки"
          onChange={toggleIsNew}
          name="is-new"
          checked={!!isNew}
          disabled={!isNewFilterAvailable || !filterEnabled}
          className={'whitespace-nowrap'}
        />
      </div>
      {!isNewFilterAvailable && (
        <Popover tooltip={NOT_AVAILABLE_BECAUSE_TARIFF} tooltipRef={tooltipRef} />
      )}
      <div ref={tooltipHelpRef} className="ml-1.5 text-gray-300">
        <QuestionIcon width={15} height={15} />
      </div>
      <Popover
        tooltipRef={tooltipHelpRef}
        tooltip="Товары, которые были впервые зафиксированы сервисом WeCheck за выбранный период"
      />
    </div>
  );
};

export default TableIsNewFilter;

const BarIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      d="M7.90383 0.478969C7.50947 -0.127593 6.62142 -0.127592 6.22706 0.478969L5.46118 1.65697C5.2355 2.00409 4.81923 2.17624 4.41428 2.08991L3.0363 1.79612C2.32697 1.64489 1.69761 2.27542 1.85015 2.98446L2.14241 4.34294C2.22995 4.74986 2.05629 5.16845 1.70639 5.39389L0.535044 6.14859C-0.0761347 6.54238 -0.0761342 7.43606 0.535044 7.82984L1.7064 8.58455C2.05629 8.80998 2.22995 9.22858 2.14241 9.6355L1.85015 10.994C1.69761 11.703 2.32697 12.3335 3.0363 12.1823L4.41428 11.8885C4.81923 11.8022 5.2355 11.9743 5.46118 12.3215L6.22706 13.4995C6.62142 14.106 7.50947 14.106 7.90383 13.4995L8.66971 12.3215C8.8954 11.9743 9.31166 11.8022 9.71661 11.8885L11.0946 12.1823C11.8039 12.3335 12.4333 11.703 12.2807 10.994L11.9885 9.6355C11.9009 9.22858 12.0746 8.80998 12.4245 8.58455L13.5958 7.82984C14.207 7.43606 14.207 6.54238 13.5958 6.14859L12.4245 5.39389C12.0746 5.16845 11.9009 4.74986 11.9885 4.34294L12.2807 2.98446C12.4333 2.27541 11.8039 1.64489 11.0946 1.79612L9.71661 2.08991C9.31167 2.17624 8.8954 2.00409 8.66971 1.65697L7.90383 0.478969ZM7.04439 10.2666C8.84852 10.2666 10.3111 8.8041 10.3111 6.99997C10.3111 5.19584 8.84852 3.7333 7.04439 3.7333C5.24026 3.7333 3.77772 5.19584 3.77772 6.99997C3.77772 8.8041 5.24026 10.2666 7.04439 10.2666Z"
      clipRule="evenodd"
    />
  </svg>
);
export default BarIcon;

const ProblemsIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.625 11C20.625 16.3157 16.3157 20.625 11 20.625C5.68426 20.625 1.375 16.3157 1.375 11C1.375 5.68426 5.68426 1.375 11 1.375C16.3157 1.375 20.625 5.68426 20.625 11ZM22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM11.722 12.066H10.462L10.252 5.5H11.918L11.722 12.066ZM12.156 14.278C12.156 14.88 11.652 15.314 11.064 15.314C10.49 15.314 10 14.88 10 14.278C10 13.69 10.49 13.242 11.064 13.242C11.652 13.242 12.156 13.69 12.156 14.278Z"
        fill="#EB392D"
      />
    </svg>
  );
};

export default ProblemsIcon;

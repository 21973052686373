import PropTypes from 'prop-types';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { CHART_TYPE } from '../../constants';
import { formatNumber } from '../Tooltip/utils';
import { useFetchFilteredChartsData } from 'hooks/useFetchFilteredChartsData';
import moment from 'moment';
import { DATE_FORMAT } from 'context/filter/filter.context.reducer.methods';

const CHART_DATE = [
  moment().subtract(7, 'days').format(DATE_FORMAT),
  moment().subtract(1, 'days').format(DATE_FORMAT),
];

const getDatesForGraph = (lastDay, days = 6) => {
  if (!lastDay) return null;

  const firstDay = moment(lastDay).subtract(days, 'd').format(DATE_FORMAT);

  return [firstDay, lastDay];
};

const TableCellSellersFrontTooltip = (props) => {
  const { data, location, colDef, value: defaultValue } = props;

  const { filter } = useFilterReducer();

  const { data: chartData } = useFetchFilteredChartsData({
    type: CHART_TYPE.ORDERS,
    date: getDatesForGraph(filter.date[1]) || CHART_DATE,
  });

  const total = chartData?.[colDef.field];
  const isOrdersRub = colDef?.field === 'orders_rub';
  const isFrontCalcPercent = colDef.field === 'orders_rub' || colDef.field === 'orders_qty';
  return (
    <div className={`opacity-100`}>
      <div
        className={`
        max-h-full max-w-full
        bg-gray-100 p-2
        shadow-black-bright shadow rounded-md
        flex items-center justify-center
        `}
      >
        {location === 'header' ? (
          <>{<span className={'whitespace-pre-line'}>{defaultValue}</span>}</>
        ) : (
          <div>
            {isFrontCalcPercent ? (
              <>
                <span className="block">{`Заказы: ${(+data?.[colDef?.field])?.toLocaleString(
                  'ru',
                )}${isOrdersRub ? ' ₽' : ' шт'}`}</span>
                <span className="block">{`Доля от всех заказов: ${formatNumber(
                  (+data?.[colDef?.field] / +total) * 100,
                )} %`}</span>
              </>
            ) : (
              <>
                <span className="block">{`Товаров: ${(+data?.[colDef?.field])?.toLocaleString(
                  'ru',
                )} шт`}</span>
                <span className="block">{`Товаров с заказами: ${(+data?.[
                  'active_sku_qty'
                ])?.toLocaleString('ru')} шт`}</span>
                <span className="block">{`Доля товаров с заказами: ${formatNumber(
                  +data?.['sku_qty_percent'],
                )} %`}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TableCellSellersFrontTooltip.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    sku_qty_percent: PropTypes.any,
    active_sku_qty: PropTypes.any,
  }),
  location: PropTypes.string.isRequired,
  reactContainer: PropTypes.object,
  colDef: PropTypes.object,
  valueFormatted: PropTypes.any,
  value: PropTypes.string,
};

export default TableCellSellersFrontTooltip;

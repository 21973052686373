import PropTypes from 'prop-types';
import cn from 'classnames';

import { Input } from 'components/RangeFilter/components/Input';
import { SeparatorHorizontal } from 'components/RangeFilter/components/SeparatorHorizontal';
import { SeparatorVertical } from 'components/RangeFilter/components/SeparatorVertical';
import { SortButton } from 'components/RangeFilter/components/SortButton';
import { useRef } from 'react';

export const InputsRow = ({
  from,
  to,
  onFromChange,
  onToChange,
  column,
  sortable,
  isNumeric,
  getActiveClassNames,
  onFocus,
  enterCallback,
}) => {
  const maxRef = useRef();
  return (
    <div
      className={cn('flex items-center mb-8', {
        'mb-8': isNumeric,
        'mb-3': !isNumeric,
      })}
    >
      <div className="flex items-center">
        <Input
          onChange={onFromChange}
          name="from"
          value={from}
          placeholder="От"
          isNumeric={isNumeric}
          getActiveClassNames={getActiveClassNames}
          onFocus={onFocus}
          enterCallback={() => {
            maxRef.current.focus();
          }}
        />

        <SeparatorHorizontal />

        <Input
          onFocus={onFocus}
          onChange={onToChange}
          name="to"
          value={to}
          placeholder="До"
          isNumeric={isNumeric}
          getActiveClassNames={getActiveClassNames}
          outerRef={maxRef}
          enterCallback={enterCallback}
        />
      </div>

      <SeparatorVertical />

      {sortable && <SortButton column={column} sortable={sortable} />}
    </div>
  );
};
InputsRow.propTypes = {
  column: PropTypes.object.isRequired,
  to: PropTypes.string,
  from: PropTypes.string,
  onFromChange: PropTypes.func.isRequired,
  onToChange: PropTypes.func.isRequired,
  sortable: PropTypes.bool,
  isNumeric: PropTypes.bool,
  getActiveClassNames: PropTypes.any,
  onFocus: PropTypes.any,
  enterCallback: PropTypes.func,
};

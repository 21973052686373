export const AG_GRID_LOCALE_RU = {
  count: 'Количество',
  min: 'Минимальное',
  max: 'Максимальное',
  avg: 'Среднее',
  sum: 'Сумма',
  pinColumn: 'Закрепить колонку',
  pinLeft: 'Закрепить слева',
  pinRight: 'Закрепить справа',
  noPin: 'Не закреплять',
  valueAggregation: 'Value Aggregation',
  autosizeThiscolumn: 'Автоширина этой колонки',
  autosizeAllColumns: 'Автоширина всех колонок',
  resetColumns: 'Колонки по умолчанию',
  expandAll: 'Развернуть все',
  collapseAll: 'Свернуть все',
  searchOoo: 'Поиск...',
  filterOoo: 'Поиск...',
  copy: 'Скопировать',
  copyWithHeaders: 'Скопировать с заголовком',
  paste: 'Вставить',
  export: 'Скачать',
  applyFilter: 'Применить',
  resetFilter: 'Сбросить',
  contains: 'Содержит',
  notContains: 'Не содержит',
};

import PropTypes from 'prop-types';

import ProductTableHeader from 'components/ProductTableHeader';

const Product = ({ children, ...rest }) => (
  <main>
    <ProductTableHeader {...rest} />
    {children}
  </main>
);

Product.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Product;

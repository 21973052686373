import Table from 'components/Table';
import { COLUMNS, TABLE_TYPE } from 'constants/table';
import { useFetchFilteredChartsData } from 'hooks/useFetchFilteredChartsData';
import { CHART_TYPE } from 'constants/chart';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';

const SalesByDay = () => {
  const {
    data: querySales,
    isLoading,
    isFetching,
    hasNextPage,
  } = useFetchFilteredDataPaginated({ type: TABLE_TYPE.SALES_BY_DAY });

  const {
    data: queryProductsCharts,
    isLoading: isProductsChartsLoading,
    isFetching: isProductsChartsFetching,
  } = useFetchFilteredChartsData({ type: CHART_TYPE.PRODUCTS });

  const {
    data: queryBrandsCharts,
    isLoading: isBrandsChartsLoading,
    isFetching: isBrandsChartsFetching,
  } = useFetchFilteredChartsData({ type: CHART_TYPE.BRANDS });

  const {
    data: querySellersCharts,
    isLoading: isSellersChartsLoading,
    isFetching: isSellersChartsFetching,
  } = useFetchFilteredChartsData({ type: CHART_TYPE.SELLERS });

  const additionalData = {
    [COLUMNS.SKU_QTY]: queryProductsCharts,
    [COLUMNS.BRANDS_COUNT]: queryBrandsCharts,
    [COLUMNS.SELLERS_COUNT]: querySellersCharts,
  };

  return (
    <Table
      data={querySales}
      additionalData={additionalData}
      type={TABLE_TYPE.SALES_BY_DAY}
      paginationOnBack={true}
      isLoading={
        isLoading ||
        (isFetching && !hasNextPage) ||
        isBrandsChartsLoading ||
        isBrandsChartsFetching ||
        isSellersChartsLoading ||
        isSellersChartsFetching ||
        isProductsChartsLoading ||
        isProductsChartsFetching
      }
      noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
    />
  );
};

export default SalesByDay;

const WindowIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9H23M3.7 21H20.3C21.7912 21 23 19.7912 23 18.3V5.7C23 4.20883 21.7912 3 20.3 3H3.7C2.20883 3 1 4.20883 1 5.7V18.3C1 19.7912 2.20883 21 3.7 21Z"
        stroke="#219653"
        strokeWidth="2"
      />
      <mask id="path-2-inside-1_544_13022" fill="white">
        <path d="M5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6Z" />
        <path d="M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6Z" />
        <path d="M11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z" />
      </mask>
      <path
        d="M5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6Z"
        fill="#219653"
      />
      <path
        d="M8 6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6C6 5.44772 6.44772 5 7 5C7.55228 5 8 5.44772 8 6Z"
        fill="#219653"
      />
      <path
        d="M11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6Z"
        fill="#219653"
      />
      <path
        d="M3 6C3 5.44772 3.44772 5 4 5V9C5.65685 9 7 7.65685 7 6H3ZM4 5C4.55228 5 5 5.44772 5 6H1C1 7.65685 2.34315 9 4 9V5ZM5 6C5 6.55228 4.55228 7 4 7V3C2.34315 3 1 4.34315 1 6H5ZM4 7C3.44772 7 3 6.55228 3 6H7C7 4.34315 5.65685 3 4 3V7ZM6 6C6 5.44772 6.44772 5 7 5V9C8.65685 9 10 7.65685 10 6H6ZM7 5C7.55228 5 8 5.44772 8 6H4C4 7.65685 5.34315 9 7 9V5ZM8 6C8 6.55228 7.55228 7 7 7V3C5.34315 3 4 4.34315 4 6H8ZM7 7C6.44772 7 6 6.55228 6 6H10C10 4.34315 8.65685 3 7 3V7ZM9 6C9 5.44772 9.44772 5 10 5V9C11.6569 9 13 7.65685 13 6H9ZM10 5C10.5523 5 11 5.44772 11 6H7C7 7.65685 8.34315 9 10 9V5ZM11 6C11 6.55228 10.5523 7 10 7V3C8.34315 3 7 4.34315 7 6H11ZM10 7C9.44772 7 9 6.55228 9 6H13C13 4.34315 11.6569 3 10 3V7Z"
        fill="#219653"
        mask="url(#path-2-inside-1_544_13022)"
      />
    </svg>
  );
};

export default WindowIcon;

import { useProblemsProducts } from 'hooks/useProblemsProducts';
import PropTypes from 'prop-types';
import { getDetailInfo, getFromTo } from './detailsInfo';
import BarIcon from 'assets/icons/BarIcon';
import DownIcon from 'assets/icons/DownIcon';
import React, { useEffect, useState } from 'react';
import DetailsPopup from './DetailsPopup';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import cn from 'classnames';
import { Skeleton } from 'components/Skeleton';

const ProblemsDetail = ({ propertyKey }) => {
  const { addFilterRange, clearFilterRange } = useFilterReducer();
  const [isPopupActive, setPopupActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isThisChanged, setIsThisChanged] = useState(false);
  const {
    problemProductsSum,
    problemsSettings,
    isLoading: isProblemsLoading,
  } = useProblemsProducts();
  const sumCount =
    !problemProductsSum[propertyKey] || problemProductsSum[propertyKey] === '-'
      ? 0
      : +problemProductsSum[propertyKey];
  const detailInfo = getDetailInfo(propertyKey);
  const settingsValue = problemsSettings.find((el) => el.field === propertyKey) || {};
  const { condition, value } = settingsValue;
  const strCondition = condition !== undefined ? (condition === 0 ? '< ' : '> ') : '';
  const showLoading = isThisChanged && (isLoading || isProblemsLoading);
  const handleUseFilter = () => {
    clearFilterRange();
    addFilterRange({
      value:
        propertyKey === 'prime_cost_qty'
          ? ['0', '0']
          : getFromTo(detailInfo.maxValue, detailInfo.minValue, condition, +value),
      key: detailInfo.tableKey,
    });
  };
  const resultValue =
    propertyKey === 'prime_cost_qty'
      ? sumCount
      : `${strCondition}${
          detailInfo.valueFormatter ? detailInfo.valueFormatter(value || 0) : value || 0
        }${value === undefined ? '' : detailInfo.postfix}`;

  useEffect(() => {
    if (!isProblemsLoading) {
      setIsThisChanged(false);
    }
  }, [isProblemsLoading]);
  return (
    <div className="block min-w-[180px] lg:w-[14.3%] border border-[#E8E8E8] bg-[#FAFAFA] rounded-lg py-3 px-4">
      <span className="block text-gray-400 mb-3 text-xs lg:text-sm lg:leading-[17px]">
        {detailInfo.label}
      </span>
      <div className="flex items-center">
        <span
          className={cn(
            'block text-2xl leading-[29px] font-medium pr-2 border-r border-gray-230 mr-2 whitespace-nowrap',
            {
              '!border-none': propertyKey === 'prime_cost_qty',
            },
          )}
        >
          {showLoading ? <Skeleton classNames="h-[29px] w-[70px]" /> : resultValue}
        </span>
        {propertyKey !== 'prime_cost_qty' && (
          <button
            className="text-[#C4C4C4] hover:!text-green relative"
            onClick={() => setPopupActive(true)}
          >
            <BarIcon width={16} height={16} />
          </button>
        )}
        <div className="relative">
          {isPopupActive && (
            <DetailsPopup
              setPopupActive={setPopupActive}
              isPercent={detailInfo.isPercent}
              maxValue={detailInfo.maxValue}
              minValue={detailInfo.minValue}
              isNumber={detailInfo.isNumber}
              value={value || 0}
              condition={condition}
              field={propertyKey}
              setIsLoading={setIsLoading}
              setIsThisChanged={setIsThisChanged}
            />
          )}
        </div>
      </div>
      <div className="mt-2 border-t border-[#D9D9D9] border-dashed pt-2">
        {showLoading ? (
          <Skeleton classNames="h-[17px] w-[85px]" />
        ) : (
          <>
            {sumCount === 0 ? (
              <span className="text-sm leading-[17px] text-gray-400"> Нет товаров</span>
            ) : (
              <button
                className="flex items-center text-green hover:text-gray-700 text-sm leading-[17px]"
                onClick={handleUseFilter}
              >
                <span className="block mr-0.5">{`${sumCount} товаров`}</span>
                <DownIcon width={12} height={12} className="-rotate-90 ml-0.5 relative top-[1px]" />
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ProblemsDetail.propTypes = {
  propertyKey: PropTypes.string,
};

export default ProblemsDetail;

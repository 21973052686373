import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FrontLoginForm from './FrontLoginForm';
import { PAGE_ADVERTISING_RATES } from 'constants/pages';
import { UnauthorizedAdvertisingRates } from 'pages';

const FrontUnauthenticatedApp = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Switch>
        <Route path={PAGE_ADVERTISING_RATES} component={UnauthorizedAdvertisingRates} />
        <Route path="/" component={FrontLoginForm} />
      </Switch>
    </div>
  );
};

export default FrontUnauthenticatedApp;

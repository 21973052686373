const GREEN = 'var(--color-green)';
const GREEN_LIGHT = 'var(--color-green-light)';
const SALAD = 'var(--color-salad)';
const GRAY = 'var(--color-gray)';
const GRAY_100 = 'var(--color-gray-100)';
const PURPLE = 'var(--color-purple)';
const ROSE = 'var(--color-rose)';
const ORANGE = 'var(--color-orange)';
const DESERT = 'var(--color-desert)';
const PINK = 'var(--color-pink)';
const MOJO = 'var(--color-mojo)';
const BLUE = 'var(--color-blue)';
const VIOLET = 'var(--color-violet)';
const BROWN = 'var(--color-brown)';
const CEMENT = 'var(--color-cement)';
const GRAY_DARK = 'var(--color-gray-dark)';
const GRAY_LIGHT = 'var(--color-gray-light)';
const MALIBU = 'var(--color-malibu)';
const MOON = 'var(--color-moon)';
const BOUQUET = 'var(--color-bouquet)';
const SALMON = 'var(--color-salmon)';
const PINE = 'var(--color-pine)';
const MINT = 'var(--color-mint)';
const BLACK = 'var(--color-black)';
const NEPAL = 'var(--color-nepal)';
const VIKING = 'var(--color-viking)';
const HELIOTROPE = 'var(--color-heliotrope)';
const YELLOW = 'var(--color-yellow)';
const YELLOW_LIGHT = 'var(--color-yellow-light)';
const LEAF = 'var(--color-leaf)';
const CONTESSA = 'var(--color-contessa)';
const HOT_PINK = 'var(--color-hot-pink)';
const YUMA = 'var(--color-yuma)';
const GRAY_500 = 'var(--color-gray-500)';
const BRAND_WB = 'var(--color-wb)';
const FLIRT = 'var(--color-flirt)';
const BRAND_OZON = 'var(--color-ozon)';
const SCIENCE_BLUE = 'var(--color-science-blue)';
const FUEL_YELLOW = 'var(--color-fuel-yellow)';
const BRANDY_PUNCH = 'var(--color-brandy-punch)';
const SILVER_CHALICE = 'var(--color-silver-chalice)';
const ABBEY = 'var(--color-abbey)';
const PARADISO = 'var(--color-paradiso)';
const HIPPIE_BLUE = 'var(--color-hippie-blue)';
const BARLEY_CORN = 'var(--color-barley-corn)';
const COPPER_RUST = 'var(--color-copper-rust)';

const CHARTS_COLORS_WB = [BRAND_WB, ORANGE];
const CHARTS_COLORS_OZON = [BRAND_OZON, SCIENCE_BLUE];

const COLORS_PROPS = [
  GREEN,
  GREEN_LIGHT,
  GRAY,
  GRAY_100,
  PURPLE,
  ORANGE,
  PINK,
  BLUE,
  GRAY_DARK,
  BROWN,
  SALAD,
  VIOLET,
  ROSE,
  DESERT,
  MOJO,
  CEMENT,
  MALIBU,
  MOON,
  BOUQUET,
  SALMON,
  PINE,
  MINT,
  NEPAL,
  VIKING,
  HELIOTROPE,
  YELLOW,
  LEAF,
  CONTESSA,
  HOT_PINK,
  YUMA,
  YELLOW_LIGHT,
  GRAY_LIGHT,
  GRAY_500,
  BRAND_WB,
  FLIRT,
  BRAND_OZON,
  SCIENCE_BLUE,
  FUEL_YELLOW,
  BRANDY_PUNCH,
  SILVER_CHALICE,
  ABBEY,
  PARADISO,
  HIPPIE_BLUE,
  BARLEY_CORN,
  COPPER_RUST,
];

module.exports = {
  GREEN,
  GREEN_LIGHT,
  SALAD,
  GRAY,
  GRAY_100,
  PURPLE,
  ORANGE,
  DESERT,
  PINK,
  ROSE,
  MOJO,
  BLUE,
  GRAY_DARK,
  BROWN,
  VIOLET,
  CEMENT,
  MALIBU,
  MOON,
  BOUQUET,
  SALMON,
  PINE,
  MINT,
  BLACK,
  NEPAL,
  VIKING,
  HELIOTROPE,
  YELLOW,
  LEAF,
  CONTESSA,
  HOT_PINK,
  YUMA,
  YELLOW_LIGHT,
  COLORS_PROPS,
  GRAY_LIGHT,
  GRAY_500,
  BRAND_WB,
  FLIRT,
  BRAND_OZON,
  SCIENCE_BLUE,
  CHARTS_COLORS_WB,
  CHARTS_COLORS_OZON,
  FUEL_YELLOW,
  BRANDY_PUNCH,
  SILVER_CHALICE,
  ABBEY,
  PARADISO,
  HIPPIE_BLUE,
  BARLEY_CORN,
  COPPER_RUST,
};

import React, { useEffect } from 'react';

import client from 'services/activates';
import ActivationCard from 'components/ActivationCard';

const ActivateFullAccess = () => {
  useEffect(() => {
    client.postActiveFormRequest();
  }, []);

  return (
    <ActivationCard
      title={`Мы получили вашу заявку<br/>на полный доступ`}
      description="В ближайшее время мы отправим вам ссылку для&nbsp;активации полного доступа на&nbsp;5&nbsp;дней."
    />
  );
};

export default ActivateFullAccess;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchCategories } from 'hooks/useSearch';
import { useDebounce } from 'hooks';
import { useModalFilter } from 'context/filter.modal.context';
import {
  removeDuplicates,
  getFlatList,
  getParentKey,
  wrapSearchResults,
  removeKeyFromCategories,
} from 'helpers/utils';
import { SearchInput } from 'components/FilterModal/components';
import LoadingSpin from 'components/LoadingSpin';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import Chip from 'components/Chip';
import { ReactComponent as SearchIcon } from 'assets/images/searchCurrent.svg';
import { TreeView } from 'components/Tree';

const PositionsCategories = ({ selected, setSelected }) => {
  const { filter, chips } = useFilterReducer();
  const [expandedKeys, setExpandedKeys] = useState(filter?.category || []);
  const [search, setSearch] = useState('');

  const { data, isLoading } = useSearchCategories();
  const treeData = data?.[0]?.children || [];

  const {
    state: { localFilter, touched, localChips },
    updateCategoriesList,
  } = useModalFilter();

  useEffect(() => {
    updateCategoriesList(selected ? [{ ...selected }] : []);
  }, [selected, updateCategoriesList]);

  const debouncedSearch = useDebounce(search, 500);

  const addExpandedKey = (prev, key) => (prev.indexOf(key) < 0 ? [...prev, key] : prev);

  const removeExpandedKey = (prev, key) => {
    const index = prev.indexOf(key);
    if (index >= 0) {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    }

    return prev;
  };

  const onCategoryCheck = ({ key, title, checked }) => {
    let result = [];
    if (!checked) {
      result = [...localChips?.category, { key, title }];
      setSelected({ key, title });
      setExpandedKeys((prev) => addExpandedKey(prev, key));
    } else {
      result = removeKeyFromCategories(localChips?.category, key);
      setSelected();
      setExpandedKeys((prev) => removeExpandedKey(prev, key));
    }
    updateCategoriesList(removeDuplicates(result) || []);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const selectedKeysFromFilter = localFilter?.category; // All nodes from localChips at localFilter;
  const selectedKeysFromChips = chips.category.map((item) => item.key) || []; // All keys from chips;

  const resultingCheckedKeys = [
    ...selectedKeysFromFilter, // we get selected keys from filter
    ...(touched.category ? [] : selectedKeysFromChips), // we add keys from chips, if category wasn't touched;
  ];

  let resultingExpandedKeys = expandedKeys;
  let resultingTreeData = treeData;
  let message = '';

  if (debouncedSearch.length >= 3) {
    // if we've debounced search - transform treeData and set list of expanded keys;
    const flatList = getFlatList(treeData);

    const expandedWithSearch = flatList
      .map((item) => {
        return item.title.toLowerCase().indexOf(debouncedSearch.toLowerCase()) > -1
          ? getParentKey(item.key, treeData)
          : null;
      })
      .filter(Boolean);

    resultingExpandedKeys = [...expandedKeys, ...expandedWithSearch];

    resultingTreeData = wrapSearchResults(treeData, debouncedSearch);

    if (!resultingTreeData.length) {
      message = 'По вашему запросу нет данных. Попробуйте изменить фильтры';
    }
  }
  const isDisabled = selected;
  const onExpand = (key) => {
    const newExpKeys = [...resultingExpandedKeys];
    const index = newExpKeys.indexOf(key);
    if (index === -1) {
      newExpKeys.push(key);
    } else {
      newExpKeys.splice(index, 1);
    }
    setExpandedKeys(newExpKeys);
  };
  return (
    <React.Fragment>
      {isDisabled ? (
        <div className="relative mb-6">
          <span className="absolute top-[12px] left-[10px]">
            <SearchIcon />
          </span>
          <div className="w-full appearance-none border border-gray-250 pl-8 pr-1.5 py-0.5 h-[40px] leading-none">
            <Chip
              title={selected.title}
              action={() => {
                setSelected();
                updateCategoriesList();
              }}
              color="bg-blue"
              className="w-min"
            />
          </div>
        </div>
      ) : (
        <SearchInput
          value={search}
          onChange={onChange}
          placeholder="Поиск по категориям"
          isLoading={isLoading}
        />
      )}
      <div
        className="
        flex-1
        sm:h-full
        "
      >
        {isLoading ? (
          <LoadingSpin isVisible={true} />
        ) : (
          <div
            className="
              overflow-y-auto
              sm:h-full md:max-h-[400px]
            "
          >
            {message}
            <TreeView
              data={resultingTreeData}
              onExpand={({ key }) => onExpand(key)}
              expandedKeys={resultingExpandedKeys}
              onCheck={({ key, title }, checked) => onCategoryCheck({ key, title, checked })}
              checkedKeys={resultingCheckedKeys}
              checkboxRender={({ level }) => level !== 0}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

PositionsCategories.propTypes = {
  selected: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelected: PropTypes.func.isRequired,
};

export { PositionsCategories };

const ConsultIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.91895 20.2779C8.91895 18.5178 10.5222 18.6845 12.4998 18.6845C14.4775 18.6845 16.0807 18.5178 16.0807 20.2779M8 4H12M15 13.5C15 14.8807 13.8807 16 12.5 16C11.1193 16 10 14.8807 10 13.5C10 12.1193 11.1193 11 12.5 11C13.8807 11 15 12.1193 15 13.5ZM4 23H16C17.6569 23 19 21.6569 19 20V4C19 2.34315 17.6569 1 16 1H4C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23Z"
        stroke="#219653"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ConsultIcon;

import { PAYMENT_FIELDS_NAMES } from 'context/payment.context';

export const generatePaymentsRequestOptions = (tariffs = false, exclude = null) => {
  if (!tariffs) return {};

  tariffs = tariffs.filter((tariff) => tariff?.type_id !== exclude);

  const result = {
    [PAYMENT_FIELDS_NAMES.PLATFORM]: [],
    [PAYMENT_FIELDS_NAMES.TARIFF]: [],
    [PAYMENT_FIELDS_NAMES.PERIOD]: [],
  };

  const periodsByIds = {};

  tariffs.forEach((tariff) => {
    // if (
    //   !result[PAYMENT_FIELDS_NAMES.PLATFORM].find(
    //     (platform) => platform.value === tariff?.platform_id,
    //   )
    // ) {
    //   result[PAYMENT_FIELDS_NAMES.PLATFORM].push({
    //     label: tariff?.platform,
    //     value: tariff?.platform_id,
    //   });
    // }

    if (!result[PAYMENT_FIELDS_NAMES.PLATFORM].length) {
      result[PAYMENT_FIELDS_NAMES.PLATFORM] = tariff?.platform_id;
    }

    tariff?.periods.forEach((tariffPeriod) => {
      if (!periodsByIds[tariffPeriod?.period_id]) {
        periodsByIds[tariffPeriod?.period_id] = {
          label: tariffPeriod?.period,
          value: tariffPeriod?.period_id,
          quantile: tariffPeriod?.period_quantile,
        };
      }
    });

    result[PAYMENT_FIELDS_NAMES.PERIOD] = Object.values(periodsByIds);

    if (!result[PAYMENT_FIELDS_NAMES.TARIFF].find((type) => type.label === tariff?.type)) {
      result[PAYMENT_FIELDS_NAMES.TARIFF].push({
        label: tariff?.type,
        value: tariff?.type_id,
        periods: tariff?.periods.map((period) => {
          const fullPrice =
            tariff?.periods.find(({ period_length }) => period_length === 1).price || 0;
          const priceForPeriod = period.price / period.period_length;
          const percent = (1 - priceForPeriod / fullPrice) * 100;

          return {
            value: period?.period_id,
            price: priceForPeriod,
            percent: Math.round(percent) || null,
          };
        }),
      });
    }
  });

  return result;
};

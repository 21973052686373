const getResultValue = (value) => {
  switch (value) {
    case undefined: {
      return '-';
    }
    case null: {
      return 0;
    }
    default: {
      return value;
    }
  }
};

export const innerTableTooltipWrapper = (columns) =>
  columns.map((col) => {
    const { headerName, tooltipValueGetter } = col;
    return {
      ...col,
      tooltipValueGetter: tooltipValueGetter
        ? (params) => {
            const toolTipValue = tooltipValueGetter(params);
            const resultValue = getResultValue(toolTipValue);
            return String(toolTipValue).includes(':')
              ? resultValue
              : `${headerName}: ${resultValue}`;
          }
        : (params) =>
            String(getResultValue(params?.value)).includes(':')
              ? getResultValue(params?.value)
              : `${headerName}: ${getResultValue(params?.value)}`,
    };
  });

import PropTypes from 'prop-types';

const DeliverySearchAllInfo = ({ sum_data }) => {
  return (
    <div>
      <div className="bg-[#F0F0F0] p-4 flex flex-col lg:flex-row space-y-2 lg:space-y-0">
        <div className="rounded-lg border border-[#E8E8E8] bg-[#FAFAFA] py-3 px-4 ">
          <div className="flex-col items-start lg:flex-row flex lg:items-center">
            <span className="whitespace-nowrap pb-2 lg:pb-0">
              <span className="text-2xl text-black inline-block">
                {(sum_data.total_delivery_qty || 0).toLocaleString()}
              </span>
              <span className="text-2xl text-[#858585] inline-block ml-1">{'шт'}</span>
            </span>
            <div className="h-px w-full lg:w-px lg:h-[50px] bg-[#E8E8E8] lg:mx-3" />
            <div className="mt-2 lg:mt-0 block text-[#858585]">
              <span className="inline-block lg:block">Количество товаров</span>
              <span className="inline-block ml-1 lg:ml-0 lg:block">в поставке</span>
            </div>
          </div>
        </div>
        <div className="rounded-lg border border-[#E8E8E8] bg-[#FAFAFA] py-3 px-4 lg:mx-4">
          <div className="flex-col items-start lg:flex-row flex lg:items-center">
            <span className="whitespace-nowrap pb-2 lg:pb-0">
              <span className="text-2xl text-black inline-block">
                {(sum_data.total_prime_cost_delivery_qty || 0).toLocaleString()}
              </span>
              <span className="text-2xl text-[#858585] inline-block ml-1">{'₽'}</span>
            </span>
            <div className="h-px w-full lg:w-px lg:h-[50px] bg-[#E8E8E8] lg:mx-3" />
            <div className="mt-2 lg:mt-0 block text-[#858585]">
              <span className="inline-block lg:block">Себестоимость</span>
              <span className="inline-block ml-1 lg:ml-0 lg:block">поставки</span>
            </div>
          </div>
        </div>
        <div className="rounded-lg border border-[#E8E8E8] bg-[#FAFAFA] py-3 px-4 ">
          <div className="flex-col items-start lg:flex-row flex lg:items-center">
            <span className="whitespace-nowrap pb-2 lg:pb-0">
              <span className="text-2xl text-black inline-block">
                {(sum_data.total_retail_cost_delivery_qty || 0).toLocaleString()}
              </span>
              <span className="text-2xl text-[#858585] inline-block ml-1">{'₽'}</span>
            </span>
            <div className="h-px w-full lg:w-px lg:h-[50px] bg-[#E8E8E8] lg:mx-3" />
            <div className="mt-2 lg:mt-0 block text-[#858585]">
              <span className="inline-block lg:block">Розничная стоимость</span>
              <span className="inline-block ml-1 lg:ml-0 lg:block">поставки</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DeliverySearchAllInfo.propTypes = {
  sum_data: PropTypes.any,
};

export { DeliverySearchAllInfo };

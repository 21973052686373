import PropTypes from 'prop-types';

export function ChipWithCount({ title, count, ...etc }) {
  return (
    <div
      className="flex gap-2 items-center font-normal font-SofiaPro leading-5 w-full pr-2"
      {...etc}
    >
      <p className="text-black">{title}</p>
      <div className="grow lg:border-b lg:border-gray-250 h-3.5 lg:border-dashed" />
      <p className="text-gray-700 whitespace-nowrap">{count.toLocaleString('ru')} шт</p>
    </div>
  );
}

ChipWithCount.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

import React from 'react';
import { useAnalyticType } from 'context/analytic.type.context';
import { useAuth } from 'context/auth.context';
import classNames from 'classnames';

const InnerNotification = () => {
  const { isInner } = useAnalyticType();
  const { tariff } = useAuth();
  if (!isInner) {
    return null;
  }
  const showBorderBottom = !(tariff && tariff.project_name !== 'Расширение WB');
  return (
    <div
      className={classNames(
        'text-center text-gray-700 leading-4 bg-yellow py-3 px-2 text-[13px] header__notification mt-0',
        {
          'border-b border-[#D8D8C0]': showBorderBottom,
        },
      )}
    >
      Это бета-версия внутренней аналитики. Если обнаружили неточность,{' '}
      <span className={'text-green cursor-pointer'}>напишите в чат</span> или на
      <a className="underline ml-1" href="mailto:info@wecheck.ru">
        info@wecheck.ru
      </a>
    </div>
  );
};

export default InnerNotification;

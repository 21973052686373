import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import LoginForm from 'components/LoginForm';
import { useAuth } from 'context/auth.context';
import { AuthorizationLayout } from 'components/Layout';

const ActivateDemo = () => {
  const [verified, setVerified] = useState(false);
  const [verifying, setVerifying] = useState(true);

  const [error, setError] = useState('');
  const { tariffId, token } = useParams();
  const { user, activateDemo, updateUser, tariff } = useAuth();

  useEffect(() => {
    user &&
      !verified &&
      activateDemo({ tariffId, token })
        .then((result) => {
          if (result.success) {
            setError('');
          }

          setVerified(result.success);
          setVerifying(false);
        })
        .catch(() => {
          setError('Ошибка.');
          setVerifying(false);

          setVerified(true);
        });

    return () => {
      setError(null);
      setVerifying(false);
      setVerified(true);
    };
  }, [user, activateDemo, tariffId, token, verified]);

  if (!user) {
    return (
      <AuthorizationLayout hideButtonBack={true}>
        <LoginForm showNavigation={false} />
      </AuthorizationLayout>
    );
  }

  if (!verifying && verified && !tariff) {
    updateUser();

    return <></>;
  }

  if (tariff) {
    return <Redirect to="/main" />;
  }

  return (
    <>
      <h1 className="pb-3 text-xl max-w-full w-72 text-center">
        {error
          ? error
          : verifying
          ? 'Доступ активируется...'
          : verified
          ? 'Доступ актвирован!'
          : ''}
      </h1>
    </>
  );
};

export default ActivateDemo;

import React, { useMemo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useFetchProfileData } from 'hooks/useFetchProfileData';
import { ACTION_TYPE } from 'constants/profile';
import { usePayment } from 'context/payment.context';

import Pagination from 'components/Pagination';
import DownloadIcon from 'assets/icons/DownloadIcon';
import HelpModal from 'components/HelpModal/HelpModal';
import apiClient from 'services/baseClient';

const PAGE_COUNT = 10;

const Table = ({ cols, children, className }) => (
  <div className={`flex overflow-y-auto overflow-y-hidden mx-[-0.75rem] mb-4 lg:mx-0 ${className}`}>
    <div className={`grid grid-cols-[repeat(${cols},_auto)] grow`}>{children}</div>
  </div>
);
Table.defaultProps = {
  className: '',
};
Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    ),
  ]),
  cols: PropTypes.number.isRequired,
  className: PropTypes.string,
};

const HeaderColumn = ({ children }) => (
  <div className="border-t border-b border-gray-100 lg:border-t-0 text-xs font-bold bg-gray-100 whitespace-nowrap py-2 px-3 lg:text-base lg:p-0 lg:bg-white lg:border-b lg:border-gray-300 lg:pb-2">
    {children}
  </div>
);

HeaderColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

const handleActClick = (id) => {
  apiClient.getOrderAct(id);
};

const ActLoader = ({ hasAct, id }) => {
  return (
    <div
      className={`${hasAct ? 'text-green' : 'text-gray-250'} group relative cursor-pointer`}
      onClick={hasAct ? () => handleActClick(id) : null}
    >
      <DownloadIcon />
      {!hasAct && (
        <div className="absolute -left-[280px] -top-8">
          <HelpModal
            title={
              <span>
                Этот счет был оплачен онлайн на
                <br />
                сайте. Чек отправлен на почту
              </span>
            }
            orientation="left"
            externalClasses="opacity-100 z-[1] min-w-max"
          />
        </div>
      )}
    </div>
  );
};
ActLoader.propTypes = {
  hasAct: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
};

const TableCell = ({ children, cols }) => (
  <div
    className={`flex items-center border-b border-gray-100 even-row-${cols}:bg-gray-50 lg:even-row-${cols}:bg-transparent lg:border-0 leading-none text-xs py-2 px-3 lg:px-0 lg:py-4 lg:text-base lg:font-thin`}
  >
    {children}
  </div>
);
TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  cols: PropTypes.number.isRequired,
};

const periodDates = (period, paymentDate) => {
  const monthCount = parseInt(period);

  const startDate = moment(paymentDate, 'DD.MM.YYYY').format('DD.MM.YY');

  const endDate = moment(paymentDate, 'DD.MM.YYYY').add(monthCount, 'month').format('DD.MM.YY');

  return `${startDate} - ${endDate}`;
};

const Subscriptions = () => {
  const { openPaymentWithTariff, paymentRequestFields } = usePayment();

  const [ordersPage, setOrdersPage] = useState(1);
  const { data: subscriptionsList } = useFetchProfileData({
    type: ACTION_TYPE.SUBSCRIPTIONS_LIST,
  });

  const { data: ordersList } = useFetchProfileData({
    type: ACTION_TYPE.ORDERS_LIST,
  });

  const activeSubscriptions = useMemo(
    () => subscriptionsList?.filter((item) => item?.active),
    [subscriptionsList],
  );

  const ordersPaginatedList = useMemo(
    () => ordersList?.slice((ordersPage - 1) * PAGE_COUNT, ordersPage * PAGE_COUNT),
    [ordersPage, ordersList],
  );

  const handleExtend =
    ({ type_id }) =>
    () =>
      openPaymentWithTariff(type_id);

  const handleAddSubscription = () => {
    openPaymentWithTariff(paymentRequestFields[0].value);
  };

  return (
    <div className="px-3 lg:px-8">
      <h2 className="text-xl font-bold mb-2 lg:mb-4 mt-2 lg:mt-7 lg:mb-8 lg:text-2xl lg:mt-14">
        Активные подписки
      </h2>
      <Table cols={4} className="lg:max-w-2xl">
        <HeaderColumn>Услуга</HeaderColumn>
        <HeaderColumn>Сайты</HeaderColumn>
        <HeaderColumn>Срок действия</HeaderColumn>
        <HeaderColumn />
        {activeSubscriptions?.map((tariff, index) => (
          <Fragment key={index}>
            <TableCell cols={4}>{tariff.type}</TableCell>
            <TableCell cols={4}>{tariff.platform || tariff.platform_id}</TableCell>
            <TableCell cols={4}>{moment(tariff.active_to).format('DD.MM.YY')}</TableCell>
            <TableCell cols={4}>
              <button
                className="cursor-pointer text-green font-bold"
                onClick={handleExtend(tariff)}
              >
                Продлить
              </button>
            </TableCell>
          </Fragment>
        ))}
      </Table>
      <button
        className="bg-green text-sm text-white py-2 px-10 br-5 rounded"
        onClick={handleAddSubscription}
      >
        Добавить подписку
      </button>

      <h2 className="text-xl font-bold mb-4 mt-7 lg:mb-8 lg:text-2xl lg:mt-20">История оплат</h2>
      <Table cols={7} className="mb-4">
        <HeaderColumn>Оплата</HeaderColumn>
        <HeaderColumn>Сайты</HeaderColumn>
        <HeaderColumn>Период действия</HeaderColumn>
        <HeaderColumn>Сумма</HeaderColumn>
        <HeaderColumn>Способ оплаты</HeaderColumn>
        <HeaderColumn>Статус</HeaderColumn>
        <HeaderColumn>Акт</HeaderColumn>
        {ordersPaginatedList?.map((order, index) => (
          <Fragment key={index}>
            <TableCell cols={7}>{order.payment_date}</TableCell>
            <TableCell cols={7}>{order.platform}</TableCell>
            <TableCell cols={7}>{periodDates(order.period, order.date)}</TableCell>
            <TableCell cols={7}>
              <span className="font-bold">{order.amount.toLocaleString()}&nbsp;р</span>
            </TableCell>
            <TableCell cols={7}>{order.payment_type}</TableCell>
            <TableCell cols={7}>{order.status}</TableCell>
            <TableCell cols={7}>
              <ActLoader hasAct={order.has_act_file} id={order.id} />
            </TableCell>
          </Fragment>
        ))}
      </Table>

      <Pagination
        getPage={(page) => setOrdersPage(page)}
        getNextPage={() => setOrdersPage(ordersPage + 1)}
        currentPage={ordersPage}
        getPrevPage={() => setOrdersPage(ordersPage - 1)}
        totalPages={Math.ceil(ordersList?.length / PAGE_COUNT)}
        className="lg:mb-14"
      />
    </div>
  );
};

export default Subscriptions;

export const formatNumber = (value) => {
  if (value === '-') return null;

  if (value === 0) return '0';

  if (!value) return '';

  if (typeof value === 'string') {
    value = parseInt(value);
  }

  if (value <= 0.01) return '<0,01';

  if (!value.toFixed) return value;

  value = Math.round(value * 10) / 10;

  return value.toLocaleString('ru');
};

export const getQtyPostfix = (value) => {
  const lastNumber = +`${value}`[value.length - 1];

  if (value > 10 && value < 20) {
    return 'раз';
  }

  if (lastNumber === 2 || lastNumber === 3 || lastNumber === 4) {
    return 'раза';
  }

  return 'раз';
};

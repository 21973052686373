import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import React from 'react';
import { MODAL_MODES } from '../constants';
import SignIn from './SignInContent';
import { useAuth } from 'context/auth.context';
import PasswordRecovery from './PasswordRecoveryContent';
import PasswordRecoverySuccess from './PasswordRecoverySuccessContent';
import SignUp from './SignUpContent';
import SignUpSuccess from './SignUpSuccessContent';
import { IS_PRODUCTION } from 'constants/environment';
import gtag from 'ga-gtag';
import ym from 'react-yandex-metrika';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import ButtonPrimary from 'components/ButtonPrimary';

const MODAL_INFO = {
  [MODAL_MODES.SIGN_IN]: {
    title: 'Вход в систему',
    submitButtonText: 'Продолжить',
    content: SignIn,
    externalFooterContent: ({ setMode, setState }) => (
      <button
        className="border border-gray-250 px-3 py-1 rounded"
        onClick={() => {
          setMode(MODAL_MODES.PASSWORD_RECOVERY);
          setState({});
        }}
      >
        Забыли пароль?
      </button>
    ),
    onSubmit: async ({ state, authContext, setState, setIsLoading }) => {
      setIsLoading(true);
      const response = await authContext.login({ username: state.email, password: state.password });
      if (response) {
        if (IS_PRODUCTION) {
          gtag('event', 'user_login', {
            login: 'success',
          });

          ym('reachGoal', 'login_success');
        }
        setIsLoading(false);
        //window.location.assign(window.location);
        window.location.pathname = '/navigation';
        setState({});
      } else {
        setIsLoading(false);
        setState({ ...state, error: true });
      }
    },
  },
  [MODAL_MODES.REGISTRATION]: {
    title: 'Регистрация',
    content: SignUp,
    submitButtonText: 'Регистрация',
    externalFooterContent: ({ setState, setIsModalOpen }) => (
      <button
        className="border border-gray-250 px-3 py-1 rounded"
        onClick={() => {
          setIsModalOpen(false);
          setState({});
        }}
      >
        Закрыть
      </button>
    ),
    submitDisabled: ({ state }) => {
      const { email, name, password, passwordConfirm, confirm } = state;
      return !(email && name && password && passwordConfirm && confirm);
    },
    onSubmit: async ({ state, authContext, setState, setMode, setIsLoading }) => {
      setIsLoading(true);
      const { email, name, password, passwordConfirm } = state;
      if (password === passwordConfirm) {
        if (password.length < 6) {
          setState({ ...state, error: { password: true } });
        } else {
          if (IS_PRODUCTION) {
            gtag('event', 'click_button', {
              button: 'registration',
            });
            ym('reachGoal', 'click_registration');
          }
          const response = await authContext.register({ email, name, password });
          if (!response.error) {
            if (IS_PRODUCTION) {
              gtag('event', 'user_registration', {
                registration: 'succes',
              });
              ym('reachGoal', 'registration_succes');
            }
            setMode(MODAL_MODES.REGISTRATION_SUCCESS);
          } else {
            setState({ ...state, error: response.error });
          }
        }
      } else {
        setState({ ...state, error: { passwordConfirm: true } });
      }
      setIsLoading(false);
    },
  },
  [MODAL_MODES.REGISTRATION_SUCCESS]: {
    content: SignUpSuccess,
    submitButtonText: 'Хорошо',
    onSubmit: ({ setIsModalOpen, setState }) => {
      setIsModalOpen(false);
      setState({});
    },
  },
  [MODAL_MODES.PASSWORD_RECOVERY]: {
    title: 'Восстановление пароля',
    content: PasswordRecovery,
    submitButtonText: 'Отправить мне ссылку',
    externalFooterContent: ({ setState, setIsModalOpen }) => (
      <button
        className="border border-gray-250 px-3 py-1 rounded"
        onClick={() => {
          setIsModalOpen(false);
          setState({});
        }}
      >
        Закрыть
      </button>
    ),
    onSubmit: async ({ setMode, authContext, state }) => {
      const { success } = await authContext.recoverForEmail(state.email);
      if (success) {
        if (IS_PRODUCTION) {
          gtag('event', 'user_password', {
            password: 'send_email',
          });
          ym('reachGoal', 'password_send_email');
        }
        setMode(MODAL_MODES.PASSWORD_RECOVERY_SUCCESS);
      }
    },
  },
  [MODAL_MODES.PASSWORD_RECOVERY_SUCCESS]: {
    title: 'Восстановление пароля',
    content: PasswordRecoverySuccess,
    submitButtonText: 'Хорошо',
    externalFooterContent: ({ setState, setIsModalOpen }) => (
      <button
        className="border border-gray-250 px-3 py-1 rounded"
        onClick={() => {
          setIsModalOpen(false);
          setState({});
        }}
      >
        Закрыть
      </button>
    ),
    onSubmit: ({ setState, setIsModalOpen }) => {
      setIsModalOpen(false);
      setState({});
    },
  },
};

const ModalContent = (props) => {
  const { isModalOpen, setIsModalOpen, mode } = props;
  const { isMobile } = useUI();
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useAuth();
  const Content = MODAL_INFO[mode]?.content;
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setState({});
  };
  const submitDisabled = MODAL_INFO[mode]?.submitDisabled
    ? MODAL_INFO[mode]?.submitDisabled({ ...props, state, setState, authContext })
    : false;
  const submitModal = () =>
    MODAL_INFO[mode]?.onSubmit({ ...props, state, setState, authContext, isLoading, setIsLoading });
  const externalFooterContent =
    MODAL_INFO[mode]?.externalFooterContent &&
    MODAL_INFO[mode].externalFooterContent({ ...props, state, setState, authContext });
  if (isMobile) {
    return (
      <MobileInterfaceV2
        onPanelClose={handleCloseModal}
        rootClassName="bottom-0 absolute"
        isActive={isModalOpen}
        headerText={MODAL_INFO[mode]?.title}
      >
        <div className="p-4">
          {MODAL_INFO[mode] && <Content state={state} setState={setState} />}
        </div>
        <div
          className={`border-t border-gray-250 px-6 py-4 flex items-center ${
            externalFooterContent ? 'justify-between' : 'justify-end'
          } gap-2`}
        >
          {externalFooterContent}
          <ButtonPrimary
            onClick={submitModal}
            label={MODAL_INFO[mode]?.submitButtonText || 'Применить'}
            disabled={submitDisabled}
          />
        </div>
      </MobileInterfaceV2>
    );
  }
  return (
    <Modal
      isOpen={isModalOpen}
      title={MODAL_INFO[mode]?.title}
      submitButtonText={MODAL_INFO[mode]?.submitButtonText}
      closeModal={handleCloseModal}
      submitModal={submitModal}
      externalFooterContent={externalFooterContent}
      renderCancelButton={false}
      submitButtonDisabled={submitDisabled}
      width={452}
    >
      {MODAL_INFO[mode] && <Content state={state} setState={setState} isLoading={isLoading} />}
    </Modal>
  );
};
ModalContent.propTypes = {
  setIsModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  mode: PropTypes.string,
  setMode: PropTypes.func,
};

export default ModalContent;

const BellIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.107 18.4174C15.0663 18.6429 14.9576 18.8505 14.7956 19.0125C14.3564 19.4518 13.6443 19.4518 13.2051 19.0125L4.98761 10.7951C4.5484 10.3559 4.5484 9.64379 4.98761 9.20459C5.14963 9.04256 5.35728 8.93389 5.58277 8.89313L8.15749 8.42762C8.43087 8.37819 8.68264 8.24644 8.87909 8.04999L10.8067 6.12242C12.7593 4.1698 15.9251 4.1698 17.8777 6.12243C19.8303 8.07505 19.8303 11.2409 17.8777 13.1935L15.9502 15.1211C15.7537 15.3175 15.622 15.5693 15.5725 15.8427L15.107 18.4174ZM5.22694 6.92503C4.60047 7.0383 4.02355 7.34021 3.57339 7.79037C2.35314 9.01063 2.35314 10.989 3.57339 12.2093L11.7909 20.4268C13.0111 21.647 14.9895 21.647 16.2098 20.4268C16.6599 19.9766 16.9619 19.3997 17.0751 18.7732L17.5052 16.3945L19.2919 14.6077C22.0256 11.874 22.0256 7.44188 19.2919 4.70821C16.5583 1.97454 12.1261 1.97454 9.39244 4.70821L7.60569 6.49496L5.22694 6.92503ZM5.40855 16.392C5.01803 16.0015 4.38486 16.0015 3.99434 16.392C3.60381 16.7825 3.60381 17.4157 3.99434 17.8062L6.19398 20.0058C6.58451 20.3964 7.21767 20.3964 7.6082 20.0058C7.99872 19.6153 7.99872 18.9822 7.6082 18.5916L5.40855 16.392Z"
        fill="white"
      />
      <path
        d="M18.2319 5.76799C18.6224 6.15852 19.2555 6.15852 19.6461 5.76799L21.1455 4.26857C21.536 3.87804 21.536 3.24488 21.1455 2.85435V2.85435C20.755 2.46383 20.1218 2.46383 19.7313 2.85435L18.2319 4.35378C17.8413 4.7443 17.8413 5.37747 18.2319 5.76799V5.76799Z"
        fill="white"
      />
    </svg>
  );
};

export default BellIcon;

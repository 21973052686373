import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'context/auth.context';
import { PAGE_PROFILE_SUBSCRIPTIONS } from 'constants/pages';
import { useUI } from 'context/ui.context';
import cn from 'classnames';

const getDays = (amount) => {
  const last = +amount.toString().slice(amount.toString().length - 1);

  if (last === 1) {
    return 'д.';
  } else if (last > 1 && last < 5) {
    return 'д.';
  } else if (last === 0 || last >= 5) {
    return 'д.';
  }
};

const LIMIT = 5;
const TariffInfo = () => {
  const { tariff } = useAuth();
  const { isMobile, screen } = useUI();
  const daysLeft = tariff?.days_left || 0;

  const classNames = {
    desktop: {
      noTariff: 'rounded-md px-3 py-2 self-center ml-auto text-xs flex flex-wrap bg-green-lightest',
      tariff: `rounded-md px-3 py-2 self-center ml-auto text-xs flex flex-wrap ${
        daysLeft > LIMIT ? 'bg-gray-100' : 'bg-pink-light'
      }`,
    },
    mobile: {
      noTariff:
        'flex flex-col px-3 py-2 self-center mr-5 ml-auto text-xs flex flex-wrap bg-green-lightest',
      tariff: `flex flex-col p-4 self-center ml-auto text-xs flex flex-wrap ${
        daysLeft > LIMIT ? 'bg-gray-100' : 'bg-pink-light'
      }`,
    },
  };

  const className = classNames[screen] || classNames.mobile;

  if (!tariff) {
    return (
      <div className={className.noTariff}>
        <div className="mr-2 text-gray-400">У вас нет активных подписок</div>
        <NavLink to={PAGE_PROFILE_SUBSCRIPTIONS} className="text-green">
          Активировать
        </NavLink>
      </div>
    );
  }

  return (
    <div className={className.tariff}>
      <div
        className={cn({
          ['mr-2']: !isMobile,
          ['text-green']: daysLeft > LIMIT,
          ['text-gray-700']: daysLeft < LIMIT,
          ['text-gray-400 mb-1 text-xs']: isMobile,
        })}
      >
        Тариф: <span>{tariff?.project_name || ''}</span>
      </div>
      <div
        className={cn({
          ['text-gray-400']: daysLeft > LIMIT,
          ['text-red']: daysLeft < LIMIT,
          ['text-black text-sm font-bold']: isMobile,
        })}
      >
        {tariff?.days_left === 0 ? (
          <>Осталось менее 1 д.</>
        ) : (
          <>
            Осталось {tariff?.days_left} {getDays(tariff?.days_left)}
          </>
        )}
      </div>
    </div>
  );
};

export default TariffInfo;

import { MonitoringGroupsProvider } from '../../../context/monitoring.groups.context';
import Monitoring from './Monitoring';

const MonitoringWrapper = () => {
  return (
    <MonitoringGroupsProvider>
      <Monitoring />
    </MonitoringGroupsProvider>
  );
};

export { MonitoringWrapper };

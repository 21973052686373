const RefreshIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.54178L11 5.04178L7.5 8.54178M15 11.4584C15 15.3244 11.866 18.4584 8 18.4584C4.13401 18.4584 1 15.3244 1 11.4584C1 7.59242 4.13401 4.45841 8 4.45841C8.81815 4.45841 9.75 4.45841 10.9167 5.04175"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;

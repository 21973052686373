import React, { useState } from 'react';

import { useFrontAuthentication } from '../../context/front.authentication.context';

const FrontLoginForm = () => {
  const { login, error } = useFrontAuthentication();
  const [values, setValues] = useState({ username: '', password: '' });

  const disabled = () => !values.username || !values.password;

  const handleSubmit = (e) => {
    e.preventDefault();
    login(values);
  };

  return (
    <main className="p-4">
      <form className="w-48 max-w-full">
        {error && (
          <div className="bg-orange w-full text-white text-center p-1.5 text-sm mb-8">{error}</div>
        )}
        <div className="mb-7">
          <label className="block mb-2 text-black flex-auto pr-3" htmlFor="username">
            <span className="text-red-600 pr-1">*</span>
            Login
          </label>
          <input
            className="w-full appearance-none border border-gray-200 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green"
            id="username"
            name="username"
            type="text"
            value={values.username}
            onChange={(e) => setValues((prev) => ({ ...prev, username: e.target.value }))}
          />
        </div>
        <div className="mb-7">
          <label className="block mb-2 text-black flex-auto pr-3" htmlFor="password">
            <span className="text-red-600 pr-1">*</span>
            Password
          </label>
          <input
            className="w-full appearance-none border border-gray-200 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 focus:ring-green"
            id="password"
            name="password"
            type="password"
            value={values.password}
            onChange={(e) => setValues((prev) => ({ ...prev, password: e.target.value }))}
          />
        </div>
        <button
          onClick={handleSubmit}
          disabled={disabled()}
          type="submit"
          className="relative min-h-[38px] w-full inline-flex justify-center py-2 px-4 border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        >
          Submit
        </button>
      </form>
    </main>
  );
};

export default FrontLoginForm;

import { useHistory } from 'react-router';

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchInnerData } from 'hooks/useFetchInnerData';
import { F_INNER } from 'constants/filter';
import DropdownWithCheckboxes from 'components/DropdownWithCheckboxes';
import AddSmthButton from 'components/AddSmthButton';
import { PAGE_API_KEYS } from 'constants';
import { FETCH_INNER } from 'constants/inner';
import { useEffect, useState } from 'react';

const Footer = () => {
  let history = useHistory();

  const handleClick = () => history.push(PAGE_API_KEYS);

  return <AddSmthButton label="Добавить ключ" onClick={handleClick} />;
};

export const PricesApiKeySelect = () => {
  const { isFetched, data: apiKeys = [] } = useFetchInnerData({
    type: FETCH_INNER.API_KEYS_WITH_TYPE,
    filter: { apiKeysType: 2 },
  });
  const [label, setLabel] = useState('Ключ не выбран');
  const apiKeysCount = apiKeys.length || 0;

  const { filter, setApiKeys } = useFilterReducer();
  const apiKeysChecked = filter?.[F_INNER.API_KEYS] || [];
  const checked = apiKeysChecked[0];
  useEffect(() => {
    if (apiKeys.length !== 0) {
      const defaultActiveKey =
        checked &&
        apiKeys.find((el) => el.id === +checked) &&
        apiKeys.find((el) => el.id === +checked).status === 1
          ? apiKeys.find((el) => el.id === +checked)
          : apiKeys.filter((el) => el.status === 1)[0];
      setApiKeys(defaultActiveKey.id);
      setLabel(defaultActiveKey.name);
    }
  }, [isFetched, apiKeys, setApiKeys, checked]);

  const options = [
    ...apiKeys
      .filter((el) => el.status === 1)
      .map((api) => ({
        value: api?.id,
        label: api?.name,
      })),
  ];

  const handleChange = (e) => {
    const id = e.target.name;
    setApiKeys(id);
    setLabel(apiKeys.find((el) => +el.id === +id).name);
  };

  if (!apiKeysCount) {
    return null;
  }

  return (
    <DropdownWithCheckboxes
      label={label}
      checkboxSelection={true}
      options={options}
      checked={apiKeysChecked}
      onChange={handleChange}
      footer={<Footer />}
    />
  );
};

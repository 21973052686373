import cn from 'classnames';
import PropTypes from 'prop-types';

const OrdersStockToggler = ({ isOrdersData, setIsOrdersData }) => {
  return (
    <div className="mb-8">
      <div className="flex cursor-pointer justify-center">
        <button
          className={cn('border border-grey rounded-l py-1 px-2', {
            'text-green border-green': !isOrdersData,
          })}
          onClick={() => setIsOrdersData(false)}
        >
          Остатки
        </button>
        <button
          className={cn('border border-grey rounded-r py-1 px-2', {
            'text-green border-green': isOrdersData,
          })}
          onClick={() => setIsOrdersData(true)}
        >
          Заказы
        </button>
      </div>
    </div>
  );
};

OrdersStockToggler.propTypes = {
  isOrdersData: PropTypes.bool.isRequired,
  setIsOrdersData: PropTypes.func.isRequired,
};

export default OrdersStockToggler;

const shortcuts = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'млрд' },
  { value: 1e12, symbol: 'трлн' },
  { value: 1e15, symbol: 'квадр' },
  { value: 1e18, symbol: 'квинт' },
];
const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

export const roundValue = (value, noPadding = false, noShortcut = false) => {
  const short = shortcuts
    .slice()
    .reverse()
    .find((short) => Math.abs(value) >= short.value);

  return short
    ? `${(value / short.value).toFixed(1).replace('.0', '').replace('.', ',').replace(rx, '$1')}${
        noPadding || short.value === 1 ? '' : ' '
      }${!noShortcut ? short.symbol : ''}`
    : '0';
};

const LoopIcon = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96894 7.2151C1.96894 4.22351 4.36694 1.88177 7.22009 1.88177C10.0732 1.88177 12.4712 4.22351 12.4712 7.2151C12.4712 8.67364 11.9012 9.9777 10.9864 10.9312C10.973 10.9422 10.9598 10.9535 10.9468 10.9653C10.9093 10.9994 10.8752 11.0356 10.8445 11.0736C9.89493 11.9914 8.61164 12.5484 7.22009 12.5484C4.36694 12.5484 1.96894 10.2067 1.96894 7.2151ZM11.58 12.9689C10.3745 13.8841 8.86812 14.4308 7.22009 14.4308C3.18543 14.4308 -0.000244141 11.1541 -0.000244141 7.2151C-0.000244141 3.27607 3.18543 -0.000579834 7.22009 -0.000579834C11.2548 -0.000579834 14.4404 3.27607 14.4404 7.2151C14.4404 8.85886 13.8857 10.3873 12.946 11.6104L15.7295 14.4112C16.104 14.788 16.088 15.3837 15.6938 15.7417C15.2996 16.0998 14.6764 16.0845 14.3018 15.7076L11.58 12.9689Z"
      fill="currentColor"
    />
  </svg>
);
export default LoopIcon;

import React, { Fragment } from 'react';

import Chip from 'components/Chip';
import {
  CHIP_ID_KEY,
  CHIP_TITLE_KEY,
  useFilterReducer,
} from 'context/filter/filter.context.reducer';
import { F_INNER } from 'constants';

const CHIP_COLORS = {
  [F_INNER.CATEGORY]: 'bg-blue',
  [F_INNER.SUBJECT]: 'bg-yellow',
  [F_INNER.BRAND]: 'bg-green-light',
  [F_INNER.WAREHOUSE]: 'bg-amber-200',
};

const ChipListInner = () => {
  const { chips, deleteChip } = useFilterReducer();

  const onRemove = (type, key) => {
    deleteChip({ type, key });
  };
  return (
    <>
      {Object.keys(chips).map((type) => (
        <Fragment key={type}>
          {chips[type].map((chip) => (
            <Chip
              id={chip?.[CHIP_ID_KEY]}
              key={`${type}_${chip?.[CHIP_ID_KEY]}`}
              color={CHIP_COLORS[type]}
              title={chip?.[CHIP_TITLE_KEY] || `${type} ID ${chip?.[CHIP_ID_KEY]}`}
              action={() => onRemove(type, chip?.[CHIP_ID_KEY])}
            />
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default ChipListInner;

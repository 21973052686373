const NewExclamationIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 12 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.65644 36.0058H9.49644L10.5604 0.361816H1.51644L2.65644 36.0058ZM5.92444 53.6378C9.11644 53.6378 11.8524 51.2818 11.8524 48.0138C11.8524 44.8218 9.11644 42.3898 5.92444 42.3898C2.80844 42.3898 0.148438 44.8218 0.148438 48.0138C0.148438 51.2818 2.80844 53.6378 5.92444 53.6378Z"
        fill="#219653"
      />
    </svg>
  );
};

export default NewExclamationIcon;

import React from 'react';
import { PAGE_MAIN } from 'constants/pages';
import SuccessPaymentLayout from 'components/SuccessPaymentLayout';

const SuccessPaymentUL = () => (
  <SuccessPaymentLayout
    title={
      <>
        Ваша заявка принята! <br />
        Мы все проверим, и пришлем вам счет для оплаты
      </>
    }
    linkText={'Перейти на главную'}
    link={PAGE_MAIN}
  />
);

export default SuccessPaymentUL;

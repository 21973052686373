import DownIcon from 'assets/icons/DownIcon';
import React from 'react';
import BarIcon from 'assets/icons/BarIcon';
import { useUserList } from 'context/user.list.context';
import Chip from 'components/Chip';
import { MODAL_MODES } from '../constants';
import PropTypes from 'prop-types';

const ActiveList = ({ setModalMode, setSelectedFavorite, onBackClick }) => {
  const { selectedList } = useUserList();
  return (
    <div>
      <div className="border-b border-[#E8E8E8] px-5 py-4 flex">
        <button className="self-center text-[#CBCBCB]" onClick={onBackClick}>
          <DownIcon width={20} height={20} className="rotate-90" />
        </button>
        <div className="grow flex justify-center">
          <span>{selectedList.name}</span>
        </div>
        <button
          className="self-center text-[#CBCBCB]"
          onClick={() => setModalMode(MODAL_MODES.SETTINGS)}
        >
          <BarIcon width={16} height={16} />
        </button>
      </div>
      <div className="w-full flex flex-wrap pt-2 pl-2 pr-4 pb-4">
        {selectedList?.items?.map((el) => (
          <Chip
            key={el.id}
            title={el.name}
            action={() => {
              setSelectedFavorite(el);
              setModalMode(MODAL_MODES.REMOVE_FAVORITE);
            }}
            color="bg-blue"
            className="w-min ml-2 mt-2 max-w-full !whitespace-normal w-fit h-fit"
            textClassName={'text-ellipsis overflow-hidden'}
          />
        ))}
      </div>
    </div>
  );
};

ActiveList.propTypes = {
  setModalMode: PropTypes.func,
  setSelectedFavorite: PropTypes.func,
  onBackClick: PropTypes.func,
};

export default ActiveList;

import PropTypes from 'prop-types';
import ButtonPrimary from '../ButtonPrimary';
import { useAuth } from 'context/auth.context';
import Popover from '../Popover';
import React, { useRef } from 'react';

const HintsInsert = ({ phrase, setPhrase, handleHints }) => {
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();

  return (
    <div className="pt-4 px-8">
      <span className="block text-[#333D37] leading-[20px] mb-2">Ввод поисковой фразы</span>
      <input
        value={phrase || ''}
        onChange={(e) => setPhrase(e.target.value)}
        type="text"
        autoFocus={true}
        placeholder={'Поисковая фраза...'}
        className="block p-4 border border-[#D9D9D9] rounded-lg w-[420px] placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
      />

      <div className="relative w-fit">
        <div ref={tooltipNoTariffRef}>
          <ButtonPrimary
            onClick={handleHints}
            label="Получить отчёт"
            className="text-base mt-4"
            disabled={!phrase || noTariff}
          />
          {noTariff && (
            <Popover
              tooltipRef={tooltipNoTariffRef}
              className="whitespace-nowrap"
              tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
            />
          )}
        </div>
      </div>
    </div>
  );
};

HintsInsert.propTypes = {
  phrase: PropTypes.string,
  setPhrase: PropTypes.func,
  handleHints: PropTypes.func,
};

export default HintsInsert;

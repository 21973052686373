import ButtonPlusMinus from '../ButtonPlusMinus';
import PropTypes from 'prop-types';
import { useState } from 'react';
import cn from 'classnames';

const TableSeoPhraseNestedCell = ({ value, data, node }) => {
  const [isExpanded, setIsExpanded] = useState(node.expanded);
  const hasChildren = Array.isArray(data.children) && data.children.length !== 0;
  return (
    <span className="flex items-center overflow-hidden">
      {hasChildren && (
        <ButtonPlusMinus
          onClick={() => {
            setTimeout(() => node.setExpanded(!isExpanded), 0);
            setIsExpanded(!isExpanded);
          }}
          isLoading={false}
          isExpanded={isExpanded}
        />
      )}
      <span className={cn({ 'pl-6': !hasChildren })}>{value}</span>
    </span>
  );
};
TableSeoPhraseNestedCell.propTypes = {
  value: PropTypes.string.isRequired,
  api: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  data: PropTypes.object,
  colDef: PropTypes.shape({
    field: PropTypes.string,
    id: PropTypes.string,
  }),
};
export default TableSeoPhraseNestedCell;

/**
 * @typedef IProps
 *
 * @property {boolean} isActive Флаг, определяющий активность иконки
 * @property {string} iconActiveColor Активный цвет знака вопроса
 * @property {string} iconNonactiveColor Неактивный цвет знака вопроса
 * @property {string} backgroundActiveColor Активный цвет фона вокруг знака вопроса
 * @property {string} backgroundNonactiveColor Неактивный цвет фона вокруг знака вопроса
 * */
import propTypes from 'prop-types';
import { useMemo, useState } from 'react';

/**
 * @return {JSX.Element} Иконку со знаком вопроса, которая может быть "закрашенной"
 *
 * @param {import('react').HTMLAttributes<SVGElement> & IProps} props
 * */
export function QuestionIconV2({
  isActive,
  iconActiveColor,
  iconNonactiveColor,
  backgroundActiveColor,
  backgroundNonactiveColor,
  ...svgProps
}) {
  const [initialState] = useState(isActive);
  const computedIconColor = useMemo(() => {
    if (initialState) {
      if (isActive) return iconActiveColor;

      return iconNonactiveColor;
    }

    return iconNonactiveColor;
  }, [initialState, isActive, iconActiveColor, iconNonactiveColor]);

  const computedBgColor = useMemo(() => {
    if (initialState) {
      if (isActive) return backgroundActiveColor;

      return backgroundNonactiveColor;
    }

    return backgroundNonactiveColor;
  }, [initialState, isActive, backgroundActiveColor, backgroundNonactiveColor]);

  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      className="group transition-colors"
      {...svgProps}
    >
      <g clipPath="url(#clip0_8244_5818)">
        <rect width="22" height="22" transform="translate(0 0.253906)" fill="transparent" />
        <circle cx="11" cy="11.2539" r="11" fill={computedBgColor} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.3291 8.99092C9.3291 8.06827 10.0771 7.32031 10.9997 7.32031C11.9224 7.32031 12.6703 8.06827 12.6703 8.99092C12.6703 9.91357 11.9224 10.6615 10.9997 10.6615H9.99971V13.3391H11.9997V12.5237C13.5409 12.0883 14.6703 10.6715 14.6703 8.99092C14.6703 6.9637 13.0269 5.32031 10.9997 5.32031C8.97249 5.32031 7.3291 6.9637 7.3291 8.99092H9.3291ZM10.9996 17.1867C11.696 17.1867 12.2605 16.6222 12.2605 15.9259C12.2605 15.2295 11.696 14.665 10.9996 14.665C10.3033 14.665 9.73877 15.2295 9.73877 15.9259C9.73877 16.6222 10.3033 17.1867 10.9996 17.1867Z"
          fill={computedIconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_8244_5818">
          <rect width="22" height="22" transform="translate(0 0.253906)" />
        </clipPath>
      </defs>
    </svg>
  );
}

QuestionIconV2.propTypes = {
  isActive: propTypes.bool,
  iconActiveColor: propTypes.string,
  iconNonactiveColor: propTypes.string,
  backgroundActiveColor: propTypes.string,
  backgroundNonactiveColor: propTypes.string,
};

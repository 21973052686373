import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useTable } from 'context/table.context';

const TableHeaderFilterSearch = forwardRef(({ filterChangedCallback, colDef: { field } }, ref) => {
  const { filterText, addFilterText, removeFilterText } = useFilterReducer();
  const { type, isFilterTextOnBack } = useTable();
  // It's simple solution for hidding filter after cancel and submit
  // It's made because ag-grid sometimes doesn't hide filter by itself
  const hideFilter = () => {
    const filterPopup = document.getElementsByClassName('ag-tabs')[0];
    if (filterPopup) {
      filterPopup.style.display = 'none';
    }
  };
  const currentFilterText = filterText?.[field] || '';

  const [value, setValue] = useState(currentFilterText);
  const [instanceParams, setInstanceParams] = useState(null);

  const disabled = value.length < 3;

  const isFilteredOnBack = isFilterTextOnBack({ type, key: field });

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        if (isFilteredOnBack || !currentFilterText) {
          return true;
        }
        const value = params.data[field];
        let passed = true;

        currentFilterText
          .toLowerCase()
          .split(' ')
          .forEach((filterWord) => {
            if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
              passed = false;
            }
          });

        return passed;
      },

      isFilterActive() {
        return !disabled;
      },

      getModel() {
        if (!this.isFilterActive() || isFilteredOnBack) {
          return null;
        }

        return {
          filterType: 'text',
          type: 'contains',
          value,
        };
      },

      setModel() {},

      hidePopup() {
        if (!instanceParams || !instanceParams?.hidePopup) return;

        instanceParams.hidePopup();
      },

      afterGuiAttached(params) {
        setInstanceParams(params);
      },
    };
  });

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const cleanSearch = () => {
    setValue('');
    removeFilterText({ key: field });
    hideFilter();
  };

  const handleFilter = () => {
    addFilterText({ value, key: field });
    hideFilter();
  };

  useEffect(() => {
    filterChangedCallback();
    return () => {
      filterChangedCallback();
    };
  }, [currentFilterText, filterChangedCallback]);

  return (
    <div className={`p-4 w-full`}>
      <div className="ag-filter-body-wrapper ag-set-filter-body-wrapper mb-4">
        <input
          className="w-full appearance-none border border-gray-250 rounded-md py-2 pl-3 pr-1 text-gray-700 leading-tight focus:outline-none focus:border-green"
          type="text"
          name="search"
          value={value}
          autoComplete="off"
          onChange={onChange}
          aria-label="Search filter values"
          placeholder="Поиск..."
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleFilter();
            }
          }}
        />
      </div>
      <div className="flex justify-between space-x-3">
        <button
          type="button"
          onClick={cleanSearch}
          className="w-full inline-flex justify-center py-2.5 border border-gray-250 transition-all rounded-md text-red-bright hover:bg-gray-230 focus:bg-gray-230 focus:outline-none"
        >
          Сбросить
        </button>
        <button
          type="button"
          onClick={handleFilter}
          disabled={disabled}
          className="w-full inline-flex justify-center py-2.5 border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:bg-transparent focus:text-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        >
          Применить
        </button>
      </div>
    </div>
  );
});

TableHeaderFilterSearch.displayName = 'TableHeaderFilter';
TableHeaderFilterSearch.propTypes = {
  hidePopup: PropTypes.func,
  colDef: PropTypes.shape({
    field: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  filterChangedCallback: PropTypes.func.isRequired,
};
export default TableHeaderFilterSearch;

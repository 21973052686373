const WhatsAppIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.9999 19.009C36.9948 28.3797 29.3714 36 20.0002 36H19.9946H19.9935C17.0119 35.9984 14.0841 35.2159 11.5124 33.7309L6.64168 35.008L3.2901 35.8867L4.20551 32.545L5.48914 27.859C3.85789 25.1906 2.99569 22.1217 3.00002 18.9915C3.00397 9.6206 10.6272 2.00132 20.0035 2.00004M36.9999 19.009C36.9999 19.0099 36.9999 19.0108 36.9999 19.0118L34.9999 19.0054L36.9999 19.0062C36.9999 19.0071 36.9999 19.008 36.9999 19.009ZM36.9999 19.009C37.0066 16.7748 36.5703 14.5615 35.7161 12.497C34.8622 10.4334 33.6078 8.55946 32.0254 6.98356C30.4507 5.40006 28.5777 4.14431 26.5148 3.28892C24.4509 2.43314 22.2378 1.99507 20.0035 2.00004M20.0035 2.00004C20.0043 2.00004 20.0051 2.00004 20.0059 2.00004V4.00004L20.0011 2.00005C20.0019 2.00005 20.0027 2.00004 20.0035 2.00004Z"
        fill="url(#paint0_linear_10048_1693)"
        stroke="white"
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.488 11.4502C15.15 10.6994 14.7943 10.6842 14.4733 10.6712L13.6089 10.6606C13.3082 10.6606 12.8196 10.7735 12.4065 11.2248C11.9935 11.6761 10.8281 12.7668 10.8281 14.9852C10.8281 17.2037 12.444 19.3474 12.6692 19.6486C12.8944 19.9498 15.7887 24.6474 20.3723 26.4548C24.1813 27.9568 24.9564 27.6581 25.7834 27.5831C26.6105 27.508 28.4514 26.4923 28.8269 25.4394C29.2024 24.3864 29.2027 23.4843 29.0901 23.2957C28.9775 23.1071 28.6768 22.995 28.2255 22.7694C27.7742 22.5437 25.5575 21.453 25.1442 21.3024C24.7309 21.1518 24.4305 21.077 24.1295 21.5283C23.8285 21.9796 22.9654 22.9948 22.7022 23.2957C22.439 23.5967 22.1763 23.6345 21.725 23.409C21.2737 23.1836 19.8216 22.7073 18.0988 21.1712C16.7583 19.976 15.8535 18.5 15.59 18.0489C15.3266 17.5979 15.5621 17.3536 15.7882 17.1289C15.9906 16.9268 16.239 16.6023 16.4649 16.3391C16.6908 16.076 16.7652 15.8878 16.9153 15.5874C17.0654 15.2869 16.9906 15.023 16.8778 14.7976C16.7649 14.5722 15.8888 12.3422 15.488 11.4504"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10048_1693"
          x1="19.6946"
          y1="5.80032"
          x2="19.8467"
          y2="31.4372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#57D163" />
          <stop offset="1" stopColor="#23B33A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default WhatsAppIcon;

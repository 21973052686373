import { tariffsEnum } from 'constants/tariffs';
import { TariffRadio } from 'components/Tariffs/components/TariffRadio';
import { getFormattedPrice } from 'components/Tariffs/components/TariffColumn';
import { List } from 'components/Tariffs/components/List';
import { useTariffs } from 'context/tariffs.context';

export const TariffRadioList = () => {
  const { activeTariff, handleTariff, tariffsData, activeTariffData } = useTariffs();

  const basicPrice = tariffsData[tariffsEnum.BASIC].newPrice;
  // const expertPrice = tariffsData[tariffsEnum.EXPERT].newPrice;
  const proPrice = tariffsData[tariffsEnum.PRO].newPrice;

  return (
    <>
      <div
        className="bg-gray-100 border-y border-gray-200 px-6 py-3 mt-5"
        style={{
          width: 'calc(100% + 1.5rem)',
          marginLeft: '-0.75rem',
        }}
      >
        <div className="flex">
          <TariffRadio
            id={tariffsEnum.BASIC}
            price={getFormattedPrice(basicPrice)}
            checked={activeTariff === tariffsEnum.BASIC}
            onChange={() => handleTariff(tariffsEnum.BASIC)}
            name={'Basic'}
          />
          {/*<TariffRadio*/}
          {/*  id={tariffsEnum.EXPERT}*/}
          {/*  price={getFormattedPrice(expertPrice)}*/}
          {/*  checked={activeTariff === tariffsEnum.EXPERT}*/}
          {/*  onChange={() => handleTariff(tariffsEnum.EXPERT)}*/}
          {/*  name={'Expert'}*/}
          {/*  recommended*/}
          {/*/>*/}
          <TariffRadio
            id={tariffsEnum.PRO}
            price={getFormattedPrice(proPrice)}
            checked={activeTariff === tariffsEnum.PRO}
            onChange={() => handleTariff(tariffsEnum.PRO)}
            name={'Pro'}
          />
        </div>
        <div className="grid mt-3" style={{ gridTemplateColumns: '4fr 1fr 1fr 1fr' }}>
          <div />
          <div className="text-gray-400 text-xs">BA</div>
          <div className="text-gray-400 text-xs">EX</div>
          <div className="text-gray-400 text-xs">EN</div>
        </div>
      </div>
      {activeTariffData && <List item={activeTariffData} items={tariffsData} />}
    </>
  );
};

import moment from 'moment';
import { useQuery } from 'react-query';

import apiClient from 'services/baseClient';
import { STALE_TIME, TABLE_TYPE } from 'constants';
import Table from 'components/Table';

const periodDates = (period, paymentDate) => {
  const monthCount = parseInt(period);

  const startDate = moment(paymentDate, 'DD.MM.YYYY').format('DD.MM.YY');

  const endDate = moment(paymentDate, 'DD.MM.YYYY').add(monthCount, 'month').format('DD.MM.YY');

  return `${startDate} - ${endDate}`;
};

const convertData = (data) =>
  data.map((order) => {
    const { bonus, period, ...other } = order;
    return {
      ...other,
      period: periodDates(period, order.date),
      bonus: bonus.toLocaleString('ru'),
    };
  });
export const ReferralsPaymentHistoryTable = () => {
  const { data = [], isLoading } = useQuery(
    ['referrals_payment'],
    () => apiClient.getReferralsMyRefsTransactions(),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      staleTime: STALE_TIME,
      retry: 3,
    },
  );

  return (
    <Table
      data={convertData(data)}
      type={TABLE_TYPE.REFERRALS_PAYMENT}
      isLoading={isLoading}
      noRowsText={`По вашему запросу нет данных.`}
      showSidebar={false}
    />
  );
};

import React from 'react';
import { SeoHintsContent } from 'components/SeoHints';
import { BY_PHRASES, BY_PRODUCTS } from '../../../constants';
import NavList from '../../../components/NavList';

const Hints = () => {
  return (
    <div className="pt-4">
      <div className="px-8">
        <h1>Поисковые подсказки</h1>
        <div className="text-gray-400 mt-1 mb-4">
          <span className="block">
            Инструмент покажет подсказки, которые отображаются в поиcковой строке wildberries по
            указанной фразе.
          </span>
          <span className="block">
            Подсказки являются наиболее актуальной информацией о том, что сейчас ищут пользователи
          </span>
        </div>
      </div>
      <div className="px-8">
        <NavList
          items={
            // window.location.hostname === 'go.wecheck.ru'
            //   ? [
            //       {
            //         link: `/seo/${BY_PRODUCTS}`,
            //         title: 'По товарам',
            //       },
            //       {
            //         link: `/seo/hints`,
            //         title: 'По подсказкам',
            //       },
            //     ]
            //   :
            [
              {
                link: `/seo/${BY_PRODUCTS}`,
                title: 'По товарам',
              },
              {
                link: `/seo/${BY_PHRASES}`,
                title: 'По поисковым фразам',
              },
              {
                link: `/seo/hints`,
                title: 'По подсказкам',
              },
            ]
          }
        />
      </div>
      <SeoHintsContent />
    </div>
  );
};

export default Hints;

import { roundValue } from 'helpers/shortcuts';

function emptyValueDecorator(params, handler) {
  const {
    colDef: { cellRendererParams },
    value,
  } = params;

  const leaveEmpty = cellRendererParams && cellRendererParams?.leaveEmpty;

  if (!value && value !== 0 && leaveEmpty) {
    return '';
  }

  if (!value && !leaveEmpty) {
    return 0;
  }

  if (value === '-' || value === ' ') {
    return value;
  }

  if (!Number.isInteger(+value) && typeof value !== 'number') return value;

  return handler();
}
function getFormatNumber(value) {
  return Intl.NumberFormat('ru-RU').format(+value);
}

function getCurrency(value) {
  return `${value} ₽`;
}

function getBoolean(value) {
  return value ? 'Дa' : 'Нет';
}

function getPercentage(value, avoidChar) {
  if (value > 0 && value[0] !== '+') {
    value = `${avoidChar ? '' : '+'}${value}`;
  }
  value = `${value}%`;

  return value;
}

const amountFormatter = (params) =>
  params.value === undefined
    ? '-'
    : emptyValueDecorator(params, () => getFormatNumber(+params.value));

const amountFormatterWithHyphen = (params) => {
  const value = params.value;
  if ((!value && value !== 0) || value === '-') {
    return '-';
  }

  return emptyValueDecorator(params, () => getFormatNumber(+params.value));
};

const amountShortcutFormatter = (params) =>
  params.value === undefined ? '-' : emptyValueDecorator(params, () => roundValue(+params.value));

const currencyFormatter = (params) =>
  params.value === undefined
    ? '-'
    : emptyValueDecorator(params, () => getCurrency(getFormatNumber(+params.value)));

// const currencyShortcutFormatter = (params) =>
//   emptyValueDecorator(params, () => getCurrency(roundValue(+params.value)));

const advTypeFormatter = (params) => {
  const { value } = params;
  if (value.toLowerCase() === 'category') {
    return 'Категория';
  }

  if (value.toLowerCase() === 'search_phrase') {
    return 'Поисковая фраза';
  }

  return value;
};

const booleanFormatter = (params) => (params.value === undefined ? '-' : getBoolean(params.value));

const percentageFormatter = (params) =>
  params.value === undefined
    ? '-'
    : emptyValueDecorator(params, () => getPercentage(params.value, params.avoidChar));

export {
  advTypeFormatter,
  amountFormatter,
  amountShortcutFormatter,
  booleanFormatter,
  percentageFormatter,
  amountFormatterWithHyphen,
  currencyFormatter /*, currencyShortcutFormatter*/,
};

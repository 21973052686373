import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import LoadingSpin from 'components/LoadingSpin';
import cn from 'classnames';

const PieChartWithCentralLabel = lazy(() => import('components/PieChart/PieChartWithCentralLabel'));

const PieChartPercent = ({ percent, isLoading }) => (
  <Suspense fallback={<LoadingSpin />}>
    <LoadingSpin isVisible={isLoading} iconClassNames="text-gray-250" />
    <div
      className={cn('relative', {
        ['hidden']: isLoading,
      })}
    >
      <PieChartWithCentralLabel fullValue={100} value={percent} size={56} paddingAngle={20} />
      <span className="absolute inset-x-0 top-5 text-center">{percent}%</span>
    </div>
  </Suspense>
);

PieChartPercent.propTypes = {
  percent: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
};

export default PieChartPercent;

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useFavourite } from 'hooks/useFavourite';
import { LIST_TYPE } from 'constants/list';
import ButtonStar from 'components/ButtonStar';

export const ProductFavourite = () => {
  const { id } = useParams();

  const { listData, selected, handleFavourite, isLoading } = useFavourite(
    LIST_TYPE.BY_PRODUCTS,
    +id,
  );
  const isProductFavourite = useMemo(() => selected.length > 0, [selected]);
  const computedFavoriteText = useMemo(() => {
    if (isProductFavourite) return 'Товар в избранном';

    return 'Добавить в избранное';
  }, [isProductFavourite]);

  return (
    <div className="flex flex-grow items-center justify-start lg:justify-end">
      <ButtonStar
        active={isProductFavourite}
        options={listData}
        isLoading={isLoading}
        onChange={handleFavourite}
        label={computedFavoriteText}
        selected={selected}
        expandClassname={'right-0'}
        listsClassname={'!right-[224px]'}
      />
    </div>
  );
};

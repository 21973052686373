import { imageValue } from 'helpers/generateGridColumnsHelpers';
import { currencyFormatter } from 'helpers/cellValueFormatters';
import { currencyTooltipFormatter } from 'helpers/tooltipValueGetters';

export const columnDefsAdvertisingRates = [
  {
    headerName: 'Позиция',
    field: 'position',
    width: 80,
    valueFormatter: (params) => {
      return `# ${params.value}`;
    },
    suppressMenu: true,
  },
  {
    headerName: 'Стр',
    field: 'page',
    width: 50,
    suppressMenu: true,
  },
  {
    headerName: 'За 1000 показов',
    field: 'cpm',
    width: 135,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    suppressMenu: true,
  },
  {
    headerName: 'Фото',
    field: 'image',
    width: 70,
    cellRendererParams: {
      suppressRightAlign: true,
    },
    ...imageValue,
    suppressMenu: true,
  },
  {
    headerName: 'Название товара',
    field: 'name',
    flex: 1,
    minWidth: 150,
    cellRenderer: 'productLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
      withoutFavorite: true,
    },
    tooltipComponent: 'tableCellInfoTooltip',
    tooltipValueGetter: (props) => props,
    suppressMenu: true,
  },
  {
    headerName: 'Продавец',
    field: 'seller',
    width: 150,
    valueFormatter: (params) => {
      return params.value.name;
    },
    cellRendererParams: {
      suppressRightAlign: true,
    },
    cellRenderer: 'sellerLinkTableCell',
    tooltipComponent: 'tableCellInfoTooltip',
    tooltipValueGetter: (props) => props,
    suppressMenu: true,
  },
  {
    headerName: 'Предмет',
    field: 'subject',
    width: 100,
    suppressMenu: true,
    cellRendererParams: {
      suppressRightAlign: true,
    },
  },
  {
    headerName: 'Цена товара',
    field: 'price',
    width: 135,
    valueFormatter: currencyFormatter,
    tooltipValueGetter: currencyTooltipFormatter(''),
    suppressMenu: true,
  },
  {
    headerName: 'URL',
    field: 'id',
    cellRenderer: 'wecheckProductLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 100,
    suppressMenu: true,
  },
  {
    headerName: 'URL',
    field: 'id',
    cellRenderer: 'platformProductLinkTableCell',
    cellRendererParams: {
      suppressRightAlign: true,
    },
    headerComponentParams: { sortable: false },
    width: 70,
    suppressMenu: true,
  },
];

import CheckIcon from 'assets/icons/CheckIcon';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import baseInnerClient from 'services/baseInnerClient';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { F_INNER } from 'constants';
import { PricesAlertContext } from 'pages/Inner/Prices/Prices';
import BellIcon from 'assets/icons/BellIcon';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';
import ButtonPrimary from '../ButtonPrimary';
import Input from '../Input';

const TableInnerPriceChanger = (props) => {
  const { filter } = useFilterReducer();
  const { value: propsValue, data } = props;
  const [value, setValue] = useState(propsValue);
  const [prevValue, setPrevValue] = useState(value);
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef();
  const { isMobile } = useUI();
  const addAlertByKey = useContext(PricesAlertContext);
  const icon = useMemo(
    () => (
      <div className="relative h-[42px] min-w-[42px] flex justify-center items-center self-center mr-4">
        <div className="absolute rounded-full h-[42px] min-w-[42px] opacity-30 bg-[#6E6E6E]" />
        <BellIcon width={24} height={24} className="absolute mx-auto opacity-100" />
      </div>
    ),
    [],
  );

  const createError = () => {
    addAlertByKey({
      key: `error-${value + '-' + data.id}`,
      message: 'Не удалось изменить цену. Повторите попытку',
      type: 'error',
      icon,
    });
  };
  const handleSubmit = async () => {
    setIsActive(false);
    try {
      const result = await baseInnerClient.put('/user/in/prices', {
        data: [
          {
            id: data.id,
            price: +value,
          },
        ],
        user_api_key_id: filter[F_INNER.API_KEYS][0],
      });
      if (result.status === 204) {
        addAlertByKey({
          key: `success-${value + '-' + data.id}`,
          message: (
            <div>
              <span className="block">Цена изменена</span>
              <span className="block">{`${propsValue} ₽ → ${value} ₽`}</span>
              <span className="block">Новая цена отобразится</span>
              <span className="block">на сайте через 5 минут</span>
            </div>
          ),
          type: 'success',
          icon,
        });
      } else {
        createError();
      }
    } catch (e) {
      createError();
    }
  };
  useEffect(() => {
    if (isActive) {
      inputRef.current.selectionStart = String(value).length;
      inputRef.current.selectionEnd = String(value).length;
    }
  }, [isActive, value]);
  return (
    <div className="w-full h-full px-4 py-1">
      <div
        className={cn('flex border border-gray-250 rounded px-1 py-[3px] bg-white', {
          'border-gray-250': !isActive,
          'border-green': value !== propsValue,
        })}
      >
        <div className="grow flex mr-1">
          <input
            ref={inputRef}
            className={cn('w-full self-center focus:outline-none text-right h-[22px]', {
              'pr-1': isActive,
            })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => {
              setIsActive(true);
              setPrevValue(value);
            }}
          />
        </div>
        {isActive && !isMobile && (
          <button
            className="bg-green rounded text-white p-1 relative justify-end focus:outline-none"
            onClick={handleSubmit}
          >
            <CheckIcon width={14} height={14} className="text-right" />
          </button>
        )}
      </div>
      {isMobile && (
        <MobileInterfaceV2
          onPanelClose={() => {
            setValue(prevValue);
            setIsActive(false);
          }}
          rootClassName="h-fit bottom-0 absolute"
          isActive={isActive}
          headerText="Цена, ₽"
        >
          <div className={'p-4'}>
            <Input
              name="price"
              onChange={(value) => setValue(value)}
              value={value}
              valid={true}
              className="h-[32px]"
            />
          </div>
          <div className="p-4 flex space-x-3">
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted-red"
              onClick={() => {
                setValue(prevValue);
                setIsActive(false);
              }}
              label="Отмена"
            />
            <ButtonPrimary
              className="flex-1 py-2 justify-center"
              onClick={handleSubmit}
              label="Сохранить"
            />
          </div>
        </MobileInterfaceV2>
      )}
    </div>
  );
};

TableInnerPriceChanger.propTypes = {
  value: PropTypes.any,
  data: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default TableInnerPriceChanger;

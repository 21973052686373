import axios from 'axios';
import { getToken } from 'utils/token';
import { getApi } from 'utils/api';
import { REFERRALS_URL_KEY, PAGE_SIGNUP } from 'constants';

const transformChartData = (data) => {
  if (!data) return [];

  return {
    labels: data?.chart_dates,
    chartData: data?.all_data?.plain_values?.[0]?.data?.map((item) => (item ? +item : item)) || [],
  };
};

export const getPrefix = ({ uri, endpoint }) => {
  if (uri) return `${uri}&`;

  return `${endpoint}?`;
};

class BaseClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
      xsrfHeaderName: 'X-CSRFTOKEN',
      xsrfCookieName: 'csrftoken',
    });

    this.client.interceptors.request.use(function (config) {
      const token = getToken();
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9rZWtzdW02NjYud2VjaGVjay5kZXZcL2FwaVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2NjQ5NjAxNDksImV4cCI6MTY2NzU1MjE0OSwibmJmIjoxNjY0OTYwMTQ5LCJqdGkiOiIyemI5cjhLUW1QeHhNR2VOIiwic3ViIjoyLCJwcnYiOiIyYjFjMDc5NDJhM2NlYTQ3MDY4ZDRmNTA1ZWY5ZDgzZWYwNTk0NGNhIn0.fyK7q4XBs1YybMFXYaYU1X_SdOrgCOgmULvPC4Odsdo`;

      return config;
    });

    this.get = this.get.bind(this);
    this.getSales = this.getSales.bind(this);
    this.getSalesBrands = this.getSalesBrands.bind(this);
    this.getSalesSellers = this.getSalesSellers.bind(this);
    this.getSalesProducts = this.getSalesProducts.bind(this);
    this.getSalesCategories = this.getSalesCategories.bind(this);
    this.getBrandsSearch = this.getBrandsSearch.bind(this);
    this.getSellersSearch = this.getSellersSearch.bind(this);
    this.getProductSearch = this.getProductSearch.bind(this);
    this.getProductSearchListing = this.getProductSearchListing.bind(this);
    this.getCategoriesTree = this.getCategoriesTree.bind(this);
    this.getBrandsChart = this.getBrandsChart.bind(this);
    this.getProductsChart = this.getProductsChart.bind(this);
    this.getSellersChart = this.getSellersChart.bind(this);
    this.getProductRatings = this.getProductRatings.bind(this);
    this.getProductStock = this.getProductStock.bind(this);
    this.getProductSales = this.getProductSales.bind(this);
    this.activateDemo = this.activateDemo.bind(this);
    this.getDynamicChart = this.getDynamicChart.bind(this);
    this.getCategoryParents = this.getCategoryParents.bind(this);
    this.getProductChanges = this.getProductChanges.bind(this);
    this.getProductPrice = this.getProductPrice.bind(this);
    this.getSellersNames = this.getSellersNames.bind(this);
    this.getBrandsNames = this.getBrandsNames.bind(this);
    this.getApiKeys = this.getApiKeys.bind(this);
    this.getApiKeysDetails = this.getApiKeysDetails.bind(this);
    this.getDictionaryApiKeys = this.getDictionaryApiKeys.bind(this);
    this.createApiKey = this.createApiKey.bind(this);
    this.deleteApiKey = this.deleteApiKey.bind(this);
    this.updateApiKeyName = this.updateApiKeyName.bind(this);
    this.seoCompare = this.seoCompare.bind(this);
    this.seoMonitoringProducts = this.seoMonitoringProducts.bind(this);
    this.seoHints = this.seoHints.bind(this);
    this.seoSmartDescriptionWords = this.seoSmartDescriptionWords.bind(this);
    this.getProductDaysSum = this.getProductDaysSum.bind(this);
  }

  async put(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.put(endpoint, data, config);
  }

  async post(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.post(endpoint, data, config);
  }

  async patch(endpoint, data, { token } = {}) {
    const config = {
      body: data ? JSON.stringify(data) : null,
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.patch(endpoint, data, config);
  }

  async delete(endpoint, { token } = {}) {
    const config = {
      headers: {},
    };
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return this.client.delete(endpoint, config);
  }

  async get(endpoint, options = {}) {
    const {
      filter,
      restricts,
      isColumn,
      isNew,
      hasAdvertising,
      page,
      per_page,
      sort,
      order,
      sortDefault,
      name,
      warehouseType,
      direction,
      priceRange,
      period,
      step,
      visibility,
      avgPosition,
      depth,
      sortAdditional,
      isInner,
      apiKeysType,
    } = options;

    let uri = '';

    const transformedFilter = encodeURIComponent(JSON.stringify(filter));
    const transformedRestricts = restricts ? JSON.stringify(restricts) : '';

    if (filter) {
      uri = `${getPrefix({ uri, endpoint })}filter=${transformedFilter}`;
    }

    if (!filter?.platform?.includes('1') && warehouseType && warehouseType !== 'null') {
      uri = `${getPrefix({ uri, endpoint })}warehouse_type[0]=${warehouseType}`;
    }

    if (isColumn) {
      uri = `${getPrefix({ uri, endpoint })}column=stock`;
    }

    if (direction) {
      uri = `${getPrefix({ uri, endpoint })}direction=${direction}`;
    }

    if (priceRange) {
      uri = `${getPrefix({ uri, endpoint })}price_range=${encodeURIComponent(
        JSON.stringify(priceRange),
      )}`;
    }

    if (period) {
      uri = `${getPrefix({ uri, endpoint })}period=${period}`;
    }

    if (step) {
      uri = `${getPrefix({ uri, endpoint })}step=${step}`;
    }

    if (restricts) {
      uri = `${getPrefix({ uri, endpoint })}restricts=${transformedRestricts}`;
    }

    if (name) {
      uri = `${getPrefix({ uri, endpoint })}name=${name}`;
    }

    if (apiKeysType) {
      uri = `${getPrefix({ uri, endpoint })}type=${apiKeysType}`;
    }

    if (isNew !== undefined) {
      uri = `${getPrefix({ uri, endpoint })}only_new=${isNew ? 'true' : 'false'}`;
    }

    if (hasAdvertising !== undefined) {
      uri = `${getPrefix({ uri, endpoint })}has_advertising=${hasAdvertising ? 'true' : 'false'}`;
    }

    if (visibility) {
      uri = `${getPrefix({ uri, endpoint })}visibility=${visibility}`;
    }

    if (avgPosition) {
      uri = `${getPrefix({ uri, endpoint })}avg_position_by_day=${avgPosition}`;
    }

    if (!uri) {
      uri = endpoint;
    }

    if (page) {
      uri = `${getPrefix({ uri, endpoint })}page=${page}&per_page=${per_page || 250}`;
    }

    if (sort) {
      uri = `${getPrefix({ uri, endpoint })}sort=${sort}`;
    }

    if (order) {
      uri = `${getPrefix({ uri, endpoint })}order=${order}`;
    }

    if (sortDefault) {
      uri = `${getPrefix({ uri, endpoint })}sort=${sortAdditional || 'sold_money'}`;
      uri = `${getPrefix({ uri, endpoint })}order=desc`;
    }

    if (depth || depth === 0) {
      uri = `${getPrefix({ uri, endpoint })}depth=${depth}`;
    }
    const config = {
      headers: isInner
        ? {
            'REQUEST-TYPE': 1,
          }
        : {},
    };
    return this.client
      .get(uri, config)
      .then((res) => res.data)
      .catch((error) => {
        const status = error?.response?.status || null;
        const message = error.message || 'Something went wrong :(';

        if (status === 403 || status === 423) {
          return {
            error: {
              message:
                error?.response?.data?.status ||
                error?.response?.message ||
                'Something went wrong :(',
              showLoginButton: status === 403,
            },
          };
        }

        throw new Error(message);
      });
  }

  // USE IN AUTH CONTEXT
  getPlatforms() {
    return this.get('/platforms');
  }

  activateDemo(tariffId, token) {
    return this.get(`/user/tariffs/${tariffId}/demo?token=${token}`);
  }

  getPositions(props) {
    return this.get('/listing', { filter: { ...props } }).then((data) => data?.all_data || {});
  }

  // USE IN useFetchFilteredData(Paginated)
  getSales(props) {
    return this.get('/sales', props).then((data) => data?.all_data?.table_data || []);
  }

  getSalesCategories(props) {
    return this.get('/ext/categories', props).then((data) => {
      return data?.data || [];
    });
  }

  getSalesBrands(props) {
    return this.get('/ext/brands', props).then((data) => data?.data || []);
  }

  getSalesSellers(props) {
    return this.get('/ext/sellers', props).then((data) => {
      return data?.data || [];
    });
  }

  getSalesProducts(props) {
    return this.get('/ext/products', props).then((data) => {
      return data?.data || [];
    });
  }

  getSalesWarehouses(props) {
    return this.get('/ext/warehouses', props).then((data) => data?.data || []);
  }

  // USE IN useMultiChartFilteredData
  getDynamicChart(props) {
    return this.get('/ext/trends', props).then((data) => {
      return data?.data || [];
    });
  }

  getPriceSegmentsChart(props) {
    return this.get('/sales/price-segments', props).then((data) => data || []);
  }

  // USE IN useSearch
  getBrandsSearch(query, filter) {
    const queryFilter = { ...filter, name: query };
    return this.get('/brands', { filter: queryFilter }).then((data) => {
      if (data.length === 0) return 'По вашему запросу нет данных. Попробуйте изменить фильтры';

      return Array.isArray(data) ? data.filter((item) => !item.disabled).slice(0, 200) : [];
    });
  }

  getSellersSearch(query, filter) {
    const queryFilter = { ...filter, name: query };
    return this.get('/sellers', { filter: queryFilter }).then((data) => {
      if (data.length === 0) return 'По вашему запросу нет данных. Попробуйте изменить фильтры';

      return data.filter((item) => !item.disabled).slice(0, 200);
    });
  }

  getProductSearch(query, platform) {
    const queryFilter = { search: query, platform };
    return this.get('/products', { filter: queryFilter });
  }

  getCategoriesTree(filter) {
    return this.get('/treecategories', { filter }).then((data) => {
      return data;
    });
  }

  getCategoryParents({ id, platform, date }) {
    return this.get('/treecategories', { filter: { id, platform, date }, direction: 'up' });
  }

  // END useSearch

  // USE FETCH IN PRODUCT ROW DATA
  getProductSales({ id, ...rest }) {
    return this.get(`/product/${id}/sales`, rest).then((data) => {
      if (!data) return {};

      const dataset1 = data?.all_data?.chart_data[0]?.sold_money.map((item) =>
        item ? +item : item,
      );
      const dataset2 = data?.all_data?.chart_data[0]?.sold_number.map((item) =>
        item ? +item : item,
      );

      return {
        items: data?.all_data?.table_data || [],
        labels: data?.chart_dates,
        chartData: [dataset1, dataset2],
        orders: data?.counts?.counts || 0,
        sum: data?.counts?.sum || 0,
        notPaginated: {
          ...data?.avg_counts,
          ...data?.counts,
        },
      };
    });
  }

  getProductDays({ id, ...rest }) {
    return this.get(`/ext/product/${id}/days`, rest).then((data) => data?.data || []);
  }

  getProductDaysSum({ id, ...rest }) {
    return this.get(`/ext/product/${id}/sum`, rest).then((data) => {
      return data?.data || {};
    });
  }

  getProduct({ id, ...rest }) {
    return this.get(`/product/${id}`, rest);
  }

  getProductSimilar({ id, ...rest }) {
    return this.get(`/product/${id}/similar`, rest);
  }

  getProductColors({ id, ...rest }) {
    return this.get(`/ext/product/${id}/colors`, rest).then((data) =>
      data ? { all_data: data.data || {} } : {},
    );
  }

  getProductSizes({ id, ...rest }) {
    return this.get(`/ext/product/${id}/sizes`, rest).then((data) => data.data || []);
  }

  getProductWarehouses(props) {
    return this.get(`/ext/warehouses`, props).then((data) => data.data || []);
  }

  getProductListing({ id, ...rest }) {
    return this.get(`/product/${id}/listing`, rest);
  }

  getProductHours({ id, ...rest }) {
    return this.get(`/product/${id}/hours`, rest);
  }

  getProductSearchListing({ id, ...rest }) {
    return this.get(`/product/${id}/search`, rest);
  }

  getProductChanges({ id, ...rest }) {
    return this.get(`/product/${id}/changes`, rest);
  }

  getProductAdvertising({ id, ...rest }) {
    return this.get(`/product/${id}/adv`, rest).then((data) => data?.table_data || []);
  }

  getProductPrice({ id, ...rest } = {}) {
    return this.get(`/product/${id}/prices`, rest).then((data) => {
      if (!data) return {};
      return {
        labels: data?.chart_dates,
        chartData: data.all_data.chart_data[0]?.price.map((item) => (item ? +item : item)),
        minPrice: data.all_data?.prices.minimum,
        maxPrice: data.all_data?.prices.maximum,
      };
    });
  }

  getProductRatings(props) {
    return this.get('/ratings', props).then((data) => {
      if (!data) return {};

      const dataset1 = data.all_data.rating_data[0].data.map((item) => (item ? +item : item));
      const dataset2 = data.all_data.reviews_data[0].data.map((item) => (item ? +item : item));
      return {
        labels: data?.chart_dates,
        chartData: [dataset1, dataset2],
        reviewsCount: dataset2[dataset2.length - 1],
      };
    });
  }

  getProductStock(props) {
    return this.get('/common/stock', props).then((data) => {
      if (!data) return {};

      return {
        tableData: data?.all_data?.table_data,
        labels: data?.chart_dates,
        chartData: data.all_data.stock_data[0].data.map((item) => (item ? +item : item)),
      };
    });
  }

  // USE IN FETCH CHART ROW DATA
  getBrandsChart(props) {
    return this.get('/sales/brands/chart/', props).then(transformChartData);
  }

  getSellersChart(props) {
    return this.get('/sales/sellers/chart', props).then(transformChartData);
  }

  getProductsChart(props) {
    return this.get('/sales/products/chart', props).then(transformChartData);
  }

  getOrdersChart(props) {
    return this.get('/ext/charts', props).then((data) => data?.data || []);
  }

  // USE IN PROFILE DATA
  getOrdersList() {
    return this.get('/user/orders').then((data) => data || []);
  }

  getOrderAct(id) {
    return this.get(`${getApi()}/user/orders/${id}/act-file`).then(({ url, extension }) => {
      axios({
        url,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Act.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    });
  }

  getSubscriptions() {
    return this.get('/user/tariffs').then((data) => data || []);
  }

  getTariffsList() {
    return this.get('/tariffs').then((data) => data || []);
  }

  getCheckCpm(value) {
    return this.get(`/checkcpm?keyword=${value}`).then((data) => data || {});
  }

  // START useFetchNamesData +++
  getSellersNames({ filter }) {
    return this.get('/search/sellers', { filter }).then((data) => data || []);
  }

  getBrandsNames({ filter }) {
    return this.get('/search/brands', { filter }).then((data) => data || []);
  }
  // END useFetchNamesData +++

  getSearchAmount({ filter }) {
    return this.get('/search/amount', { filter }).then((data) => data?.all_data || {});
  }

  getSearchProduct({ filter }) {
    return this.get('/search/product', { filter }).then((data) => data?.all_data || []);
  }

  getSearchPhrases({ filter, ...other }) {
    console.log({ filter, ...other });
    return this.get('/seo/search/phrases', { filter, ...other });
  }

  seoCompare({ filter }) {
    return this.get('/search/cross', { filter }).then((data) => data?.all_data || {});
  }

  seoMonitoringProducts({ filter }) {
    return this.get('/product/simple-info', { filter }).then((data) => data || []);
  }

  seoMonitoringPhrases({ filter, restricts }) {
    return this.get('/search/phrases', { filter, restricts }).then((data) => data?.all_data || []);
  }

  seoByPhrase({ filter, restricts, warehouseType }) {
    return this.get('/search/for-products', { filter, restricts, warehouseType }).then(
      (data) => data?.all_data || [],
    );
  }

  seoHints({ filter, restricts }) {
    return this.get('/search/suggest', { filter, restricts }).then((data) => data?.all_data || {});
  }

  seoSmart({ filter, sort, order }) {
    return this.get('/search/subject', { filter, sort, order }).then(
      (data) => data?.all_data || {},
    );
  }

  seoSmartDescriptionWords({ filter }) {
    return this.get('/chat-gpt/product-description', { filter }).then((data) => data || {});
  }

  getReferralMe() {
    return this.get('/referral/me').then((data) => {
      return {
        ...data,
        refLink: `go.wecheck.ru/${PAGE_SIGNUP.replace('/', '')}?${REFERRALS_URL_KEY}=${
          data.refLink
        }`,
      };
    });
  }

  getReferralsMyRefs() {
    return this.get('/referral/my-refs').then((data) => {
      return data || [];
    });
  }

  getReferralsMyRefsTransactions() {
    return this.get('/referral/my-refs-transaction').then((data) => {
      return data || [];
    });
  }

  getSalesCategoriesGrowth(props) {
    return this.get('/sales/categories/growth', props).then((data) => data || []);
  }

  getSalesBrandsGrowth(props) {
    return this.get('/sales/brands/growth', props).then((data) => data || []);
  }

  getSalesProductsGrowth(props) {
    return this.get('/sales/products/growth', props).then((data) => data || []);
  }

  getSellersGrowth(props) {
    return this.get('/sales/sellers/growth', props).then((data) => data || []);
  }

  getApiKeys(props) {
    const { filter = {} } = props;
    return this.get('/user/in/api-keys', filter).then((data) => data.data || []);
  }

  getApiKeysDetails(props) {
    const { filter = {} } = props;
    const { apiKeysIds } = filter;
    return this.get(`user/in/update_statistics?ids=${apiKeysIds}`, filter).then(
      (data) => data.data || [],
    );
  }

  getDictionaryApiKeys(props) {
    const { filter = {} } = props;
    return this.get('user/in/dictionaries/user/api-keys', filter).then((data) => data.data || []);
  }

  createApiKey(props) {
    const { name, api_key, apiKeyType } = props;
    return this.post(`/user/in/api-keys`, { name, token: api_key, type: apiKeyType })
      .then((data) => data || {})
      .catch(() => ({ status: 422 }));
  }

  deleteApiKey(props) {
    const { id } = props;
    return this.delete(`/user/in/api-keys/${id}`).then((data) => data || {});
  }

  updateApiKeyName(props) {
    const { id, name } = props;
    return this.put(`/user/in/api-keys/${id}`, { name }).then((data) => data || {});
  }

  loadTemplates(props) {
    const { url } = props;
    return this.get(`/user/table-presets/by-url/${url}`).then((data) => data.data || []);
  }

  createTemplate(props) {
    const { data } = props;
    return this.post(`/user/table-presets`, data).then((data) => data || {});
  }

  updateTemplate(props) {
    const { id, data } = props;
    return this.patch(`/user/table-presets/${id}`, data).then((data) => data || {});
  }

  deleteTemplate(props) {
    const { id } = props;
    return this.delete(`/user/table-presets/${id}`).then((data) => data || {});
  }

  createCommonTemplate(props) {
    const { data } = props;
    return this.post(`/user/presets`, data).then((data) => data || {});
  }

  getCommonTemplates() {
    return this.get(`/user/presets`).then((data) => data?.data || []);
  }

  deleteCommonTemplate({ id }) {
    return this.delete(`/user/presets/${id}`).then((data) => data || {});
  }

  updateCommonTemplate({ id, ...other }) {
    return this.patch(`/user/presets/${id}`, other).then((data) => data || {});
  }
}

const apiClient = new BaseClient(getApi());

export default apiClient;

import PropTypes from 'prop-types';

import { TYPE_ABSOLUTE, TYPE_PERCENT } from 'constants/chart';

import Checkbox from '../Checkbox';
import {
  BLUE,
  GREEN,
  ORANGE,
  PINK,
  PURPLE,
  BROWN,
  GRAY_DARK,
  SALAD,
  VIOLET,
  ROSE,
  DESERT,
  MOJO,
  CEMENT,
  MALIBU,
  MOON,
  BOUQUET,
  SALMON,
  PINE,
  MINT,
  BRAND_WB,
  FLIRT,
  BRAND_OZON,
  SCIENCE_BLUE,
  FUEL_YELLOW,
  BRANDY_PUNCH,
  SILVER_CHALICE,
  ABBEY,
  PARADISO,
  HIPPIE_BLUE,
  BARLEY_CORN,
  COPPER_RUST,
} from 'constants/colors';
import { isLineAvailable } from 'components/MultiChart/utils';
import cn from 'classnames';
import { useUI } from 'context/ui.context';

export const COLORS = {
  [PURPLE]: 'purple',
  [ORANGE]: 'orange',
  [PINK]: 'pink',
  [GREEN]: 'green',
  [BLUE]: 'blue',
  [BROWN]: 'brown',
  [GRAY_DARK]: 'gray',
  [SALAD]: 'salad',
  [VIOLET]: 'violet',
  [ROSE]: 'rose',
  [DESERT]: 'desert',
  [MOJO]: 'mojo',
  [CEMENT]: 'cement',
  [MALIBU]: 'malibu',
  [MOON]: 'moon',
  [BOUQUET]: 'bouquet',
  [SALMON]: 'salmon',
  [PINE]: 'pine',
  [MINT]: 'mint',
  [BRAND_WB]: 'wb',
  [FLIRT]: 'flirt',
  [BRAND_OZON]: 'ozon',
  [SCIENCE_BLUE]: 'science-blue',
  [FUEL_YELLOW]: 'fuel-yellow',
  [BRANDY_PUNCH]: 'brandy-punch',
  [SILVER_CHALICE]: 'silver-chalice',
  [ABBEY]: 'abbey',
  [PARADISO]: 'paradiso',
  [HIPPIE_BLUE]: 'hippie-blue',
  [BARLEY_CORN]: 'barley-corn',
  [COPPER_RUST]: 'copper-rust',
};

export const getName = ({ key, type }) =>
  key.indexOf(type) > -1 ? key.slice(0, key.indexOf(type) - 1) : key;

export const getKey = ({ name, type }) => `${name}_${type}`;

export const getLabelWithPostfix = ({ label, labelsPostfix, name }) => {
  if (labelsPostfix && labelsPostfix[name]) {
    label = `${label} <span class="text-gray-400 pl-1">${labelsPostfix[name]}</span>`;
  }

  return label;
};

export const getLabel = ({ key, type, labels, labelsPostfix }) => {
  const name = getName({ key, type });

  let label = labels?.[name] || name;

  return getLabelWithPostfix({ label, labelsPostfix });
};

const Legend = ({
  name,
  color,
  dataKey,
  isLastInRow,
  onChange,
  label,
  checked,
  disabled,
  onMouseEnter,
  onMouseLeave,
}) => {
  if (!label || !color) return <></>;
  const isEmpty = dataKey.startsWith('empty');
  return (
    <li
      key={dataKey}
      className={`pr-3 mr-3 py-1 pl-1 text-xs overflow-hidden ${
        isLastInRow ? '' : 'border-r border-gray-200'
      }`}
    >
      <div className={isEmpty ? 'hidden' : 'block'}>
        <Checkbox
          name={name}
          color={color}
          label={label}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          labelClass="truncate group-hover:!text-green cursor-pointer"
          className="items-center group cursor-pointer"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>
    </li>
  );
};
Legend.propTypes = {
  dataKey: PropTypes.string.isRequired,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  isLastInRow: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  line: PropTypes.shape({
    color: PropTypes.string,
    dataKey: PropTypes.string.isRequired,
  }),
};
const CustomizedLegend = ({
  dynamicColors,
  type,
  labels,
  shownDynamicLabels,
  active,
  onChange,
  isError,
  controls,
  data,
  labelsPostfix,
  columns,
  colors,
  setHoveredKey,
}) => {
  const labelsKey = Object.keys(labels);
  const dynamicLabelsKey = Object.keys(shownDynamicLabels);
  const labelsKeyCount = labelsKey.length + dynamicLabelsKey.length;
  const { isMobile } = useUI();

  return (
    <div
      className={cn('flex items-start h-0', {
        ['h-auto flex']: isMobile,
      })}
    >
      {!labelsKeyCount ? (
        'Загрузка...'
      ) : isError ? (
        'Что-то пошло не так :('
      ) : (
        <ul
          className={cn({
            [`grid grid-rows-2 grid-cols-[repeat(${columns},_auto)]`]: labelsKeyCount > columns,
            ['flex']: labelsKeyCount <= columns,
            ['grow shrink-0 grid-cols-[repeat(3,_auto)]']: isMobile,
          })}
        >
          {labelsKey.map((name, i) => {
            const key = getKey({ name, type });

            const color = COLORS?.[colors[i]];
            const label = getLabelWithPostfix({ label: labels[name], labelsPostfix, name });
            return (
              <Legend
                key={key}
                dataKey={key}
                name={name}
                color={color}
                isLastInRow={!controls && (i === labelsKeyCount - 1 || (i + 1) % columns === 0)}
                onChange={onChange}
                label={label}
                checked={active.includes(name)}
                disabled={!isLineAvailable({ data, type, key })}
                onMouseEnter={() => setHoveredKey(name)}
                onMouseLeave={() => setHoveredKey()}
              />
            );
          })}
          {dynamicLabelsKey.map((name, i) => {
            const key = getKey({ name, type });
            const currentIndex = i + labelsKey.length;
            const color = COLORS?.[dynamicColors[name]];
            const label = getLabelWithPostfix({ label: shownDynamicLabels[name], labelsPostfix });

            return (
              <Legend
                name={name}
                key={key}
                dataKey={key}
                color={color}
                isLastInRow={
                  !controls &&
                  (currentIndex === labelsKeyCount - 1 || (currentIndex + 1) % columns === 0)
                }
                onChange={onChange}
                label={label}
                checked={active.includes(name)}
                disabled={!isLineAvailable({ data, type, key })}
                onMouseEnter={() => setHoveredKey(name)}
                onMouseLeave={() => setHoveredKey()}
              />
            );
          })}
        </ul>
      )}

      {!!labelsKeyCount && controls && controls}
    </div>
  );
};
CustomizedLegend.defaultProps = {
  isError: false,
  controls: null,
  data: {},
  labelsPostfix: {},
};
CustomizedLegend.propTypes = {
  type: PropTypes.oneOf([TYPE_ABSOLUTE, TYPE_PERCENT]).isRequired,
  active: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  labelsPostfix: PropTypes.object,
  controls: PropTypes.element,
  data: PropTypes.object,
  columns: PropTypes.number.isRequired,
  dynamicColors: PropTypes.object,
  labels: PropTypes.object,
  shownDynamicLabels: PropTypes.object,
  colors: PropTypes.array,
  setHoveredKey: PropTypes.func,
};

export default CustomizedLegend;

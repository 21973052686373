import React from 'react';
import PropTypes from 'prop-types';

import { Redirect, useParams } from 'react-router-dom';
import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useProduct } from 'context/product.context';
import HelpBlock from 'components/HelpBlock';

const Advertising = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { isLoading: productLoading } = useProduct();
  const { isAdvertisingFilterAvailable } = useUserTariffs();

  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_ADVERTISING,
    id,
    enabled: isAdvertisingFilterAvailable && fetchEnabled,
  });

  if (!isAdvertisingFilterAvailable) {
    return <Redirect to={`/product/${id}`} />;
  }

  return (
    <>
      <HelpBlock type="product_advertising" />
      <Table
        data={data || []}
        type={TABLE_TYPE.PRODUCT_ADVERTISING}
        autoSize={false}
        noRowsText={`Товар<br/>не рекламировался`}
        isLoading={isLoading || isFetching || productLoading}
      />
    </>
  );
};
Advertising.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Advertising;

import React, { useEffect, useCallback, useReducer } from 'react';

import {
  CHIP_ID_KEY,
  CHIP_TITLE_KEY,
  useFilterReducer,
} from 'context/filter/filter.context.reducer';
import { F_INNER } from 'constants';
import { CHIPS_KEYS_INNER } from 'context/filter/useFilterInner';
import { getChipsInitial } from 'context/filter/filter.context.reducer.methods';

const FilterModalInnerContext = React.createContext();
FilterModalInnerContext.displayName = 'FilterModalInner.Context';

const MODAL_FILTER_KEYS = [F_INNER.CATEGORY, F_INNER.BRAND, F_INNER.SUBJECT, F_INNER.API_KEYS];

const MODAL_INNER_FILTER_INITIAL_STATE = {
  chips: getChipsInitial(CHIPS_KEYS_INNER),
  filter: {
    ...getChipsInitial(MODAL_FILTER_KEYS),
    [F_INNER.QUANTITY]: 0,
    [F_INNER.WAREHOUSE]: [],
  },
};

const ACTIONS = {
  UPDATE_FILTER: 'UPDATE_MODAL_INNER_FILTER',
  RESET_FILTER: 'RESET_MODAL_INNER_FILTER',
  SET_QUANTITY: 'SET_QUANTITY',
};

const filterModalReducer = (state, action) => {
  const { filter, chips } = state;

  switch (action.type) {
    case ACTIONS.UPDATE_FILTER: {
      return {
        ...state,
        filter: { ...filter, ...action.filter },
        chips: { ...chips, ...action.chips },
      };
    }

    case ACTIONS.RESET_FILTER: {
      return {
        filter: {
          ...MODAL_INNER_FILTER_INITIAL_STATE.filter,
          [F_INNER.API_KEYS]: state.filter[F_INNER.API_KEYS],
        },
        chips: MODAL_INNER_FILTER_INITIAL_STATE.chips,
      };
    }

    default:
      return state;
  }
};

const FilterModalInnerProvider = (props) => {
  const [state, dispatch] = useReducer(filterModalReducer, MODAL_INNER_FILTER_INITIAL_STATE);
  const { filter, chips, updateFromModalFilter } = useFilterReducer();

  const updateFilter = ({ items, type }) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        [type]: Array.isArray(items)
          ? items.filter((item) => !!item[CHIP_ID_KEY]).map((item) => item[CHIP_ID_KEY])
          : items,
      },
      ...(CHIPS_KEYS_INNER.includes(type) && {
        chips: {
          [type]: items.filter((item) => !!item[CHIP_ID_KEY] && !!item[CHIP_TITLE_KEY]),
        },
      }),
    });
  };

  const updateApiKeys = (items) => {
    updateFilter({
      type: F_INNER.API_KEYS,
      items,
    });
  };

  const updateCategories = (items) => {
    updateFilter({
      type: F_INNER.CATEGORY,
      items,
    });
  };

  const updateSubjects = (items) => {
    updateFilter({
      type: F_INNER.SUBJECT,
      items,
    });
  };

  const updateBrands = (items) => {
    updateFilter({
      type: F_INNER.BRAND,
      items,
    });
  };

  const updateQuantity = (items) => {
    updateFilter({
      type: F_INNER.QUANTITY,
      items,
    });
  };

  const updateWarehouse = (items) => {
    updateFilter({
      type: F_INNER.WAREHOUSE,
      items,
    });
  };

  const updateGlobalFilter = () => {
    updateFromModalFilter({
      filter: state.filter,
      chips: state.chips,
    });
  };

  const updateModalFilterFromGlobalState = useCallback(() => {
    const filterModal = Object.keys(filter)
      .filter((key) => [...MODAL_FILTER_KEYS, F_INNER.QUANTITY, F_INNER.WAREHOUSE].includes(key))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: filter[key] });
      }, {});
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: filterModal,
      chips,
    });
  }, [filter, chips]);

  const resetFilterToGlobalState = () => {
    updateModalFilterFromGlobalState();
  };

  const resetAllFilters = () => {
    dispatch({
      type: ACTIONS.RESET_FILTER,
      filter,
      chips,
    });
  };

  const cleanFilterByType = (type) => {
    dispatch({
      type: ACTIONS.UPDATE_FILTER,
      filter: {
        ...(MODAL_INNER_FILTER_INITIAL_STATE.filter[type] && {
          [type]: MODAL_INNER_FILTER_INITIAL_STATE.filter[type],
        }),
      },
      chips: {
        ...(MODAL_INNER_FILTER_INITIAL_STATE.chips[type] && {
          [type]: MODAL_INNER_FILTER_INITIAL_STATE.chips[type],
        }),
      },
    });
  };

  useEffect(() => {
    updateModalFilterFromGlobalState();
  }, [updateModalFilterFromGlobalState]);

  const value = {
    apiKeys: state.filter[F_INNER.API_KEYS],
    brands: state.filter[F_INNER.BRAND],
    categories: state.filter[F_INNER.CATEGORY],
    subjects: state.filter[F_INNER.SUBJECT],
    quantity: state.filter[F_INNER.QUANTITY],
    warehouses: state.filter[F_INNER.WAREHOUSE],
    updateApiKeys,
    updateBrands,
    updateCategories,
    updateSubjects,
    updateWarehouse,
    updateGlobalFilter,
    resetFilterToGlobalState,
    resetAllFilters,
    cleanFilterByType,
    updateQuantity,
    updateModalFilterFromGlobalState,
  };

  return <FilterModalInnerContext.Provider {...props} value={value} />;
};

const useModalFilterInner = () => React.useContext(FilterModalInnerContext);

export { FilterModalInnerProvider, useModalFilterInner };

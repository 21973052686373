const VideoIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.62041 18H13.3796C15.0204 18 16 17.1429 16 15.665V8.34236C16 6.85714 15.102 6 13.4612 6H4.62041C3.05306 6 2 6.85714 2 8.34236V15.665C2 17.1429 2.97959 18 4.62041 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.6219 16.6282L16 14.2382V9.75417L19.6219 7.37178C19.9602 7.14416 20.3582 7 20.7164 7C21.4925 7 22 7.43247 22 8.05463V15.9454C22 16.5675 21.4925 17 20.7164 17C20.3582 17 19.9602 16.8558 19.6219 16.6282Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default VideoIcon;

import PropTypes from 'prop-types';
import cn from 'classnames';

import CloseIcon from 'assets/icons/CrossIcon';

const Tag = ({ count, onClose, className }) => (
  <span
    className={cn(`bg-green rounded-sm text-white inline-flex px-2 py-1 leading-none text-xs`, {
      [className]: className,
    })}
  >
    {count}
    {onClose && (
      <button onClick={onClose} className="ml-2">
        <CloseIcon className="w-3 h-3" />
      </button>
    )}
  </span>
);

Tag.propTypes = {
  count: PropTypes.number.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default Tag;

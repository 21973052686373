import PropTypes from 'prop-types';

const TableInnerPredict = (props) => {
  const { value, data, valueFormatted, colDef } = props;
  if (!value || value === '-') {
    const predictKey = colDef.field === 'delivery_rub' ? 'predict_logistic' : 'predict_commission';
    return (
      <div className="text-gray-300">
        {colDef.valueFormatter({ ...props, value: data[predictKey] })}
      </div>
    );
  }
  return <div>{valueFormatted}</div>;
};

TableInnerPredict.propTypes = {
  value: PropTypes.any,
  data: PropTypes.any,
  colDef: PropTypes.any,
  valueFormatted: PropTypes.any,
};

export default TableInnerPredict;

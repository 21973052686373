import PropTypes from 'prop-types';

export const Footer = ({ disabled, onClean, onSubmit }) => {
  // It's simple solution for hidding filter after cancel and submit
  // It's made because ag-grid sometimes doesn't hide filter by itself
  const hideFilter = () => {
    const filterPopup = document.getElementsByClassName('ag-tabs')[0];
    if (filterPopup) {
      filterPopup.style.display = 'none';
    }
  };
  const handleCancel = () => {
    onClean();
    hideFilter();
  };
  const handleApply = () => {
    onSubmit();
    hideFilter();
  };
  return (
    <div className="flex justify-between space-x-3">
      <button
        className="w-full inline-flex justify-center py-2.5 border border-gray-250 transition-all rounded-md text-red-bright hover:bg-gray-230 focus:bg-gray-230 focus:outline-none"
        onClick={handleCancel}
      >
        Сбросить
      </button>

      <button
        disabled={disabled}
        className="w-full inline-flex justify-center py-2.5 border border-green transition-all rounded-md text-white bg-green hover:bg-transparent hover:text-green focus:outline-none focus:bg-transparent focus:text-green disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        onClick={handleApply}
      >
        Применить
      </button>
    </div>
  );
};
Footer.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string,
  from: PropTypes.string,
  onClean: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

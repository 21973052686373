import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTable } from 'context/table.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { FilterDatePicker, Footer, InputsRow } from './components';
import 'assets/styles/react-filter-datepicker.css';

const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => 'mm/dd/yyyy',
  },
};

const getCurrentRestricts = (filterRange, field, index) => {
  if (!filterRange || !filterRange[field]) return '';
  const value = filterRange[field][index];
  if (value) {
    const splitted = value.split('-');
    return `${splitted[2]}.${splitted[1]}.${splitted[0].slice(2)}`;
  }
  return filterRange[field][index];
};

const RangeDateFilter = forwardRef(
  (
    {
      colDef: {
        field,
        headerComponentParams: { sortable },
      },
      noLimit,
      filterChangedCallback,
      column,
    },
    ref,
  ) => {
    const [activeInput, setActiveInput] = useState('from');
    const { type, isTableRestrictedOnBack } = useTable();
    const { filterRange, addFilterRange, removeFilterRange } = useFilterReducer();

    const [from, setFrom] = useState(getCurrentRestricts(filterRange, field, 0));
    const [to, setTo] = useState(getCurrentRestricts(filterRange, field, 1));
    const [maxDate, setMaxDate] = useState();
    const [minDate, setMinDate] = useState();
    const [selectedDate, setSelectedDate] = useState();

    const [instanceParams, setInstanceParams] = useState(null);

    const restrictedOnBack = isTableRestrictedOnBack(type);
    const disabled = (from === '' && to === '') || (from !== '' && to !== '' && +from > +to);

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params) {
          if (restrictedOnBack) return true;

          if (from === '') {
            return params.data[field] <= +to;
          }

          if (to === '') {
            return params.data[field] >= +from;
          }

          return params.data[field] >= +from && params.data[field] <= +to;
        },

        isFilterActive() {
          return !disabled;
        },

        getModel() {
          if (!this.isFilterActive()) {
            return null;
          }

          return {
            type: 'inRange',
            filter: from,
            filterTo: to,
          };
        },

        setModel() {},

        hidePopup() {
          if (!instanceParams || !instanceParams?.hidePopup) return;

          instanceParams.hidePopup();
        },

        afterGuiAttached(params) {
          setInstanceParams(params);
        },
      };
    });

    const handleChangeDate = (value) => {
      const ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(value);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(value);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(value);
      const formatted = `${da}.${mo}.${ye}`;
      if (activeInput === 'from') {
        setFrom(formatted);
        setActiveInput('to');
      } else {
        setTo(formatted);
      }
    };
    const convertForFilter = (value) => {
      if (value) {
        const splitted = value.split('.');
        return `20${splitted[2]}-${splitted[1]}-${splitted[0]}`;
      }
      return value;
    };
    const handleSubmit = () => {
      addFilterRange({ value: [convertForFilter(from), convertForFilter(to)], key: field });
    };

    const cleanFilter = () => {
      setFrom('');
      setTo('');

      removeFilterRange({ key: field });
    };

    const handleChangeActiveDate = (value) => {
      if (value) {
        const splittedValue = value.split('.');
        setSelectedDate(new Date(`${splittedValue[1]}/${splittedValue[0]}/${splittedValue[2]}`));
      } else {
        setSelectedDate();
      }
    };

    useEffect(() => {
      if (activeInput === 'from') {
        if (to) {
          const splittedTo = to.split('.');
          setMaxDate(new Date(`${splittedTo[1]}/${splittedTo[0]}/${splittedTo[2]}`));
        } else {
          setMaxDate(noLimit ? undefined : new Date());
        }
        setMinDate();
        handleChangeActiveDate(from);
      } else {
        if (from) {
          const splittedFrom = from.split('.');
          setMinDate(new Date(`${splittedFrom[1]}/${splittedFrom[0]}/${splittedFrom[2]}`));
        }
        setMaxDate(noLimit ? undefined : new Date());
        handleChangeActiveDate(to);
      }
    }, [from, to, activeInput, noLimit]);

    useEffect(() => {
      filterChangedCallback();
      return () => {
        filterChangedCallback();
      };
    }, [filterRange, filterChangedCallback]);

    return (
      <div className="p-4 w-full">
        <p className="text-sm mb-4">Введите даты, в рамках которых хотите отфильтровать колонку</p>

        <InputsRow
          from={from}
          onFromChange={() => setFrom(from)}
          to={to}
          onToChange={() => setTo(to)}
          column={column}
          sortable={sortable}
          isNumeric={false}
          disableOnChange
          getActiveClassNames={(key) => (activeInput === key ? 'border-green' : '')}
          onFocus={(key) => setActiveInput(key)}
          enterCallback={(e) => {
            if (e.keyCode === 13) {
              handleSubmit();
            }
          }}
        />
        <FilterDatePicker
          handleChangeDate={handleChangeDate}
          maxDate={maxDate}
          minDate={minDate}
          selectedDate={selectedDate}
          locale={locale}
        />

        <Footer disabled={!!disabled} onClean={cleanFilter} onSubmit={handleSubmit} />
      </div>
    );
  },
);

RangeDateFilter.displayName = 'TableHeaderFilter';
RangeDateFilter.propTypes = {
  hidePopup: PropTypes.func,
  noLimit: PropTypes.bool,
  colDef: PropTypes.shape({
    field: PropTypes.string.isRequired,
    headerComponentParams: PropTypes.shape({
      sortable: PropTypes.bool,
    }),
  }).isRequired,
  filterChangedCallback: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
};
export default RangeDateFilter;

import PropTypes from 'prop-types';
import LoadingSpin from 'components/LoadingSpin';

const SignIn = ({ state, setState, isLoading }) => {
  const handleChange = (e, key) => {
    setState({ ...state, [key]: e.target.value });
  };
  const { error } = state;
  return (
    <div>
      {isLoading ? (
        <LoadingSpin classNames="h-[136px]" />
      ) : (
        <>
          <div>
            <label className="block">
              Email
              <span className="text-red">*</span>
            </label>
            <input
              name="email"
              className=" px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
              value={state.email || ''}
              onChange={(e) => handleChange(e, 'email')}
            />
          </div>
          <div className="mt-4">
            <label className="block">
              Пароль
              <span className="text-red">*</span>
            </label>
            <input
              type="password"
              className="px-3 mt-2 h-8 rounded w-64 border border-gray-250 focus:outline-none focus:border focus:border-green"
              value={state.password || ''}
              onChange={(e) => handleChange(e, 'password')}
            />
          </div>
          {error && (
            <div className="w-64 bg-orange text-white py-1.5 px-7 mt-4">
              <span>Неверный логин или пароль</span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

SignIn.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default SignIn;

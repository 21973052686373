const PopoverArrowIcon = (props) => (
  <svg
    {...props}
    width="17"
    height="7"
    viewBox="0 0 17 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.50001 0L0.22415 6.61905L16.7759 6.61905L8.50001 0Z" fill="white" />
  </svg>
);

export default PopoverArrowIcon;

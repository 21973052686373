import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useLocalStorage from 'hooks/useLocalStorage';
import DownIcon from 'assets/icons/DownIcon';
// import ListIcon from 'assets/icons/ListIcon';
// import VideoIcon from 'assets/icons/VideoIcon';
import { CustomVideoPlayer } from 'components/CustomVideoPlayer';
import Modal from 'components/Modal';
import { useUI } from 'context/ui.context';
import { HELP_BLOCK_INFO } from 'components/HelpBlock/constants';

const HelpBlock = ({ type }) => {
  const [collapsed = [], setCollapsed] = useLocalStorage(`help_collapsed`);
  const [stateCollapsed, setStateCollapsed] = useState(collapsed.includes(type));
  const [isShowVideo, setIsShowVideo] = useState(false);
  const { tabName, helpText, video } = HELP_BLOCK_INFO[type];
  const handleHeaderClick = () => {
    const newValue = !stateCollapsed;
    setStateCollapsed(newValue);
    const newCollapsed = [...collapsed];
    if (newValue) {
      newCollapsed.push(type);
    } else {
      const index = collapsed.indexOf(type);
      newCollapsed.splice(index, 1);
    }
    setCollapsed(newCollapsed);
  };
  const { isMobile } = useUI();
  return (
    <div className="bg-gray-100 px-3 py-2">
      <div className="border border-solid border-gray-200 rounded-xl bg-white">
        <button onClick={handleHeaderClick} className="w-full">
          <div className="flex w-full justify-between text-left px-4 py-2 lg:py-3 group cursor-pointer">
            <span className="block font-medium lg:font-bold text-lg">{tabName}</span>
            <div className="flex items-center min-w-max">
              <span className="hidden relative top-0.5 lg:block text-gray-400 group-hover:text-green">
                Как пользоваться
              </span>
              <DownIcon
                width={16}
                height={16}
                className={cn('ml-1.5 group-hover:text-green', {
                  'rotate-180': !stateCollapsed,
                })}
              />
            </div>
          </div>
        </button>
        {!stateCollapsed && (
          <div className="px-4">
            <div
              className={cn('py-3 block lg:flex justify-between', {
                'border-t border-grey': !stateCollapsed,
              })}
            >
              <span className="text-gray-400 text-sm lg:text-base">{helpText}</span>
              {/*<div className="block lg:flex border-t pt-3 mt-3 lg:mt-0 lg:pt-0 lg:border-l lg:border-t-0 border-grey lg:pl-4 min-w-max space-y-1.5 lg:space-y-0 lg:space-x-2 lg:items-center">
                <div>
                  <button className="px-3 py-2 border border-gray-250 rounded inline-flex items-center hover:text-green hover:border-green">
                    <ListIcon width={20} height={20} className="mr-2" />
                    <span>Примеры кейсов</span>
                  </button>
                </div>
                <div>
                  <button
                    className="px-3 py-2 border border-gray-250 rounded inline-flex items-center hover:text-green hover:border-green"
                    onClick={() => setIsShowVideo(true)}
                  >
                    <VideoIcon width={20} height={20} className="mr-2" />
                    <span>Видеоинструкция</span>
                  </button>
                </div>
              </div>*/}
            </div>
          </div>
        )}
      </div>
      <Modal
        footer={false}
        width={isMobile ? undefined : 1000}
        title="Видео о WeCheck"
        isOpen={isShowVideo}
        closeModal={() => setIsShowVideo(false)}
      >
        <CustomVideoPlayer videoId={video} />
      </Modal>
    </div>
  );
};

HelpBlock.propTypes = {
  type: PropTypes.string.isRequired,
};

export default HelpBlock;

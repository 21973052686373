import { useUI } from '../../context/ui.context';
import { MONITORING_GROUPS_MODAL_MODES } from './constants';
import cn from 'classnames';
import ButtonPrimary from '../ButtonPrimary';
import PropTypes from 'prop-types';
import baseClient from '../../services/baseClient';

const GroupDelete = ({
  setMode,
  modalSelected,
  setModalSelected,
  monitoringGroups,
  setMonitoringGroups,
}) => {
  const { isMobile } = useUI();

  const handleCancel = () => {
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
  };
  const handleDelete = async () => {
    const groupsBeforeChanging = JSON.parse(JSON.stringify(monitoringGroups));
    const selectedBefore = JSON.parse(JSON.stringify(modalSelected));
    baseClient.deleteCommonTemplate({ id: modalSelected.id }).then((data) => {
      if (data.status !== 200) {
        setMonitoringGroups(() => groupsBeforeChanging);
        setModalSelected(selectedBefore);
      }
    });
    setMonitoringGroups(monitoringGroups.filter((el) => el.id !== modalSelected.id));
    setMode(MONITORING_GROUPS_MODAL_MODES.INFO);
    setModalSelected();
  };
  return (
    <div className="w-full flex grow items-center justify-center">
      <div
        className={cn({
          'w-full': isMobile,
        })}
      >
        <div className="p-5 lg:p-0">
          <span className="block text-gray-700 text-lg font-bold text-center mb-1">
            Вы действительно хотите <br />
            удалить <span className="text-green">{modalSelected.name}</span>?
          </span>
          <span className="block text-center text-gray-400">Содержимое списка также удалится</span>
        </div>
        {isMobile ? (
          <div className="border-t border-gray-250 px-6 py-4 flex items-center justify-end gap-2 bg-[#F5F5F5]">
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary__inverted text-gray-400 border-gray-250"
              onClick={handleCancel}
              label="Не удалять"
            />
            <ButtonPrimary
              className="flex-1 py-2 justify-center button-primary"
              onClick={handleDelete}
              label="Да, удалить"
            />
          </div>
        ) : (
          <div className="flex space-x-3 justify-center mt-6">
            <button
              className="py-2.5 px-3 rounded border border-gray-250 text-gray-400"
              onClick={handleCancel}
            >
              Не удалять
            </button>
            <button
              className="py-2.5 px-3 rounded border bg-green text-white"
              onClick={handleDelete}
            >
              Да, удалить
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

GroupDelete.propTypes = {
  mode: PropTypes.string,
  setMode: PropTypes.func,
  modalSelected: PropTypes.any,
  setModalSelected: PropTypes.func,
  monitoringGroups: PropTypes.array,
  setMonitoringGroups: PropTypes.func,
};

export { GroupDelete };

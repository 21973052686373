import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Skeleton } from 'components/Skeleton';

export function ReferralTab({ title, isLoading, children, isWide }) {
  return (
    <div
      className={classNames(
        'w-full text-base px-5 py-3 bg-gray-50 grid content-between rounded-2xl border-gray-100 border items-start',
        {
          'lg:w-80': isWide,
          'lg:w-60': !isWide,
        },
      )}
    >
      <header className="font-bold text-base text-black-bright mb-3">{title}</header>
      <div
        className={classNames({
          ['min-w-full']: isLoading,
          ['hidden']: !isLoading,
        })}
      >
        <Skeleton classNames="max-w-[50%] h-8" />
      </div>
      <div
        className={classNames('flex flex-col gap-1', {
          ['hidden']: isLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
}

ReferralTab.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  isLoading: PropTypes.bool,
  isWide: PropTypes.bool,
};

ReferralTab.defaultProps = {
  isWide: false,
};

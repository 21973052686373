// import { useState } from 'react';
// import cn from 'classnames';

import { useGlobalDataLoading } from 'hooks/useGlobalDataLoading';
// import useInterestingTexts from 'hooks/useInterestingTexts';
import LoadingIcon from 'assets/icons/LoadingIcon';

const InterestingTextModal = () => {
  const loading = useGlobalDataLoading();

  // const [expanded, setExpanded] = useState(true);
  // const text = useInterestingTexts({ loading });
  //
  // const interestingCheckboxId = 'interesting-info-checkbox';
  //
  // const handleExpanded = () => setExpanded((prev) => !prev);

  if (/*!text ||*/ !loading) {
    return null;
  }
  // className="flex gap-3 pb-5 mb-5 border-b border-gray-230 items-center"
  return (
    <div className="fixed bottom-20 left-2/4 ml-[-194px] w-[388px] z-50 bg-white shadow-xl rounded-md border border-gray-250 px-6 py-4">
      <div className="flex gap-3 items-center">
        <LoadingIcon className="h-5 w-5 flex-none animate-spin text-green" />
        <h3 className="text-green block text-xl mb-0 leading-none">Идет загрузка данных</h3>
      </div>
      {/*<label
        htmlFor={interestingCheckboxId}
        className={cn(
          `
                cursor-pointer
                items-start mb-1
                flex gap-2
                group
                text-base text-lg leading-none hover:text-green
          `,
        )}
      >
        <span
          className={cn(
            `
                block
                p-0.5 w-3.5 h-3.5 rounded border border-gray-bright border-solid
                before:content-[''] before:block before:bg-black before:h-[1px] before:w-full before:mt-[45%] before:transition-all
                after:content-[''] after:block after:bg-black after:h-[1px] after:w-full after:relative after:-top-[1px] after:transition-all
                before:after:w-full
                group-hover:border-green group-hover:after:bg-green group-hover:before:bg-green 
          `,
            {
              ['after:rotate-0']: expanded,
              ['after:-rotate-90']: !expanded,
            },
          )}
        />
        <input
          checked={expanded}
          type="checkbox"
          id={interestingCheckboxId}
          className="appearance-none absolute"
          onChange={handleExpanded}
        />
        А вы знали?
      </label>
      {expanded ? (
        <p className="text-base text-gray-400" dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <></>
      )}*/}
    </div>
  );
};

export default InterestingTextModal;

import LoadingIcon from 'assets/icons/LoadingIcon';
import propTypes from 'prop-types';
import cn from 'classnames';
/**
 * @typedef IProps
 *
 * @property {string} [classNames] Опциональный набор классов, который будет применен к КОНТЕЙНЕРУ Spinner'а
 * @property {string} [iconClassNames] Опциональный набор классов, который будет применен к ИКОНКЕ Spinner'а
 * @property {boolean} [isVisible] Опциональный флаг, определяющий видимость элемента
 * */
/**
 * @returns {JSX.Element} Лоадер загрузки
 * @param {IProps} props
 * */
const LoadingSpin = ({ isVisible, classNames, iconClassNames, ...divProps }) => (
  <div
    className={cn('w-full h-full flex items-center justify-center', {
      ['hidden']: !isVisible,
      [classNames]: classNames,
    })}
    {...divProps}
  >
    <LoadingIcon
      className={cn('z-100 w-10 h-10 animate-spin text-green', {
        [iconClassNames]: iconClassNames,
      })}
    />
  </div>
);
LoadingSpin.defaultProps = {
  isVisible: true,
};
LoadingSpin.propTypes = {
  classNames: propTypes.string,
  iconClassNames: propTypes.string,
  isVisible: propTypes.bool,
};

export default LoadingSpin;

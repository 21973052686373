import { lazy } from 'react';
import PropTypes from 'prop-types';

const getClassName = (orientation, externalClasses = 'py-3 px-4', isHidden) => {
  let resultClassName = `
      min-h-min
      bg-white
      opacity-0
      shadow-black-bright shadow rounded
      absolute
      group-hover:block group-hover:opacity-100 group-hover:transition-opacity
      group-hover:before:opacity-100 text-gray-bright`;
  switch (orientation) {
    case 'left': {
      resultClassName = `${resultClassName}
      before:content-[''] before:block before:w-4 before:h-4
      before:absolute before:bottom-full before:inset-y-5 before:right-[-6px]
      before:bg-white 
      before:-rotate-[45deg] before:translate-y-[9px] -before:translate-x-[5px]
      before:shadow-[2px_2px_1px_0px_rgba(0,0,0,0.20),_0px_0px_0px_0px_#fff]`;
      break;
    }
    case 'top': {
      resultClassName = `${resultClassName}
      before:content-[''] before:block before:w-4 before:h-4 opacity-100
      before:absolute before:inset-x-2 before:bottom-0.5
      before:bg-white
      before:rotate-45 before:translate-y-[9px] -before:translate-x-[5px]
      before:shadow-[2px_2px_1px_0px_rgba(0,0,0,0.20),_0px_0px_0px_0px_#fff]`;
      break;
    }
    default: {
      resultClassName = `${resultClassName} inset-y-10 sm:min-w-[20rem] max-w-sm before:opacity-0
      before:content-[''] before:block before:w-4 before:h-4
      before:absolute before:bottom-full 
      before:bg-white before:opacity-100
      before:-rotate-[135deg] before:translate-y-[9px] -before:translate-x-[5px]
      before:shadow-[2px_2px_1px_0px_rgba(0,0,0,0.10),_0px_0px_0px_0px_#fff]`;
    }
  }
  if (isHidden) {
    resultClassName += ' hidden';
  }
  return `${resultClassName} ${externalClasses}`;
};
export default function HelpModal({
  title,
  orientation,
  externalClasses = '',
  isHidden = true,
  children,
  outerRef,
  ...etc
}) {
  const className = getClassName(orientation, externalClasses, isHidden);
  return (
    <article className={className} {...etc} ref={outerRef}>
      <div
        className="
        font-light text-sm leading-5
        "
      >
        {children || title}
      </div>
    </article>
  );
}

HelpModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  orientation: PropTypes.string,
  externalClasses: PropTypes.string,
  isHidden: PropTypes.bool,
  children: PropTypes.any,
  outerRef: PropTypes.any,
};

export const AsyncHelpModal = lazy(() => import('./HelpModal'));

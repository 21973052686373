import React, { useState } from 'react';
import {
  BLUE,
  BOUQUET,
  CEMENT,
  CONTESSA,
  GRAY,
  HELIOTROPE,
  LEAF,
  MALIBU,
  MINT,
  MOON,
  NEPAL,
  ORANGE,
  PINE,
  PINK,
  PURPLE,
  ROSE,
  SALMON,
  VIKING,
  YELLOW,
  YUMA,
} from 'constants/colors';

const ChartDoughnutContext = React.createContext();
ChartDoughnutContext.displayName = 'Chart.Doughnut.Context';

const defaultColors = [
  HELIOTROPE,
  ROSE,
  GRAY,
  MINT,
  YUMA,
  PINK,
  NEPAL,
  ORANGE,
  BOUQUET,
  YELLOW,
  LEAF,
  VIKING,
  CONTESSA,
  MALIBU,
  PURPLE,
  PINE,
  MOON,
  SALMON,
  CEMENT,
  BLUE,
  MINT,
];

const ChartDoughnutProvider = (props) => {
  const [active, setActive] = useState(null);
  const [colors, setColors] = useState(defaultColors);

  const setActiveIndex = (index) => setActive(index);
  const removeActiveIndex = () => setActive(null);
  const getColorByIndex = (index) => colors[index];

  const setDefaultColors = () => setColors(defaultColors);

  const value = {
    activeIndex: active,
    setActiveIndex,
    removeActiveIndex,
    setColors,
    setDefaultColors,
    defaultColors,
    colors,
    getColorByIndex,
  };

  return <ChartDoughnutContext.Provider value={value} {...props} />;
};

const useChartDoughnut = () => {
  return React.useContext(ChartDoughnutContext);
};

export { ChartDoughnutProvider, useChartDoughnut };

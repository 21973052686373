import { useProduct } from 'context/product.context';
import { ProductColorLabel, ProductSizeLabel } from '../components';
import HelpModal from 'components/HelpModal';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

export const ProductTitleRow = ({ barcode }) => {
  const { productData } = useProduct();
  const [showTooltip, setShowTooltip] = useState(true);
  const nameRef = useRef();
  const spanRef = useRef();

  const outerWidth = spanRef?.current?.offsetWidth || 0;
  const realWidth = nameRef.current?.offsetWidth || 0;

  useEffect(() => {
    setShowTooltip(realWidth < outerWidth);
  }, [outerWidth, realWidth]);

  return (
    <div className="relative">
      {/*this element to understand real width*/}
      <h1 className="font-normal lg:text-2xl absolute -z-10 opacity-0" ref={spanRef}>
        <span>{productData?.name}</span>
      </h1>
      <div
        className="
      space-y-2
      flex flex-col items-start
      lg:flex-row lg:space-y-0 lg:space-x-3
      "
      >
        <div className="group relative cursor-pointer">
          <h1
            className="font-normal lg:text-2xl max-w-full text-ellipsis line-clamp-1 "
            ref={nameRef}
          >
            <span>{productData?.name}</span>
          </h1>
          {showTooltip && (
            <HelpModal
              title={productData?.name || ''}
              externalClasses="opacity-100 z-1 px-4 py-3 !text-black"
            />
          )}
        </div>
        <ProductSizeLabel barcode={barcode} />
        <ProductColorLabel />
      </div>
    </div>
  );
};

ProductTitleRow.propTypes = {
  isInner: PropTypes.bool,
  barcode: PropTypes.string,
};

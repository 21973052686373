import React from 'react';

import { useParams } from 'react-router-dom';
import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFetchFilteredProductData } from 'hooks/useFetchFilteredProductData';
import { useProduct } from 'context/product.context';
import HelpBlock from 'components/HelpBlock';
import PropTypes from 'prop-types';

const Changes = ({ fetchEnabled }) => {
  const { id } = useParams();
  const { isLoading: productLoading } = useProduct();

  const { data, isLoading, isFetching } = useFetchFilteredProductData({
    type: TABLE_TYPE.PRODUCT_CHANGES,
    id,
    enabled: fetchEnabled,
  });

  return (
    <>
      <HelpBlock type="product_changes" />
      <Table
        data={data || []}
        type={TABLE_TYPE.PRODUCT_CHANGES}
        autoSize={true}
        loading={isLoading}
        noRowsText={`У товара<br/>не было изменений`}
        isLoading={isLoading || isFetching || productLoading}
      />
    </>
  );
};
Changes.propTypes = {
  fetchEnabled: PropTypes.bool,
};
export default Changes;

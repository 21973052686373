import PropTypes from 'prop-types';

const Input = ({ name, value, onChange, autoFocus, valid, prefix, placeholder, className }) => (
  <div className="relative">
    {prefix && (
      <span className="text-xs absolute pointer-events-none left-[8px] top-[4px]">{prefix}</span>
    )}
    <input
      className={`
    w-full appearance-none border rounded px-1.5 pt-0.5 h-[22px] text-gray-700 text-xs
    leading-none focus:outline-none focus:shadow-outline focus:ring-2 focus:ring-offset-2 
    ${!valid ? 'border-red focus:ring-red' : 'border-gray-300 focus:ring-green'}
    ${prefix ? 'pl-6' : ''} ${className}`}
      id={name}
      name={name}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      autoFocus={autoFocus}
      placeholder={placeholder}
    />
  </div>
);

Input.defaultProps = {
  autoFocus: false,
  valid: true,
  prefix: null,
  placeholder: '',
  className: '',
};
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  valid: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default Input;

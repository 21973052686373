import axios from 'axios';
import { getApi } from 'utils/api';

/**
 * Функция для взаимодействия с сущностью `client`
 *
 * @param {string} endPoint URL адрес, на который необходимо "достучаться
 * @param {Object} [data] Опциональные данные, которые (если переданы) будут отправлены вместе с запросом
 * */
export async function axiosClient(endPoint, data, method = 'GET') {
  const client = axios.create({
    baseURL: getApi(),
    method,
  });
  const { data: result, status, statusText } = await client.post(endPoint, data);

  if (status !== 200) {
    throw new Error(`useClient -> error -> ${statusText}`);
  }

  return result;
}
/**
 * Самописный хук для взаимодействия с сущностью `client`
 *
 * @param {string} endPoint URL адрес, на который необходимо "достучаться
 * @param {Object} [data] Опциональные данные, которые (если переданы) будут отправлены вместе с запросом
 * */
export default async function useAxiosClient(endPoint, data) {
  return await axiosClient(endPoint, data);
}

import { getApi } from 'utils/api';
import axios from 'axios';

class ActivatesClient {
  constructor(baseURL) {
    this.client = axios.create({
      baseURL: baseURL,
    });
  }

  async post(endpoint = {}) {
    return this.client.post(endpoint);
  }

  postActiveFormRequest() {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    if (params.form === undefined) return false;
    const url = Object.keys(params).reduce((acc, item, index) => {
      if (item !== 'form')
        acc += `${item}=${params[item]}${index + 1 !== Object.keys(params).length ? '&' : ''}`;
      return acc;
    }, '');

    return this.post(`/saveform?${url}`, null, {});
  }
}

const client = new ActivatesClient(getApi());

export default client;

import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

const UIContext = React.createContext();
UIContext.displayName = 'UI.Context';

export const PROMO_CODE_MODAL = 'promoCode';

const UIProvider = (props) => {
  const [screen, setScreen] = useState('desktop');
  const [activeModal, setActiveModal] = useState('');
  const isMobile = screen === 'mobile';
  const isTablet = screen === 'tablet';
  const isDesktop = screen === 'desktop';

  const screens = useMemo(
    () => ({
      mobile: {
        min: 0,
        max: 1023,
      },
      table: {
        min: 0,
        max: 0,
      },
      desktop: {
        min: 1024,
        max: 9999,
      },
    }),
    [],
  );

  const setInitialScreen = useCallback(() => {
    return setScreen(
      Object.keys(screens).reduce((acc, item) => {
        if (window.innerWidth <= screens[item].max && window.innerWidth >= screens[item].min) {
          acc = item;
        }

        return acc;
      }, 'desktop'),
    );
  }, [screens]);

  useLayoutEffect(() => {
    setInitialScreen();

    window.addEventListener('resize', setInitialScreen, true);

    return () => window.removeEventListener('resize', setInitialScreen, true);
  }, [screen, setInitialScreen]);

  const value = {
    screen,
    isMobile,
    isTablet,
    isDesktop,
    activeModal,
    setActiveModal,
  };

  return <UIContext.Provider value={value} {...props} />;
};

const useUI = () => React.useContext(UIContext);

export { UIProvider, useUI };

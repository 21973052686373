import { Fragment, useState } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

/**
 * @typedef IProps
 *
 * @property {Function} onSubmit Функция, которая будет вызвана при клике на кнопку "Применить"
 * @property {number} initialState Значение, которое будет отрисоано по умолчанию
 * @property {unknown[]} options Массив жлементов, которые будут отрисваны в качестве опций выбора
 * */
/**
 * @return {JSX.Element} Компонент для отрисовки всплывашки с выбором Select'а в Мобильной версии приложения
 *
 * @param {IProps & import("react").HTMLAttributes<HTMLDivElement>} props
 * */
export function MobileSelectPanel({ options, onSubmit, initialState, ...divProps }) {
  const [state, setState] = useState(initialState || 100);

  const onClickFunction = () => {
    onSubmit(state);
  };

  return (
    <aside className="grid gap-4 p-4 content-between h-full" {...divProps}>
      <div className="grid gap-4">
        {options.map((item, i) => {
          const isChecked = state === Number(item);
          const htmlFor = `${item}-checkbox_selectMobile`;

          return (
            <Fragment key={item + '_selectMobile'}>
              <div className="flex flex-grow items-center gap-4 font-SofiaPro">
                <label
                  htmlFor={htmlFor}
                  className={classNames(
                    `
                    transition transition-all
                    h-6 w-6 flex items-center justify-center
                    border border-solid border-green rounded-[4px]
                    `,
                    {
                      ['bg-green']: isChecked,
                    },
                  )}
                >
                  <input
                    id={htmlFor}
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setState(item)}
                    className="
                  appearance-none absolute before:relative before:-top-0.5
                  before:rotate-45 before:h-4 before:w-2 before:block before:content-['']
                  before:border-b-2 before:border-r-2 before:border-solid before:border-b-white before:border-r-white
                  "
                  />
                </label>
                <p className="font-medium text-lg">{item}</p>
              </div>
              {options[i + 1] && <span className="w-full h-[2px] bg-gray-100 rounded" />}
            </Fragment>
          );
        })}
      </div>
      <button
        className="flex justify-center align-center rounded bg-green p-2"
        type="button"
        onClick={onClickFunction}
      >
        <p className="text-white font-medium text-xl">Применить</p>
      </button>
    </aside>
  );
}

MobileSelectPanel.propTypes = {
  initialState: propTypes.number.isRequired,
  onSubmit: propTypes.func.isRequired,
  options: propTypes.array.isRequired,
};

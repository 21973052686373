import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useUI } from 'context/ui.context';
import TableHeaderDateControls from 'components/TableHeaderDateControls';
import { PlatformSelect } from 'components/Filter/components';
import SelectionModal from 'components/Positions/SelectionModal';
import DownIcon from 'assets/icons/DownIcon';
import Chip from 'components/Chip';
import cn from 'classnames';
import Calendar from 'assets/icons/Calendar';
import Popover from 'components/Popover';
import { PositionsContext } from 'pages/Positions/context';

const PositionsHeader = ({
  setSelectedCategory,
  selectedCategory,
  setSelectedBrand,
  selectedBrand,
  setSelectedSeller,
  selectedSeller,
}) => {
  const { isMobile, setActiveModal } = useUI();
  const { setSearchedBrand, setSearchedSeller } = useContext(PositionsContext);

  const tooltipRefBrands = useRef(),
    tooltipRefSellers = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState();

  const handleChipClick = (mode) => {
    setIsModalOpen(true);
    setModalMode(mode);
  };
  const modal = (
    <SelectionModal
      isOpen={isModalOpen}
      setModalOpen={setIsModalOpen}
      mode={modalMode}
      setSelectedCategory={setSelectedCategory}
      selectedCategory={selectedCategory}
      setSelectedBrand={setSelectedBrand}
      selectedBrand={selectedBrand}
      setSelectedSeller={setSelectedSeller}
      selectedSeller={selectedSeller}
    />
  );
  const categoryStep = (
    <div className="ml-2 flex">
      <span className="border-l border-gray-250 pl-2 self-center block font-bold pr-2">Шаг 1</span>
      {selectedCategory ? (
        <Chip
          title={selectedCategory?.title}
          action={(e) => {
            e.stopPropagation();
            setSelectedCategory();
            setSelectedBrand();
            setSelectedSeller();
          }}
          onChipClick={() => handleChipClick('category')}
          className="cursor-pointer"
          color="bg-blue"
        />
      ) : (
        <button
          onClick={() => handleChipClick('category')}
          className="py-1.5 px-3 rounded border border-gray-300 text-sm h-8 flex group"
        >
          Выберите категорию
          <DownIcon
            width={14}
            height={14}
            className="self-center -rotate-90 group-hover:text-green ml-1.5"
          />
        </button>
      )}
    </div>
  );
  const brandStep = (
    <>
      {selectedBrand ? (
        <Chip
          title={selectedBrand?.title}
          action={(e) => {
            e.stopPropagation();
            setSearchedBrand('');
            setSelectedBrand();
          }}
          onChipClick={() => handleChipClick('brand')}
          className="cursor-pointer"
          color="bg-green-light"
        />
      ) : (
        <div className="group relative" ref={tooltipRefBrands}>
          <button
            onClick={() => handleChipClick('brand')}
            disabled={!selectedCategory || (selectedCategory && selectedSeller)}
            className={cn('py-1.5 px-3 rounded border border-gray-300 text-sm h-8 flex group', {
              'opacity-50': !selectedCategory || (selectedCategory && selectedSeller),
            })}
          >
            Выберите бренд
            <DownIcon
              width={14}
              height={14}
              className="self-center -rotate-90 group-hover:text-green ml-1.5"
            />
          </button>
          {(!selectedCategory || (selectedCategory && selectedSeller)) && !isMobile && (
            <Popover
              offset={[0, !selectedCategory ? -90 : -130]}
              tooltip={
                !selectedCategory
                  ? 'Сначала выберите категорию'
                  : `Вы можете выбрать только \n
                        один из фильтров: либо по \n
                        бренду, либо по продавцу`
              }
              tooltipRef={tooltipRefBrands}
            />
          )}
        </div>
      )}
    </>
  );

  const sellerStep = (
    <>
      {selectedSeller ? (
        <Chip
          title={selectedSeller?.title}
          action={(e) => {
            e.stopPropagation();
            setSearchedSeller('');
            setSelectedSeller();
          }}
          onChipClick={() => handleChipClick('seller')}
          className="cursor-pointer"
          color="bg-yellow"
        />
      ) : (
        <div className="group relative" ref={tooltipRefSellers}>
          <button
            onClick={() => handleChipClick('seller')}
            disabled={!selectedCategory || (selectedCategory && selectedBrand)}
            className={cn(
              'py-1.5 px-3 rounded border border-gray-300 text-sm h-8 flex group relative',
              {
                'opacity-50': !selectedCategory || (selectedCategory && selectedBrand),
              },
            )}
          >
            Выберите продавца
            <DownIcon
              width={14}
              height={14}
              className="self-center -rotate-90 group-hover:text-green ml-1.5"
            />
          </button>
          {(!selectedCategory || (selectedCategory && selectedBrand)) && !isMobile && (
            <Popover
              offset={[0, !selectedCategory ? -90 : -130]}
              tooltip={
                !selectedCategory
                  ? 'Сначала выберите категорию'
                  : `Вы можете выбрать только \n
                        один из фильтров: либо по \n
                        бренду, либо по продавцу`
              }
              tooltipRef={tooltipRefSellers}
            />
          )}
        </div>
      )}
    </>
  );
  if (isMobile) {
    return (
      <div className="px-3 pt-2 whitespace-nowrap">
        <span className="block font-bold text-lg">Позиция и доля в категории</span>
        <div className="block w-full overflow-x-scroll py-3">
          <div className="inline-block">
            <div className="flex pt-2">
              <button
                onClick={() => setActiveModal('datePicker')}
                className="flex flex-none rounded border border-gray-250 justify-center items-center w-7 h-7 p-1 h-full mr-2"
              >
                <Calendar className="w-3 h-3 fill-gray-700" />
              </button>
              <span className="block w-px h-hull bg-gray-230 mr-2" />
              <div className="max-w-min">
                <PlatformSelect />
              </div>
              {categoryStep}
            </div>
          </div>
          <div className="inline-block">
            <div className="flex pt-2">
              <span className="border-l border-gray-250 pl-2 self-center block font-bold pr-2">
                Шаг 2
              </span>
              {brandStep}
            </div>
          </div>
          <div className="inline-block">
            <div className="flex pt-2">
              <span className="px-2 self-center block font-bold">или</span>
              {sellerStep}
            </div>
          </div>
          {modal}
        </div>
      </div>
    );
  }
  return (
    <div className="pb-4 border-b border-gray-230 px-5 pt-2">
      <div className="flex justify-between">
        <span className="block font-bold text-2xl">Позиция и доля в категории</span>
        <div className="flex space-x-3 pt-0.5">
          <TableHeaderDateControls checkIsAvailable={() => !!selectedCategory} daysRange={30} />
        </div>
      </div>
      <div className="mt-3 flex">
        <PlatformSelect
          callback={() => {
            setSelectedCategory();
            setSelectedBrand();
            setSelectedSeller();
          }}
        />
        {categoryStep}
        <div className="ml-2 flex">
          <span className="border-l border-gray-250 pl-2 self-center block font-bold pr-2">
            Шаг 2
          </span>
          {brandStep}
          <span className="px-2 self-center block font-bold">или</span>
          {sellerStep}
        </div>
        {modal}
      </div>
    </div>
  );
};

PositionsHeader.propTypes = {
  setSelectedCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelectedBrand: PropTypes.func.isRequired,
  selectedBrand: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  setSelectedSeller: PropTypes.func.isRequired,
  selectedSeller: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
};

export default PositionsHeader;
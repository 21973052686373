import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useUserTariffs } from 'context/user.tariffs.context';
import ListAlerts from 'components/ListAlerts';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import HelpBlock from 'components/HelpBlock';

const Products = () => {
  const { isOzonPlatform } = useFilterReducer();
  const { isSellersAvailable } = useUserTariffs();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.PRODUCTS,
  });

  const getData = () => {
    return {
      pages: data?.pages?.map((el) =>
        el.map(({ sales_chart, category, ...other }) => {
          const result = {};
          sales_chart.forEach(({ date, orders_rub }) => {
            result[`sales_chart/${date}`] = orders_rub;
          });
          return {
            ...other,
            sales_chart: JSON.stringify(result),
            category: category?.[0]?.name || '-',
          };
        }),
      ),
    };
  };
  return (
    <>
      <HelpBlock type="products" />
      <div className="relative">
        <Table
          data={getData()}
          type={TABLE_TYPE.PRODUCTS}
          hideSoldColumn={isOzonPlatform}
          isSellerCellClickable={isSellersAvailable}
          paginationOnBack={true}
          isLoading={isLoading || (isFetching && !hasNextPage) /* || favoriteLoading*/}
          noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
        />
      </div>
      <ListAlerts />
    </>
  );
};

export default Products;

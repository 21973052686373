const ChatWecheckIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 1.61914H3.5C2.11929 1.61914 1 2.73843 1 4.11914V12.1191C1 13.4999 2.11929 14.6191 3.5 14.6191H5V18.0971C5 18.1057 5.01012 18.1103 5.01658 18.1046L9 14.6191H16.5C17.8807 14.6191 19 13.4999 19 12.1191V4.11914C19 2.73843 17.8807 1.61914 16.5 1.61914Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChatWecheckIcon;

import SmartContent from 'components/SeoSmart';

const Smart = () => {
  return (
    <div>
      <SmartContent />
    </div>
  );
};
export default Smart;

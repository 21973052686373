const SwitcherIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 18H8C4.68629 18 2 15.3137 2 12C2 8.68629 4.68629 6 8 6H16M16 18C19.3137 18 22 15.3137 22 12C22 8.68629 19.3137 6 16 6M16 18C12.6863 18 10 15.3137 10 12C10 8.68629 12.6863 6 16 6"
        stroke="#219653"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwitcherIcon;

import PropTypes from 'prop-types';

import LinkIcon from 'assets/icons/LinkIcon';
import { useProductLink } from 'hooks/useLocationPath';

const TableCellWecheckProductLink = ({ value, valueFormatted }) => {
  const link = useProductLink(value);
  if (!value && !valueFormatted) return '';
  return (
    <a
      href={link}
      rel="noreferrer"
      target="_blank"
      className={
        'relative -top-[3px] cursor-pointer border border-green-200 hover:border-green bg-green-200 hover:bg-green rounded text-xs leading-none px-1.5 py-0.5 inline-block align-middle transition-all text-green hover:text-white'
      }
    >
      <span className="inline-block align-middle">WECHECK</span>
      <LinkIcon className="ml-1 h-2 w-2 inline-block align-middle" />
    </a>
  );
};
TableCellWecheckProductLink.defaultProps = {
  valueFormatted: '',
  value: '',
};

TableCellWecheckProductLink.propTypes = {
  valueFormatted: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default TableCellWecheckProductLink;

const LoopSuccess = (props) => {
  return (
    <svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1116_22889)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.60419 7.60419C7.51178 9.21931 5.33991 9.73766 3.62501 8.74756C1.83141 7.71202 1.21688 5.41856 2.25241 3.62496C3.21702 1.95421 5.27315 1.30654 7 2.06266V0.46756C4.72276 -0.213297 2.19362 0.726789 0.953374 2.87496C-0.496374 5.386 0.36397 8.59685 2.87501 10.0466C4.75689 11.1331 7.03184 10.9221 8.66395 9.69117C8.69542 9.74446 8.73405 9.7947 8.77984 9.84048L11.7197 12.7803C12.0126 13.0732 12.4874 13.0732 12.7803 12.7803C13.0732 12.4874 13.0732 12.0126 12.7803 11.7197L10.0177 8.95702L10 9L8.60419 7.60419Z"
          fill="#219653"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.55682 3.87494C4.86434 3.56742 5.36293 3.56742 5.67046 3.87494L6.4764 4.68089C6.59356 4.79805 6.78351 4.79805 6.90066 4.68089L9.94986 1.63174C10.2574 1.32422 10.756 1.32423 11.0635 1.63175C11.371 1.93928 11.371 2.43787 11.0635 2.74539L6.90066 6.90816C6.7835 7.02531 6.59355 7.02531 6.47639 6.90816L4.55682 4.98858C4.2493 4.68106 4.2493 4.18247 4.55682 3.87494Z"
          fill="#219653"
        />
      </g>
      <defs>
        <clipPath id="clip0_1116_22889">
          <rect {...props} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoopSuccess;

import { optionsWarehouseWb, optionsWarehouseOzon } from 'components/Filter/components';
import Checkbox from 'components/Checkbox';
import React, { useState } from 'react';
import cn from 'classnames';
import { useUI } from 'context/ui.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';

export const WarehousesPanel = () => {
  const { isOzonPlatform, filter, setWarehouse } = useFilterReducer();
  const { setActiveModal } = useUI();

  const [warehouses, setWarehouses] = useState(filter?.warehouse);

  const optionsWarehouse = isOzonPlatform ? optionsWarehouseOzon : optionsWarehouseWb;

  const changeWarehouses = (id) => {
    if (id === 'all') {
      return setWarehouses([]);
    }
    if (warehouses.includes('all')) {
      return setWarehouses([...warehouses.filter((i) => i !== 'all'), id]);
    }
    if (!warehouses.includes(id)) {
      return setWarehouses([...warehouses, id]);
    } else {
      return setWarehouses(warehouses.filter((i) => i !== id));
    }
  };

  const applyWarehouses = () => {
    setWarehouse(warehouses);
    setActiveModal('');
  };

  return (
    <div className="flex flex-col w-full grow pb-4">
      {optionsWarehouse.map((option, index) => (
        <div
          key={option.value}
          className={cn('border-b-[1px] mx-4 py-4', {
            ['border-b-[0px]']: index + 1 === optionsWarehouse.length,
          })}
        >
          <Checkbox
            label={option.label}
            onChange={() => changeWarehouses(option.value)}
            name={option.value}
            checked={
              warehouses.includes(option.value) || (option.value === 'all' && !warehouses.length)
            }
          />
        </div>
      ))}
      <button
        className="mt-auto mx-4 bg-green text-white shadow-sm items-center px-3 py-1.5 text-sm rounded"
        onClick={() => applyWarehouses()}
      >
        Применить
      </button>
    </div>
  );
};

import React from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';

/**
 * @typedef IProps
 *
 * @property {string} [classNames] Классы, которые будут дополнительно применены к Skeleton'у
 * */

/**
 * @return {JSX.Element} Универсальный Skeleton, обычно отображаемый при загрузке данных
 * */
export function Skeleton({ classNames }) {
  return (
    <div
      className={cn('bg-gray-250 animate-pulse min-w-4 w-full h-3 rounded', {
        [classNames]: classNames,
      })}
    />
  );
}

Skeleton.propTypes = {
  classNames: propTypes.string,
};

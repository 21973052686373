import { useFilterReducer } from 'context/filter/filter.context.reducer';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PositionsRating from 'components/Positions/PostitionsRating';
import { useFetchPositions } from 'hooks/useFetchPositions';
import LoadingSpin from 'components/LoadingSpin';
import { ProductGrid } from 'components/ProductGrid';
import cn from 'classnames';
import Popover from 'components/Popover';

const generateArrayOfDates = (date) => {
  const D = new Date(date[0]),
    Till = new Date(date[1]),
    result = [];
  Till.setDate(Till.getDate() + 1);

  const pad = (s) => ('00' + s).slice(-2);

  while (D.getTime() < Till.getTime()) {
    result.push('' + D.getFullYear() + '-' + pad(D.getMonth() + 1) + '-' + pad(D.getDate()));
    D.setDate(D.getDate() + 1);
  }
  return result;
};

const getEmptyMessage = (selectedCategory, selectedSeller, selectedBrand) => {
  if (selectedCategory && !selectedSeller && !selectedBrand) {
    return 'Попробуйте изменить категорию';
  } else if (selectedSeller) {
    return 'Попробуйте изменить категорию или продавца';
  } else {
    return 'Попробуйте изменить категорию или бренд';
  }
};

const PositionsContent = ({ selectedCategory, selectedBrand, selectedSeller }) => {
  const [searchAvaliable, setSearchAvaliable] = useState(false);
  const [arrayOfDays, setArrayOfDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const {
    filter: { date, platform },
  } = useFilterReducer();

  const { data, isLoading, isFetching, refetch } = useFetchPositions({
    date,
    platform,
    selectedCategory,
    selectedSeller,
    selectedBrand,
  });
  useEffect(() => {
    const result = selectedCategory && date && platform;
    if (result) {
      refetch();
    }
    setSearchAvaliable(result);
  }, [selectedBrand, selectedCategory, selectedSeller, date, platform, refetch]);

  useEffect(() => {
    const dates = date ? generateArrayOfDates(date) : [];
    setArrayOfDays(dates);
    if (dates.length !== 0) {
      setSelectedDay(dates[0]);
    }
  }, [date]);

  const selectDay = (day) => {
    setSelectedDay(day);
  };
  return (
    <div
      className={`w-full grow bg-gray-50 flex flex-col ${
        !searchAvaliable || data?.products?.length === 0 ? 'table' : ''
      }`}
    >
      {!searchAvaliable ? (
        <span className="block text-center mx-auto text-gray-400 text-normal table-cell align-middle">
          Выберите <span className="font-bold">Категорию</span>, а затем выберите
          <br />
          <span className="font-bold">Продавца</span> или <span className="font-bold">Бренд</span>,
          чтобы просмотреть
          <br />
          позиции и доли в категориях
        </span>
      ) : data?.products?.length === 0 ? (
        <span className="block text-center mx-auto text-gray-400 text-normal table-cell align-middle">
          <div>
            <span className="block font-medium text-2xl text-black mb-1">
              Нет подходящих данных
            </span>
            <span className="block">
              {getEmptyMessage(selectedCategory, selectedSeller, selectedBrand)}
            </span>
          </div>
        </span>
      ) : (
        <div className="px-3 py-3 lg:px-28 lg:py-4 grow flex flex-col">
          <div className="block whitespace-nowrap overflow-x-scroll lg:overflow-visible pb-3 lg:pb-0 lg:flex lg:justify-center lg:flex-wrap lg:gap-1">
            {arrayOfDays.map((d) => {
              const formattedD = moment(d).format('MMM D').split(' ');
              const isSelected = d === selectedDay;
              const disabled = !!(!data?.products?.[d] || !data?.products?.[d].length === 0);
              const ref = React.createRef();
              return (
                <div ref={ref} className="inline-block relative group" key={d}>
                  <button
                    className={cn(`border mr-1 rounded py-1.5 px-[5px] bg-white lg:mr-0`, {
                      'border-green': isSelected,
                      'border-gray-230': !isSelected,
                    })}
                    onClick={() => selectDay(d)}
                    disabled={disabled}
                  >
                    <span
                      className={cn(
                        `min-w-6 block border-b border-gray-230 pb-1 font-medium text-lg w-full text-center`,
                        {
                          'text-green': isSelected,
                          'text-black': !isSelected,
                          'opacity-20': disabled,
                        },
                      )}
                    >
                      {formattedD[1]}
                    </span>
                    <span
                      className={cn(`block pt-1 font-normal text-[10px] text-center`, {
                        'text-green': isSelected,
                        'text-black': !isSelected,
                        'opacity-20': disabled,
                      })}
                    >
                      {formattedD[0]}
                    </span>
                  </button>
                  {disabled && !isLoading && (
                    <Popover
                      offset={[0, -122]}
                      tooltip={'Нет данных на этот день'}
                      tooltipRef={ref}
                      className="whitespace-nowrap"
                    />
                  )}
                </div>
              );
            })}
          </div>
          {isLoading || isFetching ? (
            <div className="grow flex">
              <LoadingSpin classNames="self-center" />
            </div>
          ) : (
            <>
              {data && (selectedBrand || selectedSeller) && (
                <PositionsRating
                  selectedSeller={selectedSeller}
                  selectedBrand={selectedBrand}
                  rating={
                    selectedBrand ? data.brands_part[selectedDay] : data.sellers_part[selectedDay]
                  }
                />
              )}
              <div className="mt-4">
                <ProductGrid
                  searchData={data?.products?.[selectedDay] || []}
                  showFullInfo={false}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

PositionsContent.propTypes = {
  selectedCategory: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  selectedBrand: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
  selectedSeller: PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    key: PropTypes.number,
  }),
};

export default PositionsContent;

import { useUI } from 'context/ui.context';

const ApiKeysHeader = () => {
  const { isMobile } = useUI();
  return (
    <div className="w-full border border-gray-150 bg-white rounded-lg p-6 divide-y mb-4">
      <span className="block font-bold text-2xl leading-9 pb-3 text-gray-700">
        {`Добавьте `} {isMobile && <br />}
        {`API-ключ, чтобы проанализировать собственные продажи на ${
          isMobile ? 'Wildberries' : 'WB'
        }`}
      </span>
      <span className="block pt-3 text-base text-gray-400 font-normal">
        {/*{!isMobile ? (*/}
        <span>
          Авторизация осуществляется по токенам API, которые владелец личного кабинета (главный
          пользователь) самостоятельно генерирует в разделе
          <a
            href="https://seller.wildberries.ru/supplier-settings/access-to-api"
            target="_blank"
            rel="noreferrer"
            className="text-green lg:ml-1.5 hover:underline inline-block lg:mx-1"
          >
            Профиль → Настройки → Доступ к новому API.
          </a>
          Обратите внимание, что токен отображается ТОЛЬКО в момент создания. Его надо сохранить,
          потому что больше его отобразить будет нельзя
        </span>
        {/*) : (
          <>
            Что такое API-ключи и как их получить? Прочитайте на
            <a
              href="https://openapi.wb.ru/"
              target="_blank"
              rel="noreferrer"
              className="text-green ml-1.5 hover:underline focus:underline"
            >
              Wildberries
            </a>
          </>
        )}*/}
      </span>
    </div>
  );
};

export default ApiKeysHeader;

export const getCheckedApiKeys = ({ apiKeys }) => {
  return apiKeys?.length ? apiKeys : [-1];
};

export const getItemNameById = ({ id, data }) => {
  if (!id || !data || !data.length) return null;
  if (id === -1) {
    return 'Все ключи';
  }
  const item = data.find((item) => item?.id === id);

  return item?.name || `${id}`;
};

const MagicIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33446 14.6048L3.38721 14.8723V14.8722C3.56919 15.8174 4.02413 16.6862 4.69432 17.368C5.36451 18.0498 6.21944 18.5135 7.15028 18.7001L7.41388 18.7536C8.10082 18.8878 8.8087 18.6674 9.30313 18.1651L19.0298 8.30789C19.5238 7.80634 19.7408 7.08907 19.6096 6.39252L19.5568 6.12495V6.12514C19.3749 5.17987 18.9199 4.31108 18.2497 3.6293C17.5795 2.94752 16.7246 2.48384 15.7938 2.29721L15.5302 2.24366C14.8432 2.1095 14.1353 2.32987 13.6409 2.83215L3.9142 12.6894C3.42029 13.191 3.20321 13.9082 3.33446 14.6048ZM15.1218 4.34628L15.3854 4.39983C15.9014 4.50302 16.3754 4.75958 16.7475 5.13669C17.1195 5.51397 17.3728 5.99483 17.475 6.5184L17.5278 6.78598L15.9177 8.41771L13.5117 5.97804L15.1218 4.34628ZM12.0229 7.48941L14.4393 9.94236L7.82263 16.6513L7.55903 16.5977C7.04305 16.4945 6.569 16.238 6.19696 15.8609C5.82492 15.4836 5.57162 15.0027 5.46941 14.4791L5.41666 14.2116L12.0229 7.48941Z"
        fill="currentColor"
      />
      <path
        d="M2.25467 4.74698L3.08141 6.26233C3.16999 6.42563 3.33907 6.52707 3.52255 6.52707C3.70633 6.52707 3.8754 6.42562 3.96399 6.26233L4.78204 4.74667L6.26284 3.90827C6.4238 3.8184 6.52379 3.64688 6.52379 3.46074C6.52379 3.27429 6.42379 3.10278 6.26284 3.0129L4.76881 2.183L3.94239 0.680754C3.8538 0.517458 3.68473 0.416016 3.50125 0.416016C3.31746 0.416016 3.1484 0.517466 3.0598 0.680754L2.24176 2.19642L0.760958 3.03481C0.599994 3.12468 0.5 3.29621 0.5 3.48235C0.5 3.66879 0.600001 3.84031 0.760958 3.93018L2.25467 4.74698Z"
        fill="currentColor"
      />
      <path
        d="M20.4941 15.632L19.5496 13.9002C19.4483 13.7136 19.2551 13.5977 19.0451 13.5977C18.8354 13.5977 18.6421 13.7136 18.5409 13.9002L17.6059 15.6324L15.9131 16.5906C15.7295 16.6933 15.6152 16.8893 15.6152 17.102C15.6152 17.3151 15.7295 17.5111 15.9131 17.6138L17.6207 18.5623L18.5656 20.2792C18.6665 20.4658 18.86 20.5817 19.0697 20.5817C19.2798 20.5817 19.473 20.4658 19.5739 20.2792L20.5089 18.547L22.2017 17.5888C22.3856 17.4861 22.4996 17.2901 22.4996 17.0773C22.4996 16.8643 22.3856 16.6682 22.2017 16.5655L20.4941 15.632Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MagicIcon;

// Иконка сортировки (параметр order определает, в какую сторону будет направлена стрелочка)
import PropTypes from 'prop-types';

export default function SortIcon({ order, isSorted, ...props }) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 15 15"
      style={{ transform: isSorted && order === 'desc' ? 'scale(1, -1)' : '' }}
    >
      <rect width="10" height="1.5" transform="matrix(1 0 0 -1 0 11.4976)" fill="currentColor" />
      <path d="M4 7.49756H10V5.99756H4V7.49756Z" fill="currentColor" />
      <path d="M7 3.49756H10V1.99756H7V3.49756Z" fill="currentColor" />
    </svg>
  );
}
SortIcon.propTypes = {
  isSorted: PropTypes.bool,
  order: PropTypes.oneOf(['desc', 'asc']),
};

export const MODES = {
  INSERT: 'insert',
  RESULT: 'result',
};

export const MONITORING_GROUPS_MODAL_MODES = {
  INFO: 'info',
  RENAME: 'rename',
  DELETE: 'delete',
};

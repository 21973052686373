const LinkIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="3"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M20.8,15.9V3.2H8" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M20,4L3.2,20.8" />
  </svg>
);
export default LinkIcon;

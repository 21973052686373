import ButtonLabel from 'components/ButtonLabel';

import { PERIOD_MONTH, PERIOD_WEEK, PERIOD_DAY } from 'constants/chart';
import { useDynamic } from 'context/dynamic.context';

const LABELS = {
  [PERIOD_DAY]: 'День',
  [PERIOD_WEEK]: 'Неделя',
  [PERIOD_MONTH]: 'Месяц',
};

const PeriodSwitcher = () => {
  const { periods, periodActive, periodsAvailable, handlePeriod, isDataLoading } = useDynamic();

  if (!periods.length) {
    return <></>;
  }

  return (
    <div className="flex ml-auto items-center pl-2">
      <span className="text-xs pr-2 mr-2 border-r border-gray-200">Шаг</span>

      {periods.map((period) => (
        <ButtonLabel
          key={period}
          onClick={() => handlePeriod(period)}
          label={LABELS[period]}
          active={period === periodActive}
          disabled={isDataLoading || !periodsAvailable.includes(period)}
        />
      ))}
    </div>
  );
};

export default PeriodSwitcher;

export default function ArrowDirRight(props) {
  return (
    <svg width="14" height="24" viewBox="0 0 14 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03137 9.82628C9.20108 9.82628 9.36385 9.8937 9.48386 10.0137C9.60386 10.1337 9.67128 10.2965 9.67128 10.4662V14.2224C9.67128 14.5586 9.9438 14.8311 10.28 14.8311C10.6161 14.8311 10.8887 14.5586 10.8887 14.2224V10.4662C10.8887 9.9736 10.693 9.50119 10.3447 9.15288C9.99637 8.80457 9.52396 8.60889 9.03137 8.60889H5.27515C4.93897 8.60889 4.66645 8.88141 4.66645 9.21758C4.66645 9.55376 4.93897 9.82628 5.27515 9.82628H9.03137Z"
      />
      <path
        d="M9.72217 9.77539L3.88884 15.6087"
        stroke="#B9B9B9"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import ListAlerts from 'components/ListAlerts';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';

const Products = () => {
  const { isOzonPlatform } = useFilterReducer();
  const { isSellersAvailable } = useUserTariffs();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.PRODUCTS_GROWTH,
  });

  return (
    <>
      <Table
        data={data}
        type={TABLE_TYPE.PRODUCTS_GROWTH}
        hideSoldColumn={isOzonPlatform}
        isSellerCellClickable={isSellersAvailable}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage) /* || favoriteLoading*/}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Products;

import { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useCopyToClipboard from 'hooks/useCopyToClipboard';
import apiClient from 'services/baseClient';
import { ButtonGroup, ButtonItem } from 'components/ButtonGroup';
import {
  ReferralsPaymentHistoryTable,
  ReferralsRegistrationsTable,
  ReferralTab,
} from './components';
import Modal from 'components/Modal';
import { useUI } from 'context/ui.context';
import { MobileInterfaceV2 } from 'components/MobileInterface/MobileInterface';

function ModalParagraph({ children, ...pProps }) {
  return (
    <p
      className="font-SofiaPro text-medium text-lg leading-[1.625rem] p-2 text-[rgba(51,_61,_55,_1)]"
      {...pProps}
    >
      {children}
    </p>
  );
}

ModalParagraph.propTypes = {
  children: PropTypes.node.isRequired,
};

const tabsMap = {
  referrals: 'referrals',
  paymentReferrals: 'payment-referrals',
};

function ProfileReferralsPage() {
  const [referralLink, setReferralLink] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(tabsMap.paymentReferrals);
  const [isShowHelp, setIsShowHelp] = useState(false);
  const { isMobile } = useUI();

  const [wasCopied, setWasCopied] = useState(false);

  const onHelpClick = () => {
    setIsShowHelp((prev) => !prev);
  };

  const isMyReferralsRegistrationsActive = useMemo(() => {
    return activeTab === tabsMap.referrals;
  }, [activeTab]);

  const isMyReferralsPaymentsActive = useMemo(() => {
    return activeTab === tabsMap.paymentReferrals;
  }, [activeTab]);

  const copyToClipboard = useCopyToClipboard(referralLink.refLink);

  const onClickMyReferrals = () => {
    setActiveTab(tabsMap.referrals);
  };

  const onClickPayments = () => {
    setActiveTab(tabsMap.paymentReferrals);
  };

  const handleCopy = () => {
    copyToClipboard();
    setWasCopied(true);
  };

  const handleAfterCopy = useCallback(() => {
    if (!wasCopied) return;

    return setTimeout(() => setWasCopied(false), 5000);
  }, [wasCopied]);

  useEffect(() => {
    apiClient.getReferralMe().then((data) => {
      setReferralLink(data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const timer = handleAfterCopy();

    return () => clearTimeout(timer);
  }, [handleAfterCopy]);

  return (
    <div>
      <article className="relative flex flex-col mb-2 lg:mb-4 mt-2 lg:mt-7 lg:mb-8 lg:mt-[52px] px-3 lg:px-8">
        <div className="max-w-4xl gap-8 grid">
          <header className="flex flex-col gap-2 lg:gap-8 leading-7 text-left">
            <h2 className="text-lg font-bold lg:text-2xl">Реферальная программа</h2>
            <p className="text-gray-bright text-xl leading-8">
              Если пользователь перейдет по вашей ссылке и зарегистриуется в течение 30 дней, вы
              будете пожизненно получать 10% от всех его оплат.
            </p>
          </header>
          <section className="flex flex-col flex-grow gap-5 md:flex-row">
            <ReferralTab title="Ваша реферальная ссылка" isLoading={isLoading} isWide>
              <p className="text-gray-bright">{referralLink.refLink}</p>
              <button
                disabled={isLoading}
                className={cn('w-fit', {
                  ['text-gray-bright']: wasCopied,
                  ['text-green']: !wasCopied,
                })}
                onClick={handleCopy}
              >
                {wasCopied ? 'Скопировано' : 'Скопировать'}
              </button>
            </ReferralTab>
            <ReferralTab title="Всего заработано" isLoading={isLoading}>
              <p className="text-green text-2xl leading-6">{referralLink.wallet} ₽</p>
            </ReferralTab>
            <ReferralTab title="Правила">
              <button
                type="button"
                onClick={onHelpClick}
                className="flex text-green w-fit text-base"
              >
                <span>Ознакомиться</span>
                <div className="h-2.5 relative top-0.5 left-1">→</div>
              </button>
            </ReferralTab>
          </section>
          <ButtonGroup>
            <ButtonItem
              onClick={onClickPayments}
              title="Оплаты"
              isActive={isMyReferralsPaymentsActive}
            />

            <ButtonItem
              onClick={onClickMyReferrals}
              title="Регистрации"
              isActive={isMyReferralsRegistrationsActive}
            />
          </ButtonGroup>
        </div>

        {!isMobile && (
          <Modal
            isOpen={isShowHelp}
            title="Правила проведения реферальной программы"
            closeModal={() => setIsShowHelp(false)}
            footer={false}
            classNames="bg-gray-50 max-h-[70vh] overflow-y-scroll"
          >
            <div className="grid gap-8 justify-items-center">
              <div>
                <ModalParagraph>
                  Приглашайте на <strong>WeCheck</strong> новых пользователей и получайте{' '}
                  <strong>10%</strong> с каждой их покупки. <strong>Навсегда.</strong>
                </ModalParagraph>
                {/*<ModalParagraph>*/}
                {/*  Например, если ваш коллега оплатит тариф Pro на 6 месяцев, вы получите 4140*/}
                {/*  рублей. Со всех его будущих покупок вам будет начисляться еще <strong>10%</strong>{' '}*/}
                {/*  их стоимости — и так <strong>до бесконечности.</strong>*/}
                {/*</ModalParagraph>*/}
                <ModalParagraph>
                  Делитесь сервисом с другими предпринимателями и экономьте!
                </ModalParagraph>
                <ModalParagraph>
                  Как присоединиться к реферальной программе
                  <br />
                  <ul className="list-decimal ml-8 grid gap-2">
                    <li>
                      Скопируйте ссылку в <strong>Личном кабинете</strong> и поделитесь ею с
                      коллегами.
                    </li>
                    <li>
                      Если пользователь перейдет по вашей ссылке и оплатит тариф в течение{' '}
                      <strong>30 дней</strong>, вы будете получать <strong>10%</strong> от стоимости
                      каждой его покупки.
                      <br />
                      <br />
                      <strong>Будьте внимательны:</strong> если ваш коллега перейдет по реферальным
                      ссылкам от нескольких пользователей, бонус получит владелец первой ссылки.
                    </li>
                    <li>
                      Следите за списком привлеченных пользователей в личном кабинете, в разделе{' '}
                      <strong>“Реферальная программа”:</strong> там отображаются email привлеченных
                      пользователей, даты покупок и сумма накопленных бонусов.
                    </li>
                  </ul>
                </ModalParagraph>
              </div>
              <button
                onClick={onHelpClick}
                type="button"
                className="font-bold text-white font-SofiaPro text-[1.25rem] leading-[1.375rem] w-fit flex items-center justify-center gap-[0.625rem] py-[1.125rem] px-8 rounded-[100px] bg-[rgba(33,_150,_83,_1)]"
              >
                Закрыть
              </button>
            </div>
          </Modal>
        )}
        {isMobile && (
          <MobileInterfaceV2
            rootClassName="absolute h-[90vh] bottom-0 overflow-y-scroll"
            headerText="Правила проведения реферальной программы"
            isActive={isShowHelp}
            onPanelClose={onHelpClick}
          >
            <div className="grid gap-8 justify-items-center p-4">
              <div className="mb-10">
                <ModalParagraph>
                  Приглашайте на <strong>WeCheck</strong> новых пользователей и получайте{' '}
                  <strong>10%</strong> с каждой их покупки. <strong>Навсегда.</strong>
                </ModalParagraph>
                {/*<ModalParagraph>*/}
                {/*  Например, если ваш коллега оплатит тариф Pro на 6 месяцев, вы получите 4140*/}
                {/*  рублей. Со всех его будущих покупок вам будет начисляться еще <strong>10%</strong>{' '}*/}
                {/*  их стоимости — и так <strong>до бесконечности.</strong>*/}
                {/*</ModalParagraph>*/}
                <ModalParagraph>
                  Делитесь сервисом с другими предпринимателями и экономьте!
                </ModalParagraph>
                <ModalParagraph>
                  Как присоединиться к реферальной программе
                  <br />
                  <ul className="list-decimal ml-8 grid gap-2">
                    <li>
                      Скопируйте ссылку в <strong>Личном кабинете</strong> и поделитесь ею с
                      коллегами.
                    </li>
                    <li>
                      Если пользователь перейдет по вашей ссылке и оплатит тариф в течение{' '}
                      <strong>30 дней</strong>, вы будете получать <strong>10%</strong> от стоимости
                      каждой его покупки.
                      <br />
                      <br />
                      <strong>Будьте внимательны:</strong> если ваш коллега перейдет по реферальным
                      ссылкам от нескольких пользователей, бонус получит владелец первой ссылки.
                    </li>
                    <li>
                      Следите за списком привлеченных пользователей в личном кабинете, в разделе{' '}
                      <strong>“Реферальная программа”:</strong> там отображаются email привлеченных
                      пользователей, даты покупок и сумма накопленных бонусов.
                    </li>
                  </ul>
                </ModalParagraph>
              </div>
              <div className="fixed bottom-0 left-0 right-0 p-4 bg-white">
                <button
                  onClick={onHelpClick}
                  type="button"
                  className="font-normal text-white font-SofiaPro text-[0.875rem] leading-[1.375rem] w-full flex items-center justify-center gap-[0.625rem] py-2 px-3 rounded-[4px] bg-[rgba(33,_150,_83,_1)]"
                >
                  Закрыть
                </button>
              </div>
            </div>
          </MobileInterfaceV2>
        )}
      </article>
      <div>
        {isMyReferralsPaymentsActive && <ReferralsPaymentHistoryTable />}
        {isMyReferralsRegistrationsActive && <ReferralsRegistrationsTable />}
      </div>
    </div>
  );
}

ProfileReferralsPage.propTypes = {};

export default ProfileReferralsPage;

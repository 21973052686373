import React, { useEffect, useRef, useState } from 'react';
import { MODES, TABS } from './constants';
import ButtonPrimary from '../ButtonPrimary';
import MagicIcon from 'assets/icons/MagicIcon';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { COLUMNS, SEO_SMART, TABLE_TYPE } from '../../constants';
import { useHistory, useLocation } from 'react-router';
import cn from 'classnames';
import Table from '../Table';
import PropTypes from 'prop-types';
import { useAuth } from 'context/auth.context';
import Popover from '../Popover';

const SmartContent = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const productURL = searchParams.get('product');
  const [mode, setMode] = useState(productURL ? MODES.RESULT : MODES.INSERT);
  const [product, setProduct] = useState(productURL || '');
  const [activeTab, setActiveTab] = useState(TABS.REQUESTS);
  const { noTariff } = useAuth();
  const location = useLocation();
  const history = useHistory();

  const {
    data = {},
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_SMART,
    filter: {
      platform: '2',
      product: [product],
    },
    //order: filter.order,
    //sort: filter.sort,
  });
  // console.log(data);
  const handleSmart = (newProduct) => {
    setProduct(newProduct);
    const params = new URLSearchParams({
      ['product']: newProduct,
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setMode(MODES.RESULT);
    refetch();
  };

  useEffect(() => {
    if (productURL && !noTariff) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productURL, refetch /*, filter.sort, filter.order*/]);
  return (
    <div className="mt-20 flex flex-col items-center">
      <span className="font-bold block text-7xl leading-[79px] text-[#03261D]">
        Умный подбор ключевых слов
      </span>
      <span className="font-bold block text-5xl leading-[53px] text-[rgba(13,_48,_39,_0.4)]">
        для продвижения товаров в топ
      </span>
      <span className="mt-10 block text-[#365C5A] text-2xl text-center">
        WeCheck проанализирует категорию товара,
        <br />
        изучит конкурентов и покажет наиболее важные слова и фразы,
        <br />
        которые повысят его видимость и увеличат продажи
      </span>
      <div className="relative mt-[60px]">
        <div
          className="w-[680px] h-[105px] absolute top-[10px] -z-10"
          style={{
            background: 'linear-gradient(90.05deg, #00B8C4 0.85%, #E17878 50.4%, #FFC121 99.96%)',
            borderRadius: 70,
            filter: 'blur(50px)',
          }}
        />
        <SmartInput product={product} handleSmart={handleSmart} />
        {mode === MODES.RESULT && (
          <div className="w-[710px] mt-5 bg-[#FAFAFA] rounded-3xl shadow-[0px_4px_40px_rgba(136,_186,_172,_0.4)]">
            <div className="p-6 bg-white rounded-t-3xl">
              <div className="flex cursor-pointer">
                <button
                  className={cn('border border-grey rounded-l py-1 px-2', {
                    'text-green bg-[#F1FCF6]': activeTab === TABS.REQUESTS,
                  })}
                  onClick={() => setActiveTab(TABS.REQUESTS)}
                >
                  Запросы
                </button>
                <button
                  className={cn('border border-grey rounded-r py-1 px-2', {
                    'text-green bg-[#F1FCF6]': activeTab === TABS.WORDS,
                  })}
                  onClick={() => setActiveTab(TABS.WORDS)}
                >
                  Слова
                </button>
              </div>
            </div>
            {activeTab === TABS.REQUESTS && (
              <Table
                data={data[activeTab] || []}
                type={TABLE_TYPE.SEO_SMART_REQUESTS}
                isLoading={isLoading || isFetching}
                noRowsText={`По вашему запросу нет данных.`}
                showSidebar={false}
              />
            )}
            {activeTab === TABS.WORDS && (
              <Table
                data={data[activeTab] || []}
                pagination={true}
                isLoading={isLoading || isFetching}
                type={TABLE_TYPE.SEO_SMART_WORDS}
                typeNested={TABLE_TYPE.SEO_SMART_WORDS_INNER}
                nested={COLUMNS.SIMILAR_PHRASES}
                autoSizeColumns={[COLUMNS.WORD]}
                hideFilterPanel={true}
                autoSizeNestedColumns={[COLUMNS.NAME]}
                noRowsText={'Данные отсутствуют'}
                nestedCheckbox={true}
                nestedParentColumn={COLUMNS.WORD}
                showSidebar={false}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const SmartInput = ({ product, handleSmart }) => {
  const [changedProduct, setChangedProduct] = useState(product);
  const { noTariff } = useAuth();
  const tooltipNoTariffRef = useRef();

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSmart(changedProduct);
    }
  };
  return (
    <div className="w-[710px] p-6 bg-white flex rounded-3xl z-10 space-x-4">
      <input
        value={changedProduct || ''}
        onChange={(e) => setChangedProduct(e.target.value)}
        onKeyDown={handleKeyDown}
        type="text"
        autoFocus={true}
        placeholder={'Введите артикул или ссылку на товар ...'}
        className="block p-4 bg-[#F1F1F1] grow rounded-lg w-[420px] placeholder:italic placeholder:text-gray-300 focus:outline-none focus:shadow-outline focus:ring-1 focus:ring-offset-0 focus:ring-green z-10"
      />
      <div className="relative w-fit">
        <div ref={tooltipNoTariffRef}>
          <ButtonPrimary
            onClick={() => handleSmart(changedProduct)}
            label={
              <div className="flex space-x-3 items-center">
                <span className="block">Получить подбор</span>
                <MagicIcon width={22} height={20} className="relative top-[1px]" />
              </div>
            }
            className="text-base rounded-lg h-[52px]"
            disabled={!changedProduct || noTariff}
          />
          {noTariff && (
            <Popover
              tooltipRef={tooltipNoTariffRef}
              className="whitespace-nowrap"
              tooltip="Чтобы воспользоваться сервисами SEO, необходимо оплатить тарифный план"
            />
          )}
        </div>
      </div>
    </div>
  );
};

SmartInput.propTypes = {
  handleSmart: PropTypes.func,
  product: PropTypes.string,
};

export default SmartContent;

import PropTypes from 'prop-types';
import React from 'react';
import { CustomVideoPlayer } from '../CustomVideoPlayer';

const InDaysContent = (props) => {
  const { label } = props;
  return (
    <div className="pt-2 px-12 pb-6">
      <span className="block font-bold text-[48px] leading-[60px] text-[#213A2B]">{label}</span>
      <div className="mt-6">
        <span className="block text-base text-[#A6B0AA] mb-2">Урок на видео</span>
        <CustomVideoPlayer videoId={'DJsHWO2kcMU'} maxHeight={400} />
      </div>
    </div>
  );
};

InDaysContent.propTypes = {
  label: PropTypes.string,
};

export default InDaysContent;

import PropTypes from 'prop-types';

import DownIcon from 'assets/icons/DownIcon';

export const PlatformMobileButton = ({ label, onClick, type, bg }) => (
  <button
    className={`bg-${bg} flex text-white whitespace-nowrap shadow-sm inline-flex items-center w-full px-2 py-1 text-sm font-medium rounded hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 cursor-pointer`}
    onClick={(e) => onClick(e, type)}
  >
    <span className="mr-auto">{label}</span>
    <DownIcon className="w-3 h-3 ml-2" aria-hidden="true" />
  </button>
);

PlatformMobileButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  bg: PropTypes.string,
};

import React, { useState } from 'react';
import { AUTHENTICATION_STORAGE_KEY, AUTHENTICATION_STORAGE_TOKEN } from 'constants/authentication';

const setToken = () => {
  window.localStorage.setItem(AUTHENTICATION_STORAGE_KEY, AUTHENTICATION_STORAGE_TOKEN);

  return AUTHENTICATION_STORAGE_TOKEN;
};

const getToken = () => window.localStorage.getItem(AUTHENTICATION_STORAGE_KEY);

const removeToken = () => {
  window.localStorage.removeItem(AUTHENTICATION_STORAGE_KEY);
};

const FrontAuthenticationContext = React.createContext();
FrontAuthenticationContext.displayName = 'Front.Authentication.Context';

const checkUser = () => {
  const token = getToken();

  return token === AUTHENTICATION_STORAGE_TOKEN;
};

const FrontAuthenticationProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(checkUser());
  const [error, setError] = useState(null);

  const login = async ({ username, password }) => {
    if (
      username !== process.env.REACT_APP_FRONT_LOGIN ||
      password !== process.env.REACT_APP_FRONT_PASSWORD
    ) {
      setError('Wrong login or password!');
      removeToken();
      setIsAuthenticated(false);
    } else {
      setToken();
      setIsAuthenticated(true);
    }
  };

  const logout = () => {
    removeToken();
  };

  const value = { isAuthenticated, error, login, logout };

  return <FrontAuthenticationContext.Provider value={value} {...props} />;
};

const useFrontAuthentication = () => {
  return React.useContext(FrontAuthenticationContext);
};

export { FrontAuthenticationProvider, useFrontAuthentication };

import { useEffect, useState } from 'react';
import { MODES } from './constants';
import { useHistory, useLocation } from 'react-router';
import HintsInsert from './HintsInsert';
import { useQuerySeo } from 'hooks/useFetchSeo';
import { SEO_HINTS, PAGE_SEO_HINTS } from '../../constants';
import HintsResult from './HintsResult';

const HintsContent = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const phraseURL = searchParams.get('phrase');
  const [mode, setMode] = useState(phraseURL ? MODES.RESULT : MODES.INSERT);
  const [phrase, setPhrase] = useState(phraseURL || '');
  const location = useLocation();
  const history = useHistory();

  const {
    data = {},
    refetch,
    isLoading,
    isFetching,
  } = useQuerySeo({
    type: SEO_HINTS,
    filter: {
      platform: '2',
    },
    restricts: {
      search: [phrase.toLowerCase()],
    },
  });

  useEffect(() => {
    if (phraseURL) {
      refetch();
    }
  }, [phraseURL, refetch]);

  const handleHints = () => {
    const params = new URLSearchParams({
      ['phrase']: phrase,
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
    setMode(MODES.RESULT);
    refetch();
  };

  const handleNewHints = () => {
    setPhrase('');
    history.push(PAGE_SEO_HINTS);
    setMode(MODES.INSERT);
  };

  if (mode === MODES.INSERT) {
    return <HintsInsert phrase={phrase} setPhrase={setPhrase} handleHints={handleHints} />;
  }
  return (
    <HintsResult handleNewHints={handleNewHints} data={data} isLoading={isLoading || isFetching} />
  );
};

export default HintsContent;
